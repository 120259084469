const RouteConstant: any = {
  pageLayout: "/",
  userDashboard: "/",
  managerDashboard: "/manager-dashboard",
  wellName: "/well-details/:wellName",
  preInspectionSummary: "/difa-details/pre-inspection-summary",
  dismentalRequest: "/difa-details/dimental-request",
  documentation:"/documentation",
};

export default RouteConstant;
