import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCauseOfFailure } from "../../../difaPacketBuilder/difaPacketBuilderSlice";
import RecommendationList from "./RecommendationList";
import CauseOfFailurePreview from "./CauseOfFailurePreview";

const primaryFailedItems: string[] = [
    "ESP Cable",
    "ESP Motor",
    "ESP Pump",
    "ESP Pump Intake / Gas Separator",
    "ESP Protector",
    "Other ESP System Component",
    "Unknown",
];

const primaryFailedSubComponents: Record<string, string[]> = {
    "ESP Cable": [
        "Clamps/Straps",
        "Main Power Cable",
        "Motor Lead Extension",
        "Packer Penetrator",
        "Splices",
        "Wellhead Penetrator",
        "ESP Cable – Unknown Subcomponent",
    ],
    "ESP Motor": [
        "Base",
        "Coupling",
        "Bushings (Head/Base)",
        "Motor End Connectors",
        "Oil",
        "O-rings and Other Seals",
        "Retaining Rings",
        "Rotor Bearing",
        "Rotors",
        "Shaft",
        "Stator",
        "Thrust Bearing",
        "End Coil/Leads",
        "Varnish",
        "Unknown Subcomponent",
    ],
    Unknown: ["Unknown Subcomponent"],
    "ESP Pump": [
        "Base",
        "Coupling",
        "Bushings (Head/Base)",
        "Head (or Head/Discharge)",
        "Housing",
        "Impellers",
        "Impellers/Diffusers",
        "O-rings and Other Seals",
        "Retaining Rings",
        "Shaft",
        "Shaft Bearings Sleeve (Head/Base)",
        "Thrust Washers",
        "AR Bearing",
        "Keystock",
        "Bearing Support (Head/Base)",
        "Unknown Subcomponent"
    ],
    "ESP Pump Intake / Gas Separator": [
        "Base/Intake",
        "Coupling",
        "Discharge Ports",
        "Head",
        "Housing",
        "Impellers/Propeller",
        "Inducer Section",
        "Intake Ports/Screen",
        "Mechanical Seals",
        "Oil",
        "O-rings and Other Seals",
        "Radial Bearings",
        "Relief Valves",
        "Shaft",
        "Shaft Bearings Sleeve (Head/Base)",
        "Unknown Subcomponent"

    ],
    "ESP Protector": [
        "Bag/Bellows Chamber Assembly",
        "Base",
        "Bearing Support",
        "Coupling",
        "Port/Fill Valve",
        "Head",
        "Housing",
        "Labyrinth Chamber",
        "Mechanical Seals",
        "Oil",
        "O-rings and Other Seals",
        "Radial Bearings",
        "Relief Valves",
        "Shaft",
        "Thrust Bearing",
        "Unknown Subcomponent"

    ],
    "Other ESP System Component": [
        "Downhole Sensors",
        "Shroud",
        "Tubing",
        "CT Line",
        "Sandguard",
        "Desander",
        "Non-ESP' Component",
        "Other"

    ],
    "Unknown ": ["Unknown"]
};

const failureCategories: string[] = [
    "Electrical",
    "External",
    "Material",
    "Mechanical",
    "Other",
    "Unknown",
];

const failureSubComponentDescriptors: Record<string, string[]> = {
    "Electrical": [
        "Failed Hypot Test",
        "High Impedance/Resistance",
        "Low Impedance/Resistance",
        "Open Circuit",
        "Short Circuit",
        "Phase Unbalance"
    ],
    "External": [
        "Coated – External",
        "Coated – Internal",
        "Contaminated",
        "Stuck Closed",
        "Stuck Open",
        "Plugged",
        "Material",
    ],
    "Material": [
        "Brittle",
        "Burn",
        "Corroded",
        "Discolored",
        "Eroded/Pressure Washed",
        "Hardened",
        "Melted",
        "Overheated",
        "Swollen",
        "Worn",
        "Parted",
    ],
    "Mechanical": [
        "Bent",
        "Broken/Fractured",
        "Buckled",
        "Burst/Ruptured",
        "Collapsed",
        "Cracked",
        "Damaged",
        "Dented",
        "Disconnected",
        "Failed Pressure Test",
        "Failed Vibration Test",
        "Faulty Clearance or Alignment",
        "Leaking",
        "Loose/Spinning",
        "Low Efficiency",
        "Punctured",
        "Scratched",
        "Squashed/Flattened",
        "Stuck",
        "Torn",
        "Twisted",
        "Vibration/Rub Marks",
        "Vibration/Unbalanced",
    ],
    "Other": [
        "Maintenance Discard",
        "Missing",
        "Other"
    ],
    "Unknown": [
        "Unknown"
    ]
};

const genericCauseOfFailure: string[] = [
    "System Design/Selection",
    "Manufacturing",
    "Storage and Transportation",
    "Installation",
    "Operation",
    "Reservoir or Fluids",
    "Completion",
    "Normal or Expected Wear and Tear",
    "Technology Limitation",
    "Well Construction",
    "Facilities",
    "Other",
    "Unknown",
];

const specificFailureCause: Record<string, string[]> = {
    "System Design/Selection": [
        "Equipment Selection",
        "Equipment Selection – Materials",
        "Improper Data Used in Design/Selection",
        "Equipment Selection – Pressure Capacity",
        "Equipment Selection – Volumetric Capacity",
        "System Configuration",
    ],
    "Manufacturing": [
        "Equipment Testing",
        "Fabrication Problem",
        "Materials Selection",
        "Mechanical Design",
        "Quality Control",
    ],
    "Storage and Transportation": [
        "Packaging or Restraints",
        "Storage",
        "Transportation",
    ],
    "Installation": [
        "System Assembly",
        "Well Cleanout",
        "Installation – ESP Field Service",
        "Installation – Service Rig",
        "Installation – ESP Shop",
        "Reran Damaged Equipment",
    ],
    "Operation": [
        "Enhanced Recovery Method or Production Strategy",
        "Inadequate Monitoring",
        "Operating Procedure",
        "Operation of Other Wells in Field / Offset Frac",
        "Well Treatment",
    ],
    "Reservoir or Fluids": [
        "Asphaltene",
        "Bottomhole Temperature",
        "Free Gas",
        "Sand",
        "Scale",
        "Solids/Foreign Debris",
        "Paraffin",
        "Corrosive Fluids",
        "Water Cut",
        "High Inflow",
    ],
    "Completion": [
        "Failure of Perforations/Liner/Openhole",
        "Failure or Improper Sand Control System",
        "Wellbore Completion Failure",
    ],
    "Normal or Expected Wear and Tear": [
        "Normal or Expected Wear and Tear",
    ],
    "Technology Limitation": [
        "Technology Limitation"
    ],
    "Well Construction": [
        "No Tangent Section",
        "High DLS",
        "Facilities",
        "Poor Power Quality",
        "Surface Equipment Failure"
    ],
    "Facilities": [
        "Poor Power Quality",
        "Surface Equipment Failure"
    ],
    "Other": [
        "Natural Disaster",
        "Power Disruption/Lightning",
        "Sabotage/Vandalism",
        "Weather/Oceanographic",
        "Failed Item Not Available",
        "Non-ESP Downhole Failure",
        "No Failed Item",
        "Failed Item Damaged",
        "Not Enough Data",
    ],
    "Unknown": [
        "Unknown"
    ],
}

const PrimaryFailedComponents: React.FC<{ source: string }> = ({ source }) => {
    const dispatch = useDispatch();
    const { causeOfFailure } = useSelector(
        (state: any) => state?.difaPacketBuilder
    )
    console.log("Cause of Failure", causeOfFailure);
    const [selectedPrimaryItem, setSelectedPrimaryItem] = useState<string>(causeOfFailure?.primaryFailedItems || "");
    const [selectedPrimaryFailedSubComponent, setSelectedPrimaryFailedSubComponent] = useState<string>(causeOfFailure?.primaryFailedSubComponents || "");
    const [selectedFailureCategory, setSelectedFailureCategory] = useState<string>(causeOfFailure.failureCategoryItem || "");
    const [selectedSubComponent, setSelectedSubComponent] = useState<string>("");
    const [selectedPrimaryItem2, setSelectedPrimaryItem2] = useState<string>("");
    const [selectedFailedCategory, setSelectedFailedCategory] = useState<string>(causeOfFailure?.selectedFailedCategory || "");
    const [selectedPrimaryFailedSubComponent2, setSelectedPrimaryFailedSubComponent2] = useState<string>(causeOfFailure.primaryFailedSubComponents2 || "");
    const [selectedFailureDescriptor, setSelectedFailureDescriptor] = useState<string>(causeOfFailure.failedDescriptors || "");
    const [selectedFailureDescriptor2, setSelectedFailureDescriptor2] = useState<string>(causeOfFailure.failedDescriptors2 || "");
    const [selectedFailedCategory2, setSelectedFailedCategory2] = useState<string>(causeOfFailure?.selectedFailedCategory2 || "");
    const [selectedGenericCauseOfFailure, setSelectedGenericCauseOfFailure] = useState<string>(causeOfFailure?.genericCauseOfFailure || "");
    const [selectedSpecificCauseOfFailure, setSelectedSpecificCauseOfFailure] = useState<string>(causeOfFailure?.specificCauseOfFailure || "");
    const catogoryDropdownRef = useRef<HTMLDivElement>(null);
    const [categoryDropdownOpen, setcategoryDropdownOpen] = useState(false);
    const [descriptorsDropdownOpen, setdescriptorsDropdownOpen] = useState(false);
    const isPreview = source === "preview-report";
    const [categorySearchTerm, setcategorySearchTerm] = useState("");
    const descriptorsDropdownRef = useRef<HTMLDivElement>(null);
    const [subComponentDropdownOpen, setSubComponentDropdownOpen] = useState(false);
    const [subComponentSearchTerm, setSubComponentSearchTerm] = useState("");
    const subComponentDropdownRef = useRef(null);
    const componentDropdownRef = useRef<HTMLDivElement>(null); 
    const [componentDropdownOpen, setcomponentDropdownOpen] = useState(false);
    const [componentSearchTerm, setComponentSearchTerm] = useState("");
    const [selectedPrimaryComponent, setSelectedPrimaryComponent] = useState<string>(causeOfFailure?.primaryFailedItems || "");
    const [failureCategoryDropdownOpen, setFailureCategoryDropdownOpen] = useState(false);
    const [failureCategorySearchTerm, setFailureCategorySearchTerm] = useState("");
    const [failureDescriptorDropdownOpen, setFailureDescriptorDropdownOpen] = useState(false);
    const [failureDescriptorSearchTerm, setFailureDescriptorSearchTerm] = useState("");
    const failureCategoryDropdownRef = useRef(null);
    const failureDescriptorDropdownRef = useRef(null);
    const genericCauseOfFailureRef =useRef(null);
    const [genericDropdownOpen, setGenericDropdownOpen] = useState(false);
    const failureDescriptorRef = useRef<HTMLDivElement>(null);
    const specificCauseRef = useRef<HTMLDivElement>(null);
    const [descriptorDropdownOpen, setDescriptorDropdownOpen] = useState(false);
    const [specificCauseDropdownOpen, setSpecificCauseDropdownOpen] = useState(false);
    const [descriptorSearchTerm, setDescriptorSearchTerm] = useState("");
    const [specificCauseSearchTerm, setSpecificCauseSearchTerm] = useState("");

    const subComponentRef = useRef<HTMLDivElement>(null);
    const failureCategoryRef = useRef<HTMLDivElement>(null);
    const [subComponentDropdown2Open, setSubComponentDropdown2Open] = useState(false);
    const [failureCategory2DropdownOpen, setFailureCategory2DropdownOpen] = useState(false);
    const [subComponent2SearchTerm, setSubComponent2SearchTerm] = useState("");
    const [failureCategory2SearchTerm, setFailureCategory2SearchTerm] = useState("");

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                catogoryDropdownRef.current &&
                !catogoryDropdownRef.current.contains(event.target as Node)
            ) {
                setcategoryDropdownOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [catogoryDropdownRef]);
   

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (failureDescriptorRef.current && !failureDescriptorRef.current.contains(event.target as Node)) {
              setDescriptorDropdownOpen(false);
            }
            if (specificCauseRef.current && !specificCauseRef.current.contains(event.target as Node)) {
              setSpecificCauseDropdownOpen(false);
            }
            if (subComponentRef.current && !subComponentRef.current.contains(event.target as Node)) {
                setSubComponentDropdown2Open(false);
              }
              if (failureCategoryRef.current && !failureCategoryRef.current.contains(event.target as Node)) {
                setFailureCategory2DropdownOpen(false);
              }
          };
        
          document.addEventListener('mousedown', handleClickOutside);
          return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    return (
        isPreview ? <CauseOfFailurePreview /> : (

            <div className="flex flex-col gap-6 text-neutral-grey">
                <div className="difa-widget-containers text-dark rounded-lg shadow-md">
                    <div className="header-main">
                        <h3>Failed item</h3>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 !gap-4 !px-6 !py-5 border-t border-dark">
                        {/* Primary Failed Item */}
                        <div className="flex flex-col md:flex-row gap-4">
                            <div className="input-field-container">
                                <div className="input-field-caption">Primary failed item</div>
                                <div className="input-field-wrapper relative" ref={catogoryDropdownRef}>
                                    <button className="input-field w-full text-left !h-full"
                                        onClick={() => setcategoryDropdownOpen(!categoryDropdownOpen)}
                                    >

                                        {selectedPrimaryItem || "Select item"}
                                    </button>
                                    {categoryDropdownOpen && (
                                        <div className="absolute mt-1 w-full bg-white border border-gray-300 rounded shadow-lg z-10">
                                            <input
                                                type="text"
                                                className="w-full p-2 border-b border-gray-300"
                                                placeholder="Search..."
                                                value={categorySearchTerm}
                                                onChange={(e) => {
                                                    setcategorySearchTerm(e.target.value);
                                                }}
                                            />
                                            <ul className="max-h-60 overflow-y-auto">
                                                {primaryFailedItems?.map((option, index) => (
                                                    <li
                                                        key={index}
                                                        className="p-2 hover:bg-gray-200 cursor-pointer"
                                                        onClick={(e) => {
                                                            const value = (e.target as HTMLLIElement).textContent; // Using `textContent` since it's a list item
                                                            if (value) {
                                                                setSelectedPrimaryItem(value);
                                                                dispatch(setCauseOfFailure({ ...causeOfFailure, primaryFailedItems: value, primaryFailedSubComponents: "" }));
                                                            }
                                                        }}


                                                    >
                                                        {option}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-col md:flex-row gap-4">
                            <div className="input-field-container">
                                <div className="input-field-caption">Primary failed sub-component</div>
                                <div className="input-field-wrapper relative" ref={subComponentDropdownRef}>
                                    <button
                                        className="input-field w-full text-left !h-full"
                                        onClick={() => setSubComponentDropdownOpen(!subComponentDropdownOpen)}
                                    >
                                        {selectedPrimaryFailedSubComponent || "Select component"}
                                    </button>
                                    {subComponentDropdownOpen && (
                                        <div className="absolute mt-1 w-full bg-white border border-gray-300 rounded shadow-lg z-10">
                                            <input
                                                type="text"
                                                className="w-full p-2 border-b border-gray-300"
                                                placeholder="Search..."
                                                value={subComponentSearchTerm}
                                                onChange={(e) => {
                                                    setSubComponentSearchTerm(e.target.value);
                                                }}
                                            />
                                            <ul className="max-h-60 overflow-y-auto">
                                                {primaryFailedSubComponents[selectedPrimaryItem]
                                                    ?.filter((sub) =>
                                                        sub.toLowerCase().includes(subComponentSearchTerm.toLowerCase())
                                                    )
                                                    .map((sub, index) => (
                                                        <li
                                                            key={index}
                                                            className="p-2 hover:bg-gray-200 cursor-pointer"
                                                            onClick={() => {
                                                                setSelectedPrimaryFailedSubComponent(sub);
                                                                dispatch(
                                                                    setCauseOfFailure({
                                                                        ...causeOfFailure,
                                                                        primaryFailedSubComponents: sub,
                                                                    })
                                                                );
                                                                setSubComponentDropdownOpen(false); // Close dropdown on selection
                                                            }}
                                                        >
                                                            {sub}
                                                        </li>
                                                    ))}
                                            </ul>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col md:flex-row gap-4">
                            <div className="input-field-container">
                                <div className="input-field-caption">Failure category</div>
                                <div className="input-field-wrapper relative" ref={failureCategoryDropdownRef}>
                                    <button
                                        className="input-field w-full text-left !h-full"
                                        onClick={() => setFailureCategoryDropdownOpen(!failureCategoryDropdownOpen)}
                                    >
                                        {selectedFailedCategory || "Select category"}
                                    </button>
                                    {failureCategoryDropdownOpen && (
                                        <div className="absolute mt-1 w-full bg-white border border-gray-300 rounded shadow-lg z-10">
                                            <input
                                                type="text"
                                                className="w-full p-2 border-b border-gray-300"
                                                placeholder="Search..."
                                                value={failureCategorySearchTerm}
                                                onChange={(e) => setFailureCategorySearchTerm(e.target.value)}
                                            />
                                            <ul className="max-h-60 overflow-y-auto">
                                                {failureCategories
                                                    .filter((item) =>
                                                        item.toLowerCase().includes(failureCategorySearchTerm.toLowerCase())
                                                    )
                                                    .map((item, index) => (
                                                        <li
                                                            key={index}
                                                            className="p-2 hover:bg-gray-200 cursor-pointer"
                                                            onClick={() => {
                                                                setSelectedFailedCategory(item);
                                                                dispatch(
                                                                    setCauseOfFailure({
                                                                        ...causeOfFailure,
                                                                        selectedFailedCategory: item,
                                                                    })
                                                                );
                                                                setFailureCategoryDropdownOpen(false);
                                                            }}
                                                        >
                                                            {item}
                                                        </li>
                                                    ))}
                                            </ul>
                                        </div>
                                    )}

                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col md:flex-row gap-4">
                            <div className="input-field-container">
                                <div className="input-field-caption">Failure descriptors</div>
                                <div className="input-field-wrapper relative" ref={failureDescriptorDropdownRef}>
                                    <button
                                        className="input-field w-full text-left !h-full"
                                        onClick={() => setFailureDescriptorDropdownOpen(!failureDescriptorDropdownOpen)}
                                    >
                                        {selectedFailureDescriptor || "Select descriptor"}
                                    </button>
                                    {failureDescriptorDropdownOpen && (
                                        <div className="absolute mt-1 w-full bg-white border border-gray-300 rounded shadow-lg z-10">
                                            <input
                                                type="text"
                                                className="w-full p-2 border-b border-gray-300"
                                                placeholder="Search..."
                                                value={failureDescriptorSearchTerm}
                                                onChange={(e) => setFailureDescriptorSearchTerm(e.target.value)}
                                            />
                                            <ul className="max-h-60 overflow-y-auto">
                                                {failureSubComponentDescriptors[selectedFailedCategory]
                                                    ?.filter((desc) =>
                                                        desc.toLowerCase().includes(failureDescriptorSearchTerm.toLowerCase())
                                                    )
                                                    .map((desc, index) => (
                                                        <li
                                                            key={index}
                                                            className="p-2 hover:bg-gray-200 cursor-pointer"
                                                            onClick={() => {
                                                                setSelectedFailureDescriptor(desc);
                                                                dispatch(
                                                                    setCauseOfFailure({
                                                                        ...causeOfFailure,
                                                                        failedDescriptors: desc,
                                                                    })
                                                                );
                                                                setFailureDescriptorDropdownOpen(false);
                                                            }}
                                                        >
                                                            {desc}
                                                        </li>
                                                    ))}
                                            </ul>
                                        </div>

                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {/* second component will come here */}

                <div className="difa-widget-containers rounded-lg shadow-md">
                    <div className="header-main">
                        <h3>Primary failed component</h3>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 !gap-4 !px-6 !py-5 border-t border-dark">
                        {/* Primary Failed Item */}
                        <div className="flex flex-col md:flex-row gap-4">
                            <div className="input-field-container">
                                <div className="input-field-caption">Primary failed component</div>
                                <div className="input-field-wrapper relative" ref={componentDropdownRef}>
                                    <button className="input-field w-full text-left !h-full"
                                        onClick={() => setcomponentDropdownOpen(!componentDropdownOpen)}
                                    >

                                        {selectedPrimaryComponent || "Select component"}
                                    </button>
                                    {componentDropdownOpen && (
                                        <div className="absolute mt-1 w-full bg-white border border-gray-300 rounded shadow-lg z-10">
                                            <input
                                                type="text"
                                                className="w-full p-2 border-b border-gray-300"
                                                placeholder="Search..."
                                                value={componentSearchTerm}
                                                onChange={(e) => {
                                                    setComponentSearchTerm(e.target.value);
                                                }}
                                            />
                                            <ul className="max-h-60 overflow-y-auto">
                                                {primaryFailedItems?.map((option, index) => (
                                                    <li
                                                        key={index}
                                                        className="p-2 hover:bg-gray-200 cursor-pointer"
                                                        onClick={(e) => {
                                                            const value = (e.target as HTMLLIElement).textContent; // Using `textContent` since it's a list item
                                                            if (value) {
                                                                setSelectedPrimaryComponent(value);
                                                                dispatch(setCauseOfFailure({ ...causeOfFailure, primaryFailedItems2: value, primaryFailedSubComponents: "" }));
                                                                setFailureCategoryDropdownOpen(false);
                                                            }
                                                           
                                                        }}


                                                    >
                                                        {option}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        {/* Primary Failed Sub-Component */}
                        <div className="input-field-container">
                            <div className="input-field-caption">Primary failed sub-component</div>
                            <div className="input-field-wrapper relative" ref={subComponentRef}>
                                <button 
                                  className="input-field w-full text-left !h-full"
                                  onClick={() => setSubComponentDropdown2Open(!subComponentDropdown2Open)}
                                >
                                  {selectedPrimaryFailedSubComponent2 || "Select sub-component"}
                                </button>
                                {subComponentDropdown2Open && (
                                  <div className="absolute mt-1 w-full bg-white border border-gray-300 rounded shadow-lg z-10">
                                    <input
                                      type="text"
                                      className="w-full p-2 border-b border-gray-300"
                                      placeholder="Search..."
                                      value={subComponent2SearchTerm}
                                      onChange={(e) => setSubComponent2SearchTerm(e.target.value)}
                                    />
                                    <ul className="max-h-60 overflow-y-auto">
                                      {primaryFailedSubComponents[selectedPrimaryItem]
                                        ?.filter(sub => sub.toLowerCase().includes(subComponent2SearchTerm.toLowerCase()))
                                        .map((sub, index) => (
                                          <li
                                            key={index}
                                            className="p-2 hover:bg-gray-200 cursor-pointer"
                                            onClick={() => {
                                              setSelectedPrimaryFailedSubComponent2(sub);
                                              dispatch(setCauseOfFailure({ ...causeOfFailure, primaryFailedSubComponents2: sub }));
                                              setSubComponentDropdown2Open(false);
                                            }}
                                          >
                                            {sub}
                                          </li>
                                        ))}
                                    </ul>
                                  </div>
                                )}
                              </div>
                        </div>

                        {/* Failure Category */}
                        <div className="input-field-container">
                            <div className="input-field-caption">Failure category</div>
                            <div className="input-field-wrapper relative" ref={failureCategoryRef}>
                                <button 
                                  className="input-field w-full text-left !h-full"
                                  onClick={() => setFailureCategory2DropdownOpen(!failureCategory2DropdownOpen)}
                                >
                                  {selectedFailedCategory2 || "Select category"}
                                </button>
                                {failureCategory2DropdownOpen && (
                                  <div className="absolute mt-1 w-full bg-white border border-gray-300 rounded shadow-lg z-10">
                                    <input
                                      type="text"
                                      className="w-full p-2 border-b border-gray-300"
                                      placeholder="Search..."
                                      value={failureCategory2SearchTerm}
                                      onChange={(e) => setFailureCategory2SearchTerm(e.target.value)}
                                    />
                                    <ul className="max-h-60 overflow-y-auto">
                                      {failureCategories
                                        .filter(item => item.toLowerCase().includes(failureCategory2SearchTerm.toLowerCase()))
                                        .map((item, index) => (
                                          <li
                                            key={index}
                                            className="p-2 hover:bg-gray-200 cursor-pointer"
                                            onClick={() => {
                                              setSelectedFailedCategory2(item);
                                              dispatch(setCauseOfFailure({ ...causeOfFailure, selectedFailedCategory2: item }));
                                              setFailureCategory2DropdownOpen(false);
                                            }}
                                          >
                                            {item}
                                          </li>
                                        ))}
                                    </ul>
                                  </div>
                                )}
                              </div>
                        </div>

                        {/* Primary Failed Sub-Component */}
                        <div className="input-field-container">
                            <div className="input-field-caption">Failure descriptors</div>
                            <div className="input-field-wrapper relative" ref={failureDescriptorRef}>
                                <button 
                                    className="input-field w-full text-left !h-full"
                                    onClick={() => setDescriptorDropdownOpen(!descriptorDropdownOpen)}
                                >
                                    {selectedFailureDescriptor2 || "Select descriptor"}
                                </button>
                                {descriptorDropdownOpen && (
                                    <div className="absolute mt-1 w-full bg-white border border-gray-300 rounded shadow-lg z-10">
                                        <input
                                            type="text"
                                            className="w-full p-2 border-b border-gray-300"
                                            placeholder="Search..."
                                            value={descriptorSearchTerm}
                                            onChange={(e) => setDescriptorSearchTerm(e.target.value)}
                                        />
                                        <ul className="max-h-60 overflow-y-auto">
                                            {failureSubComponentDescriptors[selectedFailedCategory2]
                                                ?.filter(desc => desc.toLowerCase().includes(descriptorSearchTerm.toLowerCase()))
                                                .map((desc, index) => (
                                                    <li
                                                        key={index}
                                                        className="p-2 hover:bg-gray-200 cursor-pointer"
                                                        onClick={() => {
                                                            setSelectedFailureDescriptor2(desc);
                                                            dispatch(setCauseOfFailure({ ...causeOfFailure, failedDescriptors2: desc }));
                                                            setDescriptorDropdownOpen(false);
                                                        }}
                                                    >
                                                        {desc}
                                                    </li>
                                                ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>

                        {/* Generic Cause of Failure  */}
                        <div>
                        <div className="input-field-container">
                                <div className="input-field-caption">Generic cause of failure</div>
                                <div className="input-field-wrapper relative" ref={genericCauseOfFailureRef}>
                                    <button className="input-field w-full text-left !h-full"
                                        onClick={() => setGenericDropdownOpen(!genericDropdownOpen)}
                                    >

                                        {selectedGenericCauseOfFailure || "Select general cause"}
                                    </button>
                                    {genericDropdownOpen && (
                                        <div className="absolute mt-1 w-full bg-white border border-gray-300 rounded shadow-lg z-10">
                                            <input
                                                type="text"
                                                className="w-full p-2 border-b border-gray-300"
                                                placeholder="Search..."
                                                value={componentSearchTerm}
                                                onChange={(e) => {
                                                    setSelectedGenericCauseOfFailure(e.target.value);
                                                }}
                                            />
                                            <ul className="max-h-60 overflow-y-auto">
                                                {genericCauseOfFailure?.map((option, index) => (
                                                    <li
                                                        key={index}
                                                        className="p-2 hover:bg-gray-200 cursor-pointer"
                                                        onClick={(e) => {
                                                            const value = (e.target as HTMLLIElement).textContent; // Using `textContent` since it's a list item
                                                            if (value) {
                                                                setSelectedGenericCauseOfFailure(value);
                                                                dispatch(setCauseOfFailure({ ...causeOfFailure, genericCauseOfFailure: value, primaryFailedSubComponents: "" }));
                                                                setFailureCategoryDropdownOpen(false);
                                                            }
                                                           
                                                        }}


                                                    >
                                                        {option}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                </div>
                            </div>  
                        </div>

                        {/* Specific Cause of Failure  */}
                        <div className="input-field-container">
                            <div className="input-field-caption">Specific failure cause</div>
                            <div className="input-field-wrapper relative" ref={specificCauseRef}>
                                <button 
                                    className="input-field w-full text-left !h-full"
                                    onClick={() => setSpecificCauseDropdownOpen(!specificCauseDropdownOpen)}
                                >
                                    {selectedSpecificCauseOfFailure || "Select specific cause"}
                                </button>
                                {specificCauseDropdownOpen && (
                                    <div className="absolute mt-1 w-full bg-white border border-gray-300 rounded shadow-lg z-10">
                                        <input
                                            type="text"
                                            className="w-full p-2 border-b border-gray-300"
                                            placeholder="Search..."
                                            value={specificCauseSearchTerm}
                                            onChange={(e) => setSpecificCauseSearchTerm(e.target.value)}
                                        />
                                        <ul className="max-h-60 overflow-y-auto">
                                            {specificFailureCause[selectedGenericCauseOfFailure]
                                                ?.filter(cause => cause.toLowerCase().includes(specificCauseSearchTerm.toLowerCase()))
                                                .map((cause, index) => (
                                                    <li
                                                        key={index}
                                                        className="p-2 hover:bg-gray-200 cursor-pointer"
                                                        onClick={() => {
                                                            setSelectedSpecificCauseOfFailure(cause);
                                                            dispatch(setCauseOfFailure({ ...causeOfFailure, specificCauseOfFailure: cause }));
                                                            setSpecificCauseDropdownOpen(false);
                                                        }}
                                                    >
                                                        {cause}
                                                    </li>
                                                ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col border-dark difa-widget-containers">
                    <div className="header-main">
                        <h3>Failure cause summary</h3>
                        <h5>Provide a concise summary of the ESP failure cause here.</h5>
                    </div>
                    <div className="px-6 py-5 border-t border-dark">
                        <textarea
                            className="card-textarea"
                            maxLength={500}
                            rows={6}
                            value={causeOfFailure?.failureSummary}
                            placeholder="Add comments here..."
                            onBlur={(e) => {
                                dispatch(setCauseOfFailure({ ...causeOfFailure, failureSummary: e.target.value }))
                            }}
                            onChange={(e) => {

                                dispatch(setCauseOfFailure({ ...causeOfFailure, failureSummary: e.target.value }))
                            }}
                        ></textarea>
                    </div>
                </div>
                <div className="flex flex-col gap-5 border-dark difa-widget-containers">
                    <div className="header-main">
                        <h3>DIFA conclusion</h3>
                        <h5>Summarize the evidence you have gathered, describe what happened during the dismantle, and explain what led to the cause of failure.</h5>
                    </div>
                    <div className="px-6 py-5 border-t border-dark">
                        <textarea
                            className="card-textarea"
                            maxLength={500}
                            rows={6}
                            value={causeOfFailure?.conclusion}
                            placeholder="Add comments here..."
                            onBlur={(e) => {
                                dispatch(setCauseOfFailure({ ...causeOfFailure, conclusion: e.target.value }))
                            }}
                            onChange={(e) => {
                                dispatch(setCauseOfFailure({ ...causeOfFailure, conclusion: e.target.value }))
                            }}
                        ></textarea>
                    </div>
                </div>

                <RecommendationList />
            </div>
        )
    )
};

export default PrimaryFailedComponents;