// DifaReport.tsx

import React, { useState } from "react";
import Header from "./components/Header";
import DifaPacket from "./components/DifaPacket";
import DifaDocReport from "./components/DifaReport"
import Tabs from "../common/horizontalTabs/Tabs";

const DifaReport: React.FC = () => {

  const tabs = [
    { label: "DIFA reports", component: DifaDocReport },
    { label: "DIFA packet", component: DifaPacket },
  ]

  return (
    <div className="difa-documentation">
      <Header headerName="Documents" />
      <div className="flex flex-col">
        <Tabs tabs={tabs} />
      </div>
    </div>
  );
};

export default DifaReport;