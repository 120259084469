// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.switch {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 20px;
  margin: 0;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.switch input:checked + .slider {
  background-color: #0094BD;
}
.switch input:focus + .slider {
  box-shadow: 0 0 1px #0094BD;
}
.switch input:checked + .slider:before {
  transform: translateX(13px);
}
.switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #384252;
  transition: 0.4s;
}
.switch .slider.round {
  border-radius: 34px;
}
.switch .slider.round::before {
  border-radius: 50%;
}
.switch .slider::before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 2.3px;
  background-color: 384252;
  transition: 0.4s;
}`, "",{"version":3,"sources":["webpack://./src/features/common/switch/DifaSwitch.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,qBAAA;EACA,WAAA;EACA,YAAA;EACA,SAAA;AACJ;AACI;EACI,UAAA;EACA,QAAA;EACA,SAAA;AACR;AACQ;EACI,yBAAA;AACZ;AAEQ;EACI,2BAAA;AAAZ;AAGQ;EAGI,2BAAA;AADZ;AAKI;EACI,kBAAA;EACA,eAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EACA,yBAAA;EAEA,gBAAA;AAHR;AAKQ;EACI,mBAAA;AAHZ;AAOY;EACI,kBAAA;AALhB;AASQ;EACI,kBAAA;EACA,WAAA;EACA,YAAA;EACA,WAAA;EACA,SAAA;EACA,aAAA;EACA,wBAAA;EAEA,gBAAA;AAPZ","sourcesContent":[".switch {\n    position: relative;\n    display: inline-block;\n    width: 36px;\n    height: 20px;\n    margin: 0;\n\n    input {\n        opacity: 0;\n        width: 0;\n        height: 0;\n\n        &:checked+.slider {\n            background-color: #0094BD;\n        }\n\n        &:focus+.slider {\n            box-shadow: 0 0 1px #0094BD;\n        }\n\n        &:checked+.slider:before {\n            -webkit-transform: translateX(13px);\n            -ms-transform: translateX(13px);\n            transform: translateX(13px);\n        }\n    }\n\n    .slider {\n        position: absolute;\n        cursor: pointer;\n        top: 0;\n        left: 0;\n        right: 0;\n        bottom: 0;\n        background-color: #384252;\n        -webkit-transition: .4s;\n        transition: .4s;\n\n        &.round {\n            border-radius: 34px;\n        }\n\n        &.round {\n            &::before {\n                border-radius: 50%;\n            }\n        }\n\n        &::before {\n            position: absolute;\n            content: \"\";\n            height: 16px;\n            width: 16px;\n            left: 4px;\n            bottom: 2.3px;\n            background-color: 384252;\n            -webkit-transition: .4s;\n            transition: .4s;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
