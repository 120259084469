// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* DashboardLayout.css */
.dashboard-content {
  display: flex;
  padding: 20px 24px;
  gap: 20px;
  flex-direction: column;
}

.dashboard-section {
  background-color: #1b2a3d;
  padding: 20px 20px 0px 20px;
  border-radius: 8px;
  box-sizing: border-box;
}

.dashboard-section .section-title-container {
  display: flex;
  justify-content: start;
  align-items: center;
  border-bottom: 1px solid #3a4d65;
  /* Adds a separator line */
  gap: 12px;
  padding-bottom: 16px;
}

.dashboard-section .section-title {
  font-size: 19px;
  color: #f4fbfc;
}

.dashboard-section .section-count {
  border-radius: 4px;
  border: 1px solid var(--Shades-White, #fff);
  color: #fff;
  min-height: 21px;
  min-width: 25px;
  display: flex;
  justify-content: center;
  font-size: 14px;
  align-items: center;
}

.dashboard-section .section-card-container {
  height: calc(100vh - 241px);
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none;
}

.section-card-container::-webkit-scrollbar {
  display: none;
}
`, "",{"version":3,"sources":["webpack://./src/features/dashboard/components/DashboardLayout.css"],"names":[],"mappings":"AAAA,wBAAwB;AACxB;EACE,aAAa;EACb,kBAAkB;EAClB,SAAS;EACT,sBAAsB;AACxB;;AAEA;EACE,yBAAyB;EACzB,2BAA2B;EAC3B,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,gCAAgC;EAChC,0BAA0B;EAC1B,SAAS;EACT,oBAAoB;AACtB;;AAEA;EACE,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,2CAA2C;EAC3C,WAAW;EACX,gBAAgB;EAChB,eAAe;EACf,aAAa;EACb,uBAAuB;EACvB,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,2BAA2B;EAC3B,qBAAqB,EAAE,gBAAgB;EACvC,wBAAwB;AAC1B;;AAEA;EACE,aAAa;AACf","sourcesContent":["/* DashboardLayout.css */\n.dashboard-content {\n  display: flex;\n  padding: 20px 24px;\n  gap: 20px;\n  flex-direction: column;\n}\n\n.dashboard-section {\n  background-color: #1b2a3d;\n  padding: 20px 20px 0px 20px;\n  border-radius: 8px;\n  box-sizing: border-box;\n}\n\n.dashboard-section .section-title-container {\n  display: flex;\n  justify-content: start;\n  align-items: center;\n  border-bottom: 1px solid #3a4d65;\n  /* Adds a separator line */\n  gap: 12px;\n  padding-bottom: 16px;\n}\n\n.dashboard-section .section-title {\n  font-size: 19px;\n  color: #f4fbfc;\n}\n\n.dashboard-section .section-count {\n  border-radius: 4px;\n  border: 1px solid var(--Shades-White, #fff);\n  color: #fff;\n  min-height: 21px;\n  min-width: 25px;\n  display: flex;\n  justify-content: center;\n  font-size: 14px;\n  align-items: center;\n}\n\n.dashboard-section .section-card-container {\n  height: calc(100vh - 241px);\n  scrollbar-width: none; /* For Firefox */\n  -ms-overflow-style: none;\n}\n\n.section-card-container::-webkit-scrollbar {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
