import { createSlice, createAsyncThunk, ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { getDifaList, GetAllEngineers } from "./DashboardServices";

export const fetchDifaList: any = createAsyncThunk('dasboard/fetchDifaList', async ({ emailId, startPage, pageSize }: { emailId: string, startPage: string, pageSize: string }) => {
    const response = await getDifaList(emailId,startPage, pageSize);
    return response;
});

export const fetchCustomers: any = createAsyncThunk('dasboard/fetchCustomer', async () => {
    const response = await GetAllEngineers();
    return response;
});

const initialState: any = {
    loading: false,
    allDifaList: [],
    allCustomers: [],
    message: null
};

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
    },
    extraReducers: (builder: ActionReducerMapBuilder<any>) => {
        builder.addCase(fetchDifaList.pending, (state: any) => {
            state.loading = true;
        });
        //fullfilled
        builder.addCase(fetchDifaList.fulfilled, (state: any, action: any) => {
            state.allDifaList = action.payload;
            state.loading = false;
        });
        //rejected
        builder.addCase(fetchDifaList.rejected, (state: any, action: any) => {
            state.message = action.payload;
            state.loading = false;
        });

        builder.addCase(fetchCustomers.pending, (state: any) => {
            state.loading = true;
        });
        //fullfilled
        builder.addCase(fetchCustomers.fulfilled, (state: any, action: any) => {
            state.allCustomers = action.payload;
            state.loading = false;
        });
        //rejected
        builder.addCase(fetchCustomers.rejected, (state: any, action: any) => {
            state.message = action.payload;
            state.loading = false;
        });

    }
})

export default dashboardSlice.reducer;