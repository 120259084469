import { useLocation, useNavigate } from "react-router-dom";
import Header from "../difaPacketBuilder/components/Header";
import { useDispatch, useSelector } from "react-redux";
import "../difaPacketBuilder/difaPacketBuilder.scss";
import { useEffect, useState } from "react";
import { fetchDifaDetails } from "../DifaPages/DifaDetailsSlice";
import WellRunLifeInformation from "../difaPacketBuilder/components/WellRunLifeInformation";
import { fetchOptimizationTickets, fetchRundaysDetails } from "../preInspectionSummary/PreInspectionSummarySlice";
import EspSizing from "../difaPacketBuilder/components/espSizing/EspSizing";
import InstallAndWellOperation from "../difaPacketBuilder/components/installWellOperation/InstallAndWellOperation";
import Production from "../difaPacketBuilder/components/production/Production";
import PullAndInbound from "../difaPacketBuilder/components/pullAndInbound/PullAndInbound";
import { fetchDocuments, fetchGeneralReport, fetchPublishedDraft, postPublishPacket, removePacket, resetPacketBuilderState, resetPublishFile, resetPublishPacketNotification } from "../difaPacketBuilder/difaPacketBuilderSlice";
import PublishDifaDialog from "../difaPacketBuilder/components/PublishDifaDialog";
import PublishedDifaDialog from "../difaPacketBuilder/components/PublishSuccessDialog";
import { toast } from "react-toastify";
import Loader from "../common/loader/Loader";
import RemoveConfirmationDialog from "../common/confirmationDialog/RemoveItem";
const PAGE_WELL_RUN_LIFE = "Well and run life information";
const PAGE_ESP_SIZING = "ESP sizing";
const PAGE_INSTALL_AND_WELL_OPERATIONS = "Install and well operations";
const PAGE_PRODUCTION = "Production";
const PAGE_PULL_AND_INBOUND = "Pull and inbound";


const DifaPacketPreview = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [showPublishDialog, setShowPublishDialog] = useState(false);
  const [showPublishedDialog, setShowPublishedDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const { difaId, customerName, wellName } = location.state || {};
  const { difaDetails } = useSelector((state: any) => state.difaDetails);
  const {
    draftId, publishedId,
    publishPacketNotification,
    sizingSummary,
    production,
    publishedPDF,
    loading,
    message
  } = useSelector((state: any) => state.difaPacketBuilder);

  const { difaPacketStatus } = useSelector(
    (state: any) => state.preInspectionSummary);
  const [currentPage, setCurrentPage] = useState(PAGE_WELL_RUN_LIFE);
  const [viewMode, setViewMode] = useState(difaPacketStatus.isPacketPublished ? 'preview-published-packet' : "preview-packet");//difaPacketStatus?.isPacketPublished ? 'published' :  "preview-packet")  
  useEffect(() => {
    dispatch(fetchDifaDetails({ difaId }));
  }, [dispatch, difaId]);

  useEffect(() => {
    difaPacketStatus && setViewMode(difaPacketStatus.isPacketPublished ? 'preview-published-packet' : "preview-packet");
  }, [difaPacketStatus]);


  useEffect(() => {
    if (publishedPDF && publishedPDF instanceof Blob) {
      const pdfUrl = URL.createObjectURL(publishedPDF);
      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "DifaPacket.pdf"; // Specify the filename for the download
      document.body.appendChild(link); // Append the link to the document

      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(pdfUrl);
      dispatch(resetPublishFile())
    }
    if (message === 'report-download-failed') {
      toast.error(<div className="flex flex-col">
        <div className="text-lg">
          Opps!!
        </div>
        <div className="text-sm">
          Report cannot be downloaded at this time. Please try again later.
        </div>
      </div>)
    }
  }, [publishedPDF, message])

  useEffect(() => {
    if (difaDetails?.wellApiNumber !== undefined) {
      dispatch(fetchRundaysDetails({ wellApiNumber: difaDetails?.wellApiNumber }));
      dispatch(fetchGeneralReport({ wellApiNumber: difaDetails?.wellApiNumber, difaId: difaDetails?.difaId }));
      dispatch(fetchOptimizationTickets({ wellName: encodeURIComponent(difaDetails?.wellName) }));
      dispatch(fetchPublishedDraft({ wellApiNumber: difaDetails?.wellApiNumber, difaId }))
      !sizingSummary?.sizingFile && dispatch(fetchDocuments({
        wellApiNumber: difaDetails?.wellApiNumber,
        difaId: difaDetails?.difaId,
        category: 'esp',
        source: "preview-packet"
      }));
      !production?.productionFile && dispatch(fetchDocuments({
        wellApiNumber: difaDetails?.wellApiNumber,
        difaId: difaDetails?.difaId,
        category: 'production',
        source: "preview-packet"
      }));
    }
  }, [dispatch, difaDetails]);

  const savePacket = () => {

  }
  const redirectToPreview = () => {
    setShowPublishDialog(false);
    setShowPublishedDialog(false);
    setViewMode("preview-published-packet");
  };

  const redirectToPIS = () => {
    navigate("/difa-details/pi-summary", {
      state: { difaId: difaId, customerName: customerName, wellName },
    });
  };

  const publishPacket = async () => {
    dispatch(postPublishPacket({
      publishPacket: {
        "wellApinumber": difaDetails?.wellApiNumber,
        "difaId": difaId,
      }
    }));
    setShowPublishedDialog(true)
  }

  const handleRemove = (remove: boolean) => {
    !remove && setShowDeleteDialog(false);
    if (remove) {
      setShowDeleteDialog(false);
      dispatch(removePacket({
        publishPacket: {
          "wellApiNumber": difaDetails?.wellApiNumber,
          "difaId": difaId
        },
        source: 'packet-preview'
      }));
      dispatch(resetPacketBuilderState())
      toast.success(
        <div className="flex flex-col">
          <div className="text-lg">
            Document deleted.
          </div>
          <div className="text-sm">
            DIFA packet has been successfully removed.
          </div>
        </div>,
        { style: { width: "400px" } }
      );
      navigate("/difa-details/pi-summary", {
        state: { difaId: difaId, customerName: customerName, wellName },
      })
    }
  }

  useEffect(() => {
    if (publishPacketNotification?.type === 'publish-success') {
      dispatch(resetPublishPacketNotification())
      setShowPublishedDialog(true)

    }
    if (publishPacketNotification?.type === 'publish-failed') {
      dispatch(resetPublishPacketNotification())
      toast.error(<div className="flex flex-col">
        <div className="text-lg">
          Opps!!
        </div>
        <div className="text-sm">
          Failed to publish report. Please try again later.
        </div>
      </div>)
    }

  }, [publishPacketNotification])

  const packetName = draftId ? "DIFA packet # " + draftId : publishedId ? "DIFA packet # " + publishedId : "DIFA packet";
  return (
    <>
      <div className="packet-builder">

        {loading && <Loader isOverlay />}
        <Header
          source={viewMode}
          pageName="DIFA packet builder"
          difaPacketName={packetName}
          customerName={customerName}
          wellName={wellName}
          onSaveHandler={savePacket}
          onPublishHandler={() => setShowPublishDialog(true)}
          onDeleteHandler={() => { setShowDeleteDialog(true) }}
        />
        <div className="flex flex-col gap-5 p-5 packet-preview-header">
          <div className="text-container">
            <span className={`text-part cursor-pointer ${currentPage === PAGE_WELL_RUN_LIFE && 'selected'}`} onClick={() => setCurrentPage(PAGE_WELL_RUN_LIFE)}>Run life Summary</span>
            <span className={`text-part cursor-pointer ${currentPage === PAGE_PULL_AND_INBOUND && 'selected'}`} onClick={() => setCurrentPage(PAGE_PULL_AND_INBOUND)}>Inbound and pull</span>
            <span className={`text-part cursor-pointer ${currentPage === PAGE_PRODUCTION && 'selected'}`} onClick={() => setCurrentPage(PAGE_PRODUCTION)}>Production</span>
            <span className={`text-part cursor-pointer ${currentPage === PAGE_INSTALL_AND_WELL_OPERATIONS && 'selected'}`} onClick={() => setCurrentPage(PAGE_INSTALL_AND_WELL_OPERATIONS)}>Well operations and install</span>
            <span className={`text-part cursor-pointer ${currentPage === PAGE_ESP_SIZING && 'selected'}`} onClick={() => setCurrentPage(PAGE_ESP_SIZING)}>ESP sizing</span>
          </div>
        </div>
        <div className="flex flex-col md:flex-row gap-5 p-7 h-fit">
          <div className="flex-auto flex flex-col w-full md:w-3/4 gap-5">
            {currentPage === PAGE_WELL_RUN_LIFE && (
              <WellRunLifeInformation difaId={difaId} source="preview-packet" />
            )}
            {currentPage === PAGE_ESP_SIZING && (
              <EspSizing
                wellApiNumber={difaDetails?.wellApiNumber}
                source="preview-packet"
                difaId={difaId}
              />
            )}
            {currentPage === PAGE_INSTALL_AND_WELL_OPERATIONS && (
              <InstallAndWellOperation
                wellApiNumber={difaDetails?.wellApiNumber}
                difaId={difaDetails?.difaId}
                source="preview-packet"
              />
            )}
            {currentPage === PAGE_PRODUCTION && <Production source="preview-packet" />}
            {currentPage === PAGE_PULL_AND_INBOUND && (
              <PullAndInbound
                wellApiNumber={difaDetails?.wellApiNumber}
                difaId={difaDetails?.difaId}
                source="preview-packet" />
            )}
          </div>
        </div>
      </div>
      <PublishDifaDialog show={showPublishDialog} close={() => { setShowPublishDialog(false); }} submit={() => publishPacket()} />
      <PublishedDifaDialog show={showPublishedDialog} close={() => { setShowPublishDialog(false); setShowPublishedDialog(false) }} onRedirect={() => redirectToPreview()} onBackToPI={() => redirectToPIS()} mode="" />
      <RemoveConfirmationDialog show={showDeleteDialog} mode='packet' close={(remove: boolean) => handleRemove(remove)} />
    </>

  );
};

export default DifaPacketPreview;
