import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Header from "./components/Header";
import PISummaryDifaDetails from "./components/PISummaryDifaDetails";
import PISummarySnapshotandChart from "./components/PISummaryChart";
import VerticalTab from "../common/verticalTabs/VerticalTab";
import { fetchDismantlePumps } from "../dismentleRequest/DismantleRequestSlice";
import { useDispatch, useSelector } from "react-redux";
import { fetchDifaDetails } from "../DifaPages/DifaDetailsSlice";
import { fetchRundaysDetails, fetchInstallReport, fetchStartupReport, fetchServiceReport, fetchOptimizationTickets } from "./PreInspectionSummarySlice";
import "./PreInspectionSummary.scss";
import AlarmHistory from "./components/AlarmHistory";
import PISummaryDocumentation from "./components/PISummaryDocumentaion";
import Loader from "../common/loader/Loader";

const PreInspectionSummary = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { difaId, customerName, wellName } = location.state || {};
  const { difaDetails, loading } = useSelector((state: any) => state.difaDetails);

  useEffect(() => {
    dispatch(fetchDifaDetails({ difaId }));
    dispatch(fetchDismantlePumps({ difaId }));
  }, [dispatch, difaId]);

  useEffect(() => {
    if (difaDetails?.wellApiNumber) {
      dispatch(fetchRundaysDetails({ wellApiNumber: difaDetails?.wellApiNumber }));
    }
    difaDetails?.wellName && dispatch(fetchOptimizationTickets({ wellName: difaDetails?.wellName }));
  }, [dispatch, difaDetails]);

  return (
    <div className="pis-details">
      <Header
        headerName="Pre-inspection summary"
        customerName={customerName}
        wellName={wellName}
      />
      <div className="pi-summary-container">
        {loading && <Loader isOverlay />}
        <PISummaryDifaDetails difaId={difaId} />
        <PISummarySnapshotandChart wellApiNumber={difaDetails?.wellApiNumber} />
        <div className="pi-summary-difa-dismantle-request">
          <div className="pis-inbound-test-header">
            <h3>Inbound testing results</h3>
            <div className="pis-inbound-test-btn disabled">
              <button className="pis-inbount-test-btn-text" disabled={true}>
                View inbound testing report
              </button>
            </div>
          </div>
          <div className="pis-difa-ds-tab-container">
            <VerticalTab difaId={difaId} wellName={wellName} />
          </div>
        </div>
        <AlarmHistory wellApiNumber={difaDetails?.wellApiNumber} />
        <div className="pi-summary-difa-dismantle-request">
          <div className="pis-inbound-test-header">
            <h3>Documentation</h3>
          </div>
          <PISummaryDocumentation wellApiNumber={difaDetails?.wellApiNumber} />
        </div>
      </div>
    </div>
  );
};

export default PreInspectionSummary;
