import React, { useState } from 'react';
import { ReactComponent as Plus } from "../../../../images/plus-indigo.svg";
import {ReactComponent as DeleteIcon} from "../../../../images/trash-01.svg"; 
import { setAttendees } from "../../../difaPacketBuilder/difaPacketBuilderSlice"; 
import { useDispatch, useSelector } from 'react-redux';

// Define TypeScript Interface for an Attendee
interface Attendee {
    name: string;
    role: string;
}

const DIFAAttendees: React.FC = () => {
    const dispatch = useDispatch();
    const attendees: Attendee[] = useSelector((state: any) => state.difaPacketBuilder.attendees);
    
    // State to track whether customers were present
    const [customerPresent, setCustomerPresent] = useState<boolean | null>(null);

    // Function to add a new row
    const addAttendee = (): void => {
        dispatch(setAttendees([...attendees, { name: "", role: "" }]));
    };

    // Function to handle input change
    const handleInputChange = (index: number, field: keyof Attendee, value: string): void => {
        const updatedAttendees: Attendee[] = [...attendees];
        updatedAttendees[index] = { ...updatedAttendees[index], [field]: value };
        dispatch(setAttendees(updatedAttendees)); 
    };

    // Function to delete a row
    const handleDelete = (index: number): void => {
        if (attendees.length > 1) {
            dispatch(setAttendees(attendees.filter((_, i: number) => i !== index)));
        }
    };

    return (
        <div className="difa-widget-containers">
            <div className="header-main border-bottom">
                <h3>DIFA attendees</h3>
                <h5>Add attendees that were present for the dismantle</h5>
            </div>

            <div className="flex flex-col gap-4 px-6 py-5">
                {/* Radio Buttons */}
                <div className="flex flex-col gap-2">
                    <p className="text-white text-sm font-semibold">
                        Were customers present during the DIFA dismantle?
                    </p> 
                    <div className="flex items-center gap-8">
                        <label className="flex items-center gap-2">
                            <input 
                                type="radio" 
                                name="customerPresent" 
                                value="yes" 
                                checked={customerPresent === true} 
                                onChange={() => setCustomerPresent(true)} 
                            />
                            Yes
                        </label>
                        <label className="flex items-center gap-2">
                            <input 
                                type="radio" 
                                name="customerPresent" 
                                value="no" 
                                checked={customerPresent === false} 
                                onChange={() => setCustomerPresent(false)} 
                            />
                            No
                        </label>
                    </div>
                </div>

                {/* Attendees List */}
                {attendees.map((attendee: Attendee, index: number) => (
                    <div key={index} className="flex flex-col md:flex-row items-center justify-between gap-4">
                        <div className="input-field-container">
                            <label className="input-field-caption">Customer name</label>
                            <input 
                                type="text" 
                                className="input-field" 
                                placeholder="Enter Name" 
                                value={attendee.name}
                                onChange={(e) => handleInputChange(index, "name", e.target.value)}
                                disabled={customerPresent === false} // Disable if No is selected
                            />
                        </div>
                        <div className="input-field-container">
                            <label className="input-field-caption">Role</label>
                            <input 
                                type="text" 
                                className="input-field"  
                                placeholder="Enter role" 
                                value={attendee.role}
                                onChange={(e) => handleInputChange(index, "role", e.target.value)}
                                disabled={customerPresent === false} // Disable if No is selected
                            />
                        </div>
                        {/* Delete Button */}
                        <button 
                            className="text-left p-2 mt-3.5 hover:bg-gray-700 cursor-pointer rounded-lg"
                            onClick={() => handleDelete(index)}
                            disabled={customerPresent === false} // Disable if No is selected
                        >
                            <DeleteIcon />
                        </button>
                    </div>
                ))}

                {/* Add Team Members Button */}
                <div className={`flex items-end justify-start gap-2 cursor-pointer ${customerPresent === false ? 'opacity-50 cursor-not-allowed' : ''}`} 
                    onClick={customerPresent !== false ? addAttendee : undefined}>
                    <Plus />
                    <span className="text-sm font-bold text-Indigo">Add additional attendees</span>
                </div>
            </div>
        </div>
    );
}

export default DIFAAttendees;
