/**
 * Gives the large array index out of given Array of Arrays
 * @param arrays - Array of items
 * @returns - Index of large Array
 */
export const indexOfLongestArray = (arrays: Array<any>): number => {
  return arrays.reduce((acc, arr, idx) => {
    return arr?.length > arrays[acc]?.length ? idx : acc;
  }, 0);
};

const getRange = (value: number, range: number) => {
  return [Math.max(0, value - range), Math.min(value + range, 100)];
};

const getHashOfString = (str: string) => {
  let hash = 0;
  for (let i = 0; i < str?.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  hash = Math.abs(hash);
  return hash;
};

const generateHSL = (
  name: string,
  saturationRange: number[],
  lightnessRange: number[]
) => {
  const hash = getHashOfString(name);
  const h = normalizeHash(hash, 0, 360);
  const s = normalizeHash(hash, saturationRange[0], saturationRange[1]);
  const l = normalizeHash(hash, lightnessRange[0], lightnessRange[1]);
  return [h, s, l];
};

const normalizeHash = (hash: number, min: number, max: number) => {
  return Math.floor((hash % (max - min)) + min);
};

const HSLtoString = (hsl: number[]) => {
  return `hsl(${hsl[0]}, ${hsl[1]}%, ${hsl[2]}%)`;
};

const generateColorHsl = (
  id: string,
  saturationRange: number[],
  lightnessRange: number[]
) => {
  return HSLtoString(generateHSL(id, saturationRange, lightnessRange));
};

/**
 * Gives the color code based on the names
 * @param userName - string
 * @returns - string
 */
export const getHSLColor = (userName: string) => {
  const range = 10;
  const saturation = 50;
  const lightness = 50;
  const saturationRange = getRange(saturation, range);
  const lightnessRange = getRange(lightness, range);
  return generateColorHsl(userName, saturationRange, lightnessRange);
};

/**
 * Convert the word to sentence cased word
 * @param str - string
 * @returns - string
 */
export const sentenceCase = (str: string | any) => {
  if (str === null || str === "") return str;
  else str = str.toString();

  return str.replace(/\w\S*/g, (txt: string) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

/**
 * Fommat the date and time
 * @param inputDate - string
 * @returns - ex Jan 10, 2023 @ 12AM
 */

export const formatDate = (inputDate: string): string | undefined => {
  if (!inputDate)
    return undefined;
  const date = new Date(inputDate);

  // Format the date part (e.g., Jan 10, 2023)
  const formattedDate = date.toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });

  // Format the time part (e.g., 12:00 AM)
  const formattedTime = date.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });

  // Combine the formatted date and time
  return `${formattedDate} @ ${formattedTime}`;
};

/**
 * Fommat the date
 * @param inputDate - string
 * @returns - ex Jan 10, 2023
 */

export const formatOnlyDate = (inputDate: string): string | undefined => {
  if (!inputDate)
    return undefined;
  const date = new Date(inputDate);

  // Format the date part (e.g., Jan 10, 2023)
  const formattedDate = date.toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });

  // Combine the formatted date and time
  return `${formattedDate}`;
};


export const formatDateInNumbers = (date: string): string | undefined => {
  if (!date)
    return undefined;
  const dateObj = new Date(date);
  return `${dateObj.getMonth() + 1
    }/${dateObj.getDate()}/${dateObj.getFullYear()}`;
};

export const formatTime = (dateString: string, isSec?: boolean ): string | undefined => {
  if (!dateString)
    return undefined;
  const date = new Date(dateString);
  return isSec ? date.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }) : date.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true });
};

export const getFirstLastEngineerLetter = (engName: string) => {
  if (!engName) {
    return "";
  }
  const difaEng = engName.replace(/  +/g, " ").split(" ");
  if (difaEng?.length) {
    if (difaEng.length > 1) {
      return difaEng[0][0]?.toUpperCase() + difaEng[1][0]?.toUpperCase();
    } else if (difaEng.length === 1) {
      return difaEng[0][0]?.toUpperCase() + difaEng[0][1]?.toUpperCase();
    } else {
      return ''
    }
  }
};

/**
 * Get the file type from URL
 * @param url - string
 * @returns - file format
 */

export const getFileType = (url: string) => {
  url = url || '';
  url = url.substring(1 + url.lastIndexOf("/"));
  url = url.split('?')[0];
  url = url.split('#')[0];
  const regex = /(?:\.([^.]+))?$/;
  const regArr = regex.exec(url) || [];
  return regArr?.length && regArr[1] ? regArr[1].toUpperCase() : '';
}

/**
 * Get the file name from the url
 * @param url - url
 * @returns - string
 */
export const getFileName = (url: string) => {
  let filename = '';
  try {
    filename = new URL(url).pathname.split('/').pop() as any;
  } catch (e) {
    console.error(e);
  }
  return filename;
}

/**
 * Get the data url from File
 * @param file - File
 * @returns - string
 */

export const toBase64 = (file: File) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = reject;
});

/**
 * Get the data url from file
 * @param url - string
 * @returns - string
 */

export const toDataURL = (url: string) => fetch(url)
  .then(response => response.blob())
  .then(blob => new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.onerror = reject
    reader.readAsDataURL(blob)
  }))

export const formatPullReason = (pullReason: string | undefined) => {
  if (!pullReason) return "-";
  const parts = pullReason.split("-");
  if (parts?.length > 1) {
    return (
      <>
        <p className="font-semibold">{parts[0]}</p>
        <p>{parts[1]}</p>
      </>
    );
  }
  return <p>{pullReason}</p>;
};
