const apiMap: { [key: string]: string | undefined } = {
  api: process.env.REACT_APP_WEB_API_BASE_URL,
};

export const getAPIBaseURL = (id = "api") => {
  const lowercasedId = id.toLowerCase();
  const baseUrl = apiMap[lowercasedId];

  if (baseUrl) {
    //return `${baseUrl}/${lowercasedId}`;
    return `${baseUrl}`;
  }

  return `/${lowercasedId}`;
};
 