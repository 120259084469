import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as ArrowRightIcon } from "../../../images/chevron-right.svg";
import RouteConstant from "../../../constants/routes";
import "./Header.scss";
import { useDispatch, useSelector } from "react-redux";
import { fetchDifaPublishReport, removeDraft, resetPacketBuilderState, setEditFlow } from "../difaPacketBuilderSlice";
import DeleteIcon from "../../../images/delete-icon.svg";
import EditIcon from "../../../images/edit.svg";
import DownloadIcon from "../../../images/download-icon.svg";
import CustomDropdown from "../../common/dropdownWithIcon/DropdownwithIcons";
import DownMenu from '../../../images/chevron-down.svg'
import { toast } from "react-toastify";
export interface HeaderProps {
  pageName: string;
  difaPacketName: string;
  customerName?: string;
  wellName?: string;
  source?: string;
  onSaveHandler: (shouldNavigate: boolean, preview: boolean) => void; // Callback to save function
  onPublishHandler: () => void; // Callback to publish function
  onDeleteHandler?: () => void; // Callback to delete function
}

const options = [
  { label: 'Edit', icon: EditIcon, 'isDisabled': false },
  { label: 'Download', icon: DownloadIcon, 'isDisabled': false },
  { label: 'Delete', icon: DeleteIcon, 'isDisabled': false },
];

const Header: React.FC<HeaderProps> = ({
  pageName,
  difaPacketName,
  customerName,
  wellName,
  source,
  onSaveHandler,
  onPublishHandler,
  onDeleteHandler
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { difaId } = location.state || {};

  const { difaDetails, reportStatus } = useSelector((state: any) => state.difaDetails);
  const { difaPacketStatus } = useSelector(
    (state: any) => state.preInspectionSummary
  );
  const {
    publishedId,
    draftId,
    isEdit
  } = useSelector((state: any) => state.difaPacketBuilder);

  const publishedPacketActions = (option: string) => {
    const isPacket = source?.includes('packet');
    if (option === 'Edit') {
      dispatch(setEditFlow(true))
      isPacket ? navigate("/difa-details/difa-packet-builder", {
        state: { difaId: difaId, customerName: customerName, wellName },
      }) : navigate("/difa-details/difa-report-builder", {
        state: { difaId: difaId, customerName: customerName, wellName },
      });
    } else if (option === 'Download') {
      dispatch(fetchDifaPublishReport({ 'wellApiNumber': difaDetails?.wellApiNumber, difaId }));
    }
    else if (option === 'Delete') {
      onDeleteHandler && onDeleteHandler();
    }
  }
  const gotoDifaDetails = () => {
    dispatch(setEditFlow(false))
    navigate("/difa-details", {
      state: { difaId, customerName, wellName },
    });
  };

  const documents = () => {
    navigate("/documentation", {
      state: { difaId, customerName, wellName },
    });
  };

  const dashboard = () => {
    dispatch(resetPacketBuilderState());
    navigate("/");
  };
  const redirectToWellName = () => {
    wellName &&
      navigate(
        RouteConstant.wellName.replace(
          ":wellName",
          encodeURIComponent(wellName)
        ),
        {
          state: { difaId: difaId, customerName: customerName },
        }
      );
  };

  const redirectToPreview = () => {
    onSaveHandler(false, true);
    // isReportBuilder ?
    //   navigate("/difa-details/difa-report-preview", {
    //     state: { difaId: difaId, customerName: customerName, wellName },
    //   })
    //   : ''
  };

  const redirectToBuilder = () => {
    source === 'preview-report' || source === 'preview-published-report' ?
      navigate("/difa-details/difa-report-builder", {
        state: { difaId: difaId, customerName: customerName, wellName },
      })
      :
      navigate("/difa-details/difa-packet-builder", {
        state: { difaId: difaId, customerName: customerName, wellName },
      });
  };

  const onCloseHandler = (shouldNavigate: boolean) => {
    const isPacket = source?.includes('packet');
    dispatch(removeDraft({
      deleteObj: {
        wellApiNumber: difaDetails?.wellApiNumber,
        difaId: difaId,
      }, source
    }));
    dispatch(setEditFlow(false))
    dispatch(resetPacketBuilderState());
    isPacket ? navigate("/difa-details/preview-difa-packet", {
      state: { difaId: difaId, customerName: customerName, wellName },
    }) : navigate("/difa-details/difa-report-preview", {
      state: { difaId: difaId, customerName: customerName, wellName },
    });
  }

  const redirectToPIS = () => {
    navigate("/difa-details/pi-summary", {
      state: { difaId: difaId, customerName: customerName, wellName },
    });
  };

  const isReportBuilder = source === "report-builder";
  const isPacketBuilder = source === "packet-builder";
  const isPreview = source === "preview-packet" || source === "preview-report";
  const isPublished = source === "preview-published-report" || source === "preview-published-packet";
  const isPublishedPreview = source?.includes('preview');
  const isReport = source?.includes('report');
  return (
    <header className="main-header">
      {isPreview && (
        <div className="preview-container">
          <span className="message">You're now in Preview mode</span>
          <button className="publish-button" onClick={onPublishHandler}>{`Publish DIFA ${source === 'preview-report' ? 'report' : 'packet'}`}</button>
          <button
            className="button back-to-builder-button"
            onClick={redirectToBuilder}
          >
            {`Back to ${source === 'preview-report' ? 'report' : 'packet'} builder`}
          </button>
        </div>
      )}
      {isEdit && isPublishedPreview && (
        <div className="preview-container">
          <span className="message">You're now in Preview mode</span>
          <button className="publish-button" onClick={onPublishHandler}>{`Publish DIFA ${source === 'preview-report' ? 'report' : 'packet'}`}</button>
          <button
            className="button back-to-builder-button"
            onClick={redirectToBuilder}
          >
            {`Back to ${source === 'preview-report' ? 'report' : 'packet'} builder`}
          </button>
        </div>
      )}
      <div className="header-content">
        {!isPreview && !isPublished && !isReportBuilder && (
          <div className="breadcrumb-container">
            <div className="breadcrumb-item" onClick={dashboard}>
              Dashboard
            </div>
            <div className="breadcrumb-separator">
              <ArrowRightIcon />
            </div>
            <div className="breadcrumb-item" onClick={gotoDifaDetails}>
              DIFA details
            </div>
            <div className="breadcrumb-separator">
              <ArrowRightIcon />
            </div>
            <div className="breadcrumb-item" onClick={redirectToPIS}>
              Pre-inspection summary
            </div>
            <div className="breadcrumb-separator">
              <ArrowRightIcon />
            </div>
            <div className="breadcrumb-item active">{pageName}</div>
          </div>
        )}
        {isReportBuilder && (
          <div className="breadcrumb-container">
            <div className="breadcrumb-item" onClick={dashboard}>
              Dashboard
            </div>
            <div className="breadcrumb-separator">
              <ArrowRightIcon />
            </div>
            <div className="breadcrumb-item" onClick={gotoDifaDetails}>
              DIFA details
            </div>
            <div className="breadcrumb-separator">
              <ArrowRightIcon />
            </div>
            <div className="breadcrumb-item active">
              DIFA report builder
            </div>

          </div>
        )}
        {isPublished && (
          <div className="breadcrumb-container">
            <div className="breadcrumb-item" onClick={dashboard}>
              Dashboard
            </div>
            <div className="breadcrumb-separator">
              <ArrowRightIcon />
            </div>
            <div className="breadcrumb-item" onClick={documents}>
              Documents
            </div>
            <div className="breadcrumb-separator">
              <ArrowRightIcon />
            </div>
            <div className="breadcrumb-item">
              DIFA {isReport ? 'report ' : 'packet '} # {draftId}
            </div>

          </div>
        )}

        <div className="header-details">
          <div className="header-details-content">
            <div className="header-title">{difaPacketName}</div>
            <div className="header-meta">
              {customerName && <div className="meta-company">{customerName} |</div>}
              <div className="meta-project">
                {wellName}
              </div>
            </div>
          </div>
          {!isPreview && !isPublished && (
            <div className="flex-row flex gap-3">
              <div className="header-button">
                <button className="header-btn-text" onClick={redirectToPreview}>
                  Preview
                </button>
              </div>

              <div className="header-button">
                {(isPacketBuilder && difaPacketStatus?.isPacketPublished) ||
                  (isReportBuilder && reportStatus?.isReportPublished) ?
                  <button className="header-btn-text" onClick={() => onCloseHandler(true)}>
                    Close
                  </button>
                  :
                  <button className="header-btn-text" onClick={() => onSaveHandler(true, false)}>
                    Save and close
                  </button>}
              </div>
              <div className="header-details-button cursor-pointer gap-2" onClick={onPublishHandler}>
                <button className="header-btn-text" onClick={onPublishHandler}>Publish</button>
                {isReportBuilder && <img alt="down menu" src={DownMenu}></img>}
              </div>
            </div>
          )}
          {isPublished && !isEdit && (
            <div className="flex-row flex gap-3">
              <div className="flex">
                <CustomDropdown options={options} onSelect={publishedPacketActions} />
              </div>
              {/* <div className="header-details-button share">
                <button className="header-btn-text">Share</button>
              </div> */}
            </div>

          )}

        </div>
      </div>
    </header>
  );
};

export default Header;
