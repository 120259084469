import React, { useEffect, useState } from 'react'
import Header from './components/Header';
import DIFASchedular from './components/difaSchedular/DIFASchedular';
import { useDispatch } from 'react-redux';
import { fetchDifaSchedular } from './DIFASchedularSlice';


const ManagerDashboard = () => {

  
  return (
    <div className="dashboard">
      <Header />
      <div className="dashboard-content text-neutral-grey">
        <div className="difa-widget-containers flex flex-col gap-4">
          <div className="header-main border-bottom">
            <h3>DIFA scheduler</h3>
            <h5>View the active DIFA's assigned to your team members</h5>
          </div>
          <DIFASchedular />
        </div>
      </div>
    </div>
  )
}

export default ManagerDashboard
