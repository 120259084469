import AlarmEvents from "./AlarmEvents";
import DifaSwitch from "../../../common/switch/DifaSwitch";
import GalleryComponent from "../../../common/gallery/Gallery";
import { useEffect, useState } from "react";
import OperationalSummary from "./OperationalSummary";
import DPBDocumentation from "./DPBDocumentation";
import MainComponent from "../../../preInspectionSummary/components/InstallReport";
import { fetchInstallReport } from "../../../preInspectionSummary/PreInspectionSummarySlice";
import EditGalleryComponent from "../../../common/gallery/EditGallery";
import { useDispatch, useSelector } from "react-redux";
import { fetchTrendsImageGallery, fetchTrendsReportImages, setWellInstallation } from '../../difaPacketBuilderSlice';
import {
  fetchInstallImageGallery,
  fetchInstallReportImages,
  removeGallery
} from "../../difaPacketBuilderSlice";
import Loader from "../../../common/loader/Loader";
import EmptyView from "../../../common/emptyPreview/View";
import CustomImageDropdown from "../../../common/dropdownWithIcon/CustomImageDropdown";
import ViewGallery from "../../../common/gallery/ViewGallery";
import { galleryUtility } from "../../../common/services/GalleryUtility";

const InstallAndWellOperation: React.FC<{
  wellApiNumber: string;
  difaId: string;
  source: string;
}> = ({ wellApiNumber, difaId, source }) => {
  const { wellInstallation } = useSelector(
    (state: any) => state.difaPacketBuilder
  );
  const [showGallery, setShowGallery] = useState(false);
  const [showEditGallery, setShowEditGallery] = useState(false);
  const [galleryMode, setGalleryMode] = useState('create');
  const [showReport, setShowReport] = useState(false);
  const [activeGalleryItem, setActiveGalleryItem] = useState(null as any);
  const [includeGallery, setIncludeGallery] = useState(wellInstallation?.isGallerySelected || false);
  const [includeTrendGallery, setIncludeTrendGallery] = useState(wellInstallation?.isTrendGallerySelected || false);
  const dispatch = useDispatch();
  const {
    installReportImages,
    installImageGallery,
    trendsImageGallery,
    trendsReportImages,
    loading,
    galleryTransactionMsg,
  } = useSelector((state: any) => state?.difaPacketBuilder);
  const [showTrendGallery, setShowTrendGallery] = useState(false);
  const [showEditTrendGallery, setShowEditTrendGallery] = useState(false);
  const [activeTrendGalleryItem, setActiveTrendGalleryItem] = useState(null as any);
  const [trendGalleryMode, setTrendGalleryMode] = useState('create');
  const [showViewGallery, setShowViewGallery] = useState(false);
  const [images, setImages] = useState([] as any);

  useEffect(() => {
    if (!installReportImages?.length && showGallery) {
      dispatch(fetchInstallReportImages({ wellApiNumber, difaId }));
    }
  }, [showGallery]);

  useEffect(() => {
    if (!trendsReportImages?.length && showGallery) {
      dispatch(fetchTrendsReportImages({ wellApiNumber, difaId }));
    }
  }, [showTrendGallery]);

  const refreshInstallGallery = () => {
    dispatch(fetchInstallImageGallery({ wellApiNumber, difaId, source }));
  }

  const refreshTrendsGallery = () => {
    dispatch(fetchTrendsImageGallery({ wellApiNumber, difaId, source }));
  }

  useEffect(() => {
    refreshInstallGallery();
    ['report-builder', 'report-preview'].includes(source) && refreshTrendsGallery();
  }, []);

  const onExpand = (source: string) => {
    if (source === 'install') {
      setIncludeGallery(!includeGallery);
      dispatch(setWellInstallation({ ...wellInstallation, isGallerySelected: !includeGallery }))
    }
    if (source === 'trend') {
      setIncludeTrendGallery(!includeTrendGallery);
      dispatch(setWellInstallation({ ...wellInstallation, isTrendGallerySelected: !includeTrendGallery }))
    }
  }

  const handleRemove = (galleryId: string) => {
    if (galleryId) {
      dispatch(removeGallery({ galleryId, source }));
    }
  };

  const isPreview = source === "preview-packet" || source === "preview-report";
  const isReportBuilder = source === 'report-builder';
  const isReportPreview = source === 'preview-report';

  const openReport = () => {
    if (!showReport) {
      dispatch(fetchInstallReport({ wellApiNumber: wellApiNumber }));
      setShowReport(!showReport);
    }
  };

  const EditGalleryHandle = (showEditGallery: boolean, gl: any) => {
    if (gl?.reportType === "trend") {
      setShowEditTrendGallery(showEditGallery);
      setActiveTrendGalleryItem(gl);
    } else if (gl?.reportType === "install") {
      setShowEditGallery(showEditGallery);
      setActiveGalleryItem(gl);
    }
  }
  const isReport = source.includes("report");
    

  return (
    <div className="flex-auto flex flex-col w-full gap-5">
      {loading && !showGallery && <Loader isOverlay />}
      {isPreview ? (
        /* Preview section starts here*/
        <>
          {!wellInstallation?.isOpSummarySelected && !wellInstallation?.isAlarmsSelected
            && !wellInstallation?.isGallerySelected && !wellInstallation?.isDocumentationSelected &&
            <EmptyView />}
          {(wellInstallation?.isOpSummarySelected || (wellInstallation?.isTrendGallerySelected && isReportPreview)) && (
            <div className="">
              <div className="difa-widget-containers">
                <div className="header-main">
                  <div className="flex flex-row justify-between flex-wrap">
                    <div>
                      <h3>Well operations</h3>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-5 py-5 px-6 border-t border-dark">
                  {wellInstallation?.isOpSummarySelected &&
                    <div className="difa-widget-containers px-4 py-4">
                      <h3 className="border-bottom pb-2">Operational summary</h3>
                      <h5 className="pt-5">{wellInstallation?.opSummary}</h5>
                    </div>
                  }
                  {wellInstallation?.isTrendGallerySelected && isReportPreview && (
                    <div className="difa-widget-containers px-4 py-4">
                      <h3 >Trend gallery</h3>
                      <h5 className="border-bottom pb-2">
                        In this gallery, our DIFA engineers have analyzed and annotated operational trends to provide insight into what led to this ESP's failure.
                      </h5>

                      {!trendsImageGallery || !trendsImageGallery?.length ? (
                        <div className="flex flex-col gap-5 p-6 border-t border-dark">
                          <div className="flex p-6 border border-dark rounded-lg items-center justify-center bg-dark-800">
                            <p className="font-normal text-sm text-center py-6">
                              No images have been added. To add trend images select the
                              “Create trend gallery” button.
                            </p>
                          </div>
                        </div>
                      ) : (
                        <div className="flex flex-row gap-5 p-6 border-t border-dark cursor-pointer rounded-md flex-wrap">
                          {trendsImageGallery &&
                            trendsImageGallery.map((gl: any, index: number) => (
                              <div
                                className="flex flex-col gap-3  w-72 max-w-72 "
                                onClick={() => {
                                  setImages(gl?.reportImageDetails);
                                  setShowViewGallery(true);
                                }}
                              >
                                <div className={`difa-widget-containers  difa-background relative max-w-72 h-[270px] p-2`}>
                                  <div className="w-full h-full flex items-center flex-col">
                                    {gl.reportImageDetails &&
                                      gl.reportImageDetails?.length && (
                                        <img
                                          className="h-48 w-full"
                                          src={gl.reportImageDetails[0].thumbnailFileLink}
                                          alt={"gallery"}
                                        ></img>
                                      )}
                                    <div className="flex justify-between w-full items-center p-2">
                                      <h2> {gl?.galleryName}</h2>
                                      <h5>{gl?.reportImageDetails?.length} images</h5>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                      )}
                    </div>
                  )}
                </div>
                {showViewGallery && (
                  <ViewGallery images={images} show={showViewGallery} close={() => setShowViewGallery(false)} name="Trends images" />
                )}
              </div>
            </div>
          )}
          {wellInstallation?.isAlarmsSelected && (
            <AlarmEvents wellApiNumber={wellApiNumber} source={source} />
          )}

          {wellInstallation?.isGallerySelected &&
            <>
              <div className="difa-widget-containers">
                {showReport && (
                  <MainComponent open={showReport} onCloseModal={setShowReport} />
                )}
                <div className="header-main">
                  <div className="flex flex-row justify-between flex-wrap">
                    <div>
                      <h3>Install photos</h3>
                      <h5>Photo evidence taken during installation</h5>
                    </div>
                    <div className="flex flex-row items-center gap-3">
                      <button
                        className="btn-secondary font-bold text-sm"
                        onClick={() => openReport()}
                      >
                        View install packet
                      </button>
                    </div>
                  </div>
                </div>
                {installImageGallery && !installImageGallery?.length ? (
                  <div className="flex flex-col gap-5 p-6 border-t border-dark">
                    <div className="flex p-6 border border-dark rounded-lg items-center justify-center bg-dark-800">
                      <p className="font-normal text-sm text-center py-6">
                        No images have been added. To add trend images select the
                        “Create trend gallery” button.
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-row gap-5 p-6 border-t border-dark cursor-pointer rounded-md flex-wrap">
                    {installImageGallery &&
                      installImageGallery.map((gl: any, index: number) => (
                        <div
                          className="flex flex-col gap-3 w-72 max-w-72"
                        >
                          <div
                            className={`difa-widget-containers  difa-background relative max-w-72 h-[270px] p-2`}
                            onClick={() => {
                              console.log(gl.reportImageDetails);
                              setImages(gl?.reportImageDetails);
                              setShowViewGallery(true);
                            }}
                          >
                            <div className="w-full h-full flex items-center flex-col relative">
                              <img
                                className="h-48 w-full"
                                src={gl.reportImageDetails[0].thumbnailFileLink}
                                alt={"gallery"}
                              ></img>
                              <div className="flex justify-between w-full items-center p-2 gap-3">
                                <h2 className="break-all"> {gl.galleryName}</h2>
                                <h5>{gl && gl.reportImageDetails?.length}&nbsp;images</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                )}
              </div>
              {showViewGallery && (
                <ViewGallery images={images} show={showViewGallery} close={() => setShowViewGallery(false)} name="install images" />
              )}
            </>
          }

          {wellInstallation?.isDocumentationSelected && (
            <DPBDocumentation wellApiNumber={wellApiNumber} source={source} />
          )}
        </>
        /* Preview section ends here*/
      ) : (
        /* Report builder section starts here  */
        <>
          <DPBDocumentation wellApiNumber={wellApiNumber} source={source} />
          <>
            {/* Install image gallery for report builder starts here */}
            <div className="difa-widget-containers">
              <div className="header-main">
                <div className="flex flex-row justify-between flex-wrap">
                  <div>
                    <h3>Install image gallery</h3>
                    <h5>
                      Upload, edit, and annotate images of installation photos.
                    </h5>
                  </div>
                  <div className="flex flex-row items-center gap-3">
                    <DifaSwitch checked={includeGallery} onChange={() => { onExpand('install') }} />
                    <h4 className="whitespace-nowrap">Include in {isReport ? 'report' : 'packet'}</h4>
                    <button
                      className="btn-secondary font-bold text-sm"
                      onClick={() => {
                        setGalleryMode('create');
                        setShowGallery(true);
                      }}
                    >
                      Create gallery
                    </button>
                  </div>
                </div>
              </div>
              {!installImageGallery || !installImageGallery?.length ? (
                <div className="flex flex-col gap-5 p-6 border-t border-dark">
                  <div className="flex p-6 border border-dark rounded-lg items-center justify-center bg-dark-800">
                    <p className="font-normal text-sm text-center py-6">
                      No images have been added. To add trend images select the
                      “Create trend gallery” button.
                    </p>
                  </div>
                </div>
              ) : (
                <div className="flex flex-row gap-5 p-6 border-t border-dark cursor-pointer rounded-md flex-wrap">
                  {installImageGallery &&
                    installImageGallery.map((gl: any, index: number) => (
                      <div
                        className="flex flex-col gap-3  w-72 max-w-72"
                      >
                        <div
                          className={`difa-widget-containers  difa-background relative max-w-72 h-[270px] p-2`}
                        >
                          <div className="w-full h-full flex items-center flex-col relative">
                            <CustomImageDropdown source={source} gl={gl} removeGallery={(galleryId: string) => galleryUtility.handleRemove(galleryId, source, refreshInstallGallery)} editGalleryHandle={EditGalleryHandle} onRenameSuccess={refreshInstallGallery} />
                            {gl.reportImageDetails &&
                              gl.reportImageDetails?.length && (
                                <img
                                  className="h-48 w-full"
                                  src={gl.reportImageDetails[0].thumbnailFileLink}
                                  onClick={() => EditGalleryHandle(true, gl)}
                                  alt={"gallery"}
                                ></img>
                              )}
                            <div className="flex justify-between w-full gap-3 p-2">
                              <h2 className="break-all"> {gl?.galleryName}</h2>
                              <h5>{gl?.reportImageDetails?.length}&nbsp;images</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              )}
            </div>
            {showGallery && (
              <GalleryComponent
                difaID={difaId}
                reportType="install"
                show={showGallery}
                mode={galleryMode}
                images={installReportImages}
                loading={loading}
                onGalleryClose={() => {
                  setShowGallery(false);
                  refreshInstallGallery();
                }}
                wellApiNumber={wellApiNumber}
                source={source}
              />
            )}
            {showEditGallery && (
              <EditGalleryComponent
                show={showEditGallery}
                galleryID={activeGalleryItem?.galleryID}
                onRemoveGallery={(galleryId: string) => galleryUtility.handleRemove(galleryId, source, refreshInstallGallery)}
                onAddNewImages={() => {
                  setShowEditGallery(false);
                  setGalleryMode('update');
                  setShowGallery(true);
                }}
                onGalleryClose={() => {
                  setShowEditGallery(false);
                  setActiveGalleryItem(null);
                  refreshInstallGallery();
                }}
                wellApiNumber={wellApiNumber}
                source={source}
              />
            )}
            {/* Install image gallery for report builder ends here */}
          </>

          <AlarmEvents wellApiNumber={wellApiNumber} source={source} />
          {isReportBuilder && (
            <>
              {/* Trends image gallery for report builder starts here */}
              <div className="difa-widget-containers">
                <div className="header-main">
                  <div className="flex flex-row justify-between flex-wrap">
                    <div>
                      <h3>Trend history gallery</h3>
                      <h5>Upload, edit, and annotate trend lines</h5>
                    </div>
                    <div className="flex flex-row items-center gap-3">
                      <DifaSwitch checked={includeTrendGallery} onChange={() => { onExpand('trend') }} />
                      <h4 className="whitespace-nowrap">Include in {isReport ? 'report' : 'packet'}</h4>
                      <button
                        className="btn-secondary font-bold text-sm"
                        onClick={() => {
                          setTrendGalleryMode('create');
                          setShowTrendGallery(true);
                        }}
                      >
                        Create trend gallery
                      </button>
                    </div>
                  </div>
                </div>
                {!trendsImageGallery || !trendsImageGallery?.length ? (
                  <div className="flex flex-col gap-5 p-6 border-t border-dark">
                    <div className="flex p-6 border border-dark rounded-lg items-center justify-center bg-dark-800">
                      <p className="font-normal text-sm text-center py-6">
                        No images have been added. To add trend images select the
                        “Create trend gallery” button.
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-row gap-5 p-6 border-t border-dark cursor-pointer rounded-md flex-wrap">
                    {trendsImageGallery &&
                      trendsImageGallery.map((gl: any, index: number) => (
                        <div
                          className="flex flex-col gap-3  w-72 max-w-72 "
                        >
                          <div
                            className={`difa-widget-containers  difa-background relative max-w-72 h-[270px] p-2`}
                          >
                            <div className="w-full h-full flex items-center flex-col relative">
                              <CustomImageDropdown gl={gl} source={source} removeGallery={(galleryId: string) => galleryUtility.handleRemove(galleryId, source, refreshTrendsGallery)} editGalleryHandle={EditGalleryHandle} onRenameSuccess={refreshTrendsGallery} />
                              {gl.reportImageDetails &&
                                gl.reportImageDetails?.length && (
                                  <img
                                    className="h-48 w-full"
                                    src={gl.reportImageDetails[0].thumbnailFileLink}
                                    alt={"gallery"}
                                    onClick={() => EditGalleryHandle(true, gl)}
                                  ></img>
                                )}
                              <div className="flex justify-between w-full items-center p-2 gap-3">
                                <h2 className="break-all"> {gl?.galleryName}</h2>
                                <h5>{gl?.reportImageDetails?.length}&nbsp;images</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                )}
              </div>
              {showTrendGallery && (
                <GalleryComponent
                  difaID={difaId}
                  reportType="trend"
                  show={showTrendGallery}
                  mode={trendGalleryMode}
                  images={trendsReportImages}
                  loading={loading}
                  onGalleryClose={() => {
                    setShowTrendGallery(false);
                    refreshTrendsGallery();
                  }}
                  wellApiNumber={wellApiNumber}
                  showGalleryTab={false}
                  source={source}
                />
              )}
              {showEditTrendGallery && (
                <EditGalleryComponent
                  show={showEditTrendGallery}
                  galleryID={activeTrendGalleryItem?.galleryID}
                  onRemoveGallery={(galleryId: string) => galleryUtility.handleRemove(galleryId, source, refreshTrendsGallery)}
                  onAddNewImages={() => {
                    setShowEditTrendGallery(false);
                    setTrendGalleryMode('update');
                    setShowTrendGallery(true);
                  }}
                  onGalleryClose={() => {
                    setShowEditTrendGallery(false);
                    setActiveTrendGalleryItem(null);
                    refreshTrendsGallery();
                  }}
                  wellApiNumber={wellApiNumber}
                  source={source}
                />
              )}
              {/* Trends image gallery for report builder starts here */}
            </>
          )}
          <OperationalSummary source={source}/>
        </>
        /* Report builder section ends here  */
      )
      }
    </div >
  );
};

export default InstallAndWellOperation;
