import { ChangeEvent, useState } from "react";
import DifaSwitch from "../../../common/switch/DifaSwitch";
import './../WellRunLifeInformation.scss'
import pumpIcon from "../../../../images/determine-pump.svg";
import { fetchRundaysDetails } from "../../difaPacketBuilderSlice";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../common/loader/Loader";

const SizingSummary: React.FC<{ wellApiNumber: string }> = ({
    wellApiNumber
}) => {
    const [expanded, setExpanded] = useState(false);
    const dispatch = useDispatch();

    const { sizingSummary, loading } = useSelector(
        (state: any) => state.difaPacketBuilder
    );

    const onExpand = (e: ChangeEvent<HTMLInputElement>) => {
        const checked = e.target.checked;
        setExpanded(checked);
        if (checked && !sizingSummary) {
            dispatch(fetchRundaysDetails({ wellApiNumber }));
        }
    }

    return (
        <div className="difa-widget-containers">
            <div className="header-main">
                <div className="flex flex-row justify-between">
                    <div>
                        <h3>DETERMINE sizing summary</h3>
                        <h5>The sizing summary provides an overview of the ESP design's key components, along with the downhole and operating conditions.</h5>
                    </div>
                    <div className="flex flex-row items-center gap-3">
                        <DifaSwitch checked={expanded} onChange={onExpand} />
                        <h4 className="whitespace-nowrap">Include in report</h4>
                    </div>
                </div>
            </div>
            {loading && <Loader isOverlay />}
            {expanded && sizingSummary && <div className="container-body border-t border-dark flex flex-col gap-2">
                <div className="flex flex-col gap-2 row-bottom-line pb-4 mb-4">
                    <h5>Project Name</h5>
                    <h1 className="header-title">{sizingSummary.projectName}</h1>
                    <h2>Equipment and performance report</h2>
                </div>
                <div className="flex flex-col gap-4">
                    <div className="difa-widget-containers p-2 flex-col gap-2">
                        <div className="flex flex-row row-bottom-line py-1">
                            <label className="w-2/5">Customer</label>
                            <label className="w-3/5">{sizingSummary.customer}</label>
                        </div>
                        <div className="flex flex-row row-bottom-line py-1">
                            <label className="w-2/5">Well Name</label>
                            <label className="w-3/5">{sizingSummary.wellname}</label>
                        </div>
                        <div className="flex flex-row row-bottom-line py-1">
                            <label className="w-2/5">Formation</label>
                            <label className="w-3/5">Formation</label>
                        </div>
                        <div className="flex flex-row row-bottom-line py-1">
                            <label className="w-2/5">Project Name</label>
                            <label className="w-3/5">{sizingSummary.projectName}</label>
                        </div>
                        <div className="flex flex-row row-bottom-line py-1">
                            <label className="w-2/5">ChampionX Representative</label>
                            <label className="w-3/5">{sizingSummary.projectName}</label>
                        </div>
                        <div className="flex flex-row row-bottom-line py-1">
                            <label className="w-2/5">Customer Representative</label>
                            <label className="w-3/5">{sizingSummary.customerRepresentative}</label>
                        </div>
                        <div className="flex flex-row py-1">
                            <label className="w-2/5">Well API Number</label>
                            <label className="w-3/5">{wellApiNumber}</label>
                        </div>
                    </div>
                    <div className="relative flex flex-col gap-4">
                        <div className="difa-widget-containers p-2 max-w-96 flex-col gap-2">
                            <h3>Casing/Tubing</h3>
                            <div className="flex flex-row row-bottom-line py-1">
                                <label className="w-2/5">Casing</label>
                                <label className="w-3/5">{sizingSummary.casing}</label>
                            </div>
                            <div className="flex flex-row row-bottom-line py-1">
                                <label className="w-2/5">Casing pressure</label>
                                <label className="w-3/5">{sizingSummary.casingPressure}</label>
                            </div>
                            <div className="flex flex-row py-1">
                                <label className="w-2/5">Tubing pressure</label>
                                <label className="w-3/5">{sizingSummary.tubingPressure}</label>
                            </div>
                        </div>

                        <div className="difa-widget-containers p-2 max-w-96 flex-col gap-2">
                            <h3>Surface Electrical @ {sizingSummary.surfaceElectricalCost}</h3>
                            <div className="flex flex-row row-bottom-line py-1">
                                <label className="w-2/5">Supply voltage</label>
                                <label className="w-3/5">{sizingSummary.supplyVoltage}</label>
                            </div>
                            <div className="flex flex-row row-bottom-line py-1">
                                <label className="w-2/5">Surface voltage</label>
                                <label className="w-3/5">{sizingSummary.supplyVoltage}</label>
                            </div>
                            <div className="flex flex-row row-bottom-line py-1">
                                <label className="w-2/5">Surface power</label>
                                <label className="w-3/5">{sizingSummary.surfacePowerFactor}</label>
                            </div>
                            <div className="flex flex-row  py-1">
                                <label className="w-2/5">Drive amperage</label>
                                <label className="w-3/5">{sizingSummary.surfaceDriveAmpsva}</label>
                            </div>
                        </div>

                        <div className="difa-widget-containers p-2 max-w-xl flex-col gap-2">
                            <h3>Equipment</h3>
                            <div className="flex flex-row row-bottom-line py-1">
                                <label className="w-2/5">Main pump</label>
                                <label className="w-3/5">{sizingSummary.pump}</label>
                            </div>
                            <div className="flex flex-row row-bottom-line py-1">
                                <label className="w-2/5">Gas separator</label>
                                <label className="w-3/5">{sizingSummary.gasSeparator}</label>
                            </div>
                            <div className="flex flex-row row-bottom-line py-1">
                                <label className="w-2/5">Protector</label>
                                <label className="w-3/5">{sizingSummary.protector}</label>
                            </div>
                            <div className="flex flex-row row-bottom-line py-1">
                                <label className="w-2/5">Motor</label>
                                <label className="w-3/5">{sizingSummary.motor}</label>
                            </div>
                            <div className="flex flex-row row-bottom-line py-1">
                                <label className="w-2/5">Sensor</label>
                                <label className="w-3/5">{sizingSummary.sensor}</label>
                            </div>
                            <div className="flex flex-row row-bottom-line py-1">
                                <label className="w-2/5">Controller</label>
                                <label className="w-3/5">{sizingSummary.controller}</label>
                            </div>
                            <div className="flex flex-row row-bottom-line py-1">
                                <label className="w-2/5">Harmonic filter</label>
                                <label className="w-3/5">{sizingSummary.harmonicFilterSelection}</label>
                            </div>
                            <div className="flex flex-row py-1">
                                <label className="w-2/5">Cable</label>
                                <label className="w-3/5">{sizingSummary.cable}</label>
                            </div>
                        </div>
                        <div className="absolute right-0 hidden text-right lg:flex justify-end ">
                            <img
                                src={pumpIcon}
                                className="lg:w-2/5 xl:w-3/4 2xl:w-full"
                                id="pump-icon"
                                alt="pump icon"
                            />
                        </div>
                    </div>
                </div>
            </div>}
        </div>
    )
}

export default SizingSummary;