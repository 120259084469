import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Header from "./components/Header";
import PISummaryDifaDetails from "./components/PISummaryDifaDetails";
import PISummarySnapshotandChart from "./components/PISummaryChart";
import VerticalTab from "../common/verticalTabs/VerticalTab";
import { fetchDismantlePumps } from "../dismentleRequest/DismantleRequestSlice";
import { useDispatch, useSelector } from "react-redux";
import { fetchDifaDetails, fetchInboundTestReport } from "../DifaPages/DifaDetailsSlice";
import { fetchRundaysDetails, fetchOptimizationTickets, fetchDifaPacketStatus } from "./PreInspectionSummarySlice";
import "./PreInspectionSummary.scss";
import AlarmHistory from "./components/AlarmHistory";
import PISummaryDocumentation from "./components/PISummaryDocumentaion";
import Loader from "../common/loader/Loader";
import { fetchPublishedDraft } from "../difaPacketBuilder/difaPacketBuilderSlice";

const PreInspectionSummary = () => {
  const [isPublished, setIsPublished] = React.useState(false);
  const [isPacketAvailable, setIsPacketAvailable] = React.useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const { difaId, customerName, wellName } = location.state || {};
  const { difaDetails, loading, inboundTestReport } = useSelector((state: any) => state.difaDetails);
  const { difaPacketStatus } = useSelector((state: any) => state.preInspectionSummary);
  useEffect(() => {
    dispatch(fetchDifaDetails({ difaId }));
    dispatch(fetchDismantlePumps({ difaId }));
    if (!inboundTestReport) {
      dispatch(fetchInboundTestReport({ difaId }));
    }
  }, [dispatch, difaId, inboundTestReport]);

  useEffect(() => {
    if (difaDetails && Object.keys(difaDetails)?.length > 0) {
      dispatch(fetchRundaysDetails({ wellApiNumber: difaDetails?.wellApiNumber }));
      dispatch(fetchOptimizationTickets({ wellName: encodeURIComponent(difaDetails?.wellName) }));
      dispatch(fetchDifaPacketStatus({ wellApiNumber: difaDetails?.wellApiNumber, difaId }));

    }
  }, [dispatch, difaDetails, difaId]);

  useEffect((
  ) => {
    if (difaPacketStatus) {
      setIsPublished(difaPacketStatus?.isPacketPublished);
      setIsPacketAvailable(difaPacketStatus?.isPacketExist);
    }
    dispatch(fetchPublishedDraft({ wellApiNumber: difaDetails?.wellApiNumber, difaId }))
  }, [difaPacketStatus, difaDetails, difaId, dispatch]);


  const openInboundReport = () => {
    const pdfUrl = inboundTestReport && inboundTestReport?.fileLink;
    if (pdfUrl) {
      window.open(pdfUrl, "_blank");
    };
  }

  return (
    <div className="pis-details">
      <Header
        headerName="Pre-inspection summary"
        customerName={customerName}
        wellName={wellName}
        isPacketAvailable={isPacketAvailable}
        ispacketPublished={isPublished}
      />
      <div className="pi-summary-container">
        {loading && <Loader isOverlay />}
        <PISummaryDifaDetails difaId={difaId} />
        <PISummarySnapshotandChart wellApiNumber={difaDetails?.wellApiNumber} summary={difaDetails?.preInspectionSummary} customerName={customerName} wellName={wellName} />
        <div className="pi-summary-difa-dismantle-request">
          <div className="pis-inbound-test-header">
            <h3>Inbound testing results</h3>
            <div className="pis-inbound-test-btn cursor-pointer" onClick={openInboundReport}>
              <button className="pis-inbount-test-btn-text" disabled={!inboundTestReport} >
                View inbound testing report
              </button>
            </div>
          </div>
          <div className="pis-difa-ds-tab-container">
            <VerticalTab difaId={difaId} wellName={wellName} />
          </div>
        </div>
        <AlarmHistory wellApiNumber={difaDetails?.wellApiNumber} />
        <div className="pi-summary-difa-dismantle-request">
          <div className="pis-inbound-test-header">
            <h3>Documentation</h3>
          </div>
          <PISummaryDocumentation />
        </div>
      </div>
    </div>
  );
};

export default PreInspectionSummary;
