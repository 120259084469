// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.report-builder .difa-inspection .input-field {
  color: #f7f9f9;
  font-family: Mulish;
  font-size: 16px;
  box-sizing: border-box;
  font-weight: 400;
  line-height: 24px;
  border-radius: var(--radius-md, 8px);
  border: 1px solid var(--Darkmode-400, #4a5463);
  background: var(--Dark-mode-800, #001023);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  padding: 10px 12px;
}
.report-builder .difa-inspection .radio-btn {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid var(--Dark-mode-300, #5D6675);
  background: var(--Dark-mode-800, #001023);
  appearance: none;
  cursor: pointer;
  accent-color: #00789E;
}
.report-builder .difa-inspection .radio-btn:focus {
  outline: #00789E;
}
.report-builder .difa-inspection .radio-btn:checked {
  background: var(--Dark-mode-400, #00789E);
}
.report-builder .difa-inspection .save-button {
  transform: rotate(-0.11deg);
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #005f7c;
  background: #00789e;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}`, "",{"version":3,"sources":["webpack://./src/features/difaReport/DifaReportBuilder.scss"],"names":[],"mappings":"AAEQ;EACI,cAAA;EACA,mBAAA;EACA,eAAA;EACA,sBAAA;EACA,gBAAA;EACA,iBAAA;EACA,oCAAA;EACA,8CAAA;EACA,yCAAA;EACA,kDAAA;EACA,kBAAA;AADZ;AAIQ;EACI,WAAA;EACA,YAAA;EACA,kBAAA;EACA,+CAAA;EACA,yCAAA;EACA,gBAAA;EACA,eAAA;EACA,qBAAA;AAFZ;AAIY;EACI,gBAAA;AAFhB;AAKY;EACI,yCAAA;AAHhB;AAOQ;EACI,2BAAA;EACA,kBAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;EACA,yBAAA;EACA,mBAAA;EACA,kDAAA;AALZ","sourcesContent":[".report-builder {\n    .difa-inspection {\n        .input-field {\n            color: #f7f9f9;\n            font-family: Mulish;\n            font-size: 16px;\n            box-sizing: border-box;\n            font-weight: 400;\n            line-height: 24px;\n            border-radius: var(--radius-md, 8px);\n            border: 1px solid var(--Darkmode-400, #4a5463);\n            background: var(--Dark-mode-800, #001023);\n            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);\n            padding: 10px 12px;\n        }\n\n        .radio-btn {\n            width: 16px;\n            height: 16px;\n            border-radius: 50%;\n            border: 1px solid var(--Dark-mode-300, #5D6675);\n            background: var(--Dark-mode-800, #001023);\n            appearance: none;\n            cursor: pointer;\n            accent-color: #00789E;\n\n            &:focus {\n                outline: #00789E;\n            }\n\n            &:checked {\n                background: var(--Dark-mode-400, #00789E);\n            }\n        }\n\n        .save-button {\n            transform: rotate(-0.11deg);\n            padding: 10px 16px;\n            justify-content: center;\n            align-items: center;\n            border-radius: 8px;\n            border: 1px solid #005f7c;\n            background: #00789e;\n            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
