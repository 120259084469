import React, { useEffect, useRef, useState } from "react";
import DifaSwitch from "../../../common/switch/DifaSwitch";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchPullImageGallery,
  fetchPullReportImages,
  removeGallery,
  resetCreateGalleryState,
} from "../../difaPacketBuilderSlice";
import { useLocation } from "react-router-dom";
import GalleryComponent from "../../../common/gallery/Gallery";
import EditGalleryComponent from "../../../common/gallery/EditGallery";
import { toast } from "react-toastify";
import Loader from "../../../common/loader/Loader";
import { setPullReasons } from "../../difaPacketBuilderSlice";
import { fetchPullReport } from "../../../preInspectionSummary/PreInspectionSummarySlice";
import PullReport from "../../../preInspectionSummary/components/PullReport";
import ViewGallery from "../../../common/gallery/ViewGallery";
import CustomImageDropdown from "../../../common/dropdownWithIcon/CustomImageDropdown";
import { ReactComponent as Arrowdown } from "../../../../images/chevron-down.svg";
import { color } from "@amcharts/amcharts5";
import { toaster } from "../../../common/services/ToasterService";
import { galleryUtility } from "../../../common/services/GalleryUtility";

const PullReason: React.FC<{ source: string }> = ({ source }) => {
  const { pullReasons } = useSelector((state: any) => state.difaPacketBuilder);

  const [expanded, setExpanded] = useState(
    pullReasons?.isPullSelected || false
  );
  const onExpand = () => {
    setExpanded(!expanded);
    dispatch(setPullReasons({ ...pullReasons, isPullSelected: !expanded }));
  };
  const location = useLocation();
  const { difaDetails } = useSelector((state: any) => state.difaDetails);
  const { difaId } = location.state || {};
  const [galleryMode, setGalleryMode] = useState("create");
  const [activeGalleryItem, setActiveGalleryItem] = useState(null as any);
  const [reasonForPull, setReasonForPull] = useState({
    failureCategory: pullReasons?.failureCategory || "",
    failureDescriptors: pullReasons?.failureDescriptors || "",
    pullSummary: pullReasons?.pullSummary || "",
  });

  const [categorySearchTerm, setcategorySearchTerm] = useState("");
  const [categoryDropdownOpen, setcategoryDropdownOpen] = useState(false);
  const catogoryOptions = [
    "Coated",
    "Corroded",
    "Electrical",
    "External",
    "External / Mechanical",
    "Material",
    "Material / External",
    "Mechanical",
    "Mechanical, Material",
    "Non-ESP Component",
    "Primary Failed Sub-Component Impellers/Diffusers",
    "Short Circuit",
    "Vibration",
    "Unknown",
    "Other",
    "None",
  ];
  const dispatch = useDispatch();
  const [descriptorsSearchTerm, setdescriptorsSearchTerm] = useState("");
  const [descriptorsDropdownOpen, setdescriptorsDropdownOpen] = useState(false);
  const descriptorsOptions = [
    "Brittle",
    "Broken",
    "Broken / Fractured",
    "Broken Shaft",
    "Broken, Damaged",
    "Broken/ fractured/vibration",
    "Buckled",
    "Burn",
    "Burn Overheated",
    "Burn/Melted",
    "Burst / Ruptured",
    "Cause General",
    "Coated",
    "Coated - Internal",
    "Coated - solids",
    "Coated External",
    "Coated Internal",
    "Coated internal / plugged",
    "Coated internal/eroded",
    "Collapsed",
    "Contaminated / Contamination",
    "Corroded",
    "Corroded ( Eroded/Pressure Washed)",
    "Cracked",
    "Cracked / Swollen",
    "Current Leakage",
    "Damage / Damaged",
    "Damaged / Punctured",
    "Degraded",
    "Discolored",
    "Disconnected",
    "Eroded",
    "Eroded (Up thrust wear)",
    "Eroded / Pressure Washed",
    "Failed Hypot Test",
    "Fracture/Broken",
    "Fractured",
    "Fractured/Broken",
    "High Impedance/Resistance",
    "Imploded and Eroded",
    "Internal/ External",
    "Leaking",
    "Leaking / Loose / Spinning",
    "Loose/Spinning",
    "Low Impedance / Resistance",
    "Melted",
    "Melted and Overheated",
    "Missing",
    "Missing (Third Party INNOVEX)",
    "Non-ESP Component",
    "None",
    "Open Circuit",
    "Other",
    "Overheated",
    "Overheated ( Worn)",
    "Overheated (Swollen in cable)",
    "Overheated / Worn",
    "Overheated Burn",
    "Overheated/brittle",
    "Overheated/Degraded",
    "Overheating",
    "Parted",
    "Phase Imbalance / Phase Unbalance / Phase Unbalanced",
    "Plugged",
    "Plugged ( Pump Base Leaking - Secondary)",
    "Plugged ( tailpipe)",
    "Plugged / Broken/Fractured",
    "Plugged-Coated",
    "Plugged / Stuck",
    "Punctured",
    "Punctured - Damaged",
    "Short",
    "Short Circuit",
    "Short Circuit Well",
    "Short Circuit, Phase Unbalance",
    "Squashed/Flattened",
    "Stuck",
    "Stuck / worn",
    "Stuck Closed",
    "Twisted",
    "Unknown",
    "Vibration",
    "Vibration/ Rub Marks",
    "Vibration/Rub Marks",
    "Vibration/Unbalanced/ Loose-Spinning",
    "Wear / Worn",
    "Worn Overheated",
    "Worn/ Overheated",
    "Worn/ Plugged",
    "Worn/Corroded",
    "Worn/Eroded",
  ];
  const [showGallery, setShowGallery] = useState(false);
  const [showEditGallery, setShowEditGallery] = useState(false);
  const [showViewGallery, setShowViewGallery] = useState(false);
  const [images, setImages] = useState([] as any);
  const isPreview = source === "preview-packet" || source === "preview-report";
  const isReportBuilder = source === "report-builder";
  const { pullReportImages, pullImageGallery, loading, galleryTransactionMsg } =
    useSelector((state: any) => state?.difaPacketBuilder);
  const [showPullReport, setPullReport] = useState(false);

  const openPullReport = () => {
    if (!showPullReport) {
      dispatch(fetchPullReport({ wellApiNumber: difaDetails?.wellApiNumber }));
      setPullReport(!showPullReport);
    }
  };

  useEffect(() => {
    if (!pullReportImages?.length && showGallery) {
      dispatch(
        fetchPullReportImages({
          wellApiNumber: difaDetails.wellApiNumber,
          difaId,
        })
      );
    }
  }, [showGallery]);

  useEffect(() => {
    refreshGallery();
  }, []);

  const refreshGallery = () => {
    dispatch(fetchPullImageGallery({
      wellApiNumber: difaDetails.wellApiNumber,
      difaId,
      source
    }));
  }

  const catogoryDropdownRef = useRef<HTMLDivElement>(null);

  const descriptorsDropdownRef = useRef<HTMLDivElement>(null);

  const HandleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setReasonForPull({
      ...reasonForPull,
      [name]: value,
    });
  };

  const catogoryFilteredOptions = catogoryOptions.filter((option) =>
    option.toLowerCase().includes(categorySearchTerm.toLowerCase())
  );

  const descriptorsFilteredOptions = descriptorsOptions.filter((option) =>
    option.toLowerCase().includes(descriptorsSearchTerm.toLowerCase())
  );

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        catogoryDropdownRef.current &&
        !catogoryDropdownRef.current.contains(event.target as Node)
      ) {
        setcategoryDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [catogoryDropdownRef]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        descriptorsDropdownRef.current &&
        !descriptorsDropdownRef.current.contains(event.target as Node)
      ) {
        setdescriptorsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [descriptorsDropdownRef]);

  const editGalleryHandle = (showEditGallery: boolean, gl: any) => {
    if (gl?.reportType === "pull") {
      setShowEditGallery(showEditGallery);
      setActiveGalleryItem(gl);
    }
  };
  const isReport = source.includes("report");

  return (
    <div className="difa-widget-containers">
      {loading && !showGallery && <Loader isOverlay />}
      <div className="header-main">
        <div className="flex flex-row justify-between flex-wrap">
          <div>
            <h3>{"Reason for pull"}</h3>
            {!isPreview && (
              <h5>
                Notice any issues during the pull? Please describe your
                observations and include supporting photos.
              </h5>
            )}
          </div>

          <div className="flex flex-row items-center gap-3">
            {!isPreview && (
              <>
                <DifaSwitch checked={expanded} onChange={onExpand} />
                <h4 className="whitespace-nowrap">Include in {isReport ? 'report' : 'packet'}</h4>
              </>
            )}
            <button
              className="btn-secondary font-bold text-sm"
              onClick={openPullReport}
            >
              View pull report
            </button>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-5 py-5 px-6 border-t border-dark">
        {!isPreview && (
          <>
            <div className="flex flex-col md:flex-row gap-4">
              <div className="input-field-container">
                <div className="input-field-caption">Failure category</div>
                <div
                  className="input-field-wrapper relative"
                  ref={catogoryDropdownRef}
                >
                  <button
                    className="input-field w-full text-left !h-full"
                    style={{ color: "white" }}
                    onClick={() =>
                      setcategoryDropdownOpen(!categoryDropdownOpen)
                    }
                  >
                    <span className="flex justify-between items-center w-full">
                      {reasonForPull.failureCategory || "Select category"}
                      <Arrowdown />
                    </span>
                  </button>
                  {categoryDropdownOpen && (
                    <div className="absolute mt-1 w-full bg-white border border-gray-300 rounded shadow-lg z-10">
                      <input
                        type="text"
                        className="w-full p-2 border-b border-gray-300"
                        placeholder="Search..."
                        value={categorySearchTerm}
                        onChange={(e) => {
                          setcategorySearchTerm(e.target.value);
                        }}
                      />
                      <ul className="max-h-60 overflow-y-auto">
                        {catogoryFilteredOptions?.map((option, index) => (
                          <li
                            key={index}
                            className="p-2 hover:bg-gray-200 cursor-pointer"
                            onClick={() => {
                              setReasonForPull({
                                ...reasonForPull,
                                failureCategory: option,
                              });
                              dispatch(
                                setPullReasons({
                                  ...pullReasons,
                                  failureCategory: option,
                                })
                              );
                              setcategoryDropdownOpen(false);
                            }}
                          >
                            {option}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
              <div className="input-field-container">
                <div className="input-field-caption">Failure descriptors</div>
                <div
                  className="input-field-wrapper relative"
                  ref={descriptorsDropdownRef}
                >
                  <button
                    className="input-field w-full text-left !h-full"
                    style={{ color: "white" }}
                    onClick={() =>
                      setdescriptorsDropdownOpen(!descriptorsDropdownOpen)
                    }
                  >
                    <span className="flex justify-between items-center w-full">
                      {reasonForPull.failureDescriptors || "Select descriptor"}
                      <Arrowdown />
                    </span>
                  </button>
                  {descriptorsDropdownOpen && (
                    <div className="absolute mt-1 w-full bg-white border border-gray-300 rounded shadow-lg z-10">
                      <input
                        type="text"
                        className="w-full p-2 border-b border-gray-300"
                        placeholder="Search..."
                        value={descriptorsSearchTerm}
                        onChange={(e) =>
                          setdescriptorsSearchTerm(e.target.value)
                        }
                      />
                      <ul className="max-h-60 overflow-y-auto">
                        {descriptorsFilteredOptions?.map((option, index) => (
                          <li
                            key={index}
                            className="p-2 hover:bg-gray-200 cursor-pointer"
                            onClick={() => {
                              setReasonForPull({
                                ...reasonForPull,
                                failureDescriptors: option,
                              });
                              dispatch(
                                setPullReasons({
                                  ...pullReasons,
                                  failureDescriptors: option,
                                })
                              );
                              setdescriptorsDropdownOpen(false);
                            }}
                          >
                            {option}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-5 border-dark">
              <div className="input-field-container">
                <div className="input-field-caption">Pull summary</div>
                <textarea
                  className="card-textarea"
                  maxLength={500}
                  rows={6}
                  value={reasonForPull.pullSummary}
                  placeholder="Add comments here..."
                  onBlur={(e) => {
                    setReasonForPull({
                      ...reasonForPull,
                      pullSummary: e.target.value,
                    });
                    dispatch(
                      setPullReasons({
                        ...pullReasons,
                        pullSummary: e.target.value,
                      })
                    );
                  }}
                  onChange={(e) => {
                    setReasonForPull({
                      ...reasonForPull,
                      pullSummary: e.target.value,
                    });
                    dispatch(
                      setPullReasons({
                        ...pullReasons,
                        pullSummary: e.target.value,
                      })
                    );
                  }}
                ></textarea>
              </div>
            </div>
          </>
        )}
        {isPreview && (
          <>
            <div className="flex flex-col md:flex-row gap-4 justify-between">
              <div className="flex flex-col gap-2 w-full md:w-1/2 p-4 bg-dark-700 justify-center rounded-lg border border-dark">
                <label className="font-normal text-base">
                  Failure category
                </label>
                <label className="font-medium text-2xl">
                  {pullReasons?.failureCategory}
                </label>
              </div>
              <div className="flex flex-col gap-2 w-full md:w-1/2 p-4 bg-dark-700 justify-center rounded-lg border border-dark">
                <label className="font-normal text-base">
                  Failure descriptor
                </label>
                <label className="font-medium text-2xl">
                  {pullReasons?.failureDescriptors}
                </label>
              </div>
            </div>
            <div className="flex flex-col md:flex-row gap-4 justify-between">
              <div className="flex flex-col gap-2 w-full p-4 bg-dark-700 justify-center rounded-lg border border-dark">
                <label className="font-normal text-base border-bottom pb-4">
                  Pull summary
                </label>
                <h5>{pullReasons?.pullSummary}</h5>
              </div>
            </div>
          </>
        )}
        <div
          className={`flex flex-col gap-4 pt-5 border-t border-dark ${isPreview ? "border rounded-lg" : ""
            }`}
        >
          <div className="flex flex-col gap-3">
            <div
              className={`flex flex-row justify-between items-center flex-wrap ${isPreview && "border-bottom preview-pull-reasons"
                }`}
            >
              <div>
                <h3>
                  {isPreview ? "Pull image evidence" : "Pull report images"}
                </h3>
                <h5>
                  {isPreview
                    ? "Image galleries showcase photos taken during the ESP being pulled."
                    : "Upload, annotate, and edit pull images."}
                </h5>
              </div>
              {!isPreview && (
                <div className="flex flex-row items-center gap-3">
                  <button
                    className="btn-secondary font-bold text-sm"
                    onClick={() => {
                      setShowGallery(true);
                      setGalleryMode("create");
                    }}
                  >
                    {" "}
                    {isReportBuilder
                      ? "Create image gallery"
                      : "Create gallery"}
                  </button>
                </div>
              )}
            </div>
          </div>
          {!pullImageGallery || !pullImageGallery?.length ? (
            <div className="flex flex-col justify-center">
              <div className="flex p-6 border border-dark rounded-lg items-center justify-center bg-dark-800">
                <p className="font-normal text-sm text-center py-6">
                  No images have been added. To add photos and create a pull
                  image gallery, <br /> click the "Add image gallery" button.
                </p>
              </div>
            </div>
          ) : (
            <div
              className={`flex flex-row gap-5 ${isPreview ? "p-6" : "pt-6 border-t border-dark"
                } cursor-pointer rounded-md flex-wrap`}
            >
              {pullImageGallery &&
                pullImageGallery?.map((gl: any, index: number) => (
                  <div
                    className="flex flex-col gap-3  w-72 max-w-72"
                    onClick={
                      isPreview
                        ? () => {
                          setImages(gl?.reportImageDetails);
                          setShowViewGallery(true);
                        }
                        : undefined
                    }
                  >
                    <div
                      className={`difa-widget-containers  difa-background relative max-w-72 h-[270px] p-2`}
                    >
                      <div className="w-full h-full flex items-center flex-col relative">
                        {!isPreview && (
                          <CustomImageDropdown
                            gl={gl}
                            source={source}
                            removeGallery={(galleryId: string) => galleryUtility.handleRemove(galleryId, source, refreshGallery)}
                            editGalleryHandle={editGalleryHandle}
                            onRenameSuccess={refreshGallery}
                          />
                        )}
                        <img
                          className="h-48 w-full"
                          src={
                            gl?.reportImageDetails?.length &&
                            gl.reportImageDetails[0].thumbnailFileLink
                          }
                          onClick={() => !isPreview ? editGalleryHandle(true, gl) : undefined}
                          alt={"gallery"}
                        ></img>
                        <div className="flex justify-between w-full items-center p-2 gap-3">
                          <h2>{gl?.galleryName}</h2>
                          <h5>{gl?.reportImageDetails?.length}&nbsp;images</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>

      {showGallery && (
        <GalleryComponent
          show={showGallery}
          difaID={difaId}
          reportType="pull"
          mode={galleryMode}
          source={source}
          images={pullReportImages}
          loading={loading}
          onGalleryClose={() => {
            setShowGallery(false);
            refreshGallery();
          }}
          wellApiNumber={difaDetails.wellApiNumber}
        />
      )}

      {showEditGallery && (
        <EditGalleryComponent
          source=""
          galleryID={activeGalleryItem?.galleryID}
          onRemoveGallery={(galleryId: string) => galleryUtility.handleRemove(galleryId, source, refreshGallery)}
          show={showEditGallery}
          onAddNewImages={() => {
            setShowEditGallery(false);
            setGalleryMode("update");
            setShowGallery(true);
          }}
          onGalleryClose={() => {
            setShowEditGallery(false);
            setActiveGalleryItem(null);
            refreshGallery();
          }}
          wellApiNumber={difaDetails.wellApiNumber}
        />
      )}
      {showPullReport && (
        <PullReport open={showPullReport} onCloseModal={setPullReport} />
      )}

      {showViewGallery && (
        <ViewGallery
          images={images}
          show={showViewGallery}
          close={() => setShowViewGallery(false)}
          name="Trends images"
        />
      )}
    </div>
  );
};

export default PullReason;
