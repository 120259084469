import LocalStorageConstant from '../../constants/localstroage.constant';

const getLocalExpiresIn = (): string => {
  return localStorage.getItem(LocalStorageConstant.expires_in) ?? '';
};

const getLocalRefreshToken = (): string => {
  return localStorage.getItem(LocalStorageConstant.refresh_token) ?? '';
};

const getLocalAccessToken = (): string => {
  return localStorage.getItem(LocalStorageConstant.access_token) ?? '';
};

const getCorrelationId = (): string => {
  return localStorage.getItem(LocalStorageConstant.correlationId) ?? '';
};

const getUserRole = () => { 
  return localStorage.getItem(LocalStorageConstant.userRole);
};

const getUser: any = () => {
  const user: string | null = localStorage.getItem(LocalStorageConstant.userName);
  return user ? JSON.parse(user) : null;
};

const getAdminName = (): string => {
  return localStorage.getItem(LocalStorageConstant.adminName) ?? '';
}

const addOrUpdateLocalExpiresIn = (token: string) => {
  localStorage.setItem(LocalStorageConstant.expires_in, token);
};

const addOrUpdateLocalAccessToken = (token: string) => {
  localStorage.setItem(LocalStorageConstant.access_token, token);
};

const addOrUpdateLocalRefreshToken = (token: string) => {
  localStorage.setItem(LocalStorageConstant.refresh_token, token);
};

const addOrUpdateLocalCorrelationId = (correlationId: string) => {
  localStorage.setItem(LocalStorageConstant.correlationId, correlationId);
};

const setUser = (user: any) => {
  //Helpers.ConsoleLog(JSON.stringify(user));
  localStorage.setItem(LocalStorageConstant.userName, JSON.stringify(user) ?? '');
};

const setUserRole = (token: string) => { 
  try {
    const base64Url = token.split('.')[1]; // Get the payload part
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/'); // Replace URL-safe characters
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
        .join('')
    );

    const decodedToken = JSON.parse(jsonPayload);
    // Extract the role from the token
    const role = decodedToken["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
    setAndminName(decodedToken?.UserFirstName);
    localStorage.setItem(LocalStorageConstant.userRole, role);
  } catch (error) {
    console.error('Error decoding token:', error);
    return false;
  }
};

const setAndminName = (name: string) => {
  localStorage.setItem(LocalStorageConstant.adminName, name);
}

const removeUser = () => {
  localStorage.removeItem(LocalStorageConstant.userAddresses);
  localStorage.removeItem(LocalStorageConstant.userName);
  localStorage.removeItem(LocalStorageConstant.userRole);
  localStorage.removeItem(LocalStorageConstant.adminName);
  localStorage.removeItem(LocalStorageConstant.access_token);
  localStorage.removeItem(LocalStorageConstant.refresh_token);
  localStorage.removeItem(LocalStorageConstant.expires_in);
  localStorage.removeItem(LocalStorageConstant.correlationId);
};

const TokenService = {
  getLocalExpiresIn,
  getLocalRefreshToken,
  getLocalAccessToken,
  getUser,
  getUserRole,
  getAdminName,
  getCorrelationId,
  addOrUpdateLocalExpiresIn,
  addOrUpdateLocalAccessToken,
  addOrUpdateLocalRefreshToken,
  addOrUpdateLocalCorrelationId,
  setUser,
  setUserRole,
  removeUser,
};

export default TokenService;
