import { deleteScheduler, getDifaSchedular, getEngineers, getSchedulersByJobid, saveScheduler } from "./DIFASchedularService";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

interface DIFASchedular {
    schedulerList: any[];
}

export const fetchDifaSchedular: any = createAsyncThunk(
    "managerDashboard/getDifaSchedular",
    async ({ pageNumber, pageSize }: { pageNumber: number, pageSize: number }) => {
        const response = await getDifaSchedular(pageNumber, pageSize);
        return response;
    }
);

export const fetchDifaSchedularById: any = createAsyncThunk(
    "managerDashboard/getDifaSchedularById",
    async ({ jobId, quoteId }: { jobId: number, quoteId: number }) => {
        const response = await getSchedulersByJobid(jobId, quoteId);
        return response;
    }
);

export const fetchEngineers: any = createAsyncThunk(
    "managerDashboard/getEngineers",
    async () => {
        const response = await getEngineers();
        return response;
    }
);

export const submitScheduler: any = createAsyncThunk(
    "managerDashboard/submitScheduler",
    async (scheduler: any, thunkAPI) => {
        try {
            const response = await saveScheduler(scheduler);
            return response;
        }
        catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data);;
        }
    }
);

export const cancelScheduler: any = createAsyncThunk(
    "managerDashboard/deleteScheduler",
    async (schedulerId: number) => {
        const response = await deleteScheduler(schedulerId);
        return response;
    }
);


const initialState = {
    difaSchedular: null as DIFASchedular | null,
    difaSchedularById: null,
    difaEngineers: null,
    loading: false,
    error: null,
}

const DIFASchedularSlice = createSlice({
    name: "managerDashboard",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchDifaSchedular.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchDifaSchedular.fulfilled, (state, { payload }) => {
            state.loading = false;
            if (state.difaSchedular && state.difaSchedular.schedulerList) {
                state.difaSchedular.schedulerList = [
                    ...state.difaSchedular.schedulerList,
                    ...payload.schedulerList
                ];
            } else {
                state.difaSchedular = payload;
            }
        });
        builder.addCase(fetchDifaSchedular.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = payload;

        });
        builder.addCase(fetchDifaSchedularById.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchDifaSchedularById.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.difaSchedularById = payload;
        });
        builder.addCase(fetchDifaSchedularById.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = payload;
        });
        builder.addCase(fetchEngineers.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchEngineers.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.difaEngineers = payload;
        });
        builder.addCase(fetchEngineers.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = payload;
        });
        builder.addCase(submitScheduler.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(submitScheduler.fulfilled, (state, { payload }) => {
            state.loading = false;
        });
        builder.addCase(submitScheduler.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = payload;
        });
        builder.addCase(cancelScheduler.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(cancelScheduler.fulfilled, (state, { payload }) => {
            state.loading = false;
        });
        builder.addCase(cancelScheduler.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = payload;
        });
    }
});

export default DIFASchedularSlice.reducer;
