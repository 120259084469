import CommonConstant from "../constants/common.constant";

const ConsoleLog = (message: any, data?: string) => {
  if (`${process.env.ENVIRONMENT}` === CommonConstant.localEnvironemt) {
    if (data) console.log(message, data);
    else console.log(message, data);
  }
};

const ConsoleLogError = (message: any, data?: string) => {
  if (`${process.env.ENVIRONMENT}` === CommonConstant.localEnvironemt) {
    if (data) console.error(message, data);
    else console.error(message, data);
  }
};

const getDeviceName = () => {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return "TAB";
  } else if (
    /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua
    )
  ) {
    return "MOBILE";
  } else {
    return "PC";
  }
};

const formatString = (format: string, ...args: any[]): string => {
  return format.replace(/{(\d+)}/g, (match, index) => {
    const argIndex = parseInt(index, 10);
    return args[argIndex] !== undefined ? args[argIndex].toString() : match;
  });
};

const isFormFieldInvalid = (name: any, form: any) =>
  !!(form.touched[name] && form.errors[name]);

const getFormErrorMessage = (name: any, form: any) => {
  return isFormFieldInvalid(name, form)
    ? '<small className="p-error">' + form.errors[name] + "</small>"
    : '<small className="p-error">&nbsp;</small>';
};

const getNext7Days = () => {
  const today = new Date();
  let next7Days = "";

  const nextDay = new Date(today);
  nextDay.setDate(today.getDate() + 7);
  next7Days = nextDay.toDateString();

  return next7Days;
};

const getNext7DaysByDate = (date: Date) => {
  let next7Days = "";

  const nextDay = new Date(date);
  nextDay.setDate(date.getDate() + 7);
  next7Days = nextDay.toDateString();

  return next7Days;
};

const formatAmountInINR = (amount: number) => {
  const formattedAmount = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 2,
  }).format(amount);

  return formattedAmount;
};

interface ArrayToSplit {
  arr: any;
  property: string;
}
/*
* This function would split any Array of object into separate arrays
* based on any given property. Like :
* var arr = [
    {type:"orange", title:"First"},
    {type:"orange", title:"Second"},
    {type:"banana", title:"Third"},
    {type:"banana", title:"Fourth"}
];
var o = groupBy(arr, 'type');

This would give us a resultant set of arrays in given manner
{
    "orange": [{
            "type": "orange", "title": "First"
        },{
            "type": "orange","title": "Second"
      }],
    "banana": [{
            "type": "banana","title": "Third"
        },{
            "type": "banana","title": "Fourth"
        }]
      }
*/
const groupArrayByField = (arr: any, property: string) => {
  return arr.reduce(function (memo: any, x: any) {
    if (!memo[x[property]]) {
      memo[x[property]] = [];
    }
    memo[x[property]].push(x);
    return memo;
  }, {});
};

const markNullValues = (param: any, round: boolean = false) => {
  if (
    param === null ||
    param === undefined ||
    param.toLowerCase() === "null" ||
    param === "" ||
    param.toLowerCase() === "none" ||
    param.toLowerCase() === "n/a" ||
    param.toLowerCase() === "na" ||
    param.toLowerCase() === "x"
  )
    return "-";
  if (round) 
    return Math.round(param * 100) / 100
  
  return param;
};

const processFrequencyUnits = (param: any, round: boolean = false) => {
  if (
    param === null ||
    param === undefined ||
    param.toLowerCase() === "null" ||
    param === "" ||
    param.toLowerCase() === "none" ||
    param.toLowerCase() === "n/a"
  )
    return "-";
  if (round) return `${Math.round(param)} Hz`;
  return `$[param} Hz`;
};

const isObjectNotEmpty = (param: {}) => {
  if (param && Object.keys(param).length > 0) return true;
  return false;
};

const isFieldEmpty = (param: string) => {
  if (
    param === null ||
    param === undefined ||
    param.toLowerCase() === "null" ||
    param === "" ||
    param.toLowerCase() === "none" ||
    param.toLowerCase() === "n/a" ||
    param === "X"
  )
    return true;
  return false;
};

const dateFormatter = (param: string) => {
  if (param) return new Date(param).toLocaleDateString();
  else return "-";
};

const processTimeUnits = (param: string) => {
  const unittypes = [
    {
      name: "SEC",
      value: " Sec",
    },
    {
      name: "HR",
      value: " Hr",
    },
    {
      name: "MIN",
      value: " Min",
    },
    {
      name: "HRS",
      value: " Hr",
    },
  ];

  const a = unittypes.find((t) => {
    return param.indexOf(t.name) > -1;
  });
  if (a) {
    return param.replace(a.name, a.value);
  }
  return param;
};

const formatYesNo =(param: string) =>{
  if (param && param.toLowerCase()==='yes')
    return 'Yes'
  else if (param &&  param.toLowerCase()==='no')
    return 'No'
  else return param;
}

const MotorsAndPhases: any = {
  'CT_MOTOR' :'CT Motor',
  'LT_MOTOR' : 'LT Motor',
  'UT_MOTOR' : 'UT Motor',
  'ALL_MOTOR_COMBINED' : 'All Motor Combined',
  'CABLE_ONLY' : 'Cable Only',
  'MOTOROrCABLE_LANDED' : 'Motor or Cable Landed',
  'MOTOROrCABLEAtSURFACE' : 'Motor or Cable at Surface',
}

const handlPullEquipmentStatus = (param: string) => {
  if (['',null, undefined,'null','NULL','false',false].includes(param)) 
    return false;
  
  return true;
}
const Helpers = {
  ConsoleLog,
  ConsoleLogError,
  getDeviceName,
  getNext7Days,
  formatString,
  formatAmountInINR,
  getFormErrorMessage,
  getNext7DaysByDate,
  groupArrayByField,
  markNullValues,
  dateFormatter,
  isObjectNotEmpty,
  isFieldEmpty,
  processFrequencyUnits,
  processTimeUnits,
  formatYesNo,
  MotorsAndPhases,
  handlPullEquipmentStatus
};

export default Helpers;
