import React, { useState } from "react";
import "./PISummaryChart.scss";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../common/loader/Loader";
import XYChartComponent from "./RundaysChart";
import MainComponent from "./InstallReport";
import StartupReport from "./StartupReport";
import PullReport from "./PullReport";
import { ReactComponent as Star } from '../../../images/star.svg';
import { ReactComponent as ThumbsUp } from '../../../images/thumbs-up.svg';
import { ReactComponent as Thubsdown } from '../../../images/thumbs-down.svg';
import { ReactComponent as Refresh } from '../../../images/repeat.svg';

import {
  fetchInstallReport,
  fetchPreInspectionSummary,
  fetchPullReport,
  fetchStartupReport,
  PostPreInspectionSummaryFeedback,
} from "../PreInspectionSummarySlice";
import { PISummarySnapshotandChartProps, PreinspectionsummaryProps } from "../../models/PreInpectionSummary";
import { toast } from "react-toastify";

const PISummarySnapshotandChart: React.FC<PISummarySnapshotandChartProps> = ({
  wellApiNumber, summary, customerName, wellName
}) => {
  const dispatch = useDispatch();
  const [showReport, setShowReport] = useState(false);
  const [showStartup, setStartupReport] = useState(false);
  const [showPullReport, setPullReport] = useState(false);
  const [difaSummary, setDifaSummary] = useState(summary);
  const [htmlDifaSummary, sethtmlDifaSummary] = useState<React.ReactNode>(summary?.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>'));

  const { difaRundaysDetails } = useSelector(
    (state: any) => state.preInspectionSummary
  );

  const { difaDetails } = useSelector((state: any) => state.difaDetails);

  const openReport = (type: string) => {
    if (type === "install") {
      if (!showReport) {
        dispatch(fetchInstallReport({ wellApiNumber: wellApiNumber }));
        setPullReport(false);
        setStartupReport(false);
        setShowReport(!showReport);
      }
    } else if (type === "startup") {
      if (!showStartup) {
        dispatch(fetchStartupReport({ wellApiNumber: wellApiNumber }));
        setStartupReport(!showStartup);
        setPullReport(false);
        setShowReport(false);
      }
    } else if (type === "pull") {
      if (!showPullReport) {
        dispatch(fetchPullReport({ wellApiNumber: wellApiNumber }));
        setPullReport(!showPullReport);
        setShowReport(false);
        setStartupReport(false);
      }
    }
  };

  const GetPreInspectionSummary = async () => {
    const response = await dispatch(fetchPreInspectionSummary({ dispatchId: difaDetails?.difaId }));
    if (fetchPreInspectionSummary.fulfilled.match(response)) {
      setDifaSummary(response.payload);
      if(response.payload != null)
      {
        sethtmlDifaSummary(response.payload.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>'));
      }
      else{
        sethtmlDifaSummary("<p>No pre-inspection summary available.</p>");
      }
    }
  };

  const PostSummaryFeedback = async (action: string) => {
    const preinspectionsummaryProps: PreinspectionsummaryProps = {
      dispatch_id: difaDetails?.difaId,
      action: action,
      previous_summary: difaSummary == null ? "" : difaSummary,
      user_prompt: "Give me a more detailed summary"
    };

    if (action === "thumbs_down") {
      const response = await dispatch(PostPreInspectionSummaryFeedback(preinspectionsummaryProps));
      if (PostPreInspectionSummaryFeedback.fulfilled.match(response)) {
        setDifaSummary(response.payload.data);
        if(response.payload.data != null)
        {
            sethtmlDifaSummary(response.payload.data.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>'));
        }
        toast.success(
          <div className="flex flex-col">
            <div className="text-lg">Summary Updated!</div>
            <div className="text-sm">Now displaying the latest summary.</div>
          </div>
        );
      }
    } else if (action === "thumbs_up") {
      const response = await dispatch(PostPreInspectionSummaryFeedback(preinspectionsummaryProps));
      if (PostPreInspectionSummaryFeedback.fulfilled.match(response)) {
        toast.success(
          <div className="flex flex-col">
            <div className="text-lg">Feedback recorded!</div>
            <div className="text-sm">Now displaying the latest summary.</div>
          </div>
        );
      }
    }
  }

  return (
    <>
      <div className="pi-summary-content-chart flex-col md:flex-row">
        <div className="pre-inspection-summary w-full md:w-1/2">
          <div className="pis-header">
            <h3>Pre-inspection snapshot</h3>
          </div>
          <div className="pis-content">
            <p 
              dangerouslySetInnerHTML={{ __html: htmlDifaSummary?.toString() || "" }}
            />
          </div>

          <div className="summary-refresh flex space-x-2">
            <Star className="starcolor" /><div className="text-sm refreshmoveright">How did we do?</div>
            <div className="flex floatrightthumbnail" >
              <Refresh className="ml-2" onClick={GetPreInspectionSummary} />
              <ThumbsUp className="ml-2" onClick={() => PostSummaryFeedback("thumbs_up")} />
              <Thubsdown className="ml-2" onClick={() => PostSummaryFeedback("thumbs_down")} />
            </div>
          </div>

          <div className="pis-footer">
            <div className="pis-footer-btn cursor-pointer" onClick={() => openReport("install")}>
              <button className="pis-footer-btn-text" >View install report</button>
            </div>
            <div className="pis-footer-btn cursor-pointer" onClick={() => openReport("startup")}>
              <button className="pis-footer-btn-text">View start up report </button>
            </div>
            <div className="pis-footer-btn cursor-pointer" onClick={() => openReport("pull")}>
              <button className="pis-footer-btn-text">View pull report</button>
            </div>
          </div>
        </div>
        <div className="pre-inspection-summary w-full md:w-1/2">
          <div className="pis-header">
            <h3>Runday comparison</h3>
            <p>View the rundays for all ESP's that have run in this well</p>
          </div>
          <div className="mt-5 pb-2">
            <XYChartComponent id="chart1" data={difaRundaysDetails} customerName={customerName} wellName={wellName} />
          </div>
          <div className="pis-rundayChart"></div>
        </div>
      </div>
      {showReport && (
        <MainComponent open={showReport} onCloseModal={setShowReport} />
      )}
      {showStartup && (
        <StartupReport open={showStartup} onCloseModal={setStartupReport} />
      )}
      {showPullReport && (
        <PullReport open={showStartup} onCloseModal={setPullReport} />
      )}
    </>
  );
};

export default PISummarySnapshotandChart;
