import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchGeneralReport,
  fetchSizingSummary,
  pushEspSizingDoc,
  fetchDocuments,
  resetCreateGalleryState,
  resetPacketBuilderState,
  SaveAsDraft,
  postPublishPacket,
  resetPublishPacketNotification
} from '../difaPacketBuilder/difaPacketBuilderSlice';
import Header from "../difaPacketBuilder/components/Header";
import WellRunLifeInformation from '../difaPacketBuilder/components/WellRunLifeInformation';
import EspSizing from '../difaPacketBuilder/components/espSizing/EspSizing';
import InstallAndWellOperation from '../difaPacketBuilder/components/installWellOperation/InstallAndWellOperation';
import PullAndInbound from '../difaPacketBuilder/components/pullAndInbound/PullAndInbound';
import Production from '../difaPacketBuilder/components/production/Production';
import { fetchDifaDetails, fetchReportStatus } from '../DifaPages/DifaDetailsSlice';
import { fetchOptimizationTickets, fetchRundaysDetails } from '../preInspectionSummary/PreInspectionSummarySlice';
import Match from './components/match/Match';
import CauseOfFailure from './components/causeOfFailure/CauseOfFailure';
import DIFAInspection from './components/difaInspection/DIFAInspection';
import { fetchDismantlePumps } from '../dismentleRequest/DismantleRequestSlice';
import "./DifaReportBuilder.scss";
import ScaleDeposite from './components/scaleDepositeAnalysis/ScaleDeposite';
import PublishEditedDifaDialog from '../difaPacketBuilder/components/PublishEditPacketDialog';
import PublishedDifaDialog from '../difaPacketBuilder/components/PublishSuccessDialog';
import Loader from '../common/loader/Loader';

const PAGE_WELL_RUN_LIFE = "Well and run life information";
const PAGE_ESP_SIZING = "ESP sizing";
const PAGE_INSTALL_AND_WELL_OPERATIONS = "Install and well operations";
const PAGE_PRODUCTION = "Production";
const PAGE_PULL_AND_INBOUND = "Pull and inbound";
const PAGE_MATCH = "Match"
const PAGE_CAUSE_OF_FAILURE = "Cause of Failure";
const PAGE_DIFA_INSPECTION = "DIFA inspection";
const PAGE_SCALE_DEPOSIT = "Scale deposit analysis results";
const formName='report-builder';

const DifaReportBuilder = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(PAGE_WELL_RUN_LIFE);
  const { difaId, customerName, wellName } = location.state || {};
  const [showPublishedDialog, setShowPublishedDialog] = useState(false);
  const [unsavedESP, setUnsavedESP] = useState(false);
  const [unsavedProduction, setUnsavedProduction] = useState(false);
  const [unsavedAdditional, setUnsavedAdditional] = useState(false);

  const [showEditDialog, setShowEditDialog] = useState(false);
  const { difaDetails } = useSelector((state: any) => state.difaDetails);
  const {
    galleryTransactionMsg,
    sizingSummary,
    loading,
    production,
    wellInstallation,
    wellRunLife,
    draftId,
    isInboundTestingSelected,
    pullReasons,
    publishPacketNotification } = useSelector(
      (state: any) => state?.difaPacketBuilder
    );

  const { difaPacketStatus } = useSelector(
    (state: any) => state.preInspectionSummary
  );

  useEffect(() => {
    if (difaDetails?.wellApiNumber !== undefined) {
      dispatch(
        fetchRundaysDetails({ wellApiNumber: difaDetails?.wellApiNumber })
      );
      dispatch(
        fetchGeneralReport({
          wellApiNumber: difaDetails?.wellApiNumber,
          difaId: difaDetails?.difaId,
        })
      );
      dispatch(fetchDocuments({
        wellApiNumber: difaDetails?.wellApiNumber,
        difaId: difaDetails?.difaId,
        category: 'esp',
        source: "report-builder"
      }));
      dispatch(fetchDocuments({
        wellApiNumber: difaDetails?.wellApiNumber,
        difaId: difaDetails?.difaId,
        category: 'production',
        source: "report-builder"
      }));
      dispatch(
        fetchOptimizationTickets({
          wellName: encodeURIComponent(difaDetails?.wellName),
        })
      );
    }
  }, [dispatch, difaDetails]);

  useEffect(() => {
    dispatch(fetchDifaDetails({ difaId }));
    dispatch(fetchDismantlePumps({ difaId }));
  }, [dispatch, difaId]);

  useEffect(() => {
    if (difaDetails?.wellApiNumber !== undefined) {
      dispatch(
        fetchSizingSummary({ wellApiNumber: difaDetails?.wellApiNumber })
      );
      dispatch(
        fetchGeneralReport({
          wellApiNumber: difaDetails?.wellApiNumber,
          difaId: difaDetails?.difaId,
        })
      );
      dispatch(
        fetchOptimizationTickets({
          wellName: encodeURIComponent(difaDetails?.wellName),
        })
      );
    }
  }, [dispatch, difaDetails]);

  useEffect(() => {
    if (publishPacketNotification?.type === 'publish-success') {
      dispatch(resetPublishPacketNotification())
      setShowPublishedDialog(true)
    }
    if (publishPacketNotification?.type === 'publish-failed') {
      dispatch(resetPublishPacketNotification())
      toast.error(<div className="flex flex-col">
        <div className="text-lg">
          Opps!!
        </div>
        <div className="text-sm">
          Failed to publish report. Please try again later.
        </div>
      </div>)
    }

  }, [publishPacketNotification])

  const publishReport = async (checked: boolean) => {
    setShowEditDialog(false);
    dispatch(postPublishPacket({
      publishPacket: {
        "wellApinumber": difaDetails?.wellApiNumber,
        "difaId": difaId,
        "IsAllowViewtoCustomer": checked,
      },
      source: "report-builder",
    },

    ));

  }

  const saveReport = async (shouldNavigate: boolean) => {
    let payload: any = "";
    const source = "report-builder";
    switch (currentPage) {
      case PAGE_WELL_RUN_LIFE:
        const wellRunLifeDetails = {
          wellApinumber: difaDetails?.wellApiNumber,
          difaId: difaId,
          isIncludeWellInformation: true,
          isIncludeRunLifeDetails: true,
          IsIncludePriorRuns: wellRunLife?.isRunsSelected,
          CreatedBy: difaDetails?.emailAddress,
          difaPacketDocuments: null,
          priorRunDetails: "",
        };
        const selectedRuns = wellRunLife?.selectedDifas?.map((item: any) => {
          return {
            DifaID: item.difaId,
            IsIncludeInReport: item.selected,
          };
        });
        wellRunLifeDetails.priorRunDetails = JSON.stringify(selectedRuns);
        dispatch(SaveAsDraft({ draftDetails: wellRunLifeDetails, source: formName }));

        break;
      case PAGE_ESP_SIZING:
        if (unsavedESP) {
          payload = {
            wellApinumber: difaDetails?.wellApiNumber,
            difaId: difaId,
            IsIncludeESPSizing: sizingSummary?.isSelected,
            category: "esp",
            difaPacketDocuments: {
              FileName: sizingSummary?.sizingFile?.name,
              FileBase64: sizingSummary?.sizingFile?.blob,
            },
          };
          dispatch(pushEspSizingDoc({ payload, source }));
          setUnsavedESP(false);
        }
        break;
      case PAGE_INSTALL_AND_WELL_OPERATIONS:
        const opTickets = wellInstallation?.opTickets?.map((item: any) => {
          return {
            TicketID: item.ticketId,
            IsIncludeinReport: true,
          }
        });
        const generalReports = wellInstallation?.generalReports?.map((item: any) => {
          return {
            DifaID: item.difaId,
            IsIncludeinReport: true,
          }
        });
        const serviceReports = wellInstallation?.serviceReports?.map((item: any) => {
          return {
            DifaID: item.difaId,
            IsIncludeinReport: true,
          }
        });
        payload = {
          wellApiNumber: difaDetails?.wellApiNumber,
          difaId,
          IsIncludeDocumentation: wellInstallation?.isDocumentationSelected,
          IsIncludeInstallImageGallery: wellInstallation?.isGallerySelected,
          IsIncludeAlarms: wellInstallation?.isAlarmsSelected,
          IsIncludeTrendHistoryGallery: wellInstallation?.isTrendGallerySelected,
          IsIncludeOperationalSummery: wellInstallation?.isOpSummarySelected,
          OperationalSummery: wellInstallation?.opSummary,
          GenerelReportsDetails:
            JSON.stringify(generalReports),
          ServiceReportsDetails: JSON.stringify(serviceReports),
          OptimizationTicketDetails:
            JSON.stringify(opTickets),
          CreatedBy: difaDetails?.emailAddress,
        };
        dispatch(SaveAsDraft({ draftDetails: payload, source: formName }));
        if (wellInstallation.additionalDocuments) {
          payload = {
            wellApinumber: difaDetails?.wellApiNumber,
            difaId: difaId,
            IsIncludeESPSizing: sizingSummary?.isSelected,
            Category: "additional",
            DifaPacketDocuments: {
              FileName: wellInstallation.additionalDocuments.name,
              FileBase64: wellInstallation.additionalDocuments.blob,
            },
          };
          dispatch(pushEspSizingDoc({ payload, source }));
          setUnsavedAdditional(false);
        }
        break;
      case PAGE_PRODUCTION:
        if (unsavedProduction) {
          payload = {
            wellApinumber: difaDetails?.wellApiNumber,
            difaId: difaId,
            IsIncludeProductionPlot: production?.isSelected,
            category: "production",
            difaPacketDocuments: {
              FileName: production?.productionFile?.name,
              FileBase64: production?.productionFile?.blob,
            },
          };

          dispatch(pushEspSizingDoc({ payload, source }));
          setUnsavedProduction(false);
        }
        break;
      case PAGE_PULL_AND_INBOUND:
      default:
        payload = {
          WellApinumber: difaDetails?.wellApiNumber,
          difaId: difaId,
          IsIncludeReasonForPull: pullReasons?.isPullSelected,
          FailureCategory: pullReasons?.failureCategory,
          FailureDescriptors: pullReasons?.failureDescriptors,
          PullSummary: pullReasons?.pullSummary,
          IsIncludeInboundTesting: isInboundTestingSelected,
        };
        dispatch(SaveAsDraft({ draftDetails: payload, source: formName }));
        break;
    }

    if (shouldNavigate) {
      toast.success(
        <div className="flex flex-col">
          <div className="text-lg">
            DIFA report has been saved successfully.
          </div>
          <div className="text-sm">
            To resume editing your DIFA report select “Edit DIFA report".
          </div>
        </div>,
        { style: { width: "400px" } }
      );

      navigate("/difa-details", {
        state: {
          difaId: difaId,
          customerName: customerName,
        },
      });
    }
  };

const redirectToPreview = () => {
  dispatch(fetchReportStatus({wellApiNumber: difaDetails?.wellApiNumber, difaId}));
          
  navigate("/difa-details/difa-report-preview", {
      state: { difaId: difaId, customerName: customerName, wellName },
    });
  };

  const redirectToDashboard = () => {
    dispatch(resetPacketBuilderState());
    navigate("/",{
      state:{}
    })
  };

  const reportTitle = draftId ? "DIFA report # " + draftId : "DIFA report";


  return (
    <div className="report-builder">
      {loading && <Loader isOverlay />}
      <Header
        pageName="DIFA packet builder"
        difaPacketName={reportTitle}
        customerName={customerName}
        wellName={wellName}
        source="report-builder"
        onSaveHandler={saveReport}
        onPublishHandler={() => { setShowEditDialog(true) }}
      />
      <div className="flex flex-col md:flex-row gap-5 p-7 h-fit">
        <div className="flex-col flex flex-auto w-full md:w-1/4 gap-4">
          <div
            onClick={() => { setCurrentPage(PAGE_WELL_RUN_LIFE); }}
            className={`difa-widget-containers cursor-pointer ${currentPage === PAGE_WELL_RUN_LIFE ? "active-page" : ""}`}
          >
            <div className="container-body">
              <div className="header-small">{PAGE_WELL_RUN_LIFE}</div>
            </div>
          </div>
          <div
            onClick={() => {
              saveReport(false);
              setCurrentPage(PAGE_ESP_SIZING);
            }}
            className={`difa-widget-containers cursor-pointer ${currentPage === PAGE_ESP_SIZING ? "active-page" : ""
              }`}
          >
            <div className="container-body">
              <div className="header-small">{PAGE_ESP_SIZING}</div>
            </div>
          </div>
          <div onClick={() => {
            saveReport(false);
            setCurrentPage(PAGE_INSTALL_AND_WELL_OPERATIONS);
          }}
            className={`difa-widget-containers cursor-pointer ${currentPage === PAGE_INSTALL_AND_WELL_OPERATIONS
              ? "active-page"
              : ""
              }`}
          >
            <div className="container-body">
              <div className="header-small">
                {PAGE_INSTALL_AND_WELL_OPERATIONS}
              </div>
            </div>
          </div>
          <div onClick={() => {
            saveReport(false);
            setCurrentPage(PAGE_PRODUCTION);
          }}
            className={`difa-widget-containers cursor-pointer ${currentPage === PAGE_PRODUCTION ? "active-page" : ""}`}
          >
            <div className="container-body">
              <div className="header-small">{PAGE_PRODUCTION}</div>
            </div>
          </div>
          <div onClick={() => {
            saveReport(false);
            setCurrentPage(PAGE_MATCH);
          }}
            className={`difa-widget-containers cursor-pointer ${currentPage === PAGE_MATCH ? "active-page" : ""}`}>
            <div className="container-body">
              <div className="header-small">{PAGE_MATCH}</div>
            </div>
          </div>
          <div onClick={() => {
            saveReport(false);
            setCurrentPage(PAGE_PULL_AND_INBOUND);
          }}
            className={`difa-widget-containers cursor-pointer ${currentPage === PAGE_PULL_AND_INBOUND ? "active-page" : ""}`}>
            <div className="container-body">
              <div className="header-small">{PAGE_PULL_AND_INBOUND}</div>
            </div>
          </div>
          <div
            onClick={() => {
              saveReport(false);
              setCurrentPage(PAGE_DIFA_INSPECTION);
            }}
            className={`difa-widget-containers cursor-pointer ${currentPage === PAGE_DIFA_INSPECTION ? "active-page" : ""}`}>
            <div className="container-body">
              <div className="header-small">{PAGE_DIFA_INSPECTION}</div>
            </div>
          </div>
          <div onClick={() => {
            saveReport(false);
            setCurrentPage(PAGE_CAUSE_OF_FAILURE);
          }}
            className={`difa-widget-containers cursor-pointer ${currentPage === PAGE_CAUSE_OF_FAILURE ? "active-page" : ""}`}>
            <div className="container-body">
              <div className="header-small">{PAGE_CAUSE_OF_FAILURE}</div>
            </div>
          </div>
          <div onClick={() => {
            saveReport(false);
            setCurrentPage(PAGE_SCALE_DEPOSIT);
          }}
            className={`difa-widget-containers cursor-pointer ${currentPage === PAGE_SCALE_DEPOSIT ? "active-page" : ""}`}>
            <div className="container-body">
              <div className="header-small">{PAGE_SCALE_DEPOSIT}</div>
            </div>
          </div>
        </div>

        <div className="flex-auto flex flex-col w-full md:w-3/4 gap-5">
          {currentPage === PAGE_WELL_RUN_LIFE && (
            <WellRunLifeInformation difaId={difaId} source="report-builder" />
          )}
          {currentPage === PAGE_ESP_SIZING && (
            <EspSizing
              wellApiNumber={difaDetails?.wellApiNumber}
              difaId={difaId}
              source="report-builder"
              unsavedCallback={() => setUnsavedESP(true)}
            />
          )}
          {currentPage === PAGE_INSTALL_AND_WELL_OPERATIONS && (
            <InstallAndWellOperation
              wellApiNumber={difaDetails?.wellApiNumber}
              difaId={difaDetails?.difaId}
              source="report-builder"
            />
          )}
          {currentPage === PAGE_PRODUCTION && <Production unsavedCallback={() => setUnsavedProduction(true)} source="report-builder" />}
          {currentPage === PAGE_PULL_AND_INBOUND && (
            <PullAndInbound
              wellApiNumber={difaDetails?.wellApiNumber}
              difaId={difaDetails?.difaId}
              source="report-builder"
            />
          )}
          {currentPage === PAGE_MATCH && <Match source="report-builder" />}
          {currentPage === PAGE_DIFA_INSPECTION && <DIFAInspection wellApiNumber={difaDetails?.wellApiNumber}
            difaId={difaDetails?.difaId}
            source="report-builder" />}
          {currentPage === PAGE_CAUSE_OF_FAILURE && <CauseOfFailure source="report-builder" />}
          {currentPage === PAGE_SCALE_DEPOSIT && <ScaleDeposite source="report-builder" />}
        </div>
      </div>
      <PublishedDifaDialog show={showPublishedDialog} close={() => { setShowEditDialog(false); setShowPublishedDialog(false) }} onRedirect={redirectToPreview} onBackToPI={redirectToDashboard} mode="report" />
      <PublishEditedDifaDialog show={showEditDialog} close={() => { setShowEditDialog(false); }} submit={(checked) => publishReport(checked)} />
    </div>
  )
}

export default DifaReportBuilder
