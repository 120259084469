import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { createDismantleRequest, getDismantlePumps, getDismantleRequestById } from "./DismantleRequestService";

export const fetchDismantlePumps: any = createAsyncThunk('dasboard/fetchDismantlePumps', async ({ difaId }: { difaId: string }) => {
    const response = await getDismantlePumps(difaId);
    return response;
});

export const createDifaDismantleRequest: any = createAsyncThunk('dasboard/createDismantleRequest', async (dismantleRequest: any, { rejectWithValue }) => {
    try {
        const response = await createDismantleRequest(dismantleRequest);
        return response;
    } catch (error: any) {
        return rejectWithValue(error?.response?.data || error?.message);
    }
});

export const getDismantleRequestByDifa: any = createAsyncThunk('dasboard/getDismantleRequest', async ({ dismantleRequestId, wellApiNumber }: { dismantleRequestId: string, wellApiNumber: string }) => {
    const response = await getDismantleRequestById(dismantleRequestId, wellApiNumber);
    return response;
});

const initialState: any = {
    loading: false,
    success: false,
    dismantlePumps: [],
    getDismantleRequest: [],
    message: null
};

const dismantleRequest = createSlice({
    name: 'dismantleRequest',
    initialState,
    reducers: {
        resetState: (state) => {
            state.loading = false;
            state.success = false;
            state.message = null;
        },
        updateGetDismantleRequestRunTime: (state, action) => {
          
            state.getDismantleRequest[0].dismantleRequests = action.payload.dismantleRequest.map((pump: any) => {
                if (pump?.serialNumber === action?.payload?.serialNumber) {
                    return {
                        ...pump,
                        runtime: action?.payload?.runtime
                    }
                }
                return pump;
            });
        },
        updateDismantleRequest(state, action) {
            state.dismantlePumps = action.payload

        }
    },
    extraReducers: (builder: any) => {
        builder.addCase(fetchDismantlePumps.pending, (state: any) => {
            state.loading = true;
        });

        //fullfilled
        builder.addCase(fetchDismantlePumps.fulfilled, (state: any, action: any) => {
            state.dismantlePumps = action.payload;
            state.loading = false;
        });

        //rejected
        builder.addCase(fetchDismantlePumps.rejected, (state: any, action: any) => {
            state.message = action.payload;
            state.loading = false;
        });

        // createDismantleRequest POST request
        builder.addCase(createDifaDismantleRequest.pending, (state: any) => {
            state.loading = true;
            state.success = false;
        });

        //fullfilled
        builder.addCase(createDifaDismantleRequest.fulfilled, (state: any, action: any) => {
            state.loading = false;
            state.success = true;
        });

        //rejected
        builder.addCase(createDifaDismantleRequest.rejected, (state: any, action: any) => {
            state.loading = false;
            state.success = false;
            state.message = action.payload;
        });

        // getDismantleRequest Get request
        builder.addCase(getDismantleRequestByDifa.pending, (state: any) => {
            state.loading = true;
        });

        //fullfilled
        builder.addCase(getDismantleRequestByDifa.fulfilled, (state: any, action: any) => {
            state.getDismantleRequest = action.payload;
            state.loading = false;
        });

        //rejected
        builder.addCase(getDismantleRequestByDifa.rejected, (state: any, action: any) => {
            state.message = action.payload;
            state.loading = false;
        });
    }
})

export const { resetState, updateGetDismantleRequestRunTime, updateDismantleRequest } = dismantleRequest.actions;
export default dismantleRequest.reducer;
