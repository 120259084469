import React, { useEffect, useState, useRef } from 'react';
import ArrowDown from './../../../images/chevron-down.svg';
import './Filter.css';

/**
 * Dropdown options
 * Name property is display value
 * value property is value binding
 */
export interface FilterOption {
  value?: string;
  name?: string;
}

export interface FilterProps {
  options: FilterOption[];
  onFilterChange: (filter: FilterOption) => void;
}

const Filter: React.FC<FilterProps> = ({ options, onFilterChange }) => {
  const [selectedOption, setSelectedOption] = useState<FilterOption>();
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    options && options.length && setSelectedOption(options[0]);
  }, [options]);

  const handleFilterChange = (option: FilterOption) => {
    setSelectedOption(option);
    onFilterChange(option);
    setIsOpen(false);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="filter-container" ref={dropdownRef}>
      <div className="selected-option" onClick={toggleDropdown}>
        {selectedOption?.name}
        <img className="dropdown-arrow" src={ArrowDown} alt="filter-dropdown-arrow" />
      </div>
      {isOpen && (
        <div className="filter-dropdown-menu">
          <div className="filter-label">Filter by</div>
          {options?.map((option, i) => (
            <div
              key={i}
              className={`filter-dropdown-item ${
                selectedOption === option ? 'selected' : ''
              }`}
              onClick={() => handleFilterChange(option)}
            >
              {option.name}
              {selectedOption === option && (
                <span className="check-mark">✔</span>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Filter;