import instance from "../../config/HttpCommon";

export const getDifaPackets = async (
    pageNumber: number,
    pageSize: number,
    operator: string[],
    installDate: string[],
    failedDate: string[],
    pullDate: string[],
    reportPublished: string[],
    rundays: string[],
    difaSceduled: string[],
    pullReasion: string[],
    failureCause: string[],
    failedItem: string[],
    primaryFailedComponent: string[]
) => {
    try {
        const payload = {
            operator,
            installDate,
            failedDate,
            pullDate,
            reportPublished,
            rundays,
            difaSceduled,
            pullReasion,
            failureCause,
            failedItem,
            primaryFailedComponent,
        };

        const DifaPacketRes = await instance.post(`Difa/GetDifaPackets`, payload, {
            params: { pageNumber, pageSize },
        });
        return DifaPacketRes?.data;
    } catch (error) {
        console.error("Error fetching equipment action:", error);
        throw error;
    }
}
export const getDifaReports = async (
    pageNumber: number,
    pageSize: number,
    operator: string[],
    installDate: string[],
    failedDate: string[],
    pullDate: string[],
    reportPublished: string[],
    rundays: string[],
    difaSceduled: string[],
    pullReasion: string[],
    failureCause: string[],
    failedItem: string[],
    primaryFailedComponent: string[]
) => {
    try {
        const payload = {
            operator,
            installDate,
            failedDate,
            pullDate,
            reportPublished,
            rundays,
            difaSceduled,
            pullReasion,
            failureCause,
            failedItem,
            primaryFailedComponent,
        };

        const DifaReportRes = await instance.post(`Difa/GetDifaReports`, payload, {
            params: { pageNumber, pageSize },
        });
        return DifaReportRes?.data;
    } catch (error) {
        console.error("Error fetching equipment action:", error);
        throw error;
    }
}
