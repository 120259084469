import empty_preview from "../../../images/empty_preview.svg";

const EmptyView = () => {
  return (
    <div className="empty-state empty-preview-container">
      <img src={empty_preview} alt="No Information Icon" />
      <h2>No information found</h2>
      <p className="details-msg">
        This section is empty. Return to the DIFA packet builder to add your
        information.
      </p>
    </div>
  );
};

export default EmptyView;
