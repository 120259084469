import React from "react";
import "./PageLayout.scss";
import { Outlet } from "react-router-dom";
import NavigationBar from "../features/navigationBar/NavBar";

export const Layout = () => {
  return (
    <div className="theme-dark main-container">
      <div className="navbar-container">
        <NavigationBar />
      </div>
      <div className="outlet-container">
        <Outlet />
      </div>
    </div>
  );
};
