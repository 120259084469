import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DifaSwitch from "../../../common/switch/DifaSwitch";
import UploadDocuments from "../../../common/uploadFiles/UploadDocuments";
import { setMatch } from "../../../difaPacketBuilder/difaPacketBuilderSlice";
import Helpers from "../../../../utilities/Helpers";
import PDFViewer from "../../../common/pdfViewer/FileViewer";
import EmptyView from "../../../common/emptyPreview/View";

interface MatchProps {
  source: string;
}

const Match: React.FC<MatchProps> = ({ source }) => {
  const { match } = useSelector((state: any) => state.difaPacketBuilder);
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState(match?.isSelected || false);
  const [uploadedFiles, setUploadedFiles] = useState<File[]>(
    match?.matchFile ? [match?.matchFile] : []
  );

  const onExpand = () => {
    setExpanded(!expanded);
    dispatch(setMatch({ ...match, isSelected: !expanded }));
  };

  const isPreview = source === "preview-report";

  const handleFilesChange = async (files: File[]): Promise<void> => {
    setUploadedFiles(files);
    const matchFile: any = files.length
      ? {
        link: URL.createObjectURL(files[0]),
        blob: await Helpers.convertFiletoBase64(files[0]),
        name: files[0].name,
      }
      : null;
    dispatch(setMatch({ ...match, matchFile }));
  };

  return (
    <div className="flex-auto flex flex-col w-full gap-5">
      {isPreview ? (
        match?.isSelected ? (
          <PDFViewer file={match?.matchFile?.link} />
        ) : (
          <EmptyView />
        )
      ) : (
        <div className="difa-widget-containers">
          <div className="header-main">
            <div className="flex flex-row justify-between">
              <div>
                <h3>Performance match</h3>
                <h5>Notice any issues during installation? Add photos and briefly describe observations.</h5>
              </div>
              <div className="flex flex-row items-center gap-3">
                <DifaSwitch checked={expanded} onChange={onExpand} />
                <h4 className="whitespace-nowrap">Include in report</h4>
              </div>
            </div>
          </div>
          <div className="block w-full border-t border-dark p-6">
            <UploadDocuments
              fileType="pdf"
              maxFileSize={100} // Max size: 100 MB
              onFilesChange={handleFilesChange}
              uploadedFiles={uploadedFiles}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Match;
