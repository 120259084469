import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import DashboardSlice from "./features/dashboard/DashboardSlice";
import DifaDetailsSlice from "./features/DifaPages/DifaDetailsSlice";
import DismantleRequestSlice from "./features/dismentleRequest/DismantleRequestSlice";
import PreInspectionSummarySlice from './features/preInspectionSummary/PreInspectionSummarySlice';
import DifaPacketBuilderSlice from './features/difaPacketBuilder/difaPacketBuilderSlice';
import DocumentationSlice from "./features/documentation/DocumentationSlice";
import DIFASchedularSlice from "./features/managerDashboard/DIFASchedularSlice";
import storage from "redux-persist/lib/storage"; // Defaults to localStorage for web


// Configuration for redux-persist
const persistConfig = {
    key: "root", // Key for the persisted state
    storage, // Storage medium (localStorage in this case)
    whitelist: ["difaDetails", "difaPacketBuilder","preInspectionSummary"], // Reducers to persist
};


// Combine reducers and wrap with persistReducer
const rootReducer = combineReducers({
    difaList: DashboardSlice,
    difaDetails: DifaDetailsSlice,
    dismantleRequest: DismantleRequestSlice,
    preInspectionSummary: PreInspectionSummarySlice,
    difaPacketBuilder: DifaPacketBuilderSlice,
    documentation: DocumentationSlice,
    difaSchedular: DIFASchedularSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer as any);


export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
}
)

export const persistor = persistStore(store);


export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
