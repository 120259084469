// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.max-h-60,
.border-gray-300 {
  background: #192636;
}

ul > li:hover {
  background: #001023 !important;
}

.border-gray-300 {
  border: #001023;
}

ul {
  max-height: 200px; /* Adjust the height as needed */
  overflow-y: auto; /* Enable vertical scrolling */
  padding-right: 10px; /* Optional, adds space for the scrollbar */
}

/* Custom scrollbar styling */
ul::-webkit-scrollbar {
  width: 6px; /* Adjust the width to make the scrollbar thin */
}

ul::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background color of the scrollbar track */
}

ul::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounded corners for the thumb */
}

ul::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the thumb when hovered */
}

.preview-pull-reasons {
  padding: 0 24px 16px 24px;
}

.create-pull-reasons {
  padding: 10px 24px 16px 12px;
}

.failed {
  color: var(--Error-Red-400, #F97066);
}

.passed {
  color: var(--Success-Green-400, #32d583);
}`, "",{"version":3,"sources":["webpack://./src/features/difaPacketBuilder/components/pullAndInbound/PullAndInbound.scss"],"names":[],"mappings":"AAAA;;EAEE,mBAAA;AACF;;AAEA;EACE,8BAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,iBAAA,EAAA,gCAAA;EACA,gBAAA,EAAA,8BAAA;EACA,mBAAA,EAAA,2CAAA;AACF;;AAEA,6BAAA;AACA;EACE,UAAA,EAAA,gDAAA;AACF;;AAEA;EACE,mBAAA,EAAA,4CAAA;AACF;;AAEA;EACE,gBAAA,EAAA,iCAAA;EACA,mBAAA,EAAA,kCAAA;AACF;;AAEA;EACE,gBAAA,EAAA,oCAAA;AACF;;AAEA;EACE,yBAAA;AACF;;AAEA;EACE,4BAAA;AACF;;AAEA;EACE,oCAAA;AACF;;AAEA;EACE,wCAAA;AACF","sourcesContent":[".max-h-60,\n.border-gray-300 {\n  background: #192636;\n}\n\nul > li:hover {\n  background: #001023 !important;\n}\n\n.border-gray-300 {\n  border: #001023;\n}\n\nul {\n  max-height: 200px; /* Adjust the height as needed */\n  overflow-y: auto; /* Enable vertical scrolling */\n  padding-right: 10px; /* Optional, adds space for the scrollbar */\n}\n\n/* Custom scrollbar styling */\nul::-webkit-scrollbar {\n  width: 6px; /* Adjust the width to make the scrollbar thin */\n}\n\nul::-webkit-scrollbar-track {\n  background: #f1f1f1; /* Background color of the scrollbar track */\n}\n\nul::-webkit-scrollbar-thumb {\n  background: #888; /* Color of the scrollbar thumb */\n  border-radius: 10px; /* Rounded corners for the thumb */\n}\n\nul::-webkit-scrollbar-thumb:hover {\n  background: #555; /* Color of the thumb when hovered */\n}\n\n.preview-pull-reasons {\n  padding: 0 24px 16px 24px\n}\n\n.create-pull-reasons {\n  padding: 10px 24px 16px 12px\n}\n\n.failed {\n  color: var(--Error-Red-400, #F97066);\n}\n\n.passed {\n  color: var(--Success-Green-400, #32d583);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
