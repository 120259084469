import React from "react";
import "./Loader.css";

const Loader = ({ isOverlay = false }) => {
  return (
    <>
      <div className={isOverlay ? "spinner-overlay" : ""}>
        <div className="spinner-container">
          <div className="spinner"></div>
          <div className="loader-label-loading">Loading...</div>
        </div>
      </div>
    </>
  );
};

export default Loader;
