import React, { useEffect, useState } from 'react';
import closeSlider from "../../../../images/close-slider.svg";
import downArrow from "../../../../images/chevron-down.svg";
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../common/loader/Loader';
import { DifaEngineers, DifaSchedularResponse } from '../../../models/difaSchedular';
import { formatDateInNumbers } from '../../../common/services/UtilityService';
import { cancelScheduler, fetchDifaSchedular, fetchEngineers, submitScheduler } from '../../DIFASchedularSlice';
import { toaster } from '../../../common/services/ToasterService';
import { set } from 'lodash';

interface Toggle {
    open: boolean;
    onCloseModal: () => void;
}

const DIFAShedularInspection: React.FC<Toggle> = ({ open, onCloseModal }) => {

    const { loading, difaSchedularById, difaEngineers } = useSelector((state: { difaSchedular: { loading: boolean; error: any; difaSchedularById: DifaSchedularResponse | null; difaEngineers: DifaEngineers[] | null } }) => state.difaSchedular);
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState(false);
    const [isAssignee, setIsAssignee] = useState(false);
    const [difaAssignee, setDifaAssignee] = useState<string | null>(null);
    const [isDIFANeeded, setIsDIFANeeded] = useState<boolean | null>(null);
    const [schedularEngineerID, setSchedularEngineerID] = useState<number | null>(null);
    const [difaDate, setDifaDate] = useState<string | null>(null);
    const [difaTime, setDifaTime] = useState<string | null>(null);
    const [isdifaChargable, setIsDifaChargable] = useState<boolean | null>(null);

    const difaNeededOption = [
        { difaNeeded: "Yes" },
        { difaNeeded: "No" },
    ];

    const difaChagableData = [
        {
            value: true,
            label: "Yes, charge client for DIFA",
            subLabel: "Yes, the ESP has been received",
        },
        {
            value: false,
            label: "No, client does not need to be charged",
            subLabel: "Yes, the ESP has been received",
        },
    ];

    useEffect(() => {
        if (!difaEngineers) {
            dispatch(fetchEngineers());
        }

        if (difaSchedularById && difaSchedularById?.difaInspectionDate) {
            setDifaAssignee(difaSchedularById?.assignedEngineer);
            setIsDIFANeeded(difaSchedularById?.isDifaNeeded);
            setDifaDate(difaSchedularById?.difaInspectionDate.split("T")[0]);
            setDifaTime(difaSchedularById?.difaInspectionDate.split("T")[1].split(":").slice(0, 2).join(":"));
            setSchedularEngineerID(difaSchedularById?.assignedEngineerID);
            setIsDifaChargable(difaSchedularById?.isDifaChargable);
        } else {
            setDifaAssignee(null);
            setIsDIFANeeded(null);
            setDifaDate(null);
            setDifaTime(null);
            setSchedularEngineerID(null);
            setIsDifaChargable(null);
        }

    }, [difaEngineers, difaSchedularById, dispatch]);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        document
            .getElementById("slider")
            ?.classList.toggle(open ? "open" : "close");

        return () => {
            document.getElementById("slider")?.classList.toggle("close");
        };
    }, [open]);

    document.querySelectorAll(".section-header").forEach((header) => {
        header.addEventListener("click", () => {
            header.parentElement?.classList.toggle("section-open");
        });
    });

    const onClick = () => {
        onCloseModal();
    };

    const handleEngineer = (name: string, id: number) => {
        setDifaAssignee(name);
        setIsAssignee(false);
        setSchedularEngineerID(id);
    }

    const handledifaNeeded = (val: string) => {
        val === "Yes" ? setIsDIFANeeded(true) : setIsDIFANeeded(false);
        setIsOpen(false);
    }

    const isbuttonDisabled = difaAssignee === null || !difaDate || !difaTime || isdifaChargable === null;

    const handleDifaSchedular = () => {
        let schedulerPayload = {}
        const combinedDateTime = `${difaDate}T${difaTime}`;
        if (isDIFANeeded) {
            schedulerPayload = {
                "jobRequestID": difaSchedularById?.jobRequestID,
                "quoteID": difaSchedularById?.quoteID,
                "assignedEngineerID": schedularEngineerID,
                "difaInspectionDate": combinedDateTime,
                "isDifaChargable": isdifaChargable,
                "isDifaNeeded": isDIFANeeded
            };
        } else {
            schedulerPayload = {
                "jobRequestID": difaSchedularById?.jobRequestID,
                "quoteID": difaSchedularById?.quoteID,
                "assignedEngineerID": schedularEngineerID ?? null,
                "difaInspectionDate": null,
                "isDifaChargable": false,
                "isDifaNeeded": isDIFANeeded
            };
        }

        dispatch(submitScheduler(schedulerPayload)).then((res: any) => {
            if (res?.meta?.requestStatus === 'fulfilled') {
                toaster.success('DIFA has been schedule', "DIFA engineers will be notified of their assignment shortly.");
                onCloseModal();
            } else {
                toaster.error(res?.payload?.title);
                console.error("error", res?.payload);
            }
        });
    };

    const cancelSchedular = (schedularId: number) => {
        dispatch(cancelScheduler(schedularId)).then((res: any) => {
            if (res?.meta?.requestStatus === 'fulfilled') {
                toaster.success('DIFA has been cancelled', "DIFA engineers will be notified of their assignment shortly.");
                onCloseModal();
            } else {
                toaster.error(res?.payload?.title);
                console.error("error", res?.payload);
            }
        });
    }

    return (
        <div className="main-component">
            <CardHeader onClose={onClick} />
            <div className="flex flex-col gap-6 justify-between text-neutral-grey h-[calc(100vh-68px)]">
                <div className="flex flex-col gap-6 pt-6 px-8">
                    {loading ? <Loader /> : difaSchedularById ?
                        (<>
                            <div className="bg-dark-700 border border-dark rounded-lg">
                                <div className="flex items-center justify-between py-4 px-6 border-bottom">
                                    <div className="flex flex-col w-full">
                                        <h2 className="text-xl font-semibold text-white">{difaSchedularById?.wellName ?? "-"}</h2>
                                        <h4 className="text-sm font-medium">{difaSchedularById?.operatorName ?? "-"}</h4>
                                    </div>
                                    <div className="flex items-center justify-end gap-2 w-full">
                                        <label className="text-sm font-semibold">DIFA needed</label>
                                        <div className="relative">
                                            <div className="flex items-center justify-between w-40 gap-4 px-3 py-[10px] border border-dark bg-dark-800 shadow-md rounded-lg cursor-pointer" onClick={isDIFANeeded === true ? undefined : toggleDropdown}>
                                                <span className="text-sm">{isDIFANeeded === null ? "Select option" : isDIFANeeded ? "Yes" : "No"}</span>
                                                <img src={downArrow} className="w-4 h-4" alt="download-icon" />
                                            </div>

                                            {/*  Dropdown menu  */}
                                            {isOpen && !difaAssignee &&
                                                (<div className="absolute mt-1 bg-dark-800 border border-dark rounded-lg w-full overflow-hidden">
                                                    <ul className="text-sm !p-0" aria-labelledby="dropdownDefaultButton">
                                                        {difaNeededOption.map((option, index) => (
                                                            <li className="p-1" key={index}>
                                                                <a onClick={() => handledifaNeeded(option?.difaNeeded)} className="block px-2 py-2 hover:bg-[#253040] rounded-md">{option.difaNeeded}</a>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>)}
                                        </div>
                                    </div>
                                </div>
                                <div className="flex items-center px-6">
                                    <div className="flex flex-col gap-1 w-full justify-center border-r border-dark py-4 pr-4">
                                        <label className="text-xs">Jop request ID</label>
                                        <label className="text-sm font-medium">{difaSchedularById?.jobRequestID}</label>
                                    </div>
                                    <div className="flex flex-col gap-1 w-full justify-center border-r border-dark p-4">
                                        <label className="text-xs">Well API</label>
                                        <label className="text-sm font-medium">{difaSchedularById?.wellapiNumber ?? "-"}</label>
                                    </div>
                                    <div className="flex flex-col gap-1 w-full justify-center py-4 pl-4">
                                        <label className="text-xs">Reason for pull</label>
                                        <label className="text-sm font-medium">{difaSchedularById?.reasonForPull ?? "-"}</label>
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-col gap-4">
                                <h2 className="text-lg font-semibold">ESP attributes</h2>
                                <div className="flex justify-between gap-6 items-center">
                                    <div className="flex flex-col justify-center w-full">
                                        <div className='flex items-center justify-between text-sm font-medium border-bottom'>
                                            <label className='flex-1 p-2'>Install start</label>
                                            <label className='p-2'>{difaSchedularById?.installStarted ? formatDateInNumbers(difaSchedularById?.installStarted) : "-"}</label>
                                        </div>
                                        <div className='flex items-center justify-between text-sm font-medium border-bottom'>
                                            <label className='flex-1 p-2'>Install complete</label>
                                            <label className='p-2'>{difaSchedularById?.installStarted ? formatDateInNumbers(difaSchedularById?.installStarted) : "-"}</label>
                                        </div>
                                        <div className='flex items-center justify-between text-sm font-medium border-bottom'>
                                            <label className='flex-1 p-2'>Pull start</label>
                                            <label className='p-2'>{difaSchedularById?.pullStarted ? formatDateInNumbers(difaSchedularById?.pullStarted) : "-"}</label>
                                        </div>
                                        <div className='flex items-center justify-between text-sm font-medium border-bottom'>
                                            <label className='flex-1 p-2'>Pull complete</label>
                                            <label className='p-2'>{difaSchedularById?.pullStarted ? formatDateInNumbers(difaSchedularById?.pullStarted) : "-"}</label>
                                        </div>
                                    </div>
                                    <div className="flex flex-col justify-center w-full">
                                        <div className='flex items-center justify-between text-sm font-medium border-bottom'>
                                            <label className='flex-1 p-2'>Actual fail date</label>
                                            <label className='p-2'>{difaSchedularById?.failedDate ? formatDateInNumbers(difaSchedularById?.failedDate) : "-"}</label>
                                        </div>
                                        <div className='flex items-center justify-between text-sm font-medium border-bottom'>
                                            <label className='flex-1 p-2'>Short run</label>
                                            <label className='p-2'>{difaSchedularById?.shortrun ?? "-"}</label>
                                        </div>
                                        <div className='flex items-center justify-between text-sm font-medium border-bottom'>
                                            <label className='flex-1 p-2'>Run days to failed</label>
                                            <label className='p-2'>{difaSchedularById?.failedRunDays ?? "-"}</label>
                                        </div>
                                        <div className='flex items-center justify-between text-sm font-medium border-bottom'>
                                            <label className='flex-1 p-2'>Run days to Pull</label>
                                            <label className='p-2'>{difaSchedularById?.pullRunDays ?? "-"}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>) :
                        <div className="text-center">No data found</div>
                    }

                    {isDIFANeeded &&
                        <>
                            <div className="difa-widget-containers flex flex-col py-4 px-6 gap-5">
                                <h3>Assign DIFA</h3>
                                <div className="flex flex-col gap-2 justify-start">
                                    <label className="text-sm font-semibold">Engineer</label>
                                    <div className="relative">
                                        <div className="flex items-center justify-between gap-6 px-3 py-[10px] border border-dark bg-dark-800 rounded-lg cursor-pointer" onClick={() => setIsAssignee(!isAssignee)}>
                                            <span className="text-sm">{!difaAssignee ? "Select engineer" : difaAssignee}</span>
                                            <img src={downArrow} alt="download-icon" />
                                        </div>

                                        {/*  Dropdown menu  */}
                                        {isAssignee &&
                                            (<div className="absolute mt-1 bg-dark-800 border border-dark rounded-lg w-full overflow-hidden">
                                                <ul className="text-sm !p-0 !max-h-32" aria-labelledby="dropdownDefaultButton">
                                                    {difaEngineers && difaEngineers?.length && difaEngineers.map((engineer, index) => (
                                                        <li className="p-1" key={index}>
                                                            <a onClick={() => handleEngineer(engineer?.name, engineer?.schedulerEngineerID)} className="block px-2 py-2 hover:bg-[#253040] rounded-md">{engineer?.name}</a>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>)}
                                    </div>
                                </div>
                                <div className="flex flex-col gap-2 justify-start">
                                    <label className="text-sm font-semibold">DIFA date and time</label>
                                    <div className="flex items-center gap-4">
                                        <div className="scheme-dark text-base" style={{ colorScheme: 'dark' }}>
                                            <input type="date" value={difaDate || ''} className="bg-dark-800 border border-dark rounded-lg p-2" onChange={(e) => setDifaDate(e.target.value)} />
                                        </div>
                                        <div className="scheme-dark text-base" style={{ colorScheme: 'dark' }}>
                                            <input type="time" value={difaTime || ''} className="bg-dark-800 border border-dark rounded-lg p-2" onChange={(e) => setDifaTime(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="difa-widget-containers flex flex-col py-4 px-6 gap-5">
                                <h3>Cost</h3>
                                <div className="flex flex-col gap-3">
                                    <label className="font-semibold text-sm">Is the DIFA chargeable?</label>
                                    <div className="flex gap-4 items-center justify-between">
                                        {difaChagableData?.map((option, index) => (
                                            <div
                                                key={index}
                                                className="flex items-start gap-2 w-full py-2 px-3 border border-dark bg-dark rounded-lg"
                                            >
                                                <input
                                                    type="radio"
                                                    value={option.value.toString()}
                                                    checked={isdifaChargable === option.value}
                                                    className="border-[#5D6675] bg-dark-800 w-4 h-4 border focus:invisible checked:border-[#0094BD] checked:bg-[#0094BD] text-[#0094BD]"
                                                    onChange={() => setIsDifaChargable(option.value)}
                                                />
                                                <label className="text-sm font-bold">
                                                    {option.label} <br />
                                                    <span className="text-xs font-normal">{option.subLabel}</span>
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </div>
                <div className={`flex items-center ${difaSchedularById && difaSchedularById?.schedulerID ? "justify-between" : "justify-end"} p-6 gap-4 border-top sticky bg-dark-800 bottom-0`}>
                    {difaSchedularById && difaSchedularById?.schedulerID && <button className="secondary-button !border-[#F97066] !text-[#F97066] text-sm" onClick={() => cancelSchedular(difaSchedularById?.schedulerID)}>Cancel DIFA</button>}
                    {difaSchedularById && !difaSchedularById?.schedulerID && <button className="secondary-button w-28 text-sm" onClick={() => { onCloseModal() }}>Cancel</button>}
                    <button className="primary-button w-28 text-sm" disabled={isbuttonDisabled} onClick={handleDifaSchedular}>{difaSchedularById && difaSchedularById?.schedulerID ? "Update" : "Save"}</button>
                </div>
            </div>
        </div>
    )
}

const CardHeader: React.FC<any> = ({ onClose }) => {
    return (
        <div className="report-card-header !justify-start gap-6 sticky top-0 z-10">
            <div className="flex gap-3 items-center">
                <div className="pr-3 border-r-2 border-[#B8C5CC]" onClick={onClose}>
                    <img
                        src={closeSlider}
                        onClick={onClose}
                        alt="icon"
                    />
                </div>
                <div>
                    <img
                        src={downArrow}
                        alt="download-icon"
                    />
                </div>
            </div>
            <h2 className="text-[23px] font-bold">Schedule DIFA inspection</h2>
        </div>
    );
};

export default DIFAShedularInspection
