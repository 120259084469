// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-component .apply-btn {
  border: 1px solid var(--Primary-Indigo-700, #005F7C);
  background: var(--Primary-Indigo-600, #00789E);
}
.main-component .apply-btn:hover {
  background: var(--Primary-Indigo-700, #005F7C);
}`, "",{"version":3,"sources":["webpack://./src/features/documentation/Documentation.scss"],"names":[],"mappings":"AAEI;EACI,oDAAA;EACA,8CAAA;AADR;AAGQ;EACI,8CAAA;AADZ","sourcesContent":[".main-component {\n\n    .apply-btn {\n        border: 1px solid var(--Primary-Indigo-700, #005F7C);\n        background: var(--Primary-Indigo-600, #00789E);\n\n        &:hover {\n            background: var(--Primary-Indigo-700, #005F7C);\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
