// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#customTooltip {
  position: absolute;
  display: none;
  padding: 8px;
  background-color: #333;
  color: #fff;
  border-radius: 4px;
  pointer-events: none;
  font-size: 12px;
}

#chart-container {
  position: relative;
  width: 100%;
  height: 400px;
  overflow: visible;
}

.chart-labels {
  font-family: Mulish;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0%;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/features/preInspectionSummary/components/RundaysChart.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,aAAA;EACA,YAAA;EACA,sBAAA;EACA,WAAA;EACA,kBAAA;EACA,oBAAA;EACA,eAAA;AACF;;AACA;EACE,kBAAA;EACA,WAAA;EACA,aAAA;EACA,iBAAA;AAEF;;AAAA;EACE,mBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,kBAAA;AAGF","sourcesContent":["#customTooltip {\n  position: absolute;\n  display: none;\n  padding: 8px;\n  background-color: #333;\n  color: #fff;\n  border-radius: 4px;\n  pointer-events: none;\n  font-size: 12px;\n}\n#chart-container {\n  position: relative;\n  width: 100%;\n  height: 400px;\n  overflow: visible;\n}\n.chart-labels {\n  font-family: Mulish;\n  font-weight: 400;\n  font-size: 10px;\n  line-height: 12px;\n  letter-spacing: 0%;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
