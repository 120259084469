import instance from "../../config/HttpCommon";

const isReportModule = (origin: string) => {
    return (origin === 'report-builder' || origin === 'report-preview');
}
export const getSizingSummary = async (wellApiNumber: string) => {
    const determineSummaryRes = await instance.get(
        `/determine/GetSizingSummary?wellApiNumber=${wellApiNumber}`
    );
    return determineSummaryRes?.data;
};

export const getDaviationSurvey = async (wellApiNumber: string) => {
    const determineSurveyRes = await instance.get(
        `/determine/GetDeviationSurveyPerformance?wellApiNumber=${wellApiNumber}`
    );
    return determineSurveyRes?.data;
};

export const getGetDeviationSurveyPerformance = async (wellApiNumber: string) => {
    const determineSurveyRes = await instance.get(
        `/determine/GetDeviationSurveyPerformance?wellApiNumber=${wellApiNumber}`
    );
    return determineSurveyRes?.data;
};

export const getEquipmentDetails = async (wellApiNumber: string) => {
    const equipmentDetailsRes = await instance.get(
        `/Determine/GetSizingSummary?wellapinumber=${wellApiNumber}`
    );
    return equipmentDetailsRes?.data;
};

export const getInstallReportImages = async (wellApiNumber: string, difaId: string, origin: string = '') => {
    const { data, statusText } = await instance.get(
        `/Difa/GetInstallReportImages?wellapinumber=${wellApiNumber}&difaId=${difaId}`
    );
    return data;
};

export const getImagesGallery = async (wellApiNumber: string, difaId: string, reportType: string, origin: string = '') => {
    if (isReportModule(origin)) {
        const gallery = await instance.get(
            `/ReportBuilder/GetImageGallery?wellapinumber=${wellApiNumber}&difaId=${difaId}&reportType=${reportType}`
        );
        return gallery?.data;
    } else {
        const gallery = await instance.get(
            `/ReportImages/GetImageGallery?wellapinumber=${wellApiNumber}&difaId=${difaId}&reportType=${reportType}`
        );
        return gallery?.data;
    }
};

export const getImageGalleryByID = async (galleryID: string, origin: string = '') => {
    if (isReportModule(origin)) {
        const gallery = await instance.get(
            `/ReportBuilder/GetImageGalleryByID?galleryID=${galleryID}`
        );
        return gallery?.data;
    } else {
        const gallery = await instance.get(
            `/ReportImages/GetImageGalleryByID?galleryID=${galleryID}`
        );
        return gallery?.data;
    }
};


export const getInboundReportImages = async (difaId: string, partType: string,) => {
    const installReportImages = await instance.get(
        `/Difa/GetInboundTestingReportImages?partType=${partType}&difaId=${difaId}`
    );
    return installReportImages?.data;
};

export const getPullReportImages = async (wellApiNumber: string, difaId: string) => {
    const installReportImages = await instance.get(
        `/Difa/GetPullReportImages?wellapinumber=${wellApiNumber}&difaId=${difaId}`
    );
    return installReportImages?.data;
};

export const createImageGallery = async (galleryDetails: any, origin: string = '') => {
    if (isReportModule(origin))
        return await instance.post(
            `/ReportBuilder/CreateImageGallery`, galleryDetails
        );
    else
        return await instance.post(
            `/ReportImages/CreateImageGallery`, galleryDetails
        );
};

export const updateImageGallery = async (galleryDetails: any, origin: string = '') => {
    if (isReportModule(origin)) {
        const installReportImages = await instance.put(
            `/ReportBuilder/UpdateGalleryDetails`, galleryDetails
        );
        return installReportImages?.data;
    } else {
        const installReportImages = await instance.put(
            `/ReportImages/UpdateGalleryDetails`, galleryDetails
        );
        return installReportImages?.data;
    }
};

export const getGalleryEquipments = async (wellApiNumber: string, difaId: string) => {
    const installReportImages = await instance.get(
        `/ReportBuilder/GetGalleryEquipments?wellapinumber=${wellApiNumber}&difaId=${difaId}`,
    );
    return installReportImages?.data;
};

export const renameImageGallery = async (galleryId: number, galaryName: string, origin: string = '') => {
    if (isReportModule(origin)) {
        const updatedGallery = await instance.put(`ReportBuilder/RenameGallery?galleryID=${galleryId}&galleryName=${galaryName}`);
        return updatedGallery?.data;
    } else {
        const updatedGallery = await instance.put(`ReportImages/RenameGallery?galleryID=${galleryId}&galleryName=${galaryName}`);
        return updatedGallery?.data;
    }
};

export const updateImageDetails = async (imageDetails: any, origin: string = '') => {
    if (isReportModule(origin)) {
        const installReportImages = await instance.put(
            `/ReportBuilder/UpdateImageDetails`, imageDetails
        );
        return installReportImages?.data;
    } else {
        const installReportImages = await instance.put(
            `/ReportImages/UpdateImageDetails`, imageDetails
        );
        return installReportImages?.data;
    }
};


export const deleteImageGallery = async (galleryId: string, origin: string = '') => {
    if (isReportModule(origin)) {
        const deleteResponse = await instance.delete(
            `/ReportBuilder/DeleteImageGallery?galleryID=${galleryId}`
        );
        return deleteResponse?.data;
    } else {
        const deleteResponse = await instance.delete(
            `/ReportImages/DeleteImageGallery?galleryID=${galleryId}`
        );
        return deleteResponse?.data;
    };
};

export const deleteImage = async (imageId: string, origin: string = '') => {
    if (isReportModule(origin)) {
        const deleteResponse = await instance.delete(
            `/ReportBuilder/DeleteImageDetails?reportImageDetailID=${imageId}`
        );
        return deleteResponse?.data;
    } else {
        const deleteResponse = await instance.delete(
            `/ReportImages/DeleteImageDetails?reportImageDetailID=${imageId}`
        );
        return deleteResponse?.data;
    }
};

export const getDifaTrackingDetails = async (wellApiNumber: string, difaId: string) => {
    const difaTrackingDetails = await instance.get(
        `/Difa/GetDifaTrackingDetails?wellapinumber=${wellApiNumber}&difaId=${difaId}`
    );
    return difaTrackingDetails?.data;
};

export const saveWellandRunlife = async (wellRunLifeDetails: any, origin: string) => {
    const wellRunLifeResponse = await instance.post(
        `/${isReportModule(origin) ? 'ReportBuilder' : 'Difa'}/SaveAsDraft`, wellRunLifeDetails
    );
    return wellRunLifeResponse?.data;
}

export const publishDifaPacket = async (publishPacket: any, origin: string) => {
    const publishPacketResponse = await instance.post(
        `/${isReportModule(origin) ? 'ReportBuilder/PublishReportDraft' : 'Difa/PublishDraft'}`, publishPacket
    );
    return publishPacketResponse?.data;
}


export const deletePacket = async (deletePacket: any, origin: string) => {
    const deletePacketResponse = await instance.delete(
        `/${isReportModule(origin) ? 'ReportBuilder/DeleteDifaReport' : 'Difa/DeleteDifaPacket'}?wellApiNumber=${deletePacket.wellApiNumber}&difaId=${deletePacket.difaId}`,
    );
    return deletePacketResponse?.data;
}

export const deleteDraft = async (deleteObj: any, origin: string) => {
    const deleteDraftResponse = await instance.delete(
        `/${isReportModule(origin) ? 'ReportBuilder/DiscardDifaPacketDraft' : 'Difa/DiscardDifaPacketDraft'}?wellApiNumber=${deleteObj.wellApiNumber}&difaId=${deleteObj.difaId}`,
    );
    return deleteDraftResponse?.data;
}

export const createDifaReportBuilder = async (createReport: any) => {
    const createReportResponse = await instance.post(
        `/ReportBuilder/CreateReportBuilder`, createReport
    );
    return createReportResponse?.data;
}

export const SaveAsDraft = async (installWellOperations: any) => {
    const wellRunLifeResponse = await instance.post(
        `/Difa/SaveAsDraft`, installWellOperations
    );
    return wellRunLifeResponse?.data;
}

export const saveEspSizingDoc = async (espSizingDetails: any, origin: string) => {
    const wellRunLifeResponse = await instance.post(
        `/${isReportModule(origin) ? 'ReportBuilder' : 'Difa'}/SaveDocuments`, espSizingDetails
    );
    return wellRunLifeResponse?.data;
}

export const GetDraft = async (wellApiNumber: string, difaId: string, origin: string) => {
    const difaTrackingDetails = await instance.get(
        `/${isReportModule(origin) ? 'ReportBuilder' : 'Difa'}/GetDraft?wellApiNumber=${wellApiNumber}&difaId=${difaId}`
    );
    return difaTrackingDetails?.data;
};

export const GetDocuments = async (wellApiNumber: string, difaId: string, category: string, origin: string) => {
    const difaTrackingDetails = await instance.get(
        `/${isReportModule(origin) ? 'ReportBuilder' : 'Difa'}/GetDocuments?wellApiNumber=${wellApiNumber}&difaId=${difaId}&category=${category}`
    );
    return difaTrackingDetails?.data;
};


export const getDifaPublishReport = async (wellApiNumber: string, difaId: string) => {
    const DifaPublishReportRes = await instance.get(
        `/Difa/GeneratePacketBuilderPDF?wellApiNumber=${wellApiNumber}&difaId=${difaId}`,
        {
            responseType: "blob", // Ensure the response is treated as binary data
        }
    );
    return DifaPublishReportRes?.data;
};