// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toast-notification {
  bottom: 110px;
  border-radius: var(--radius-md, 8px);
  border: 1px solid var(--Darkmode-400, #4A5463);
  background: var(--Dark-mode-800, #001023);
  padding: 16px;
  font-size: 14px;
  color: var(--Success-Green-400, #32D583);
}

.error {
  color: var(--Error-Red-400, #F97066) !important;
}`, "",{"version":3,"sources":["webpack://./src/features/common/notificationToast/NotificationToaster.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,oCAAA;EACA,8CAAA;EACA,yCAAA;EACA,aAAA;EACA,eAAA;EACA,wCAAA;AACJ;;AAEA;EACI,+CAAA;AACJ","sourcesContent":[".toast-notification {\n    bottom: 110px;\n    border-radius: var(--radius-md, 8px);\n    border: 1px solid var(--Darkmode-400, #4A5463);\n    background: var(--Dark-mode-800, #001023);\n    padding: 16px;\n    font-size: 14px;\n    color: var(--Success-Green-400, #32D583);\n}\n\n.error {\n    color: var(--Error-Red-400, #F97066) !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
