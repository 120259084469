import { useEffect, useRef, useState } from "react";
import DifaInput from "../input/DifaInput";
import closeIcon from "./../../../images/close-icon.svg";
import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import './EquipmentDropdown.scss';

import ArrowDown from '../../../images/chevron-down.svg';
import '../../dashboard/components/Filter.css';
import { isNull } from "lodash";


const SelectEquipmentDialog: React.FC<{ show: boolean, data: any, close?: (name?: string) => void, updateGalleryName?: boolean, gl?: any }> = ({
    show = false,
    data,
    close = (name?: string) => { },
    updateGalleryName = false,
    gl
}) => {
    const [equipement, setEquipment] = useState<any>(null);

    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const handleFilterChange = (option: any) => {
        setEquipment(option);
        setIsOpen(false);
    };

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <Dialog open={show} onClose={() => close()} className="relative z-50">
            <DialogBackdrop
                transition
                className="fixed inset-0 backdrop-blur-sm transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />
            <DialogBackdrop
                transition
                className="fixed inset-0 backdrop-blur-sm transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className="fixed inset-0 z-10 w-screen md:w-2/4 left-25">
                <div className="flex min-h-full items-end justify-center p-8 text-center sm:items-center sm:p-0">
                    <DialogPanel
                        transition
                        className="flex dialog-max-height relative transform overflow-hidden rounded-lg difa-background-with-border text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-sm md:min-w-4/5 md:w-4/5 md:max-w-full data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                    >
                        <div className="difa-widget-containers w-full">
                            <div className="header-main border-bottom">
                                <div className="flex flex-row justify-between">
                                    <div>
                                        <h3>{updateGalleryName ? "Rename" : "Create"} gallery</h3>
                                    </div>
                                    <a href="javascript:void(0)" onClick={() => close()}>
                                        <img src={closeIcon} alt="close" />
                                    </a>
                                </div>
                            </div>
                            <div className={`p-8 w-full flex flex-col ${isOpen ? 'overflow-y-auto' :''} image-container pb-16 table-main`}>
                                <div className="flex flex-col h-34 items-center justify-center gap-6">
                                    <h3>Select equipment</h3>
                                    <h5>DIFA inspection galleries are grouped based off equipment. Please select the equipment type that you would like to base your gallery off of. </h5>
                                    
                                    <div className="dropdown-container w-full p-2 border border-dark bg-dark-700 text-base rounded-lg" ref={dropdownRef}>
                                        <div className="selected-item w-full" onClick={toggleDropdown}>
                                            {equipement ? `${equipement.partType} Serial # ${equipement.serialNumber}` : 'Select equipment'}
                                            <img className="dropdown-arrow" src={ArrowDown} alt="filter-dropdown-arrow" />
                                        </div>
                                        {isOpen && (
                                            <div className="dropdown-menu bg-dark-700 w-full border border-dark rounded-lg z-40">
                                                {(isNull(data) || data?.length === 0) && <div className="dropdown-item">No equipments found</div>}
                                                {data?.map((option: any, i: any) => (
                                                    <div
                                                        key={i}
                                                        className={`dropdown-item `}
                                                        onClick={() => handleFilterChange(option)}
                                                    >
                                                        <div>{option.partType}</div>
                                                        <div className="text-grey-200 px-6">Serial # {option.serialNumber}</div>
                                                        
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                </div>
                            
                            <div className="flex-row flex gap-3 border-top justify-end items-center h-16">
                                
                                <div className="secondary-button">
                                    <button className="header-btn-text" onClick={() => close()}>Back</button>
                                </div>
                                <div className="primary-button cursor-pointer mr-6">
                                    <button className="header-btn-text" onClick={() => close(`${equipement.partType} Serial # ${equipement.serialNumber}`)} disabled={!equipement}>{updateGalleryName ? "Rename" : "Create"} Gallery</button>
                                </div>
                                
                            </div>
                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>

    );
}

export default SelectEquipmentDialog;
