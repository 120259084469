import instance from '../../config/HttpCommon';
import { CreateDismantleRequestPayload } from '../models/DismantleRequest';

export const getDismantlePumps = async (difaId: string) => {
    const DismantlePumpsRes = await instance.get(`Difa/DismantledPumps?difaId=${difaId}`);
    return DismantlePumpsRes?.data;
}

export const createDismantleRequest = async (dismantleRequest: CreateDismantleRequestPayload) => {
    const createDismantleRequestRes = await instance.post('Difa/CreateDismantleRequest', dismantleRequest);
    return createDismantleRequestRes?.data;
};

export const getDismantleRequestById = async (dismantleRequestId: string, wellApiNumber: string) => {
    const dismantleRequestRes = await instance.get(`Difa/GetDismantleRequestDetails?wellApiNumber=${wellApiNumber}&difaId=${dismantleRequestId}`);
    return dismantleRequestRes?.data;
}
