import DifaSwitch from "../../../common/switch/DifaSwitch";
import SizingSummary from "./SizingSummary";
import SizingSchematic from "./SizingSchematic";

const EspSizing: React.FC<{ wellApiNumber: string }> = ({
    wellApiNumber
}) => {
    return (
        <div className='flex-auto flex flex-col w-full gap-5'>
            <SizingSummary wellApiNumber={wellApiNumber} />
            <SizingSchematic wellApiNumber={wellApiNumber} />
            <div className="difa-widget-containers">
                <div className="header-main">
                    <div className="flex flex-row justify-between">
                        <div>
                            <h3>DETERMINE deviation survey</h3>
                            <h5>Degree change and inclination of the wellbore.</h5>
                        </div>
                        <div className="flex flex-row items-center gap-3">
                            <DifaSwitch />
                            <h4>Include&nbsp;in&nbsp;report</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div className="difa-widget-containers">
                <div className="header-main">
                    <div className="flex flex-row justify-between">
                        <div>
                            <h3>DETERMINE performance curves</h3>
                            <h5>Projected performance for the ESP design.</h5>
                        </div>
                        <div className="flex flex-row items-center gap-3">
                            <DifaSwitch />
                            <h4>Include&nbsp;in&nbsp;report</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div className="difa-widget-containers">
                <div className="header-main">
                    <div className="flex flex-row justify-between">
                        <div>
                            <h3>DETERMINE equipment details</h3>
                            <h5>Specific details of the ESP design's key components, along with surface equipment details.</h5>
                        </div>
                        <div className="flex flex-row items-center gap-3">
                            <DifaSwitch />
                            <h4>Include&nbsp;in&nbsp;report</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EspSizing;