import React, { useEffect, useState } from "react";
import DifaSwitch from "../../../common/switch/DifaSwitch";
import UploadDocuments from "../../../common/uploadFiles/UploadDocuments";
import PDFViewer from "../../../common/pdfViewer/FileViewer";
import { useDispatch, useSelector } from "react-redux";
import { setProduction } from "../../difaPacketBuilderSlice";
import Helpers from '../../../../utilities/Helpers';
import EmptyView from "../../../common/emptyPreview/View";
const Production: React.FC<{ source: string, unsavedCallback?: (status: boolean) => void; }> = ({
  source, 
  unsavedCallback
}) => {
  const { production } = useSelector(
    (state: any) => state.difaPacketBuilder
  );
  const dispatch = useDispatch();

  const [expanded, setExpanded] = useState(production?.isSelected || false);
  const [uploadedFiles, setUploadedFiles] = useState<File[]>(
    production?.productionFile ? [production?.productionFile] : []
  );
  const onExpand = () => {
    setExpanded(!expanded);
    dispatch(setProduction({ ...production, isSelected: !expanded }));
  };
  const isPreview = source === "preview-packet" || source === "preview-report";

  const [pdfUrl, setPdfUrl] = useState<string | null>(null);
  
    useEffect(() => {
      if (production?.productionFile?.link) {
        // Check if the link is a blob or a URL
        if (production.productionFile.link instanceof Blob) {
          // Create an object URL for the blob
          const objectUrl = URL.createObjectURL(production.productionFile.link);
          setPdfUrl(objectUrl);
  
          // Cleanup the object URL when the component unmounts
          return () => {
            URL.revokeObjectURL(objectUrl);
          };
        } else {
          // Assume it's a valid URL
          setPdfUrl(production.productionFile.link);
        }
      } else {
        setPdfUrl(null);
      }
    }, [production?.productionFile?.link]);

  async function handleFilesChange(files: File[]): Promise<void> {
    setUploadedFiles(files);
    const productionFile: any = files?.length
      ? {
          link: URL.createObjectURL(files[0]),
          blob: await Helpers.convertFiletoBase64(files[0]),
          name: files[0].name,
        }
      : null;
    dispatch(setProduction({ ...production, productionFile }));
    unsavedCallback && unsavedCallback(true);
  }
  const isReport = source.includes("report");
  return (
    <>
      {isPreview ? (
        production?.isSelected && production?.productionFile?.link ? (
          <PDFViewer file={production?.productionFile?.link} />
        ) : (
          <EmptyView />
        )
      ) : (
        <div className="difa-widget-containers">
          <div className="header-main">
            <div className="flex flex-row justify-between flex-wrap">
              <div>
                <h3>Production plot</h3>
                <h5>
                  Import or manually add production data to generate production
                  plots
                </h5>
              </div>
              <div className="flex flex-row items-center gap-3">
                <DifaSwitch checked={expanded} onChange={onExpand} />
                <h4 className="whitespace-nowrap">Include in {isReport ? 'report' : 'packet'}</h4>
              </div>
            </div>
          </div>
          <div className="block w-full border-t border-dark p-6">
            <UploadDocuments
              fileType="pdf"
              maxFileSize={100} // Max size: 100 MB
              onFilesChange={handleFilesChange}
              uploadedFiles={uploadedFiles}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Production;


