import React, { useState } from 'react'
import downArrow from "../../../../images/chevron-down.svg";
import { formatDateInNumbers } from "../../../common/services/UtilityService";

const ServiceCalls = () => {
  const [loading, setLoading] = useState(false);
  const difaRundaysDetails: Array<{ difaId: string; runLifeProjectNumber?: string; installDate: string; difaFailedDate?: string; pullDate: string }> = [];
  return (
    <div className="relative border border-dark overflow-x-auto shadow-md sm:rounded-lg">
      <table className="w-full text-left">
        <thead className="text-xs font-semibold border-b border-dark top-0 sticky text-neutral-grey">
          <tr className="table-row">
            <th scope="col" className="px-3 py-4">
              <div className="flex items-center">
                Dispatch #
                <img
                  src={downArrow}
                  alt="sort icon"
                  className="ms-1.5 cursor-pointer"
                />
              </div>
            </th>
            <th scope="col" className="px-3 py-4">
              <div className="flex items-center">
                Dispatch reason
                <img
                  src={downArrow}
                  alt="sort icon"
                  className="ms-1.5 cursor-pointer"
                />
              </div>
            </th>
            <th scope="col" className="px-3 py-4">
              <div className="flex items-center">
                Start date
                <img
                  src={downArrow}
                  alt="sort icon"
                  className="ms-1.5 cursor-pointer"
                />
              </div>
            </th>
            <th scope="col" className="px-3 py-4">
              <div className="flex items-center">
                Complete date
                <img
                  src={downArrow}
                  alt="sort icon"
                  className="ms-1.5 cursor-pointer"
                />
              </div>
            </th>
            <th scope="col" className="px-3 py-4">
              <div className="flex items-center">
                Problem
                <img
                  src={downArrow}
                  alt="sort icon"
                  className="ms-1.5 cursor-pointer"
                />
              </div>
            </th>
            <th scope="col" className="px-3 py-4">
              <div className="flex items-center">
                Resolution
                <img
                  src={downArrow}
                  alt="sort icon"
                  className="ms-1.5 cursor-pointer"
                />
              </div>
            </th>
          </tr>
        </thead>
        <tbody className="text-sm font-normal text-neutral-grey">
          {loading ? (
            <tr className="table-row">
              <td colSpan={6} className="text-lg text-center py-5">
                Loading...
              </td>
            </tr>
          ) : difaRundaysDetails.length > 0 ? (
            difaRundaysDetails.map((difaRundaysDetail: any, index: number) => (
              <tr key={index} className="table-row border-b border-dark">
                <td className="px-3 py-3">{difaRundaysDetail?.difaId}</td>
                <td className="px-3 py-3">{difaRundaysDetail?.runLifeProjectNumber ?? "-"}</td>
                <td className="px-3 py-3">{formatDateInNumbers(difaRundaysDetail?.installDate)}</td>
                <td className="px-3 py-3">{difaRundaysDetail?.failedDate ? formatDateInNumbers(difaRundaysDetail?.difaFailedDate) : "-"}</td>
                <td className="px-3 py-3">{formatDateInNumbers(difaRundaysDetail?.pullDate)}</td>
                <td className="px-3 py-3">{formatDateInNumbers(difaRundaysDetail?.pullDate)}</td>
              </tr>
            ))
          ) : (
            <tr className="table-row">
              <td colSpan={6} className="text-lg text-center py-5">
                No data found
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default ServiceCalls
