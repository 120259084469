// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pi-summary-content-header {
  display: flex;
  padding: var(--spacing-xl, 16px) 20px;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
  border-radius: var(--radius-md, 8px);
  border: 1px solid var(--Darkmode-400, #4a5463);
  background: linear-gradient(181deg, #253240 -16.87%, rgba(37, 48, 64, 0.4) 35.14%);
}
.pi-summary-content-header .summary-header-difa-details {
  display: flex;
  color: var(--Neutral-Grey-25, #f7f9f9);
  font-family: Mulish;
  font-size: 14px;
  flex-direction: column;
  gap: 8px;
  padding-right: 16px;
  border-right: 1px solid #4a5463;
}
.pi-summary-content-header .summary-header-difa-details .difa-detail-heading {
  font-weight: 700;
}
.pi-summary-content-header .summary-header-difa-details .difa-detail-data {
  align-items: center;
  display: flex;
  font-weight: 500;
  gap: 12px;
}
.pi-summary-content-header .summary-header-difa-details:last-child {
  border: none;
}`, "",{"version":3,"sources":["webpack://./src/features/preInspectionSummary/components/PISummaryDifaDetails.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,qCAAA;EACA,mBAAA;EACA,SAAA;EACA,eAAA;EACA,oCAAA;EACA,8CAAA;EACA,kFAAA;AACF;AAKE;EACE,aAAA;EACA,sCAAA;EACA,mBAAA;EACA,eAAA;EACA,sBAAA;EACA,QAAA;EACA,mBAAA;EACA,+BAAA;AAHJ;AAKI;EACE,gBAAA;AAHN;AAMI;EAEE,mBAAA;EACA,aAAA;EACA,gBAAA;EACA,SAAA;AAJN;AAQE;EACE,YAAA;AANJ","sourcesContent":[".pi-summary-content-header {\n  display: flex;\n  padding: var(--spacing-xl, 16px) 20px;\n  align-items: center;\n  gap: 16px;\n  flex-wrap: wrap;\n  border-radius: var(--radius-md, 8px);\n  border: 1px solid var(--Darkmode-400, #4a5463);\n  background: linear-gradient(\n    181deg,\n    #253240 -16.87%,\n    rgba(37, 48, 64, 0.4) 35.14%\n  );\n\n  .summary-header-difa-details {\n    display: flex;\n    color: var(--Neutral-Grey-25, #f7f9f9);\n    font-family: Mulish;\n    font-size: 14px;\n    flex-direction: column;\n    gap: 8px;\n    padding-right: 16px;\n    border-right: 1px solid #4a5463;\n\n    .difa-detail-heading {\n      font-weight: 700;\n    }\n\n    .difa-detail-data {\n      font-weight: 500;\n      align-items: center;\n      display: flex;\n      font-weight: 500;\n      gap: 12px;\n    }\n  }\n\n  .summary-header-difa-details:last-child {\n    border: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
