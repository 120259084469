import React, { useEffect, useState } from 'react'
import { ReactComponent as SearchIcon } from "../../../../images/search-md.svg";
import { ReactComponent as FilterIon } from "../../../../images/filter-funnel-01.svg"
import { ReactComponent as DownArrow } from "../../../../images/chevron-down.svg";
import { ReactComponent as UpArrow } from "../../../../images/chevron-up.svg";
import { ReactComponent as ArrowUpIcon } from "../../../../images/arrow-narrow-up.svg";
import { ReactComponent as ArrowDownIcon } from "../../../../images/arrow-down.svg";
import DIFAShedularInspection from './DIFAShedularInspection';
import { useDispatch, useSelector } from 'react-redux';
import { DifaSchedularResponse } from '../../../models/difaSchedular';
import { formatDateInNumbers } from '../../../common/services/UtilityService';
import Loader from '../../../common/loader/Loader';
import { fetchDifaSchedular } from '../../DIFASchedularSlice';
import { useCallback, useRef } from 'react';
import { fetchDifaSchedularById } from '../../DIFASchedularSlice';

const DIFASchedular = () => {

    const [sortConfig, setSortConfig] = useState<{ key: keyof any; direction: 'asc' | 'desc' } | null>(null);
    const [showDifaSchedularInspection, setShowDifaSchedularInspection] = useState(false);
    const dispatch = useDispatch();
    const { loading, error, difaSchedular } = useSelector((state: any) => state.difaSchedular);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [hasMore, setHasMore] = useState(true);
    const observer = useRef<IntersectionObserver | null>(null);

    const lastElementRef = useCallback((node: HTMLElement | null) => {
        if (loading) return;
        if (observer.current) observer.current.disconnect();

        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore) {
                setPageNumber(prevPageNumber => prevPageNumber + 1);
            }
        });

        if (node) observer.current.observe(node);
    }, [loading, hasMore]);

    useEffect(() => {
        dispatch(fetchDifaSchedular({ pageNumber, pageSize }));
    }, [pageNumber, pageSize, dispatch]);

    useEffect(() => {
        if (difaSchedular) {
            if (difaSchedular.schedulerList.length < pageSize) {
                setHasMore(false);
            }
        }
    }, [difaSchedular, pageSize]);

    const requestSort = (key: keyof any) => {
        let direction: 'asc' | 'desc' = 'asc';
        if (sortConfig?.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    const handleScheduler = (jobRequestID: number, quoteID: number) => {
        setShowDifaSchedularInspection(true);
        dispatch(fetchDifaSchedularById({ jobId: jobRequestID, quoteId: quoteID }))
    }

    return (
        <div className="flex flex-col gap-4 px-6 pb-5">
            <div className="flex items-center justify-end gap-2">
                {/* Search Bar */}
                <div className="relative w-1/3">
                    <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                        <SearchIcon width="20" height="20" />
                    </div>
                    <input
                        type="search"
                        id="default-search"
                        className="p-2.5 w-full ps-10 text-base font-medium rounded-lg border border-dark bg-dark-800"
                        placeholder="Search"
                        onChange={() => { }}
                        required
                    />
                </div>

                {/* Filter Button */}
                <div className="flex gap-2 items-center px-4 py-2.5 border border-dark bg-dark-700 rounded-lg">
                    <FilterIon />
                    <button
                        className=""
                        onClick={() => { }}
                    >
                        Filter
                    </button>
                </div>
            </div>

            <div className="block">
                <div className="table-main relative border border-dark overflow-auto shadow-md rounded-lg max-h-[calc(100vh-335px)]">
                    <table className="w-full text-sm text-left">
                        <thead className="text-xs top-0 sticky font-semibold text-neutral-grey border-b border-dark bg-dark-700">
                            <tr>
                                <th scope="col" className="px-3 py-4 border-r border-dark sticky left-0">
                                    <div className="flex items-center whitespace-nowrap" onClick={() => requestSort('reportNumber')}>
                                        <span>Job request ID #</span>
                                        {sortConfig?.key === 'reportNumber' && sortConfig.direction === 'asc' ? (
                                            <ArrowUpIcon className="ms-1.5" />
                                        ) : (
                                            <ArrowDownIcon className="ms-1.5" />
                                        )}

                                    </div>
                                </th>
                                <th scope="col" className="px-3 py-4">
                                    <div className="flex items-center whitespace-nowrap" onClick={() => requestSort('operatorName')}>
                                        <span>Quote ID</span>
                                        {sortConfig?.key === 'operatorName' && sortConfig.direction === 'asc' ? (
                                            <UpArrow className="ms-1.5" />
                                        ) : (
                                            <DownArrow className="ms-1.5" />
                                        )}
                                    </div>
                                </th>
                                <th scope="col" className="px-3 py-4">
                                    <div className="flex items-center whitespace-nowrap" onClick={() => requestSort('operatorName')}>
                                        <span>Job type CD</span>
                                        {sortConfig?.key === 'operatorName' && sortConfig.direction === 'asc' ? (
                                            <UpArrow className="ms-1.5" />
                                        ) : (
                                            <DownArrow className="ms-1.5" />
                                        )}
                                    </div>
                                </th>
                                <th scope="col" className="px-3 py-4">
                                    <div className="flex items-center whitespace-nowrap" onClick={() => requestSort('operatorName')}>
                                        <span>Job request status</span>
                                        {sortConfig?.key === 'operatorName' && sortConfig.direction === 'asc' ? (
                                            <UpArrow className="ms-1.5" />
                                        ) : (
                                            <DownArrow className="ms-1.5" />
                                        )}
                                    </div>
                                </th>
                                <th scope="col" className="px-3 py-4">
                                    <div className="flex items-center whitespace-nowrap" onClick={() => requestSort('operatorName')}>
                                        <span>Job start date</span>
                                        {sortConfig?.key === 'operatorName' && sortConfig.direction === 'asc' ? (
                                            <UpArrow className="ms-1.5" />
                                        ) : (
                                            <DownArrow className="ms-1.5" />
                                        )}
                                    </div>
                                </th>
                                <th scope="col" className="px-3 py-4">
                                    <div className="flex items-center whitespace-nowrap" onClick={() => requestSort('operatorName')}>
                                        <span>Job end date</span>
                                        {sortConfig?.key === 'operatorName' && sortConfig.direction === 'asc' ? (
                                            <UpArrow className="ms-1.5" />
                                        ) : (
                                            <DownArrow className="ms-1.5" />
                                        )}
                                    </div>
                                </th>
                                <th scope="col" className="px-3 py-4">
                                    <div className="flex items-center whitespace-nowrap" onClick={() => requestSort('operatorName')}>
                                        <span>Operator name</span>
                                        {sortConfig?.key === 'operatorName' && sortConfig.direction === 'asc' ? (
                                            <UpArrow className="ms-1.5" />
                                        ) : (
                                            <DownArrow className="ms-1.5" />
                                        )}
                                    </div>
                                </th>
                                <th scope="col" className="px-3 py-4">
                                    <div className="flex items-center whitespace-nowrap" onClick={() => requestSort('wellName')}>
                                        <span>Well name</span>
                                        {sortConfig?.key === 'wellName' && sortConfig.direction === 'asc' ? (
                                            <UpArrow className="ms-1.5" />
                                        ) : (
                                            <DownArrow className="ms-1.5" />
                                        )}
                                    </div>
                                </th>
                                <th scope="col" className="px-3 py-4">
                                    <div className="flex items-center cursor-pointer whitespace-nowrap" onClick={() => requestSort('reported')}>
                                        <span>Assigned engineer</span>
                                        {sortConfig?.key === 'reported' && sortConfig.direction === 'asc' ? (
                                            <UpArrow className="ms-1.5" />
                                        ) : (
                                            <DownArrow className="ms-1.5" />
                                        )}
                                    </div>
                                </th>
                                <th scope="col" className="px-3 py-4">
                                    <div className="flex items-center whitespace-nowrap" onClick={() => requestSort('runDays')}>
                                        <span>Short run</span>
                                        {sortConfig?.key === 'runDays' && sortConfig.direction === 'asc' ? (
                                            <UpArrow className="ms-1.5" />
                                        ) : (
                                            <DownArrow className="ms-1.5" />
                                        )}
                                    </div>
                                </th>
                                <th scope="col" className="px-3 py-4">
                                    <div className="flex items-center whitespace-nowrap" onClick={() => requestSort('failedRunDays')}>
                                        <span>Failed/pull rundays</span>
                                        {sortConfig?.key === 'failedRunDays' && sortConfig.direction === 'asc' ? (
                                            <UpArrow className="ms-1.5" />
                                        ) : (
                                            <DownArrow className="ms-1.5" />
                                        )}
                                    </div>
                                </th>
                                <th scope="col" className="px-3 py-4">
                                    <div className="flex items-center whitespace-nowrap" onClick={() => requestSort('installStarted')}>
                                        <span>Install started</span>
                                        {sortConfig?.key === 'installStarted' && sortConfig.direction === 'asc' ? (
                                            <UpArrow className="ms-1.5" />
                                        ) : (
                                            <DownArrow className="ms-1.5" />
                                        )}
                                    </div>
                                </th>
                                <th scope="col" className="px-3 py-4">
                                    <div className="flex items-center whitespace-nowrap" onClick={() => requestSort('pulledStarted')}>
                                        <span>Pulled started</span>
                                        {sortConfig?.key === 'pulledStarted' && sortConfig.direction === 'asc' ? (
                                            <UpArrow className="ms-1.5" />
                                        ) : (
                                            <DownArrow className="ms-1.5" />
                                        )}
                                    </div>
                                </th>
                                <th scope="col" className="px-3 py-4">
                                    <div className="flex items-center cursor-pointer whitespace-nowrap" onClick={() => requestSort('pullReason')}>
                                        <span>Reason for pull</span>
                                        {sortConfig?.key === 'pullReason' && sortConfig.direction === 'asc' ? (
                                            <UpArrow className="ms-1.5" />
                                        ) : (
                                            <DownArrow className="ms-1.5" />
                                        )}
                                    </div>
                                </th>
                                <th scope="col" className="px-3 py-4">
                                    <div className="flex items-center whitespace-nowrap" onClick={() => requestSort('reported')}>
                                        <span>Parts of inbound</span>
                                        {sortConfig?.key === 'reported' && sortConfig.direction === 'asc' ? (
                                            <UpArrow className="ms-1.5" />
                                        ) : (
                                            <DownArrow className="ms-1.5" />
                                        )}
                                    </div>
                                </th>
                                <th scope="col" className="px-3 py-4">
                                    <div className="flex items-center whitespace-nowrap" onClick={() => requestSort('reported')}>
                                        <span>DIFA needed</span>
                                        {sortConfig?.key === 'reported' && sortConfig.direction === 'asc' ? (
                                            <UpArrow className="ms-1.5" />
                                        ) : (
                                            <DownArrow className="ms-1.5" />
                                        )}
                                    </div>
                                </th>
                                <th scope="col" className="px-3 py-4">
                                    <div className="flex items-center whitespace-nowrap" onClick={() => requestSort('difaScheduled')}>
                                        <span>DIFA scheduled</span>
                                        {sortConfig?.key === 'difaScheduled' && sortConfig.direction === 'asc' ? (
                                            <UpArrow className="ms-1.5" />
                                        ) : (
                                            <DownArrow className="ms-1.5" />
                                        )}
                                    </div>
                                </th>
                                <th scope="col" className="px-3 py-4">
                                    <div className="flex items-center whitespace-nowrap" onClick={() => requestSort('difaScheduled')}>
                                        <span>Inbound test</span>
                                        {sortConfig?.key === 'difaScheduled' && sortConfig.direction === 'asc' ? (
                                            <UpArrow className="ms-1.5" />
                                        ) : (
                                            <DownArrow className="ms-1.5" />
                                        )}
                                    </div>
                                </th>
                            </tr>
                        </thead>
                            <tbody className="font-normal text-sm text-neutral-grey">
                            {loading ? <Loader /> : (
                                difaSchedular && difaSchedular?.schedulerList?.length ? difaSchedular?.schedulerList?.map((item: DifaSchedularResponse, index: number) =>
                                    <tr className="border-b border-dark" key={index}>
                                        <td className="px-3 py-4 text-Indigo hover:underline cursor-pointer border-r border-dark sticky left-0"
                                            onClick={() => handleScheduler(item?.jobRequestID, item?.quoteID)}>
                                            {item?.jobRequestID}
                                        </td>
                                        <td className="px-3 py-4">{item?.quoteID}</td>
                                        <td className="px-3 py-4">{item?.jobTypeCd ?? "-"}</td>
                                        <td className="px-3 py-4">{item?.jobRequestStatus ?? "-"}</td>
                                        <td className="px-3 py-4">{formatDateInNumbers(item?.jobStartDate)}</td>
                                        <td className="px-3 py-4">{formatDateInNumbers(item?.jobEndDate)}</td>
                                        <td className="px-3 py-4 whitespace-nowrap">{item?.operatorName ?? "-"}</td>
                                        <td className="px-3 py-4 whitespace-nowrap">{item?.wellName ?? "-"}</td>
                                        <td className="px-3 py-4 whitespace-nowrap">{item?.assignedEngineer ?? "-"}</td>
                                        <td className="px-3 py-4">{item?.shortrun ?? "-"}</td>
                                        <td className="px-3 py-4">{item?.failedDate && item?.pullRunDays ? (item?.failedDate + "/" + item?.pullRunDays) : "-"}</td>
                                        <td className="px-3 py-4">{item?.installStarted ? formatDateInNumbers(item?.installStarted) : "-"}</td>
                                        <td className="px-3 py-4">{item?.pullStarted ? formatDateInNumbers(item?.pullStarted) : "-"}</td>
                                        <td className="px-3 py-4">{item?.reasonForPull ?? "-"}</td>
                                        <td className="px-3 py-4">{item?.partsAtInbound ?? "-"}</td>
                                        <td className="px-3 py-4">{item?.isDifaNeeded ? "Yes" : "-"}</td>
                                        <td className="px-3 py-4">{item?.difaInspectionDate ? formatDateInNumbers(item?.difaInspectionDate) : "-"}</td>
                                        <td className="px-3 py-4 text-Indigo hover:underline cursor-pointer">#####</td>
                                    </tr>
                                ) : (
                                    <tr><td colSpan={18} className="text-center py-4">No data found</td></tr>
                                ))}
                            </tbody>
                    </table>
                </div>
            </div>

            {showDifaSchedularInspection &&
                <DIFAShedularInspection
                    open={showDifaSchedularInspection}
                    onCloseModal={() => setShowDifaSchedularInspection(false)}
                />
            }
        </div>
    )
}

export default DIFASchedular
