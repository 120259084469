import React from "react";
import { HeaderProps } from "../../DifaPages/components/HeaderProps";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as ArrowRightIcon } from "../../../images/chevron-right.svg";
import "../../DifaPages/components/Header.scss";
import RouteConstant from "../../../constants/routes";
import { resetPacketBuilderState } from "../../difaPacketBuilder/difaPacketBuilderSlice";
import { useDispatch } from "react-redux";

const Header: React.FC<HeaderProps> = ({
  headerName,
  customerName,
  wellName,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { difaId } = location.state || {};

  const difaDetails = () => {
    navigate("/difa-details", {
      state: { difaId: difaId, customerName: customerName },
    });
  };

  const dashboard = () => {
    dispatch(resetPacketBuilderState());        
    navigate("/");
  };

  const redirectToWellName = () => {
    wellName && navigate(
      RouteConstant.wellName.replace(
        ":wellName",
        encodeURIComponent(wellName)),
      {
        state: { difaId: difaId, customerName: customerName },
      });
  };

  return (
    <header className="main-header">
      <div className="header-content">
        <div className="breadcrumb-container">
          <div className="breadcrumb-item" onClick={dashboard}>
            Dashboard
          </div>
          <div className="breadcrumb-separator">
            <ArrowRightIcon />
          </div>
          <div className="breadcrumb-item" onClick={difaDetails}>
            DIFA details
          </div>
          <div className="breadcrumb-separator">
            <ArrowRightIcon />
          </div>
          <div className="breadcrumb-item active">{headerName}</div>
        </div>
        <div className="header-details">
          <div className="header-details-content">
            <div className="header-title">{headerName}</div>
            <div className="header-meta">
              <div className="meta-company">{customerName} |</div>
              <div className="meta-project"  >
                {wellName}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="divider"></div>
    </header>
  );
};

export default Header;
