// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.custom-checkbox {
  position: relative;
  display: flex;
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.custom-checkbox .checkmark {
  width: 20px;
  height: 20px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--Dark-mode-300, #5d6675);
  background: var(--Dark-mode-800, #001023);
}
.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.custom-checkbox input:disabled + span {
  cursor: auto;
}
.custom-checkbox input:checked + .checkmark {
  border: 1px solid var(--Primary-Indigo-700, #005f7c);
  background: var(--Primary-Indigo-600, #00789e);
}
.custom-checkbox input:checked + .checkmark::before {
  content: "✔";
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/features/common/checkbox/DifaCheckbox.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;EACI,kBAAA;EACA,aAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;AAEJ;AAAI;EACI,WAAA;EACA,YAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,+CAAA;EACA,yCAAA;AAER;AACI;EACI,kBAAA;EACA,UAAA;EACA,eAAA;EACA,SAAA;EACA,QAAA;AACR;AACQ;EACI,YAAA;AACZ;AAEQ;EACI,oDAAA;EACA,8CAAA;AAAZ;AAEY;EACI,YAAA;EACA,YAAA;AAAhB","sourcesContent":[".custom-checkbox {\n    position: relative;\n    display: flex;\n    width: 20px;\n    height: 20px;\n    align-items: center;\n    justify-content: center;\n    cursor: pointer;\n\n    .checkmark {\n        width: 20px;\n        height: 20px;\n        border-radius: 4px;\n        display: flex;\n        align-items: center;\n        justify-content: center;\n        border: 1px solid var(--Dark-mode-300, #5d6675);\n        background: var(--Dark-mode-800, #001023);\n    }\n\n    input {\n        position: absolute;\n        opacity: 0;\n        cursor: pointer;\n        height: 0;\n        width: 0;\n\n        &:disabled+span {\n            cursor: auto;\n        }\n\n        &:checked+.checkmark {\n            border: 1px solid var(--Primary-Indigo-700, #005f7c);\n            background: var(--Primary-Indigo-600, #00789e);\n\n            &::before {\n                content: \"✔\";\n                color: white;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
