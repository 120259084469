import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Header from "./components/Header";
import DifaTimeline from "./components/DifaTimeline";
import DifaDetailsRight from "./components/DifaInspectionDetails";
import { useDispatch, useSelector } from "react-redux";
import { fetchDifaDetails, fetchInboundTestReport, fetchReportStatus } from "./DifaDetailsSlice";
import Loader from "../common/loader/Loader";
import { getDismantleRequestByDifa } from "../dismentleRequest/DismantleRequestSlice";
import "./DifaDetails.scss";

const DifaDetails: React.FC = () => {
  const dispatch = useDispatch();
  const { difaDetails, loading } = useSelector(
    (state: any) => state.difaDetails
  );
  const location = useLocation();

  const { difaId, customerName } = location.state || {};

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(fetchDifaDetails({ difaId }));
    dispatch(fetchInboundTestReport({difaId}));
  }, [difaId, dispatch]);

  useEffect(() => {
    if (difaDetails?.wellApiNumber) {
      dispatch(
        getDismantleRequestByDifa({
          dismantleRequestId: difaId,
          wellApiNumber: difaDetails?.wellApiNumber,
        })
      );
      dispatch(fetchReportStatus({wellApiNumber: difaDetails?.wellApiNumber, difaId}));
    }
  }, [dispatch, difaId, difaDetails?.wellApiNumber]);
  
  return (
      <div className="difa-details">
        {loading && <Loader isOverlay />}
        <Header headerName="DIFA details" customerName={customerName} />
        <div className="difa-detail-content">
          <div className="difa-container flex-col md:flex-row">
            <div className="left-side-container w-full md:w-1/3">
              <DifaTimeline difaId={difaId}/>
            </div>
            <div className="rigth-side-container w-full md:w-2/3">
              <DifaDetailsRight />
            </div>
          </div>
        </div>
      </div> 
  );
};

export default DifaDetails;
