import React, { ChangeEvent, useState } from 'react'
import DifaSwitch from '../../../common/switch/DifaSwitch'
import UploadDocuments from '../../../common/uploadFiles/UploadDocuments';
import { useDispatch, useSelector } from 'react-redux';
import { setSizingSummary } from '../../difaPacketBuilderSlice';
import Helpers from '../../../../utilities/Helpers';


const SizingSchematic: React.FC<{wellApiNumber: string, difaId: string, unsavedCallback?: (unsaved: boolean) => void, source?:string}> = ({wellApiNumber, difaId, unsavedCallback, source = 'packet'}) => {
    const { sizingSummary } = useSelector(
        (state: any) => state.difaPacketBuilder
    );
    const dispatch = useDispatch();
    const [expanded, setExpanded] = useState(sizingSummary?.isSelected || false);
    const [uploadedFiles, setUploadedFiles] = useState<File[]>(sizingSummary?.sizingFile ? [sizingSummary?.sizingFile] : []);

    async function handleFilesChange(files: File[]): Promise<void> {
        setUploadedFiles(files);
        
        const sizingFile : any = files?.length ? {
            link: URL.createObjectURL(files[0]),
            blob : await Helpers.convertFiletoBase64(files[0]) ,
            name: files[0].name
        } : null;
        dispatch(setSizingSummary({...sizingSummary, sizingFile}))
        unsavedCallback && unsavedCallback(true)
    }

    const onExpand = (e: ChangeEvent<HTMLInputElement>) => {
        const checked = e.target.checked;
        setExpanded(checked);
        dispatch(setSizingSummary({...sizingSummary, isSelected: checked}))
    };
    const isReport = source.includes("report");
    return (
        <div className="difa-widget-containers">
            <div className="header-main">
                <div className="flex flex-row justify-between">
                    <div>
                        <h3>ESP sizing report</h3>
                        <h5>Provides an understanding of the the ESP design and expected performance.</h5>
                    </div>
                    <div className="flex flex-row items-center gap-3">
                        <DifaSwitch checked={expanded} onChange={onExpand} />
                        <h4 className="whitespace-nowrap">Include in {isReport ? 'report' : 'packet'}</h4>
                    </div>
                </div>
            </div>
            <div className="block w-full border-t border-dark p-6">
                <UploadDocuments
                    fileType="pdf"
                    maxFileSize={100} // Max size: 100 MB
                    onFilesChange={handleFilesChange}
                    uploadedFiles= {uploadedFiles}
                />
            </div>
        </div>
    )
}

export default SizingSchematic
