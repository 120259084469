import React from "react";
import "./Header.scss";
import { HeaderProps } from "./HeaderProps";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ArrowRightIcon } from "../../../images/chevron-right.svg";
import { useDispatch, useSelector } from "react-redux";
import { resetPacketBuilderState } from "../../difaPacketBuilder/difaPacketBuilderSlice";

const Header: React.FC<HeaderProps> = ({ headerName, customerName }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const dashboard = () => {
    dispatch(resetPacketBuilderState());
        
    navigate("/");
  };

  const { difaDetails } = useSelector((state: any) => state.difaDetails);

 

  return (
    <header className="main-header">
      <div className="header-content">
        <div className="breadcrumb-container">
          <div className="breadcrumb-item" onClick={dashboard}>
            Dashboard
          </div>
          <div className="breadcrumb-separator">
            <ArrowRightIcon />
          </div>
          <div className="breadcrumb-item active">{headerName}</div>
        </div>
        <div className="header-details">
          <div className="header-details-content">
            <div className="header-title">{headerName}</div>
            <div className="header-meta">
              {customerName && <div className="meta-company">{customerName} |</div>}
              <div className="meta-project" >
                {difaDetails.wellName}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="divider"></div>
    </header>
  );
};

export default Header;
