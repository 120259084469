import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { DismantleRequestProps, NameInterface } from "../../../models/DismantleRequest";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as CheckMark } from "../../../../images/check.svg";
import "./DismantleRequestDetails.scss";

const DismantleRequestDetails = () => {
  const [activeTab, setActiveTab] = useState<string>("PUMP MSC");
  const [tabContent, setTabContent] = useState<any>([]);

  const location = useLocation();
  const navigate = useNavigate();
  const { difaId, customerName } = location.state || {};

  const { difaDetails } = useSelector((state: any) => state.difaDetails);

  const getDifaDismantleRequest = useSelector(
    (state: any) => state.dismantleRequest?.getDismantleRequest[0]
  );
  const dismantleRequest = getDifaDismantleRequest?.dismantleRequests ?? [];

  useEffect(() => {
    if (dismantleRequest?.length > 0) {
      setTabContent(
        dismantleRequest.filter(
          (equipment: DismantleRequestProps) => equipment.partType === activeTab
        )
      );
    }
  }, [activeTab, getDifaDismantleRequest]);

  const name: NameInterface = {
    "PUMP MSC": "Pumps",
    "GAS HANDLING": "Gas handling",
    "PROTACTOR": "Protector",
    "MOTOR DH": "Motor",
    "SENSOR-DH": "Sensor",
    "CABLE": "Cable & MLE",
  };

  const redirectToDismantleRequest = () => {
    navigate("dismantle-request", {
      state: {
        difaId: difaId,
        customerName: customerName,
        wellName: difaDetails?.wellName,
        wellApiNumber: difaDetails?.wellApiNumber,
      },
    });
  };

  return (
    <div className="difa-detail-card-tab-content">
      <div className="difa-card-tabs flex-col md:flex-row">
        {Object.keys(name)?.map((key) => (
          <div
            key={key}
            className={`tab-item ${activeTab === key ? "active" : ""}`}
            onClick={() => setActiveTab(key)}
          >
            <div
              className={` ${activeTab === key ? "lable-active" : "tab-lable"}`}
            >
              {name[key]}
            </div>
          </div>
        ))}
      </div>

      <div className="difa-content-table overflow-hidden">
        <div className="table-header">
          <div className="table-heading">{name[activeTab]}</div>
        </div>
        <div className="table-main">
          <div className="table">
            <div
              className="table-row sticky top-0"
              style={{ background: "#253240" }}
            >
              <div className="th sno"> </div>
              <div className="th dismantle-req">Dismantle requested</div>
              <div className="th serial">Serial #</div>
              <div className="th eq-type">Equipment type</div>
              <div className="th i-results">Inbound results</div>
              <div className="th i-comments">Comments</div>
            </div>

            {tabContent?.length === 0 ? (
              <div className="no-data">No Data Found</div>
            ) : (
              tabContent?.map((pump: any, index: number) => {
                return (
                  <div className="table-row" key={index}>
                    <div className="table-column sno">{index + 1}</div>
                    <div className="table-column dismantle-req">
                      {pump.dismantleRequested ? <CheckMark /> : "-"}
                    </div>
                    <div className="table-column serial">
                      {pump?.serialNumber}
                    </div>
                    <div className="table-column eq-type">
                      {pump?.equipementType}
                    </div>
                    <div
                      className={`table-column ${pump?.inboundResults === "Fail" ? "failed" : "pass"
                        } i-results`}
                    >
                      {pump?.inboundResults === "Fail"
                        ? "Failed"
                        : pump?.inboundResults}
                    </div>
                    <div className="table-column i-comments">
                      {pump?.comments !== "" ? pump?.comments : "-"}
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
      </div>

      <div className="card-tab-footer">
        <button
          className="footer-submit-button"
          onClick={redirectToDismantleRequest}
        >
          Edit dismantle request
        </button>
      </div>
    </div>
  );
};

export default DismantleRequestDetails;
