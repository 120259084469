/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */

import closeIcon from "./../../../images/close-icon.svg";
import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import viewPublishedDIFA from "../../../images/Publish DIFA packet.svg";
import backToPIPage from "../../../images/Back to Pre-inspection.svg";
import backToDashboardPage from "../../../images/back-to-dashboard.svg";
import viewPublishedReport from "../../../images/view-published-report.svg";

const PublishedDifaDialog: React.FC<{ show: boolean, mode: string, close?: () => void, onRedirect: () => void, onBackToPI: () => void }> = ({
    show = false,
    mode = "",
    close = () => { close() },
    onRedirect = () => { onRedirect() },
    onBackToPI = () => { onBackToPI() }
}) => {
    const isReportMode = mode === "report";
    return (
        <Dialog open={show} onClose={() => close()} className="relative z-50">
            <DialogBackdrop
                transition
                className="fixed inset-0 backdrop-blur-sm transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />
            <DialogBackdrop
                transition
                className="fixed inset-0 backdrop-blur-sm transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className="fixed inset-0 z-10 w-screen">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0 sm:min-w-full">
                    <DialogPanel
                        transition
                        className="flex dialog-max-height relative transform overflow-hidden rounded-lg difa-background-with-border text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-xl md:min-w-120 md:w-120  data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                    >
                        <div className="difa-widget-containers w-full modal-bg">
                            <div className="header-main border-bottom">
                                <div className="flex flex-row justify-between">
                                    <div>
                                        <span className="publish-modal-heading">Publish {isReportMode ? 'report' : 'packet'}</span>
                                    </div>
                                    <a href="javascript:void(0)" onClick={() => close()}>
                                        <img src={closeIcon} alt="close" />
                                    </a>
                                </div>
                            </div>
                            <div className="w-full flex flex-col overflow-y-auto pt-8 pb-8 table-main border-bottom">
                                <div className="flex flex-col h-24 items-center justify-center gap-6">
                                    <span className="modal-heading" >Your DIFA {isReportMode ? 'report' : 'packet'} has been published!</span>
                                    <span className="modal-sub-header pb-4">Here is what you can do next:</span>
                                </div>
                                <div className="flex justify-center w-full">
                                    <div className="publish-cards-container text-center">
                                        {isReportMode ?
                                            <>
                                                <div className="">
                                                    <img src={viewPublishedReport} alt="view published report" onClick={onRedirect}></img>
                                                </div>
                                                <div className="">
                                                    <img src={backToDashboardPage} alt="back to dashboard" onClick={onBackToPI}></img>
                                                </div>
                                            </> :
                                            <>
                                                <div className="">
                                                    <img src={viewPublishedDIFA} alt="view published DIFA" onClick={onRedirect}></img>
                                                </div>
                                                <div className="">
                                                    <img src={backToPIPage} alt="back to pre-inspection page" onClick={onBackToPI}></img>
                                                </div>
                                            </>}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>

    );
}

export default PublishedDifaDialog;