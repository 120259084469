import React, { useRef, useEffect, useState } from 'react';
import * as pdfjsLib from 'pdfjs-dist';
import 'pdfjs-dist/web/pdf_viewer.css';
import * as pdfjs from 'pdfjs-dist';
import { toast } from 'react-toastify';
import Loader from '../loader/Loader';

pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf.worker.mjs`;

const PDFViewer: React.FC<{ file: string }> = ({ file }) => {
  const canvasRef = useRef() as any;
  const [loading, setLoading] = useState(false);
  const [pdf, setPdf] = useState(null as any);
  const [currentPage, setCurrentPage] = useState(1);
  const [numPages, setNumPages] = useState(null as any);
  const renderTaskRef = useRef(null as any);

  useEffect(() => {

    const loadPdf = async () => {
      try {
        const loadedPdf = await pdfjsLib.getDocument(file).promise;
        setPdf(loadedPdf);
        setNumPages(loadedPdf.numPages);
        setLoading(false);
      } catch (error) {
        setPdf(null);
        setNumPages(null);
        console.error('Error loading PDF:', error);
        toast.error('Error loading PDF');
        setLoading(false);
      }
    };
    setLoading(true);
    loadPdf();
  }, [file]);

  useEffect(() => {
    const renderPage = async () => {
      if (!pdf) return;

      if (renderTaskRef.current) {
        renderTaskRef.current.cancel();
      }

      const page = await pdf.getPage(currentPage);
      const viewport = page.getViewport({ scale: 1.5 });
      const canvas = canvasRef.current as any;
      if (!canvas) return;
      const context = canvas.getContext('2d');

      canvas.height = viewport.height;
      canvas.width = viewport.width;

      const renderContext = {
        canvasContext: context,
        viewport: viewport,
      };

      renderTaskRef.current = page.render(renderContext);
      try {
        await renderTaskRef.current.promise;
      } catch (error: any) {
        if (error.name === 'RenderingCancelledException') {
          console.log('Rendering cancelled');
        } else {
          console.error('Error rendering page:', error);
        }
      }
    };

    renderPage();
  }, [pdf, currentPage]);

  const goToPrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const goToNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, numPages));
  };

  return (
    <div>
      {loading && <Loader isOverlay />}
      <div className='text-center'>
        {pdf && (
          <>
            <button
              onClick={goToPrevPage}
              className={`btnNextPrev m-[5px] ${currentPage === 1 ? 'cursor-not-allowed' : 'cursor-pointer'
                }`}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <button
              onClick={goToNextPage}
              className={`btnNextPrev ${currentPage === numPages
                ? 'cursor-not-allowed'
                : 'cursor-pointer'
                }`}
              disabled={currentPage === numPages}
            >
              Next
            </button>
          </>
        )}
      </div>
      <div className='pdf-viewer-container'>
        {file ? (
          <canvas ref={canvasRef} />
        ) : (
          <h3 className='file-error-msg'>No file found</h3>
        )}
      </div>
    </div>
  );
};

export default PDFViewer;
