import { removeGallery } from "../../difaPacketBuilder/difaPacketBuilderSlice";
import { toaster } from "./ToasterService";
import { store } from "../../../store";

export const galleryUtility = {
    handleRemove: async (galleryId: string, source: string, refreshGalleryCB = () => { }) => {
        const response = await store.dispatch(removeGallery({ galleryId, source }));
        if (response?.meta?.requestStatus === 'fulfilled') {
            toaster.success('Gallery removed', "To see your images select the gallery.");
            refreshGalleryCB();
        }
        else if (response?.error) {
            toaster.error(typeof response?.payload === 'object' ? response?.payload.message : response?.payload);
        }
    }
}