import React, { ChangeEvent, useEffect, useState } from 'react'
import DifaSwitch from '../../../common/switch/DifaSwitch'
import AlarmEventsChart from './installWellOperationCharts/AlarmEventsChart';
import AlarmEventsList from './installWellOperationCharts/AlarmEventsList';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../common/loader/Loader';
import { AlarmHistoryParams } from '../../../models/PreInpectionSummary';
import { fetchFrequentAlarmReport, getAlarmHistoryData } from '../../../preInspectionSummary/PreInspectionSummarySlice';
import { setWellInstallation } from '../../difaPacketBuilderSlice';

const AlarmEvents: React.FC<{ wellApiNumber: string, source: string }> = ({ wellApiNumber, source }) => {
const { wellInstallation } = useSelector(
    (state: any) => state.difaPacketBuilder
);
    const [expanded, setExpanded] = useState(wellInstallation?.isAlarmsSelected || false);
    const [showAlarmData, setShowAlarmData] = useState(false);
    const dispatch = useDispatch();
    const isPreview = source === "preview-packet" || source === "preview-report";

    const { alarmHistoryEvent, frequentHistoryAlarm, loading } = useSelector(
        (state: any) => state?.preInspectionSummary
    );
    const onExpand = (e: ChangeEvent<HTMLInputElement>) => {
        const checked = e.target.checked;
        setExpanded(checked);
        dispatch(setWellInstallation({...wellInstallation, isAlarmsSelected: checked}))        
    
        if (checked && isEmptyObject(alarmHistoryEvent)) {
            const alarmHistoryParams: AlarmHistoryParams = {
                wellApiNumber: wellApiNumber,
                eventType: "All",
                sortOrder: "asc",
            };
            
            dispatch(getAlarmHistoryData(alarmHistoryParams));
            frequentHistoryAlarm?.length === 0 && dispatch(fetchFrequentAlarmReport({ wellApiNumber, eventType: "All" }));
        }
    };

    const handleAlarmList = () => {
        setShowAlarmData(true);
    }

    const isEmptyObject = (obj: any) => {
        return obj && Object.keys(obj)?.length === 0 && obj.constructor === Object;
    };
    const isReport = source?.includes("report");
  
    return (
        <div className="difa-widget-containers">
            <div className="header-main">
                <div className="flex flex-row justify-between flex-wrap">
                    <div>
                        <h3>Alarms</h3>
                        <h5>Select the alarm that lead to the ESP shutting down</h5>
                    </div>
                    {!isPreview && 
                    <div className="flex flex-row items-center gap-3">
                        <DifaSwitch checked={expanded} onChange={onExpand} />
                        <h4 className="whitespace-nowrap">Include in {isReport ? 'report' : 'packet'}</h4>
                        <button className="btn-secondary font-bold text-sm" onClick={handleAlarmList}>View alarm event list</button>
                    </div>
                    }
                </div>
            </div>
            {loading && <Loader isOverlay />}
            {!isEmptyObject(alarmHistoryEvent) && (
                <div className="flex flex-col gap-5 py-5 px-6 border-t border-dark">
                    <div className="flex flex-col md:flex-row gap-4 justify-between">
                        <div className="flex flex-col gap-2 w-full md:w-1/2 p-4 bg-dark-700 justify-center rounded-lg border border-dark">
                            <label className="font-medium text-2xl">{alarmHistoryEvent?.count}</label>
                            <label className="font-normal text-base">Total alarms</label>
                        </div>
                        <div className="flex flex-col gap-2 w-full md:w-1/2 p-4 bg-dark-700 justify-center rounded-lg border border-dark">
                            <label className="font-medium text-2xl">High winding temp</label>
                            <label className="font-normal text-base">Most frequent alarm</label>
                        </div>
                    </div>
                    {!isPreview && <div className="font-semibold text-lg">Top 10 alarm event types</div>}
                    {frequentHistoryAlarm?.length || alarmHistoryEvent?.alarmEventHistoryDto?.length ?
                        <AlarmEventsChart chartData={frequentHistoryAlarm.slice(0, 10) || alarmHistoryEvent?.alarmEventHistoryDto.slice(0, 10)} id="alarmHistoryEventChart" />
                        : <div className="text-lg text-center py-5">No data found</div>}
                </div>
            )}
            {showAlarmData && alarmHistoryEvent?.alarmEventHistoryDto && (
                <AlarmEventsList open={showAlarmData} onCloseModal={() => setShowAlarmData(false)} AlarmEventData={alarmHistoryEvent?.alarmEventHistoryDto} />
            )}
        </div>
    )
}

export default AlarmEvents
