import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAlarmHistory,
  getDifaRundaysDetails,
  getInstallReport,
  getStartupReport,
  getServiceReport,
  getPullReport,
  getFreuentAlarmReport,
  getOptimizationTickets,
  getPacketStatus,
  GetPreInspectionSummary,
  PostSummaryFeedback
} from "./PreInspectionSummaryServices";
import { AlarmHistoryParams, PreinspectionsummaryProps } from "../models/PreInpectionSummary";


export const fetchDifaPacketStatus: any = createAsyncThunk(
    "packet-builder/getPacketStatus",
    async ({ wellApiNumber, difaId }: { wellApiNumber: string, difaId: string }) => {
        return await getPacketStatus(wellApiNumber, difaId);
    }
);

export const getAlarmHistoryData: any = createAsyncThunk(
  "dashboard/preInspectionSummary/getAlarmHistory",
  async (alarmHistoryParams: AlarmHistoryParams) => {
    const response = await getAlarmHistory(alarmHistoryParams);
    return response;
  }
);

export const fetchFrequentAlarmReport: any = createAsyncThunk('dasboard/preInspectionSummary/fetchFrequentAlarm', async ({ wellApiNumber, eventType }: { wellApiNumber: string, eventType: string }) => {
  return await getFreuentAlarmReport(wellApiNumber, eventType);
});

export const fetchRundaysDetails: any = createAsyncThunk(
  "dasboard/preInspectionSummarySlice",
  async ({ wellApiNumber }: { wellApiNumber: string }) => {
    return await getDifaRundaysDetails(wellApiNumber);
  }
);

export const fetchOptimizationTickets: any = createAsyncThunk(
  "dasboard/preInspectionSummary/getOptimizationTickets",
  async ({ wellName }: { wellName: string }) => {
    return await getOptimizationTickets(wellName);
  }
);

export const fetchInstallReport: any = createAsyncThunk(
  "dasboard/getInstallReport",
  async ({ wellApiNumber }: { wellApiNumber: string }) => {
    return await getInstallReport(wellApiNumber);
  }
);

export const fetchStartupReport: any = createAsyncThunk(
  "dasboard/getStartupReport",
  async ({ wellApiNumber }: { wellApiNumber: string }) => {
    return await getStartupReport(wellApiNumber);
  }
);

export const fetchServiceReport: any = createAsyncThunk(
  "dasboard/getServiceReport",
  async ({ wellApiNumber }: { wellApiNumber: string }) => {
    return await getServiceReport(wellApiNumber);
  }
);

export const fetchPullReport: any = createAsyncThunk(
  "dasboard/getPullReport",
  async ({ wellApiNumber }: { wellApiNumber: string }) => {
    return await getPullReport(wellApiNumber);
  }
);

export const fetchPreInspectionSummary: any = createAsyncThunk(
  "difa/GetPreInspectionSummary",
  async ({ dispatchId }: { dispatchId: string }) => {
    return await GetPreInspectionSummary(dispatchId);
  }
);

export const PostPreInspectionSummaryFeedback: any = createAsyncThunk(
  "difa/PostSummaryFeedback",
  async (preinspectionsummary: PreinspectionsummaryProps) => {
    const response = await PostSummaryFeedback(preinspectionsummary);
    return response;
  }
);

const initialState: any = {
  loading: false,
  alarmHistoryEvent: {},
  frequentHistoryAlarm: [],
  optimizationTickets: [],
  difaRundaysDetails: {},
  installreport: {},
  startupReport: {},
  serviceReport: {},
  pullReport: {},
  message: null,
  difaPacketStatus: null,
  preInspectionSummary: {}
};

const PreInspectionSummarySlice = createSlice({
  name: "preInspectionSummary",
  initialState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder.addCase(getAlarmHistoryData.pending, (state: any) => {
      state.loading = true;
    });

    //fullfilled
    builder.addCase(getAlarmHistoryData.fulfilled, (state: any, action: any) => {
      state.alarmHistoryEvent = action.payload;
      state.loading = false;
    });

    //rejected
    builder.addCase(getAlarmHistoryData.rejected, (state: any, action: any) => {
      state.message = action.payload;
      state.loading = false;
    });

    //pending
    builder.addCase(fetchFrequentAlarmReport.pending, (state: any) => {
      state.loading = true;
    });

    //fullfilled
    builder.addCase(fetchFrequentAlarmReport.fulfilled, (state: any, action: any) => {
      state.frequentHistoryAlarm = action.payload;
      state.loading = false;
    });

    //rejected
    builder.addCase(fetchFrequentAlarmReport.rejected, (state: any, action: any) => {
      state.message = action.payload;
      state.loading = false;
    });

    //pending
    builder.addCase(fetchRundaysDetails.pending, (state: any) => {
      state.loading = true;
    });

    //fullfilled
    builder.addCase(fetchRundaysDetails.fulfilled, (state: any, action: any) => {
      state.difaRundaysDetails = action.payload //data;
      state.loading = false;
    });

    //rejected
    builder.addCase(fetchRundaysDetails.rejected, (state: any, action: any) => {
      state.message = action.payload;
      state.loading = false;
    });

    //pending
    builder.addCase(fetchOptimizationTickets.pending, (state: any) => {
      state.loading = true;
    });

    //fullfilled
    builder.addCase(fetchOptimizationTickets.fulfilled, (state: any, action: any) => {
      state.optimizationTickets = action.payload;
      state.loading = false;
    });

    //rejected
    builder.addCase(fetchOptimizationTickets.rejected, (state: any, action: any) => {
      state.message = action.payload;
      state.loading = false;
    });

    //pending
    builder.addCase(fetchInstallReport.pending, (state: any) => {
      state.loading = true;
    });

    //fullfilled
    builder.addCase(fetchInstallReport.fulfilled, (state: any, action: any) => {
      state.installreport = action.payload;
      state.loading = false;
    });

    //rejected
    builder.addCase(fetchInstallReport.rejected, (state: any, action: any) => {
      state.message = action.payload;
      state.loading = false;
    });

    //pending
    builder.addCase(fetchPullReport.pending, (state: any) => {
      state.loading = true;
    });

    //fullfilled
    builder.addCase(fetchPullReport.fulfilled, (state: any, action: any) => {
      state.pullReport = action.payload;
      state.loading = false;
    });

    //rejected
    builder.addCase(fetchPullReport.rejected, (state: any, action: any) => {
      state.message = action.payload;
      state.loading = false;
    });

    //pending
    builder.addCase(fetchStartupReport.pending, (state: any) => {
      state.loading = true;
    });

    //fullfilled
    builder.addCase(fetchStartupReport.fulfilled, (state: any, action: any) => {
      state.startupReport = action.payload;
      state.loading = false;
    });

    //rejected
    builder.addCase(fetchStartupReport.rejected, (state: any, action: any) => {
      state.message = action.payload;
      state.loading = false;
    });

    //pending
    builder.addCase(fetchServiceReport.pending, (state: any) => {
      state.loading = true;
    });

    //fullfilled
    builder.addCase(fetchServiceReport.fulfilled, (state: any, action: any) => {
      state.serviceReport = action.payload;
      state.loading = false;
    });

    //rejected
    builder.addCase(fetchServiceReport.rejected, (state: any, action: any) => {
      state.message = action.payload;
      state.loading = false;
    });

    builder.addCase(fetchDifaPacketStatus.pending, (state: any) => {
      state.loading = true;
  });

  //fullfilled
  builder.addCase(fetchDifaPacketStatus.fulfilled, (state: any, action: any) => {
      state.difaPacketStatus = action.payload
      state.loading = false;
  });

  //rejected
  builder.addCase(fetchDifaPacketStatus.rejected, (state: any, action: any) => {
      state.message = action.payload;
      state.loading = false;
  });
     //pending
     builder.addCase(fetchPreInspectionSummary.pending, (state: any) => {
      state.loading = true;
    });

    //fullfilled
    builder.addCase(fetchPreInspectionSummary.fulfilled, (state: any, action: any) => {
      state.preInspectionSummary = action.payload;
      state.loading = false;
    });

    //rejected
    builder.addCase(fetchPreInspectionSummary.rejected, (state: any, action: any) => {
      state.message = action.payload;
      state.loading = false;
    });

     //pending
     builder.addCase(PostPreInspectionSummaryFeedback.pending, (state: any) => {
      state.loading = true;
    });

    //fullfilled
    builder.addCase(PostPreInspectionSummaryFeedback.fulfilled, (state: any, action: any) => {
      state.preInspectionSummary = action.payload;
      state.loading = false;
    });

    //rejected
    builder.addCase(PostPreInspectionSummaryFeedback.rejected, (state: any, action: any) => {
      state.message = action.payload;
      state.loading = false;
    });
  },
});

export default PreInspectionSummarySlice.reducer;
