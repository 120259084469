import React, { lazy, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Layout } from "./layout/PageLayout";
import { setFavicon } from "./utilities/setFavicon";
import Dashboard from "./features/dashboard/Dashboard"
import WellDetails from "./features/wellDetail/WellDetails";
import DifaDetails from "./features/DifaPages/DifaDetails";
import PreInspectionSummary from "./features/preInspectionSummary/PreInspectionSummary";
import DismentleRequest from "./features/dismentleRequest/DismantleRequest";
import DifaReportBuilder from "./features/difaReport/DifaReportBuilder";
import DifaReportPreview from "./features/difaReport/previewDifaReport/previewDifaReport";
import DifaPacketBuilder from "./features/difaPacketBuilder/difaPacketBuilder";
import DifaPacketPreview from "./features/previewDifaPacket/previewDifaPacket";
import { ToastContainer } from "react-toastify";
import DocumentationPage from "./features/documentation/DocumentationPage";
import ManagerDashboard from "./features/managerDashboard/ManagerDashboard";
import TokenService from "./services/authentication/token.service";
import { useSelector } from "react-redux";
import Loader from "./features/common/loader/AppLoader";


import Login from './features/login/Login';
import AuthComponent from './features/common/AuthComponent';


const App: React.FC = () => {
  const isRehydrated = useSelector((state: any) => state._persist?.rehydrated);

  const applyTheme = () => {
    setFavicon();
  };

  const userRole = TokenService.getUserRole();

  useEffect(() => {
    applyTheme();
  }, []);


  if (!isRehydrated) {
    return <Loader />;
  };

  return (
    <>
      {!isRehydrated && <Loader />}
      <ToastContainer position="top-right" autoClose={3000} />
      <Router>
        <Routes>
          <Route path='/login' element={<Login />} />
          <Route path="/" element={<AuthComponent component={Layout} />} >
            <Route index element={<AuthComponent component={userRole && userRole === "Manager" ? ManagerDashboard : Dashboard} />} />
            {userRole && userRole === "User" && <Route path="dashboard" element={<AuthComponent component={Dashboard} />} />}
            {userRole && userRole === "Manager" && <Route path="manager-dashboard" element={<AuthComponent component={ManagerDashboard} />} />}
            <Route path="documentation" element={<DocumentationPage />} />
            <Route path="well-details/:wellName" element={<AuthComponent component={WellDetails} />} />
            <Route path="difa-details" element={<AuthComponent component={DifaDetails} />} />
            <Route path="difa-details/pi-summary" element={<AuthComponent component={PreInspectionSummary} />} />
            <Route path="difa-details/dismantle-request" element={<AuthComponent component={DismentleRequest} />} />
            <Route path="difa-details/difa-report-builder" element={<AuthComponent component={DifaReportBuilder} />} />
            <Route path="difa-details/difa-report-preview" element={<AuthComponent component={DifaReportPreview} />} />
            <Route path="difa-details/difa-packet-builder" element={<AuthComponent component={DifaPacketBuilder} />} />
            <Route path="difa-details/preview-difa-packet" element={<AuthComponent component={DifaPacketPreview} />} />
          </Route>
        </Routes>
      </Router>
    </>

  );
};

export default App;
