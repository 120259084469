import React from 'react'
import TokenService from '../../../services/authentication/token.service'

const Header = () => {

    const getUser = TokenService.getAdminName();
    
    return (
        <header className="main-header">
            <div className="header-content">
                <div className="header-details">
                    <div className="header-details-content">
                        <div className="header-title">Welcome back, {getUser}</div>
                        <div className="header-meta">
                            <div className="meta-company">Here are the latest ESP pulls</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="divider"></div>
        </header>
    )
}

export default Header
