import React, { useEffect } from "react";
import "./PullAndInbound.scss";
import PullReason from "./PullReason";
import InboundTesting from "./InboundTesting";
import { getDismantleRequestByDifa } from "../../../dismentleRequest/DismantleRequestSlice";
import { useDispatch, useSelector } from "react-redux";
import EmptyView from "../../../common/emptyPreview/View";

const PullAndInbound: React.FC<{ wellApiNumber: string, difaId: string, source: string }> = ({ wellApiNumber, difaId, source }) => {
  const { pullReasons, isInboundTestingSelected } = useSelector(
    (state: any) => state.difaPacketBuilder
  );
  const dispatch = useDispatch();
  const isPreview = source === "preview-packet" || source === "preview-report";
  
  useEffect(() => {
    if (wellApiNumber) {
      dispatch(
        getDismantleRequestByDifa({
          dismantleRequestId: difaId,
          wellApiNumber: wellApiNumber,
        })
      );
    }
  }, [dispatch, difaId, wellApiNumber]);

  const showEmptyView = !pullReasons?.isPullSelected && !isInboundTestingSelected;
  return (

    <div className="flex-auto flex flex-col w-full gap-5">
      {isPreview && showEmptyView && <EmptyView />}
      {isPreview && pullReasons?.isPullSelected && <PullReason source={source} />}
      {isPreview && isInboundTestingSelected && <InboundTesting source={source} />}

      {!isPreview && <PullReason source={source} />}
      {!isPreview && <InboundTesting source={source} />}
    </div>
  );
};

export default PullAndInbound;
