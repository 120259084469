
import { HTMLProps, useState } from 'react';
import './horizontalTab.scss';
// import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react';
export interface HrizontalTabProps extends HTMLProps<HTMLDivElement> {
    classNames?: string;
    tabs: Array<string>,
    tabChange?: (index: number) => void
}


const HrizontalTab: React.FC<HrizontalTabProps> = ({
    classNames,
    tabChange = () => { },
    tabs = [],
    ...addtionalProps
}) => {
    const [activeTab, setActiveTab] = useState(0);
    return (
        <>
            {
                tabs?.length &&
                <div className="max-w-7xl" {...addtionalProps}>
                    <div className="flex h-12 justify-between">
                        <div className="flex px-2 lg:px-0 w-full">
                            <div className={`lg:flex lg:space-x-4 border-dark border-b-2 w-full ${classNames}`}>
                                {/* Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
                                {tabs?.map((item, index) =>
                                    <a
                                        href="javascript:void(0)"
                                        onClick={() => {
                                            setActiveTab(index);
                                            tabChange(index);
                                        }}
                                        className={`inline-flex items-center px-1 pt-1 text-base font-semibold ${activeTab === index ? 'active-tab border-b-2' : ''}`}
                                    >
                                        {item}
                                    </a>)
                                }

                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default HrizontalTab;
