import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getDifaDetails } from "./DifaDetailsServices";

export const fetchDifaDetails: any = createAsyncThunk('dasboard/fetchDifaDetails', async ({difaId}: {difaId: string}) => {
    const response = await getDifaDetails(difaId);
    return response;
});

const initialState: any = {
    loading: false,
    difaDetails: {},
    message: null
};

const difaDetailsSlice = createSlice({
    name: 'difaDetails',
    initialState,
    reducers: {},
    extraReducers: (builder: any) => {
        builder.addCase(fetchDifaDetails.pending, (state: any) => {
            state.loading = true;
        });

        //fullfilled
        builder.addCase(fetchDifaDetails.fulfilled, (state: any, action: any) => {
            state.difaDetails = action.payload;
            state.loading = false;
        });

        //rejected
        builder.addCase(fetchDifaDetails.rejected, (state: any, action: any) => {
            state.message = action.payload;
            state.loading = false;
        });

    }
})

export default difaDetailsSlice.reducer;