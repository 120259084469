import React, { useState } from "react";
import downArrow from "../../../../images/chevron-down.svg";
import arrowUpIcon from "../../../../images/arrow-narrow-up.svg";
import arrowDownIcon from "../../../../images/arrow-down.svg";
import {
  formatDateInNumbers,
  getFirstLastEngineerLetter,
} from "../../../common/services/UtilityService";
import { useSelector } from "react-redux";

const OptimizationTickets = () => {
  const { optimizationTickets, loading } = useSelector(
    (state: any) => state.preInspectionSummary
  );

  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");

  const handleSort = () => {
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  const sortedTickets = [...optimizationTickets].sort((a, b) => {
    const dateA = new Date(a.ticketDate).getTime();
    const dateB = new Date(b.ticketDate).getTime();
    return sortOrder === "asc" ? dateA - dateB : dateB - dateA;
  });

  return (
    <div className="relative border border-dark overflow-x-auto shadow-md sm:rounded-lg">
      <table className="w-full text-left">
        <thead className="text-xs font-semibold top-0 sticky text-neutral-grey border-b border-dark">
          <tr className="table-row">
            <th scope="col" className="px-3 py-4">
              <div className="flex items-center">
                Ticket #
                <img
                  src={downArrow}
                  alt="sort icon"
                  className="ms-1.5 cursor-pointer"
                />
              </div>
            </th>
            <th scope="col" className="px-3 py-4">
              <div className="flex items-center">
                Requested
                <img
                  src={downArrow}
                  alt="sort icon"
                  className="ms-1.5 cursor-pointer"
                />
              </div>
            </th>
            <th scope="col" className="px-3 py-4">
              <div className="flex items-center">
                Last updated
                <img
                  onClick={handleSort}
                  src={sortOrder === "asc" ? arrowDownIcon : arrowUpIcon}
                  alt="sort col icon"
                  className="ms-1.5 cursor-pointer"
                />
                <img
                  src={downArrow}
                  alt="sort icon"
                  className="ms-1.5 cursor-pointer"
                />
              </div>
            </th>
            <th scope="col" className="px-3 py-4">
              <div className="flex items-center">
                Assignee
                <img
                  src={downArrow}
                  alt="sort icon"
                  className="ms-1.5 cursor-pointer"
                />
              </div>
            </th>
            <th scope="col" className="px-3 py-4">
              <div className="flex items-center">
                Subject
                <img
                  src={downArrow}
                  alt="sort icon"
                  className="ms-1.5 cursor-pointer"
                />
              </div>
            </th>
          </tr>
        </thead>
        <tbody className="text-sm font-normal text-neutral-grey">
          {loading ? (
            <tr className="table-row">
              <td colSpan={5} className="text-lg text-center py-5">
                Loading...
              </td>
            </tr>
          ) : sortedTickets.length > 0 ? (
            sortedTickets.map((optimizeTicket: any, index: number) => (
              <tr key={index} className="table-row border-b border-dark">
                <td className="px-3 py-3">{optimizeTicket?.ticketId}</td>
                <td className="px-3 py-3">
                  {formatDateInNumbers(optimizeTicket?.ticketDate)}
                </td>
                <td className="px-3 py-3">
                  {formatDateInNumbers(optimizeTicket?.ticketDate)}
                </td>
                <td className="px-3 py-3">
                  <div className="flex items-center gap-2">
                    <span className="assignee-name">
                      {getFirstLastEngineerLetter(optimizeTicket?.assignee)}
                    </span>
                    {optimizeTicket?.assignee}
                  </div>
                </td>
                <td className="px-3 py-3">{optimizeTicket?.subject}</td>
              </tr>
            ))
          ) : (
            <tr className="table-row">
              <td colSpan={5} className="text-lg text-center py-5">
                No data found
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default OptimizationTickets;
