import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import closeIcon from "./../../../images/close-icon.svg";
import arrowLeft from "./../../../images/chevron-arrow-left.svg";
import arrowRight from "./../../../images/chevron-arrow-right.svg";

import { useEffect, useState } from "react";
import './Gallery.scss';

export interface ViewGalleryComponentProps {
    show: boolean;
    name: string;
    images: Array<any>
    close: () => void
}

const ViewGallery: React.FC<ViewGalleryComponentProps> = ({ show, images = [], close, name }) => {
    const [slideIdex, setSlideIndex] = useState(0);

    const move = (left: boolean) => {
        const newIndex = left ? slideIdex - 1 : slideIdex + 1;
        setSlideIndex(newIndex);
    }
    useEffect(() => {
        setSlideIndex(0);
    }, [show])

    return (
        <>
            <Dialog open={show} onClose={close} className="relative z-50">
                <DialogBackdrop
                    transition
                    className="fixed inset-0 backdrop-blur-sm transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
                />

                <div className="fixed inset-0 z-50 w-screen">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <DialogPanel
                            transition
                            className="flex dialog-max-height relative transform overflow-hidden rounded-lg difa-background-with-border text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-sm md:min-w-3/5 md:w-3/5 md:max-w-full data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                        >
                            <div className="difa-widget-containers">
                                <div className="header-main border-bottom">
                                    <div className="flex flex-row justify-between">
                                        <div>
                                            <h3>{name}</h3>
                                        </div>
                                        <a href="javascript:void(0)" onClick={close}>
                                            <img src={closeIcon} alt="close" />
                                        </a>
                                    </div>
                                </div>
                                {
                                    images?.length > 0 &&
                                    <div className="p-6 w-full flex overflow-y-auto overflow-x-hidden image-view-container pb-16 table-main">
                                        <div className="w-4/6 h-full bg-dark-800">
                                            <div className="p-16">
                                                {
                                                    images?.length > 0 &&
                                                    <div className="gallery-container">
                                                        <a className={`move-left ${slideIdex <= 0 ? 'navigation-disabled' : ''}`} onClick={() => move(true)} ><img src={arrowLeft} alt="arrow left" /></a>

                                                        {images.map((photo: any, index: number) =>
                                                            <img width={580} className={`gallery-animate ${slideIdex === index ? 'block' : 'hidden'} h-72 w-full`} src={photo?.thumbnailFileLink || photo?.fileLink} />
                                                        )}
                                                        <a onClick={() => move(false)} className={`move-right ${slideIdex === images?.length - 1 ? 'navigation-disabled' : ''}`}><img src={arrowRight} alt="arrow left" /></a>
                                                    </div>
                                                }
                                            </div>
                                            <div className="flex gap-x-4 flex-wrap pl-4 pr-4 mb-3">
                                                {
                                                    images?.length > 0 &&
                                                    <>
                                                        {
                                                            images?.map((photo: any, index: number) =>
                                                                <div key={index} className={`pl-1 pr-1 pt-4 cursor-pointer pb-4 rounded-lg ${index === slideIdex ? 'bg-dark-500' : ''}`} onClick={() => setSlideIndex(index)}>
                                                                    <div className={`h-16 w-24 rounded-sm`} style={{ background: `url("${photo?.thumbnailFileLink || photo?.fileLink}")`, backgroundSize: 'cover' }}>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    </>
                                                }
                                            </div>
                                        </div>
                                        {
                                            images?.length > 0 &&
                                            <div className="flex-auto border-left p-5  w-1/3">
                                                <h2 className="mt-1 mb-1">Description</h2>
                                                <h5 className="mt-1 mb-1">{images[slideIdex]?.comments}</h5>
                                                <h2 className="mt-5">Details</h2>
                                                <div className="border-bottom flex justify-between gap-2"> <h5>Source</h5><h5>{images[slideIdex]?.source}</h5></div>
                                                <div className="mt-3">
                                                    <div className="border-bottom flex justify-between gap-2"> <h5>Serial#</h5><h5>{images[slideIdex]?.serialNumber}</h5></div>
                                                </div>
                                                <div className="mt-3">
                                                    <div className="flex justify-between gap-5"> <h5>Equipment description</h5><h5 className="break-all">{images[slideIdex]?.equipmentDescription}</h5></div>
                                                </div>
                                            </div>
                                        }

                                    </div>
                                }
                                {
                                    !(images?.length > 0) &&
                                    <div className="opacity-70 p-6">No data found.</div>
                                }
                            </div>
                        </DialogPanel>
                    </div>
                </div >
            </Dialog >
        </>
    );
}

export default ViewGallery;
