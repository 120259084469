// DifaReport.tsx
import React, { useEffect, useState } from "react";
import downloadIcon from "../../../images/download-01.svg";
import { ReactComponent as ArrowUpIcon } from "../../../images/arrow-narrow-up.svg";
import { ReactComponent as ArrowDownIcon } from "../../../images/arrow-down.svg";
import { ReactComponent as SearchIcon } from "../../../images/search-md.svg";
import { ReactComponent as FilterIon } from "../../../images/filter-funnel-01.svg"
import DifaCheckbox from "../../common/checkbox/DifaCheckbox";
import { ReactComponent as DownArrow } from "../../../images/chevron-down.svg";
import { ReactComponent as UpArrow } from "../../../images/chevron-up.svg";
import { AppDispatch } from "../../../store";
import { useDispatch, useSelector } from "react-redux";
import { fetchDifaReports } from "../DocumentationSlice";
import { FiltersType } from "./DifaPacket";
import FilterReports from "./FilterReports";

interface DifaReport {
  difaReportId?: number | undefined;
  wellname?: string | undefined;
  operatorName?: string | undefined;
  installDate?: Date | undefined;
  failedDate?: Date | undefined;
  pulledDate?: Date | undefined;
  rundays?: number | undefined;
  difaScheduled?: Date | undefined;
  reported?: Date | undefined;
  reportPublished?: Date | undefined;
  pullReasion?: string | undefined;
  failureCause?: string | undefined;
  failedItem?: string | undefined;
  primaryFailedComponent?: string | undefined;
  failureDescriptor?: string | undefined;
}

const DifaReport: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const { data } = useSelector((state: any) => state.documentation.reports);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [showFilterMenu, setShowFilterMenu] = useState<boolean>(false);
  const [reportDetails, setReportDetails] = useState<DifaReport[]>([]);
  const [sortConfig, setSortConfig] = useState<{ key: keyof DifaReport; direction: 'asc' | 'desc' } | null>(null);
  const [selectedRows, setSelectedRows] = useState<Set<number>>(new Set()); // Store selected rows by report number
  const [selectAll, setSelectAll] = useState(false); // Master checkbox state
  const [filters, setFilters] = useState<FiltersType>({
    operator: [],
    installDate: [],
    failedDate: [],
    pullDate: [],
    reportPublished: [],
    rundays: [],
    difaSceduled: [],
    pullReasion: [],
    failureCause: [],
    failedItem: [],
    primaryFailedComponent: [],
  });

  

// Add useEffect to fetch data
useEffect(() => {
  dispatch(fetchDifaReports({
    pageNumber: 1,
    pageSize: 12,
    operator: [],
    installDate: [],
    failedDate: [],
    pullDate: [],
    reportPublished: [],
    rundays: [],
    difaSceduled: [],
    pullReasion: [],
    failureCause: [],
    failedItem: [],
    primaryFailedComponent: [],
  }));
}, [dispatch]);


const handleApplyFilters = (newFilters: FiltersType) => {
  dispatch(fetchDifaReports({
    pageNumber: 1,
    pageSize: 12,
    operator: newFilters.operator || [],
    installDate: newFilters.installDate || [],
    failedDate: newFilters.failedDate || [],
    pullDate: newFilters.pullDate || [],
    reportPublished: newFilters.reportPublished || [],
    rundays: newFilters.rundays || [],
    difaSceduled: newFilters.difaSceduled || [],
    pullReasion: newFilters.pullReasion || [],
    failureCause: newFilters.failureCause || [],
    failedItem: newFilters.failedItem || [],
    primaryFailedComponent: newFilters.primaryFailedComponent || [],
  }));
  setFilters(newFilters);
  setShowFilterMenu(false);
};
// Add useEffect to handle API response
useEffect(() => {
  if (data) {
    const parsedData = data.map((item: any) => ({
      ...item,
      installDate: item.installDate ? new Date(item.installDate) : undefined,
      failedDate: item.failedDate ? new Date(item.failedDate) : undefined,
      pulledDate: item.pulledDate ? new Date(item.pulledDate) : undefined,
      difaScheduled: item.difaScheduled ? new Date(item.difaScheduled) : undefined,
      reportPublished: item.reportPublished ? new Date(item.reportPublished) : undefined,
      reported: item.reported ? new Date(item.reported) : undefined
    }));
    setReportDetails(parsedData);
  }
}, [data]);

  // Handle individual row selection
  const handleRowSelect = (reportNumber: number | undefined) => {
    if (reportNumber === undefined) return;
    const updatedSelectedRows = new Set(selectedRows);
    if (updatedSelectedRows.has(reportNumber)) {
      updatedSelectedRows.delete(reportNumber); // Deselect
    } else {
      updatedSelectedRows.add(reportNumber); // Select
    }
    setSelectedRows(updatedSelectedRows);
  };

  // Handle master checkbox toggle
  const handleMasterCheckboxToggle = () => {
    if (selectAll) {
      setSelectedRows(new Set()); // Deselect all
    } else {
      const allRowNumbers = reportDetails
        .map(item => item.difaReportId)
        .filter((difaReportId): difaReportId is number => difaReportId !== undefined); // Filter out undefined values

      setSelectedRows(new Set(allRowNumbers)); // Select all rows
    }
    setSelectAll(!selectAll);
  };

  // Function to request sorting
  const requestSort = (key: keyof DifaReport) => {
    let direction: 'asc' | 'desc' = 'asc';
    if (sortConfig?.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  // Function to get value by key
  const getValueByKey = (item: DifaReport, key: keyof DifaReport): string | number => {
    const value = item[key];
    if (value instanceof Date) {
      return value.getTime(); // Convert date to timestamp for sorting
    }
    return value ?? '';
  };

const processedData = React.useMemo(() => {
  // Only apply search filter locally
  return reportDetails.filter((item) => {
    const searchMatch = 
      item.wellname?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.operatorName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.difaReportId?.toString().includes(searchTerm);

    return searchMatch;
  });
}, [reportDetails, searchTerm]); 

return (
    <div className="difa-widget-containers my-5 mx-6 !w-auto">
      <div className="header-main border-bottom">
        <h3>DIFA reports</h3>
      </div>
      <div className="flex items-center justify-end gap-2 px-6 py-3">
        {/* Search Bar */}
        <div className="pr-2 download-img border-r border-dark">
          <img src={downloadIcon} alt="download-table-data" className="p-2 cursor-not-allowed" />
        </div>

        <div className="relative w-1/4">
          <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
            <SearchIcon width="20" height="20" />
          </div>
          <input
            type="search"
            id="default-search"
            className="p-2.5 w-full ps-10 text-base font-medium rounded-lg border border-dark bg-dark-800"
            placeholder="Search DIFA report number or well name"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            required
          />
        </div>

        {/* Filter Button */}
        <div className="flex gap-2 items-center px-4 py-2.5 border border-dark bg-dark-700 rounded-lg">
          <FilterIon />
          <button
            className=""
            onClick={() => setShowFilterMenu(!showFilterMenu)}
          >
            Filter
          </button>
        </div>
      </div>

      {/* Filter Menu */}
      {showFilterMenu && (
        <FilterReports
          data={reportDetails} // Original unfiltered table data
          open={showFilterMenu} // Boolean controlling modal visibility
          onCloseModal={() => setShowFilterMenu(false)} // Close modal callback
          onApplyFilters={handleApplyFilters} // Function to handle filtered data
      />
    )}
      {/* Table */}
      <div className="block px-6 pb-5">
        <div className="table-main relative border border-dark overflow-auto rounded-lg">
          <table className="w-full text-sm text-left">
            <thead className="text-xs top-0 sticky font-semibold text-neutral-grey border-b border-dark">
              <tr>
                <th scope="col" className="px-3 py-4">
                  <DifaCheckbox checked={selectAll}
                    onChange={handleMasterCheckboxToggle} />
                </th>
                <th scope="col" className="px-3 py-4 border-r border-dark">
                  <div className="flex items-center whitespace-nowrap" onClick={() => requestSort('difaReportId')}>
                    <span>DIFA report #</span>
                    {sortConfig?.key === 'difaReportId' && sortConfig.direction === 'asc' ? (
                      <ArrowUpIcon className="ms-1.5" />
                    ) : (
                      <ArrowDownIcon className="ms-1.5" />
                    )}
                    
                  </div>
                </th>
                <th scope="col" className="px-3 py-4">
                  <div className="flex items-center whitespace-nowrap" onClick={() => requestSort('wellname')}>
                    <span>Well name</span>
                    {sortConfig?.key === 'wellname' && sortConfig.direction === 'asc' ? (
                      <UpArrow className="ms-1.5" />
                    ) : (
                      <DownArrow className="ms-1.5" />
                    )}
                  </div>
                </th>
                <th scope="col" className="px-3 py-4">
                  <div className="flex items-center whitespace-nowrap" onClick={() => requestSort('operatorName')}>
                    <span>Operator name</span>
                    {sortConfig?.key === 'operatorName' && sortConfig.direction === 'asc' ? (
                      <UpArrow className="ms-1.5" />
                    ) : (
                      <DownArrow className="ms-1.5" />
                    )}
                  </div>
                </th>
                <th scope="col" className="px-3 py-4">
                  <div className="flex items-center whitespace-nowrap" onClick={() => requestSort('installDate')}>
                    <span>Install date</span>
                    {sortConfig?.key === 'installDate' && sortConfig.direction === 'asc' ? (
                      <UpArrow className="ms-1.5" />
                    ) : (
                      <DownArrow className="ms-1.5" />
                    )}
                  </div>
                </th>
                <th scope="col" className="px-3 py-4">
                  <div className="flex items-center whitespace-nowrap" onClick={() => requestSort('failedDate')}>
                    <span>Failed date</span>
                    {sortConfig?.key === 'failedDate' && sortConfig.direction === 'asc' ? (
                      <UpArrow className="ms-1.5" />
                    ) : (
                      <DownArrow className="ms-1.5" />
                    )}
                  </div>
                </th>
                <th scope="col" className="px-3 py-4">
                  <div className="flex items-center whitespace-nowrap" onClick={() => requestSort('pulledDate')}>
                    <span>Pulled date</span>
                    {sortConfig?.key === 'pulledDate' && sortConfig.direction === 'asc' ? (
                     <UpArrow className="ms-1.5" />
                    ) : (
                      <DownArrow className="ms-1.5" />
                    )}
                  </div>
                </th>
                <th scope="col" className="px-3 py-4">
                  <div className="flex items-center whitespace-nowrap" onClick={() => requestSort('rundays')}>
                    <span>Run days</span>
                    {sortConfig?.key === 'rundays' && sortConfig.direction === 'asc' ? (
                      <UpArrow className="ms-1.5" />
                    ) : (
                      <DownArrow className="ms-1.5" />
                    )}
                  </div>
                </th>
                <th scope="col" className="px-3 py-4">
                  <div className="flex items-center whitespace-nowrap" onClick={() => requestSort('difaScheduled')}>
                    <span>DIFA scheduled</span>
                    {sortConfig?.key === 'difaScheduled' && sortConfig.direction === 'asc' ? (
                     <UpArrow className="ms-1.5" />
                    ) : (
                      <DownArrow className="ms-1.5" />
                    )}
                  </div>
                </th>
                <th scope="col" className="px-3 py-4">
                  <div className="flex items-center whitespace-nowrap" onClick={() => requestSort('reported')}>
                    <span>Report published</span>
                    {sortConfig?.key === 'reported' && sortConfig.direction === 'asc' ? (
                      <UpArrow className="ms-1.5" />
                    ) : (
                      <DownArrow className="ms-1.5" />
                    )}
                  </div>
                </th>
                <th scope="col" className="px-3 py-4">
                  <div className="flex items-center cursor-pointer whitespace-nowrap" onClick={() => requestSort('reported')}>
                    <span>Pull reason</span>
                    {sortConfig?.key === 'reported' && sortConfig.direction === 'asc' ? (
                     <UpArrow className="ms-1.5" />
                    ) : (
                      <DownArrow className="ms-1.5" />
                    )}
                  </div>
                </th>
                <th scope="col" className="px-3 py-4">
                  <div className="flex items-center cursor-pointer whitespace-nowrap" onClick={() => requestSort('reported')}>
                    <span>Failure cause</span>
                    {sortConfig?.key === 'reported' && sortConfig.direction === 'asc' ? (
                      <UpArrow className="ms-1.5" />
                    ) : (
                      <DownArrow className="ms-1.5" />
                    )}
                  </div>
                </th>
                <th scope="col" className="px-3 py-4">
                  <div className="flex items-center cursor-pointer whitespace-nowrap" onClick={() => requestSort('reported')}>
                    <span>Failed item</span>
                    {sortConfig?.key === 'reported' && sortConfig.direction === 'asc' ? (
                     <UpArrow className="ms-1.5" />
                    ) : (
                      <DownArrow className="ms-1.5" />
                    )}
                  </div>
                </th>
                <th scope="col" className="px-3 py-4">
                  <div className="flex items-center cursor-pointer whitespace-nowrap" onClick={() => requestSort('reported')}>
                    <span>Primary failed component</span>
                    {sortConfig?.key === 'reported' && sortConfig.direction === 'asc' ? (
                      <UpArrow className="ms-1.5" />
                    ) : (
                      <DownArrow className="ms-1.5" />
                    )}
                  </div>
                </th>
                <th scope="col" className="px-3 py-4">
                  <div className="flex items-center cursor-pointer whitespace-nowrap" onClick={() => requestSort('reported')}>
                    <span>Failure descriptor</span>
                    {sortConfig?.key === 'reported' && sortConfig.direction === 'asc' ? (
                     <UpArrow className="ms-1.5" />
                    ) : (
                      <DownArrow className="ms-1.5" />
                    )}
                  </div>
                </th>
              </tr>
            </thead>
            <tbody className="font-normal text-sm text-neutral-grey">
              {processedData?.length > 0 ? (
                processedData?.map((difaReport: DifaReport, index: number) => (
                  <tr key={index} className="border-b border-dark">
                    <th scope="row" className="px-3 py-4">
                      <DifaCheckbox 
                        checked={selectedRows.has(difaReport.difaReportId!)}
                        onChange={() => handleRowSelect(difaReport.difaReportId!)} 
                      />
                    </th>
                    <td className="px-3 py-4 text-Indigo hover:underline cursor-pointer border-r border-dark">
                      {difaReport?.difaReportId}
                    </td>
                    <td className="px-3 py-4 whitespace-nowrap">{difaReport?.wellname}</td>
                    <td className="px-3 py-4">{difaReport?.operatorName}</td>
                    <td className="px-3 py-4">{difaReport?.installDate?.toLocaleDateString() || "-"}</td>
                    <td className="px-3 py-4">{difaReport?.failedDate?.toLocaleDateString() || "-"}</td>
                    <td className="px-3 py-4">{difaReport?.pulledDate?.toLocaleDateString() || "-"}</td>
                    <td className="px-3 py-4">{difaReport?.rundays || "-"}</td>
                    <td className="px-3 py-4">{difaReport?.difaScheduled?.toLocaleDateString() || "-"}</td>
                    <td className="px-3 py-4">{difaReport?.reportPublished?.toLocaleDateString() || "-"}</td>
                    <td className="px-3 py-4">{difaReport?.pullReasion || "-"}</td>
                    <td className="px-3 py-4">{difaReport?.failureCause || "-"}</td>
                    <td className="px-3 py-4">{difaReport?.failedItem || "-"}</td>
                    <td className="px-3 py-4">{difaReport?.primaryFailedComponent || "-"}</td>
                    <td className="px-3 py-4">{difaReport?.failureDescriptor || "-"}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={15} className="text-lg text-center py-5">
                    No data found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default DifaReport;