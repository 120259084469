import React from "react";
import { ReactComponent as Plus } from "../../../../images/plus-indigo.svg";
import { ReactComponent as DeleteIcon } from "../../../../images/trash-01.svg";
import { useDispatch, useSelector } from "react-redux";
import { setChmEmployee } from "../../../difaPacketBuilder/difaPacketBuilderSlice"; // Adjust path if needed

// Define TypeScript Interface for an Attendee
interface ChmEmployee {
    name: string;
    role: string;
}

const ChampXEmp: React.FC = () => {
    const dispatch = useDispatch();

    // Get attendees from Redux store
    const chmEmployee: ChmEmployee[] = useSelector((state: any) => state.difaPacketBuilder.chmEmployee);

    // Function to add a new row
    const addAttendee = (): void => {
        dispatch(setChmEmployee([...chmEmployee, { name: "", role: "" }]));
    };

    // Function to handle input change
    const handleInputChange = (index: number, field: keyof ChmEmployee, value: string): void => {
        const updatedChmEmployees: ChmEmployee[] = [...chmEmployee];
        updatedChmEmployees[index] = { ...updatedChmEmployees[index], [field]: value };

        dispatch(setChmEmployee(updatedChmEmployees)); // Update Redux state
    };

    // Function to delete a row
    const handleDelete = (index: number): void => {
        if (chmEmployee.length > 1) {
            dispatch(setChmEmployee(chmEmployee.filter((_, i: number) => i !== index)));
        }
    };

    return (
        <div className="difa-widget-containers">
            <div className="header-main border-bottom">
                <h3>ChampionX employees</h3>
                <h5>Add employees that are responsible for this report and account.</h5>
            </div>
            <div className="flex flex-col gap-4 px-6 py-5">
                {chmEmployee.map((attendee: ChmEmployee, index: number) => (
                    <div key={index} className="flex flex-col md:flex-row items-center justify-between gap-4">
                        <div className="input-field-container">
                            <label className="input-field-caption">ChampionX employee</label>
                            <input
                                type="text"
                                className="input-field"
                                placeholder="Enter employee name"
                                value={attendee.name}
                                onChange={(e) => handleInputChange(index, "name", e.target.value)}
                            />
                        </div>
                        <div className="input-field-container">
                            <label className="input-field-caption">Role</label>
                            <input
                                type="text"
                                className="input-field"
                                placeholder="Enter role"
                                value={attendee.role}
                                onChange={(e) => handleInputChange(index, "role", e.target.value)}
                            />
                        </div>
                        {/* Delete Button */}
                        <button className="text-left p-2 mt-3.5 hover:bg-gray-700 cursor-pointer rounded-lg" onClick={() => handleDelete(index)}>
                            <DeleteIcon />
                        </button>
                    </div>
                ))}
                {/* Add Attendee Button */}
                <div className="flex items-end justify-start gap-2 cursor-pointer" onClick={addAttendee}>
                    <Plus />
                    <span className="text-sm font-bold text-Indigo">Add team members</span>
                </div>
            </div>
        </div>
    );
};

export default ChampXEmp;
