// Header.tsx
import React from 'react';
import './Header.css';

const Header: React.FC = () => {
  return (
    <header className="main-header">
      <div className="header-content">
        <div className="header-details">
          <div className="header-details-content">
            <div className="header-title">My dashboard</div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
