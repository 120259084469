import React, { useEffect, useState } from "react";
import downloadIcon from "../../../images/download-01.svg";
import { ReactComponent as ArrowUpIcon } from "../../../images/arrow-narrow-up.svg";
import { ReactComponent as ArrowDownIcon } from "../../../images/arrow-down.svg";
import { ReactComponent as SearchIcon } from "../../../images/search-md.svg";
import { ReactComponent as FilterIon } from "../../../images/filter-funnel-01.svg"
import DifaCheckbox from "../../common/checkbox/DifaCheckbox";
import FilterReports from "./FilterPackets";
import { ReactComponent as DownArrow } from "../../../images/chevron-down.svg";
import { ReactComponent as UpArrow } from "../../../images/chevron-up.svg";
import { useDispatch, useSelector } from "react-redux";
import { fetchDifaPackets } from "../DocumentationSlice";
import type { ThunkDispatch } from "@reduxjs/toolkit";
import { AnyAction } from "redux";
import { useNavigate } from "react-router-dom";
import { fetchDifaPacketStatus } from "../../preInspectionSummary/PreInspectionSummarySlice";
import FilterPackets from "./FilterPackets";


export interface DifaPacket {
  failedItem: string | undefined;
  failureCause: string | undefined;
  primaryFailedComponent: string | undefined;
  failureDesciptor: string | undefined;
  pullReasion: string | undefined;
  reportPublished: Date | undefined;
  difaReportId?: string | undefined;
  reportNumber?: number | undefined;
  wellname?: string | undefined;
  operatorName?: string | undefined;
  installDate?: Date | undefined;
  failedDate?: Date | undefined;
  pulledDate?: Date | undefined;
  rundays?: number | undefined;
  difaScheduled?: Date | undefined;
  reported?: Date | undefined;
  failureDescriptor?: string | undefined;
}

export type FiltersType = {
  operator: string[];
  installDate: string[];
  failedDate: string[];
  pullDate: string[];
  reportPublished: string[];
  rundays: string[];
  difaSceduled: string[];
  pullReasion: string[];
  failureCause: string[];
  failedItem: string[];
  primaryFailedComponent: string[];
};

const DifaPacket: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [showFilterMenu, setShowFilterMenu] = useState<boolean>(false);
  const [reportDetails, setReportDetails] = useState<DifaPacket[]>([]);
  const [sortConfig, setSortConfig] = useState<{ key: keyof DifaPacket; direction: 'asc' | 'desc' } | null>(null);
  const [selectedRows, setSelectedRows] = useState<Set<number>>(new Set()); // Store selected rows by report number
  const [selectAll, setSelectAll] = useState(false); // Master checkbox state
  const navigate =useNavigate();
  type AppDispatch = ThunkDispatch<any, any, AnyAction>;
  const dispatch: AppDispatch = useDispatch();
  const [filters, setFilters] = useState<FiltersType>({
    operator: [],
    installDate: [],
    failedDate: [],
    pullDate: [],
    reportPublished: [],
    rundays: [],
    difaSceduled: [],
    pullReasion: [],
    failureCause: [],
    failedItem: [],
    primaryFailedComponent: [],
  });
  
  
  useEffect(() => {
    dispatch(
      fetchDifaPackets({
        pageNumber: 1,
        pageSize: 10,
        operator: [],
        installDate: [],
        failedDate: [],
        pullDate: [],
        reportPublished: [],
        rundays: [],
        difaSceduled: [],
        pullReasion: [],
        failureCause: [],
        failedItem: [],
        primaryFailedComponent: [],
      })
    );
  }, [dispatch]);

  const { data: tableData, loading, error } = useSelector((state: any) => state.documentation.packets);
useEffect(() => {
  // Create a complete payload with all filters set to empty by default
  const defaultPayload = {
    operator: [],
    installDate: [],
    failedDate: [],
    pullDate: [],
    reportPublished: [],
    rundays: [],
    difaSceduled: [],
    pullReasion: [],
    failureCause: [],
    failedItem: [],
    primaryFailedComponent: []
  };

  // Update only the selected filters with their corresponding values
  const payloadWithFilters = {

    ...defaultPayload,
    ...filters // Overwrite empty fields with the actual filter values
  };

  dispatch(
    fetchDifaPackets({
      pageNumber: 1,
      pageSize: 10,
      ...payloadWithFilters // Pass the full payload
    })
  );
}, [filters, dispatch]);


useEffect(() => {
  if (tableData) {
    const parsedData = tableData.map((packet: any) => ({
      ...packet,
      installDate: packet.installDate ? new Date(packet.installDate) : null,
      failedDate: packet.failedDate ? new Date(packet.failedDate) : null,
      pulledDate: packet.pulledDate ? new Date(packet.pulledDate) : null,
      difaScheduled: packet.difaScheduled ? new Date(packet.difaScheduled) : null,
      reportPublished: packet.reportPublished ? new Date(packet.reportPublished) : null,
    }));
    setReportDetails(parsedData); // Update state with filtered rows
  }
}, [tableData]);

  // Handle individual row selection
  const handleRowSelect = (reportNumber: number | undefined) => {
    if (reportNumber === undefined) return;
    const updatedSelectedRows = new Set(selectedRows);
    if (updatedSelectedRows.has(reportNumber)) {
      updatedSelectedRows.delete(reportNumber); // Deselect
    } else {
      updatedSelectedRows.add(reportNumber); // Select
    }
    setSelectedRows(updatedSelectedRows);
  };

  // Handle master checkbox toggle
  const handleMasterCheckboxToggle = () => {
    if (selectAll) {
      setSelectedRows(new Set()); // Deselect all
    } else {
      const allRowNumbers = tableData
        .map((item: DifaPacket) => item?.difaReportId) // Ensure `item` is properly typed
        .filter((difaReportId: any): difaReportId is number => difaReportId !== undefined); // Ensure `reportNumber` is a number

      setSelectedRows(new Set(allRowNumbers)); // Select all rows
    }
    setSelectAll(!selectAll); // Toggle the `selectAll` state
  };

 
  const handleApplyFilters = (filters: FiltersType) => {
    setFilters(filters); // Update the local state with applied filters
  };

  // Function to request sorting
  const requestSort = (key: keyof DifaPacket) => {
    let direction: 'asc' | 'desc' = 'asc';
    if (sortConfig?.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  // Function to get value by key
  const getValueByKey = (item: DifaPacket, key: keyof DifaPacket): string | number => {
    const value = item[key];
    if (value instanceof Date) {
      return value.getTime(); // Convert date to timestamp for sorting
    }
    return value ?? '';
  };

  const processedData = React.useMemo(() => {
    // Step 1: Apply Searching
    let filteredData = reportDetails.filter((item) => {
      return (
        item.wellname?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.difaReportId !== undefined &&
        item.difaReportId.toString().includes(searchTerm.toLowerCase()) ||
        (item.reportNumber !== undefined &&
          item.reportNumber.toString().includes(searchTerm))
      );
    });
    

    // Step 2: Apply Sorting on the Filtered Data
    if (sortConfig !== null) {
      filteredData.sort((a, b) => {
        const aValue = getValueByKey(a, sortConfig.key);
        const bValue = getValueByKey(b, sortConfig.key);

        if (aValue < bValue) {
          return sortConfig.direction === "asc" ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === "asc" ? 1 : -1;
        }
        return 0;
      });
    }

    return filteredData;
  }, [reportDetails, searchTerm, sortConfig]);
   
  const viewPublishedPacket = (DifaPacket:any) => {
    dispatch(fetchDifaPacketStatus({ wellApiNumber: DifaPacket?.wellApiNumber,difaId: DifaPacket?.difaId }));
    navigate("/difa-details/preview-difa-packet", {
      state: { difaId: DifaPacket?.difaId, customerName: "customer Name", wellName:DifaPacket?.wellName },
    });
  }

  return (
    <div className="difa-widget-containers my-5 mx-6 !w-auto">
      <div className="header-main border-bottom">
        <h3>DIFA packets</h3>
      </div>
      <div className="flex items-center justify-end gap-2 px-6 py-3">
        {/* Search Bar */}
        <div className="pr-2 download-img border-r border-dark">
          <img src={downloadIcon} alt="download-table-data" className="p-2 cursor-not-allowed" />
        </div>

        <div className="relative w-1/4">
          <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
            <SearchIcon width="20" height="20" />
          </div>
          <input
            type="search"
            id="default-search"
            className="p-2.5 w-full ps-10 text-base font-medium rounded-lg border border-dark bg-dark-800"
            placeholder="Search DIFA packet number or well name"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            required
          />
        </div>

        {/* Filter Button */}
        <div className="flex gap-2 items-center px-4 py-2.5 border border-dark bg-dark-700 rounded-lg">
          <FilterIon />
          <button
            className=""
            onClick={() => setShowFilterMenu(!showFilterMenu)}
          >
            Filter
          </button>
        </div>

      </div>

      {/* Filter Menu */}
      {showFilterMenu && (
        <FilterPackets
        data={tableData} // Original unfiltered table data
        open={showFilterMenu} // Boolean controlling modal visibility
        onCloseModal={() => setShowFilterMenu(false)} // Close modal callback
        onApplyFilters={handleApplyFilters} // Function to handle filtered data
      />
      

)}
      {/* Table */}
      <div className="block px-6 pb-5">
        <div className="table-main relative border border-dark overflow-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left">
            <thead className="text-xs top-0 sticky font-semibold text-neutral-grey border-b border-dark">
              <tr>
                <th scope="col" className="px-3 py-4">
                  <DifaCheckbox checked={selectAll}
                    onChange={handleMasterCheckboxToggle} />
                </th>
                <th scope="col" className="px-3 py-4 border-r border-dark">
                  <div className="flex items-center whitespace-nowrap" onClick={() => requestSort('reportNumber')}>
                    <span>DIFA packet #</span>
                    {sortConfig?.key === 'reportNumber' && sortConfig.direction === 'asc' ? (
                      <ArrowUpIcon className="ms-1.5" />
                    ) : (
                      <ArrowDownIcon className="ms-1.5" />
                    )}

                  </div>
                </th>
                <th scope="col" className="px-3 py-4">
                  <div className="flex items-center whitespace-nowrap" onClick={() => requestSort('wellname')}>
                    <span>Well name</span>
                    {sortConfig?.key === 'wellname' && sortConfig.direction === 'asc' ? (
                      <UpArrow className="ms-1.5" />
                    ) : (
                      <DownArrow className="ms-1.5" />
                    )}
                  </div>
                </th>
                <th scope="col" className="px-3 py-4">
                  <div className="flex items-center whitespace-nowrap" onClick={() => requestSort('operatorName')}>
                    <span>Operator name</span>
                    {sortConfig?.key === 'operatorName' && sortConfig.direction === 'asc' ? (
                      <UpArrow className="ms-1.5" />
                    ) : (
                      <DownArrow className="ms-1.5" />
                    )}
                  </div>
                </th>
                <th scope="col" className="px-3 py-4">
                  <div className="flex items-center whitespace-nowrap" onClick={() => requestSort('installDate')}>
                    <span>Install date</span>
                    {sortConfig?.key === 'installDate' && sortConfig.direction === 'asc' ? (
                      <UpArrow className="ms-1.5" />
                    ) : (
                      <DownArrow className="ms-1.5" />
                    )}
                  </div>
                </th>
                <th scope="col" className="px-3 py-4">
                  <div className="flex items-center whitespace-nowrap" onClick={() => requestSort('failedDate')}>
                    <span>Failed date</span>
                    {sortConfig?.key === 'failedDate' && sortConfig.direction === 'asc' ? (
                      <UpArrow className="ms-1.5" />
                    ) : (
                      <DownArrow className="ms-1.5" />
                    )}
                  </div>
                </th>
                <th scope="col" className="px-3 py-4">
                  <div className="flex items-center whitespace-nowrap" onClick={() => requestSort('pulledDate')}>
                    <span>Pulled date</span>
                    {sortConfig?.key === 'pulledDate' && sortConfig.direction === 'asc' ? (
                      <UpArrow className="ms-1.5" />
                    ) : (
                      <DownArrow className="ms-1.5" />
                    )}
                  </div>
                </th>
                <th scope="col" className="px-3 py-4">
                  <div className="flex items-center whitespace-nowrap" onClick={() => requestSort('rundays')}>
                    <span>Run days</span>
                    {sortConfig?.key === 'rundays' && sortConfig.direction === 'asc' ? (
                      <UpArrow className="ms-1.5" />
                    ) : (
                      <DownArrow className="ms-1.5" />
                    )}
                  </div>
                </th>
                <th scope="col" className="px-3 py-4">
                  <div className="flex items-center whitespace-nowrap" onClick={() => requestSort('difaScheduled')}>
                    <span>DIFA scheduled</span>
                    {sortConfig?.key === 'difaScheduled' && sortConfig.direction === 'asc' ? (
                      <UpArrow className="ms-1.5" />
                    ) : (
                      <DownArrow className="ms-1.5" />
                    )}
                  </div>
                </th>
                <th scope="col" className="px-3 py-4">
                  <div className="flex items-center whitespace-nowrap" onClick={() => requestSort('reported')}>
                    <span>Report published</span>
                    {sortConfig?.key === 'reported' && sortConfig.direction === 'asc' ? (
                      <UpArrow className="ms-1.5" />
                    ) : (
                      <DownArrow className="ms-1.5" />
                    )}
                  </div>
                </th>
                <th scope="col" className="px-3 py-4">
                  <div className="flex items-center cursor-pointer whitespace-nowrap" onClick={() => requestSort('reported')}>
                    <span>Pull reason</span>
                    {sortConfig?.key === 'reported' && sortConfig.direction === 'asc' ? (
                      <UpArrow className="ms-1.5" />
                    ) : (
                      <DownArrow className="ms-1.5" />
                    )}
                  </div>
                </th>
                <th scope="col" className="px-3 py-4">
                  <div className="flex items-center cursor-pointer whitespace-nowrap" onClick={() => requestSort('reported')}>
                    <span>Failure cause</span>
                    {sortConfig?.key === 'reported' && sortConfig.direction === 'asc' ? (
                      <UpArrow className="ms-1.5" />
                    ) : (
                      <DownArrow className="ms-1.5" />
                    )}
                  </div>
                </th>
                <th scope="col" className="px-3 py-4">
                  <div className="flex items-center cursor-pointer whitespace-nowrap" onClick={() => requestSort('reported')}>
                    <span>Failed item</span>
                    {sortConfig?.key === 'reported' && sortConfig.direction === 'asc' ? (
                      <UpArrow className="ms-1.5" />
                    ) : (
                      <DownArrow className="ms-1.5" />
                    )}
                  </div>
                </th>
                <th scope="col" className="px-3 py-4">
                  <div className="flex items-center cursor-pointer whitespace-nowrap" onClick={() => requestSort('reported')}>
                    <span>Primary failed component</span>
                    {sortConfig?.key === 'reported' && sortConfig.direction === 'asc' ? (
                      <UpArrow className="ms-1.5" />
                    ) : (
                      <DownArrow className="ms-1.5" />
                    )}
                  </div>
                </th>
                <th scope="col" className="px-3 py-4">
                  <div className="flex items-center cursor-pointer whitespace-nowrap" onClick={() => requestSort('reported')}>
                    <span>Failure descriptor</span>
                    {sortConfig?.key === 'reported' && sortConfig.direction === 'asc' ? (
                      <UpArrow className="ms-1.5" />
                    ) : (
                      <DownArrow className="ms-1.5" />
                    )}
                  </div>
                </th>
              </tr>
            </thead>
            <tbody className="font-normal text-sm text-neutral-grey">
              {processedData?.length > 0 ? (
                processedData?.map((DifaPacket: DifaPacket, index: number) => (
                  <tr key={index} className="border-b border-dark">
                    <th scope="row" className="px-3 py-4">
                      <DifaCheckbox
                        checked={selectedRows.has(Number(DifaPacket?.difaReportId!))}
                        onChange={() => handleRowSelect(Number(DifaPacket?.difaReportId!))}
                      />

                    </th>
                    <td
                    className="px-3 py-4 text-Indigo hover:underline cursor-pointer border-r border-dark"
                    onClick={() => viewPublishedPacket(DifaPacket)}
>
  {DifaPacket?.difaReportId}
</td>

                    <td className="px-3 py-4 whitespace-nowrap">{DifaPacket?.wellname}</td>
                    <td className="px-3 py-4">{DifaPacket?.operatorName}</td>
                    <td className="px-3 py-4">
                      {DifaPacket.installDate ? DifaPacket.installDate.toLocaleDateString() : "-"}
                    </td>
                    <td className="px-3 py-4">
                      {DifaPacket.failedDate ? DifaPacket.failedDate.toLocaleDateString() : "-"}
                    </td>
                    <td className="px-3 py-4">
                      {DifaPacket.pulledDate ? DifaPacket.pulledDate.toLocaleDateString() : "-"}
                    </td>
                    <td className="px-3 py-4">
                      {DifaPacket?.rundays}
                    </td>

                    <td className="px-3 py-4">{DifaPacket.difaScheduled ? DifaPacket.difaScheduled.toLocaleDateString() : "-"}</td>
                    <td className="px-3 py-4">{DifaPacket.reportPublished ? DifaPacket.reportPublished.toLocaleDateString() : "-"}</td>
                    <td className="px-3 py-4">{DifaPacket.pullReasion}</td>
                    <td className="px-3 py-4">{DifaPacket.failureCause}</td>
                    <td className="px-3 py-4">{DifaPacket.failedItem}</td>
                    <td className="px-3 py-4">{DifaPacket.primaryFailedComponent}</td>
                    <td className="px-3 py-4">{DifaPacket.failureDesciptor}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={15} className="text-lg text-center py-5">
                    No data found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default DifaPacket;