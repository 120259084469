import Token from '../../models/Token';
import { LoginModel } from '../../models/Login';
import Helpers from '../../utilities/Helpers';
import api from '../../config/HttpCommon';
import TokenService from './token.service';
import { TokenRefreshModel } from '../../models/TokenRefresh';
import GrantTypeConstant from '../../constants/grant-type.constant';

import { v4 as uuidv4 } from 'uuid';

const logout = async () => {
  TokenService.removeUser();
  window.location.href =
    process.env.REACT_APP_CONNEXIA_DEVELOPMENT_URL +
    '/Account/LogOut?app=' +
    process.env.REACT_APP_DEVELOPMENT_WEB_APP_REDIRECT_URL;
};

const isUserLoggedIn = () => {
  return TokenService.getUser() != null;
};

const login = async (login: LoginModel) => {
  return await api
    .post(
      '/Account/Token',
      {
        //grantType: GrantTypeConstant.form,
        grant_type: "sso_external",
        refresh_token: login.refresh_token,
        reference: login.reference,
      },
      {
        headers: {
          'content-type': 'application/json',
        },
      },
    )
    .then(async function (response: any) {
      if (response) {
        const uuid = uuidv4();
        const data: Token = response.data as Token; // Explicitly type response.data as Token
        TokenService.addOrUpdateLocalRefreshToken(data?.refresh_token);
        TokenService.addOrUpdateLocalAccessToken(data?.access_token);
        TokenService.addOrUpdateLocalExpiresIn(data?.expires_in);
        TokenService.addOrUpdateLocalCorrelationId(uuid);
        TokenService.setUser(data?.userName);

        return 'success';
      }
    })
    .catch(function (error) {
      Helpers.ConsoleLog(error, 'error');
      logout();
      return 'failed';
    });
};

const refreshTokenFromServer = async () => {
  try {
    const refreshModel: TokenRefreshModel = {
      grantType: GrantTypeConstant.refreshToken,
      refreshToken: TokenService.getLocalRefreshToken(),
      userName: TokenService.getUser(),
      passwordHash: ""
    };
    await api
      .post('/Account/Token', refreshModel, {
        headers: {
          'content-type': 'application/json',
        },
      })
      .then((response) => {
        if (response.status === 401) {
          return 'failed';
        } else {
          const data: Token = response.data as Token;
          TokenService.addOrUpdateLocalRefreshToken(data.refresh_token);
          TokenService.addOrUpdateLocalAccessToken(data.access_token);
          TokenService.addOrUpdateLocalExpiresIn(data.expires_in);
          TokenService.setUser(data.userName);
          return 'success';
        }
      })
      .catch(function (error) {
        Helpers.ConsoleLog(error, 'error');
        logout();
        return 'failed';
      });
  } catch (error) {
    Helpers.ConsoleLog(error, 'error');
    return 'failed';
  }
};

const AuthService = {
  logout,
  isUserLoggedIn,
  refreshTokenFromServer,
  login,
};

export default AuthService;
