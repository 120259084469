import React, { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  formatDateInNumbers,
  getFirstLastEngineerLetter,
} from '../../../../common/services/UtilityService';
import TicketView from '../../../../preInspectionSummary/components/documentationTables/TicketView';
import { ReactComponent as DownArrow } from '../../../../../images/chevron-down.svg';
import { ReactComponent as UpArrow } from '../../../../../images/chevron-up.svg';
import { useSortableData } from '../../../../hooks/useSortableData';
import DifaCheckbox from '../../../../common/checkbox/DifaCheckbox';
import { setWellInstallation } from '../../../difaPacketBuilderSlice';

interface OptimizationTicket {
  ticketId: string;
  ticketDate: string;
  lastUpdated: string;
  assignee: string;
  subject: string;
}

const OptimizeTicket: React.FC<{ searchQuery: string; source: string }> = ({
  searchQuery,
  source,
}) => {
  const [showTicket, setShowTicket] = useState(false);
  const dispatch = useDispatch();
  const { wellInstallation, preSelectedOptimizationTickets } = useSelector(
      (state: any) => state.difaPacketBuilder
    );

  useEffect(() => {
    const matchingTickets = optimizationTickets.filter((ticket: any) =>
      preSelectedOptimizationTickets?.some(
        (preSelected: any) => preSelected.TicketID === ticket.ticketId
      )
    );
    preSelectedOptimizationTickets && setSelectedTickets(matchingTickets)
      
    }, []);
  
  const { optimizationTickets, loading } = useSelector(
    (state: any) => state.preInspectionSummary
  );
  
  const isPreview = source === 'preview-packet' || source === 'preview-report';

  const {
    items: sortedDifaTrackings,
    requestSort,
    sortConfig,
  } = useSortableData<OptimizationTicket>(optimizationTickets);

  const filteredDifaTickets =
    sortedDifaTrackings &&
    sortedDifaTrackings.filter(
      (item) =>
        item?.ticketId && item?.ticketId.toString().includes(searchQuery)
    );

  const [selectedTicket, setSelectedTicket] = useState<any>(null);
  const [selectedTickets, setSelectedTickets] = useState<OptimizationTicket[]>(
    wellInstallation?.opTickets || []
  );

  function openTicket(index: number): void {
    const ticket = Array.isArray(filteredDifaTickets)
      ? filteredDifaTickets[index]
      : null;
    setSelectedTicket(ticket);
    setShowTicket(true);
  }

  function selectTicket(e: ChangeEvent<HTMLInputElement>, index: number): void {
    const ticket = Array.isArray(filteredDifaTickets)
      ? filteredDifaTickets[index]
      : null;
    if (ticket) {
      if (e.target.checked) {
        const ticketsSelected = [...selectedTickets, ticket];
        setSelectedTickets(ticketsSelected);
        dispatch(
          setWellInstallation({
            ...wellInstallation,
            opTickets: ticketsSelected,
          })
        );
      } else {
        const ticketsSelected = selectedTickets.filter(
          (x: any) => x.ticketId !== ticket.ticketId
        );
        setSelectedTickets(ticketsSelected);
        dispatch(
          setWellInstallation({
            ...wellInstallation,
            opTickets: ticketsSelected,
          })
        );
      }
    }
  }

  const ticketstoDisplay = !isPreview
    ? filteredDifaTickets
    : filteredDifaTickets?.filter((a: any) => {
      return selectedTickets?.find((x: any) => x.ticketId === a.ticketId);
    });

  return (
    <div className="table-main relative border border-dark overflow-auto shadow-md sm:rounded-lg max-h-80">
      <table className="w-full text-left">
        <thead className="text-xs font-semibold top-0 sticky text-neutral-grey border-b border-dark">
          <tr className="table-row">
          {!isPreview && (
            <th scope="col" className="px-3 py-4">
              <DifaCheckbox onChange={(e: ChangeEvent<HTMLInputElement>) => { }} />
            </th>
          )}
            <th scope="col" className="px-3 py-4">
              <div className="flex items-center whitespace-nowrap">
                Ticket #
                <div
                  className='cursor-pointer'
                  onClick={() => requestSort('ticketId')}
                >
                  {sortConfig?.key === 'ticketId' &&
                    sortConfig.direction === 'asc' ? (
                    <UpArrow className='ms-1.5' />
                  ) : (
                    <DownArrow className='ms-1.5' />
                  )}
                </div>
              </div>
            </th>
            <th scope='col' className='px-3 py-4'>
              <div className='flex items-center'>
                Requested
                <div
                  className='cursor-pointer'
                  onClick={() => requestSort('ticketDate')}
                >
                  {sortConfig?.key === 'ticketDate' &&
                    sortConfig.direction === 'asc' ? (
                    <UpArrow className='ms-1.5' />
                  ) : (
                    <DownArrow className='ms-1.5' />
                  )}
                </div>
              </div>
            </th>
            <th scope='col' className='px-3 py-4'>
              <div className='flex items-center'>
                Last updated
                <div
                  className='cursor-pointer'
                  onClick={() => requestSort('lastUpdated')}
                >
                  {sortConfig?.key === 'lastUpdated' &&
                    sortConfig.direction === 'asc' ? (
                    <UpArrow className='ms-1.5' />
                  ) : (
                    <DownArrow className='ms-1.5' />
                  )}
                </div>
              </div>
            </th>
            <th scope='col' className='px-3 py-4'>
              <div className='flex items-center'>Assignee</div>
            </th>
            <th scope='col' className='px-3 py-4'>
              <div className='flex items-center'>Subject</div>
            </th>
          </tr>
        </thead>
        <tbody className='text-sm font-normal text-neutral-grey'>
          {loading ? (
            <tr className='table-row'>
              <td colSpan={5} className='text-lg text-center py-5'>
                Loading...
              </td>
            </tr>
          ) : ticketstoDisplay && ticketstoDisplay?.length > 0 ? (
            ticketstoDisplay.map((optimizeTicket: any, index: number) => (
              <tr key={index} className='border-b border-dark'>
                {!isPreview && (
                  <th scope='row' className='px-3 py-4'>
                    <DifaCheckbox
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        selectTicket(e, index);
                      }}
                      checked={
                        !!selectedTickets.find(
                          (x: any) => x.ticketId === optimizeTicket.ticketId
                        )
                      }
                    />
                  </th>
                )}
                <td
                  className='px-3 py-3 text-Indigo whitespace-nowrap hover:underline cursor-pointer'
                  onClick={() => openTicket(index)}
                >
                  {optimizeTicket?.ticketId}
                </td>
                <td className='px-3 py-3'>
                  {formatDateInNumbers(optimizeTicket?.ticketDate)}
                </td>
                <td className='px-3 py-3'>
                  {formatDateInNumbers(optimizeTicket?.lastUpdated)}
                </td>
                <td className='px-3 py-3'>
                  <div className='flex items-center gap-2'>
                    <span className='assignee-name'>
                      {getFirstLastEngineerLetter(optimizeTicket?.assignee)}
                    </span>
                    {optimizeTicket?.assignee}
                  </div>
                </td>
                <td className='px-3 py-3 w-2/5'>
                  {optimizeTicket?.subject?.slice(0, 50)}....
                </td>
              </tr>
            ))
          ) : (
            <tr className='table-row'>
              <td colSpan={5} className='text-lg text-center py-5'>
                No data found
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {showTicket && selectedTicket && (
        <TicketView
          open={showTicket}
          onCloseModal={() => setShowTicket(false)}
          ticketData={selectedTicket}
        />
      )}
    </div>
  );
};

export default OptimizeTicket;
