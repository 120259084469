import React from "react";
import Header from "../DifaPages/components/Header";

const WellDetails: React.FC = () => {
  return (
    <div className="well-details">
       <Header headerName="Well Details" />
    </div>
  )
}

export default WellDetails
