import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const CauseOfFailurePreview = () => {
    const { causeOfFailure } = useSelector(
        (state: any) => state?.difaPacketBuilder
    )
    const [recommendations, setRecommendations] = useState([]);
    useEffect(() => {
        const persistedRecommendations = localStorage.getItem("recommendations");

        // Safely parse only if persistedRecommendations is not null
        if (persistedRecommendations) {
            setRecommendations(JSON.parse(persistedRecommendations));
        } else {
            setRecommendations([]); // Set an empty array if null
        }
    }, []);

    return (
        <div className="flex flex-col gap-6 text-neutral-grey">
            <div className="flex flex-col md:flex-row justify-between gap-6">
                <div className="flex flex-col difa-widget-containers px-5 py-[18px] gap-5">
                    <div className="pb-3 border-bottom">
                        <h3 className="font-semibold text-lg">Failed item and primary failed component</h3>
                    </div>
                    <div className="flex flex-col gap-3 pt-2">
                        <div className="flex justify-between items-center px-4 pb-3 border-bottom">
                            <label className="text-sm  flex-1">Failed item component</label>
                            <label className="text-sm ">{causeOfFailure?.primaryFailedItems}</label>
                        </div>
                        <div className="flex justify-between items-center px-4 pb-3 border-bottom">
                            <label className="text-sm  flex-1">Failed item sub-component</label>
                            <label className="text-sm"> {causeOfFailure.primaryFailedSubComponents}</label>
                        </div>
                        <div className="flex justify-between items-center px-4 pb-3 border-bottom">
                            <label className="text-sm  flex-1">Failure category</label>
                            <label className="text-sm">{causeOfFailure?.selectedFailedCategory}</label>
                        </div>
                        <div className="flex justify-between items-center px-4 pb-3 border-bottom">
                            <label className="text-sm  flex-1">Failure descriptor</label>
                            <label className="text-sm ">{causeOfFailure.failedDescriptors}</label>
                        </div>
                        <div className="flex justify-between items-center px-4 pb-3 border-bottom">
                            <label className="text-sm  flex-1">Primary failed component</label>
                            <label className="text-sm">{causeOfFailure?.primaryFailedItems2}</label>
                        </div>
                        <div className="flex justify-between items-center px-4 pb-3 border-bottom">
                            <label className="text-sm  flex-1">Primary failed sub-component</label>
                            <label className="text-sm ">{causeOfFailure?.primaryFailedSubComponents2}</label>
                        </div>
                        <div className="flex justify-between items-center px-4 pb-3 border-bottom">
                            <label className="text-sm  flex-1">Failure category</label>
                            <label className="text-sm">{causeOfFailure?.selectedFailedCategory2}</label>
                        </div>
                        <div className="flex justify-between items-center px-4 pb-3 border-bottom">
                            <label className="text-sm  flex-1">Failure descriptors</label>
                            <label className="text-sm ">{causeOfFailure?.failedDescriptors2}</label>
                        </div>
                        <div className="flex justify-between items-center px-4 pb-3 border-bottom">
                            <label className="text-sm flex-1">Failure cause general</label>
                            <label className="text-sm">{causeOfFailure?.genericCauseOfFailure}</label>
                        </div>
                        <div className="flex justify-between items-center px-4 pb-3 border-bottom">
                            <label className="text-sm  flex-1">Failure cause specific</label>
                            <label className="text-sm">{causeOfFailure?.specificCauseOfFailure}</label>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col gap-5 difa-widget-containers px-5 py-[18px]">
                    <div className="pb-3 border-bottom">
                        <h3 className="font-semibold text-lg">Failure cause summary</h3>
                    </div>
                    <p className="text-sm text-grey-100">{causeOfFailure?.failureSummary?.trim() ? causeOfFailure?.failureSummary : "No summary provided"}</p>
                </div>
            </div>
            <div className="flex flex-col gap-5 difa-widget-containers px-5 py-[18px]">
                <div className="pb-3 border-bottom">
                    <h3 className="font-semibold text-lg">Recommendations</h3>
                </div>
                <div className="flex flex-col gap-4">
                    {recommendations.length > 0 ? (
                        recommendations.map((rec, index) => (
                            <p className="p-4 border border-dark text-grey-200 text-sm rounded-lg" key={index}>{rec}</p>
                        ))
                    ) : (
                        <p>No recommendations available.</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default CauseOfFailurePreview;
