import React, { useEffect, useState } from "react";
import {
  AlarmHistoryParams,
  AlarmHistoryProps,
} from "../../models/PreInpectionSummary";
import {
  fetchFrequentAlarmReport,
  getAlarmHistoryData,
} from "../PreInspectionSummarySlice";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../common/loader/Loader";
import "./AlarmHistory.scss";
import AlarmEventsChart from "../../difaPacketBuilder/components/installWellOperation/installWellOperationCharts/AlarmEventsChart";
import AlarmEventsList from "../../difaPacketBuilder/components/installWellOperation/installWellOperationCharts/AlarmEventsList";

const AlarmHistory: React.FC<AlarmHistoryProps> = ({ wellApiNumber }) => {
  const dispatch = useDispatch();
  const [showAlarmData, setShowAlarmData] = useState(false);
  const { alarmHistoryEvent, frequentHistoryAlarm, loading } = useSelector(
    (state: any) => state?.preInspectionSummary
  );

  const alarmHistoryOption = [
    { name: "All alarm events", value: "All" },
    { name: "Shutdowns", value: "Shutdown" },
    { name: "Idle", value: "Idle" },
  ];

  const handleAlarmList = () => {
    setShowAlarmData(true);
  }

  const getMostfrequestAlarm = () => {
    const topFrequentAlarm = frequentHistoryAlarm?.length && frequentHistoryAlarm.slice(0, 10);
    const highestEvent = topFrequentAlarm.reduce((max: { eventCount: number; eventType: string }, event: { eventCount: number; eventType: string }) => event.eventCount > max.eventCount ? event : max, topFrequentAlarm[0]);
    return highestEvent?.eventType;
  }

  useEffect(() => {
    const alarmHistoryParams: AlarmHistoryParams = {
      wellApiNumber: wellApiNumber,
      eventType: "All",
      sortOrder: "asc",
    };
    if (wellApiNumber) {
      dispatch(getAlarmHistoryData(alarmHistoryParams));
      dispatch(
        fetchFrequentAlarmReport({ wellApiNumber, eventType: "All" })
      );
    }
  }, [dispatch, wellApiNumber]);

  return (
    <div className="alarm-history flex flex-col rounded-lg">
      <div className="alarm-history-header flex items-center gap-3 justify-between px-6 py-5">
        <label className="text-lg font-semibold">Alarm history</label>
        <button className="btn-secondary font-bold text-sm" onClick={handleAlarmList}>View alarm event list</button>
      </div>
      {loading && <Loader isOverlay />}
      <div className="flex flex-col gap-5 py-5 px-6 border-t border-dark">
        <div className="flex flex-col md:flex-row gap-4 justify-between">
          <div className="flex flex-col gap-2 w-full md:w-1/2 p-4 bg-dark-700 justify-center rounded-lg border border-dark">
            <label className="font-medium text-2xl">{Object.keys(alarmHistoryEvent)?.length > 0 ? alarmHistoryEvent?.count : 0}</label>
            <label className="font-normal text-base">Total alarms</label>
          </div>

          <div className="flex flex-col gap-2 w-full md:w-1/2 p-4 bg-dark-700 justify-center rounded-lg border border-dark">
            <label className="font-medium text-2xl">{frequentHistoryAlarm?.length ? getMostfrequestAlarm().split(",")[1]?.trim() : "No data Found"}</label>
            <label className="font-normal text-base">Most frequent alarm</label>
          </div>
        </div>
        {frequentHistoryAlarm?.length ?
          <AlarmEventsChart chartData={frequentHistoryAlarm.slice(0, 10)} id="alarmHistoryEventChart" />
          : <div className="text-lg text-center py-5">No data found</div>}
        {showAlarmData && alarmHistoryEvent?.alarmEventHistoryDto && (
          <AlarmEventsList open={showAlarmData} onCloseModal={() => setShowAlarmData(false)} AlarmEventData={alarmHistoryEvent?.alarmEventHistoryDto} />
        )}
      </div>
    </div>
  );
};

export default AlarmHistory;
