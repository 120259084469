import React, { useState } from 'react';
import './tab.scss';

interface Tab {
    label: string;
    component: React.ComponentType<any>;
}

interface TabsProps {
    tabs: Tab[];
    assetDetails?: boolean;
}

const Tabs: React.FC<TabsProps> = ({ tabs }) => {
    const [activeTabIndex, setActiveTabIndex] = useState(0);

    return (
        <>
            <div className="tab-section">
                <div className="tabs">
                    {tabs?.map((tab, index) => (
                        <div key={index} className={`tab ${index === activeTabIndex ? 'active' : ''
                            }`} onClick={() => setActiveTabIndex(index)}>
                            {tab.label}
                        </div>
                    ))}
                </div>
            </div>
            <div className="content-section">
                {tabs?.map((tab, index) => {
                    return (
                        <div key={index} className={index === activeTabIndex ? '' : 'tab-inactive' }>
                            {React.createElement(tab?.component)}
                        </div>
                    );
                })}
            </div>
        </>
    );
};

export default Tabs;
