/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */

import { useState } from "react";
import DifaSwitch from "../../common/switch/DifaSwitch";
import infoIcon from "./../../../images/info-circle.svg";
import './WellRunLifeInformation.scss';

import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";

const PublishEditedDifaDialog: React.FC<{ show: boolean, close?: () => void, submit?: (checked:boolean) => void }> = ({
    show = false,
    close = () => { close() },
    submit = (checked:boolean) => { submit(checked) },
}) => {    
    const [isView, setIsView] = useState(false)
    return (
        <Dialog open={show} onClose={() => close()} className="relative z-50">
            <DialogBackdrop
                transition
                className="fixed inset-0 backdrop-blur-sm transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />
            <DialogBackdrop
                transition
                className="fixed inset-0 backdrop-blur-sm transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className="fixed inset-0 z-10 w-screen">
                <div className="flex min-h-full items-end justify-end p-4 text-center sm:items-center sm:p-0 sm:min-w-2/4">
                    <DialogPanel
                        transition
                        className="flex dialog-max-height relative transform overflow-hidden rounded-lg difa-background-with-border text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-sm md:min-w-120 md:w-120  data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95 edit-publish-dialog"
                    >
                        <div className="difa-widget-containers modal-bg">
                            <div className="px-6 w-full flex flex-col overflow-y-auto table-main">
                                <div className="pt-2 h-12 items-center justify-center">
                                    <span className="dialog-content " >Ready to publish?</span>
                                    
                                </div>
                                <div className="flex flex-row justify-between border rounded-lg p-2">
                                    <span className="flex flex-row modal-heading sm:w-3/4 gap-2 dialog-content" >Allow customers to view <img alt="information" src={infoIcon}/></span>
                                    <DifaSwitch className="pt-1" checked={isView} onChange={() => { setIsView(!isView)}} />                   
                                    
                                </div>
                            </div>
                            <div className="flex-row flex gap-3 justify-end items-center h-16 px-6">
                                <div className="primary-button w-full px-6">
                                    <button className="dialog-content" onClick={() => submit(isView)} >Publish now</button>
                                </div>
                            </div>
                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>

    );
}

export default PublishEditedDifaDialog;