import React, { useEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

import { frequentAlarmProp } from "../../../models/PreInpectionSummary";

const FrequentHistoryAlarm: React.FC<frequentAlarmProp> = ({
  id,
  frequentHistoryData,
  selectedOption,
}) => {
  const getByLabelBySelectedOption = (selectedOption: string) => {
    switch (selectedOption) {
      case "Idle":
        return { xLabel: "Idle type", yLabel: "Number of idles" };
      case "Shutdown":
        return { xLabel: "Shutdown type", yLabel: "Number of shutdowns" };
      default:
        return { xLabel: "Event type", yLabel: "Alarm events" };
    }
  };

  const alarmChartXYLabels = {
    xLabel: getByLabelBySelectedOption(selectedOption)?.xLabel,
    yLabel: getByLabelBySelectedOption(selectedOption)?.yLabel,
  };

  useEffect(() => {
    const root = am5.Root.new(id);
    root.setThemes([am5themes_Animated.new(root)]);

    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: true,
        panY: true,
        pinchZoomX: true,
      })
    );

    const xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "eventType",
        maxDeviation: 0.2,
        renderer: am5xy.AxisRendererX.new(root, {
            minGridDistance: 20, // Reduce minGridDistance to show more labels
            cellStartLocation: 0.1,
        }),
      })
    );

    xAxis.children.push(
      am5.Label.new(root, {
        fill: am5.color(0xf7f9f9),
        text: alarmChartXYLabels?.xLabel,
        x: am5.percent(50),
        centerX: am5.p50,
      })
    );

    xAxis.get("renderer").grid.template.set("visible", false);
    xAxis.get("renderer").labels.template.setAll({
      fill: am5.color(0xf7f9f9),
      rotation: -90,
      centerY: am5.p50,
      centerX: am5.p100,
      text: "{eventName}",
    });

    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {
          stroke: am5.color(0xf7f9f9),
        }),
      })
    );

    yAxis.get("renderer").grid.template.set("visible", true);
    yAxis.get("renderer").grid.template.set("stroke", am5.color("#F7F9F9"));
    yAxis.get("renderer").labels.template.setAll({
      fill: am5.color(0xf7f9f9),
    });

    yAxis.children.unshift(
      am5.Label.new(root, {
        fill: am5.color(0xf7f9f9),
        rotation: -90,
        text: alarmChartXYLabels?.yLabel,
        y: am5.percent(50),
        centerX: am5.p50,
      })
    );

    const series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: "Event Count",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "eventCount",
        categoryXField: "eventType",
      })
    );

    series.bullets.push(function (root) {
      return am5.Bullet.new(root, {
        locationX: 0.8,
        locationY: 1,
        sprite: am5.Label.new(root, {
          text: "{valueY}",
          fill: am5.color("#F7F9F9"),
          centerX: am5.percent(100),
          centerY: am5.percent(100),
          populateText: true,
        }),
      });
    });

    // Define color mapping
    const colorMapping: { [key: string]: am5.Color } = {
      Idle: am5.color(0x006dfa), // Blue
      Shutdown: am5.color(0xf04438), // Red
    };

    series.columns.template.adapters.add("fill", (fill, target) => {
      const eventType = (target.dataItem?.dataContext as { eventType: string })
        .eventType;
      if (eventType) {
        const key = eventType.split(",")[0].trim();
        return colorMapping[key] || fill;
      }
      return fill;
    });

    series.columns.template.adapters.add("stroke", (stroke, target) => {
      const eventType = (target.dataItem?.dataContext as { eventType: string })
        .eventType;
      if (eventType) {
        const key = eventType.split(",")[0].trim();
        return colorMapping[key] || stroke;
      }
      return stroke;
    });

    let legend;
    if (selectedOption === "All") {
      legend = chart.children.push(
        am5.Legend.new(root, {
          nameField: "name",
          centerX: am5.percent(30),
          fillField: "color",
          strokeField: "color",
          x: am5.percent(50),
          y: am5.percent(100),
          dy: -20,
          paddingTop: 15,
        })
      );

      legend.markerRectangles.template.setAll({
        cornerRadiusTL: 10,
        cornerRadiusTR: 10,
        cornerRadiusBL: 10,
        cornerRadiusBR: 10,
        width: 10,
        height: 10,
      });

      legend.data.setAll([
        {
          name: "Shutdown",
          color: am5.color(0xf04438),
        },
        {
          name: "Idle",
          color: am5.color(0x006dfa),
        },
      ]);

      legend.labels.template.setAll({
        fill: am5.color(0xffffff),
        paddingTop: -8,
        textAlign: "right",
      });
    }

    // Sort data in descending order based on eventCount
    const sortedData = [...frequentHistoryData].sort(
      (a, b) => b.eventCount - a.eventCount
    );

    const chartData = sortedData.map((data) => ({
      ...data,
      eventName: data.eventType.split(",")[1].trim(),
    }));

    xAxis.data.setAll(chartData);
    series.data.setAll(chartData);

    return () => {
      root.dispose();
    };
  }, [id, frequentHistoryData]);

  return <div id={id} style={{ width: "100%", height: "500px" }}></div>;
};

export default FrequentHistoryAlarm;
