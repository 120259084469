import instance from "../../config/HttpCommon";
import { AlarmHistoryParams } from "../models/PreInpectionSummary";

export const getAlarmHistory = async (
  alarmHistoryParams: AlarmHistoryParams
) => {
  const alarmHistoryRes = await instance.get(
    `Difa/GetAlarmsHistory?wellApiNumber=${
      alarmHistoryParams?.wellApiNumber
    }&EventType=${alarmHistoryParams?.eventType}&pageNumber=${
      alarmHistoryParams?.pageNumber ?? 0
    }&pageSize=${alarmHistoryParams?.pageSize ?? 1000}&sortOrder=${
      alarmHistoryParams?.sortOrder
    }`
  );
  return alarmHistoryRes?.data;
};

export const getFreuentAlarmReport = async (wellApiNumber: string, eventType: string) => {
  const FrequentAlarmReportRes =
    await instance.get(`/Difa/GetFreequentHistoricalAlarms?wellApiNumber=${
      wellApiNumber}&eventType=${eventType}
 `);
  return FrequentAlarmReportRes?.data;
}

export const getDifaRundaysDetails = async (wellApiNumber: string) => {
  const DifaRundaysListRes = await instance.get(
    `/Difa/GetDifaTrackingDetails?wellApiNumber=${wellApiNumber}`
  );
  return DifaRundaysListRes?.data;
};

export const getOptimizationTickets = async (wellName: string) => {
  const DifaRundaysListRes = await instance.get(
    `/Difa/GetTickets?wellName=${wellName}`
  );
  return DifaRundaysListRes?.data;
};

export const getInstallReport = async (wellApiNumber: string) => {
  const InstallReportRes =
    await instance.get(`/Reports/GetInstallReportDetails?wellApiNumber=${wellApiNumber}
`);
  return InstallReportRes?.data;
};

export const getStartupReport = async (wellApiNumber: string) => {
  const StartupReportRes =
    await instance.get(`/Reports/GetStartupReports?wellApiNumber=${wellApiNumber}
 `);
  return StartupReportRes?.data;
};

export const getServiceReport = async (wellApiNumber: string) => {
  const ServiceReportRes =
    await instance.get(`/Reports/GetServiceReportDetails?wellApiNumber=${wellApiNumber}

 `);
  return ServiceReportRes?.data;
};

export const getPullReport = async (wellApiNumber: string) => {
  const ServiceReportRes =
    await instance.get(`/Reports/GetPullReportDetails?wellApiNumber=${wellApiNumber}
`);
  return ServiceReportRes?.data;
};

export const getPacketStatus = async (wellApiNumber: string, difaId: string) => {
  const difaPacketStatus = await instance.post(
      `/Difa/GetDifaPacketStatus?wellApiNumber=${wellApiNumber}&difaID=${difaId}`
  );
  return difaPacketStatus?.data;
};
export const GetPreInspectionSummary = async (dispatchId: string) => {
  const ServiceReportRes =
    await instance.get(`/Difa/GetPreInspectionSummary?dispatchId=${dispatchId}
`);
  return ServiceReportRes?.data;
};

export const PostSummaryFeedback = async (PreinspectionsummaryProps: any) => {
  return await instance.post(
      `/Difa/PostSummaryFeedback`, PreinspectionsummaryProps
  );
};
