import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import React, { useLayoutEffect, useRef } from "react";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import "./RundaysChart.scss";
import Helpers from "../../../utilities/Helpers";

interface XYChartProps {
  id: string;
  height?: string;
  data: any;
  customerName?: string;
  wellName?: string;
}

const XYChartComponent: React.FC<XYChartProps> = ({ id, height, data, customerName, wellName, }) => {
  const chartRef = useRef<HTMLDivElement | null>(null);
  const rootRef = useRef<am5.Root | null>(null);

  useLayoutEffect(() => {
    if (chartRef.current) {
      const root = am5.Root.new(chartRef.current);
      rootRef.current = root;
      am5.addLicense(process.env.REACT_APP_AMCHARTS_LICENSE_KEY!);

      root.setThemes([am5themes_Animated.new(root)]);

      let chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          panX: false,
          panY: false,
          paddingLeft: 0,
          paddingTop: 25,
          layout: root.verticalLayout,
        })
      );
      const cursor = chart.set(
        "cursor",
        am5xy.XYCursor.new(root, {
          behavior: "none",
        })
      );
      cursor.lineY.set("visible", true);

      const xAxis = chart.xAxes.push(
        am5xy.CategoryAxis.new(root, {
          categoryField: "difaId",
          maxDeviation: 0.2,
          renderer: am5xy.AxisRendererX.new(root, {
            minGridDistance: 30,
          }),
        })
      );

      xAxis.children.push(
        am5.Label.new(root, {
          fill: am5.color(0xf7f9f9),
          text: "Quote ID number for ESP runs",
          x: am5.percent(45),
          centerX: am5.p50,
          fontFamily: 'Mulish',
          fontWeight: '400',
          fontSize: '14px',
          textAlign: 'center'
        })
      );
      xAxis.get("renderer").grid.template.set("visible", false);
      xAxis.get("renderer").grid.template.setAll({
        fill: am5.color("#4A5463"),
      });

      xAxis.get("renderer").labels.template.setAll({
        fill: am5.color(0xffffff),
        rotation: -90,
        centerY: am5.p50,
        centerX: am5.p100,
        fontFamily: 'Mulish',
        fontWeight: '400',
        fontSize: '12px',
        textAlign: 'center',

      });

      const yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: am5xy.AxisRendererY.new(root, {}),
        })
      );
      yAxis.get("renderer").grid.template.set("visible", true);
      yAxis.get("renderer").grid.template.set("stroke", am5.color("#F7F9F9"));
      yAxis.get("renderer").labels.template.setAll({
        fill: am5.color(0xffffff),
        fontFamily: 'Mulish',
        fontWeight: '400',
        fontSize: '12px',
        textAlign: 'center'

      });
      yAxis.children.unshift(
        am5.Label.new(root, {
          fill: am5.color(0xf7f9f9),
          rotation: -90,
          text: "Rundays",
          y: am5.percent(50),
          centerX: am5.p50,
          fontFamily: 'Mulish',
          fontWeight: '400',
          fontSize: '14px',
          textAlign: 'center'
        })
      );

      const series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: "Series",
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: "rundaysToPullStartDate",
          categoryXField: "difaId",
          maskBullets: false,
          tooltip: am5.Tooltip.new(root, {
            getFillFromSprite: false,
            autoTextColor: false,
            getStrokeFromSprite: true,
            keepTargetHover: false,
            showTooltipOn: "hover",
            pointerOrientation: "up",
          }),
        })
      );

      series.columns.template.adapters.add("fill", (fill, target: any) => {
        const { action } = target.dataItem.dataContext;
        return am5.color(action === "Dismantled" ? "#6BBBAE" : "#00789E");
      });
      series.columns.template.setAll({
        strokeOpacity: 0,
        width: am5.percent(35),
        tooltipText: "", // Disable default text
        tooltipY: 0, // Position tooltip above the bar
      });

      series.bullets.push(function (root) {
        return am5.Bullet.new(root, {
          locationX: 0.5,
          locationY: 1,
          sprite: am5.Label.new(root, {
            text: "{valueY}",
            fill: am5.color("#F7F9F9"),
            centerX: am5.percent(50),
            centerY: am5.percent(90),
            populateText: true,
            fontFamily: 'Mulish',
            fontWeight: '400',
            fontSize: '10px',
            textAlign: 'center',
          }),
        });
      });

      const cursorMoved = () => {
        chart.series.each(function (series) {
          const tooltipDataItem = series.get("tooltipDataItem");
          if (tooltipDataItem) {
            let show = true;
            if (show) {
              const dataContext: any = tooltipDataItem.dataContext;
              if (dataContext != null) {
                // Ensure valueX is not null or undefined
                let html = `
                <div style="background-color: #0e1b28;  
                    z-index: 10000;
                    color: #F4FBFC; 
                    text-align: left !important;
                    width: 400px;    
                    padding-bottom:5px;
                    box-shadow: none;  
                    border-radius: 4px; 
                    font-family: Mulish;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 18px;
                    letter-spacing: 0%;
                  ">
                  <div style="
                    padding-left: 15px;  
                    border-bottom: 1px solid  #4A5463;
                    text-align: -webkit-left !important;
                    align-content: flex-start !important;
                    align-self: self-start !important;
                    padding-bottom: 5px;padding-top: 5px;">DIFA ID # ${dataContext.difaId}</div>
                  `;
                if (dataContext.action === "Dismantled") {
                  html += `
                  <div style=" 
                    display: flex;
                    padding-left: 15px;
                    justify-content: left;
                    align-content: flex-start;
                    margin-bottom: 5px;
                    ">
                    <div style="width: 50%;">Quote ID</div>
                    <div style="text-align: left; left:0px;"                 
                    >${dataContext.difaId}</div>
                  </div>
                  
                  
                  <div style=" 
                    display: flex;
                    padding-left: 15px;
                    justify-content: left;
                    align-content: flex-start;
                    margin-bottom: 5px;
                    ">
                    <div style="width: 50%;">Run #</div>
                    <div style="text-align: left; left:0px;"                 
                    >${dataContext.rundaysToPullStartDate}</div>
                  </div>
                  
                  <div style=" display: flex;
                    padding-left: 15px;
                    justify-content: left;
                    align-content: flex-start;
                    word-wrap: break-word;
                    margin-bottom: 5px;">
                    <div style="width: 50%;">Pull reason</div>
                    <div style="text-align: left !important;
                    gap: 0px;opacity: 0px;
                    ">${Helpers.markNullValues(dataContext.pullReason)}</div>
                  </div>
                  <div style=" display: flex;
                    align-content: flex-start;
                      padding-left: 15px;
                      justify-content: left;
                      color:#F7F9F9;
                      margin-bottom: 5px;">
                      <div style="width: 50%;">Primary failed component</div>
                      <div style="text-align: left !important;
                    ">${Helpers.markNullValues(
                    dataContext.primaryFailedComponent
                  )}</div>
                    </div>
                    
                    <div style=" display: flex;
                    padding-left: 15px;
                    align-content: flex-start;
                    justify-content: left;
                    margin-bottom: 5px;">
                    <div style="width: 50%;">Primary failed sub-component</div>
                    <div style="text-align: left!important;
                    ">${Helpers.markNullValues(
                    dataContext.primaryFailedSubComponent
                  )}</div>
                    </div>
                    
                    <div style=" display: flex;
                    padding-left: 15px;
                    align-content: flex-start;
                    justify-content: left;
                    margin-bottom: 5px;">
                    <div style="width: 50%;">Failure descriptor</div>
                    <div style="text-align: -webkit-left!important;
                    ">${Helpers.markNullValues(
                    dataContext.failureDescriptors
                  )}</div>
                    </div>
                    
                    <div style=" display: flex;
                    padding-left: 15px;
                    align-content: flex-start;
                    justify-content: left;
                    margin-bottom: 5px;">
                    <div style="width: 50%;">Failure cause</div>
                    <div style="text-align: -webkit-left!important;
                    ">${Helpers.markNullValues(
                    dataContext.failureCauseGeneral
                  )}</div>
                    </div>
                    <div style=" display: flex;
                    padding-left: 15px;
                    justify-content: left;
                    align-content: flex-start;
                    margin-bottom: 5px;">
                    <div style="width: 50%;">Primary pump</div>
                    <div style="text-align: left !important;
                    ">${Helpers.markNullValues(dataContext.primaryPump)}</div>
                  </div>
                  
                  </div>`;
                } else {
                  html += `<div style=" 
                    display: flex;
                    padding-left: 15px;
                    justify-content: left;
                    align-content: flex-start;
                    margin-bottom: 5px;
                    ">
                    <div style="width: 50%;">Quote ID</div>
                    <div style="text-align: left; left:0px;"                 
                    >${dataContext.difaId}</div>
                  </div>
                  <div style=" 
                    display: flex;
                    padding-left: 15px;
                    justify-content: left;
                    align-content: flex-start;
                    margin-bottom: 5px;
                    ">
                    <div style="width: 50%;">DIFA preformed</div>
                    <div style="text-align: left; left:0px;"                 
                >No DIFA needed</div></div>
                <div style=" 
                    display: flex;
                    padding-left: 15px;
                    justify-content: left;
                    align-content: flex-start;
                    margin-bottom: 5px;
                    ">
                    <div style="width: 50%;">Run #</div>
                    <div style="text-align: left; left:0px;"                 
                    >${dataContext.rundaysToPullStartDate}</div>
                  </div>
                  <div style=" display: flex;
                    padding-left: 15px;
                    justify-content: left;
                    align-content: flex-start;
                    word-wrap: break-word;
                    margin-bottom: 5px;">
                    <div style="width: 50%;">Pull reason</div>
                    <div style="text-align: left !important;
                    gap: 0px;opacity: 0px;
                    ">${Helpers.markNullValues(dataContext.pullReason)}</div>
                  </div>
                  <div style=" display: flex;
                    padding-left: 15px;
                    justify-content: left;
                    align-content: flex-start;
                    margin-bottom: 5px;">
                    <div style="width: 50%;">Primary pump</div>
                    <div style="text-align: left !important;
                    ">${Helpers.markNullValues(dataContext.primaryPump)}</div>
                  </div>`
                }

                // series.set("tooltipHTML", html);
                series.get("tooltip")?.set("html", html);
              } else {
                series.get("tooltip")?.hideTooltip();
              }
            }
          } else {
            series.get("tooltip")?.hideTooltip();
          }
        });
      };

      cursor.events.on("cursormoved", cursorMoved);
      xAxis.data.setAll(data);
      series.data.setAll(data);
      series
        .get("tooltip")
        ?.get("background")
        ?.setAll({
          fillOpacity: 0, // Remove the default background
          strokeOpacity: 0, // Remove the default border
          strokeWidth: 0,
          fill: am5.color("#0e1b28"),
        });
      var legend = chart.children.push(
        am5.Legend.new(root, {
          nameField: "name",
          centerX: am5.percent(20),
          fillField: "color",
          strokeField: "color",
          x: am5.percent(40),
        })
      );

      legend.markerRectangles.template.setAll({
        cornerRadiusTL: 10,
        cornerRadiusTR: 10,
        cornerRadiusBL: 10,
        cornerRadiusBR: 10,
        width: 10,
        height: 10,
      });
      legend.data.setAll([
        {
          name: "DIFA",
          color: am5.color("#6BBBAE"),
        },
        {
          name: "No DIFA",
          color: am5.color("#00789E"),
        },
      ]);
      legend.labels.template.setAll({
        fill: am5.color(0xffffff),
        paddingTop: -8,
        textAlign: "right",
        fontFamily: 'Mulish',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: '400',
      });

      series.appear(1000);
      chart.appear(1000, 100);
    }

    return () => {
      rootRef.current?.dispose();
      rootRef.current = null;
    };
  }, [data]);

  return (
    <div
      id={id}
      ref={chartRef}
      style={{ width: "100%", height: height || "300px", position: "relative" }} // Use height prop if provided
    />
  );
};

export default XYChartComponent;
