import { useState } from 'react'
import { Toast } from '../models/DismantleRequest';

const useToaster = () => {

  const [toast, setToast] = useState<Toast | null>(null);

  const addToast = (type: "success" | "error", message: string) => {
    setToast({ type, message });

    setTimeout(() => { 
      setToast(null) 
    }, 5000);
  }

  const onClose = () => {
    setToast(null);
  }

  return { toast, addToast, onClose };
}

export default useToaster
