import React from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ArrowRightIcon } from "../../../images/chevron-right.svg";
import { HeaderProps } from "../../DifaPages/components/HeaderProps";
import TokenService from "../../../services/authentication/token.service";

const Header: React.FC<HeaderProps> = ({ headerName }) => {
  const navigate = useNavigate();
  const userRole =TokenService.getUserRole();

  const dashboard = () => {
    userRole && userRole === "Manager" ? navigate("/manager-dashboard") : navigate("/");
  };

 

  return (
    <header className="main-header">
      <div className="header-content">
        <div className="breadcrumb-container">
          <div className="breadcrumb-item" onClick={dashboard}>
            Dashboard
          </div>
          <div className="breadcrumb-separator">
            <ArrowRightIcon />
          </div>
          <div className="breadcrumb-item active">{headerName}</div>
        </div>
        <div className="header-details">
          <div className="header-details-content">
            <div className="header-title">{headerName}</div>
          </div>
        </div>
      </div>
      <div className="divider"></div>
    </header>
  );
};

export default Header;
