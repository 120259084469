import React, { useState } from "react";
import { useSelector } from "react-redux";
import { ReactComponent as DownArrow } from "../../../../images/chevron-down.svg";
import { ReactComponent as ArrowUpIcon } from "../../../../images/arrow-narrow-up.svg";
import { ReactComponent as ArrowDownIcon } from "../../../../images/arrow-down.svg";
import { formatDateInNumbers, formatPullReason } from "../../../common/services/UtilityService";

const PriorRuns: React.FC<{ searchQuery: string }> = ({ searchQuery }) => {
  const { difaRundaysDetails = [], loading } = useSelector(
    (state: any) => state.preInspectionSummary
  );

  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("desc");

  const handleSort = () => {
    setSortOrder(sortOrder === "desc" ? "asc" : "desc");
  };

  const sortedDifaTrackings = Array.isArray(difaRundaysDetails)
    ? [...difaRundaysDetails].sort((a, b) => {
      const dateA = new Date(a.difaFailedDate).getTime();
      const dateB = new Date(b.difaFailedDate).getTime();
      return sortOrder === "desc" ? dateB - dateA : dateA - dateB;
    })
    : [];

  const filteredDifaTrackings = sortedDifaTrackings.filter((item) =>
    item?.difaId.includes(searchQuery)
  );

  return (
    <div className="table-main relative border border-dark overflow-auto shadow-md sm:rounded-lg max-h-80">
      <table className="w-full text-sm text-left">
        <thead className="text-xs top-0 sticky font-semibold text-neutral-grey border-b border-dark">
          <tr>
            <th scope="col" className="px-3 py-4">
              <div className="flex items-center whitespace-nowrap">
                <span>DIFA ID #</span>
                <div className="cursor-pointer">
                  <DownArrow className="ms-1.5" />
                </div>
              </div>
            </th>
            <th scope="col" className="px-3 py-4 whitespace-nowrap">
              <div className="flex items-center">
                <span>Install date</span>
                <div className="cursor-pointer">
                  <DownArrow className="ms-1.5" />
                </div>
              </div>
            </th>
            <th scope="col" className="px-3 py-4 whitespace-nowrap">
              <div className="flex items-center">
                <span>Failed date</span>
                <div className="flex items-center cursor-pointer">
                  {sortOrder === "desc" ? <ArrowDownIcon onClick={handleSort} className="ms-1.5" /> : <ArrowUpIcon onClick={handleSort} className="ms-1.5" />}
                  <DownArrow className="ms-1.5" />
                </div>
              </div>
            </th>
            <th scope="col" className="px-3 py-4 whitespace-nowrap">
              <div className="flex items-center">
                <span>Pull date</span>
                <div className="cursor-pointer">
                  <DownArrow className="ms-1.5" />
                </div>
              </div>
            </th>
            <th scope="col" className="px-3 py-4">
              <div className="flex items-center">
                <span>Rundays</span>
                <div className="cursor-pointer">
                  <DownArrow className="ms-1.5" />
                </div>
              </div>
            </th>
            <th scope="col" className="px-3 py-4 whitespace-nowrap">
              <div className="flex items-center">
                <span>DIFA scheduled</span>
                <div className="cursor-pointer">
                  <DownArrow className="ms-1.5" />
                </div>
              </div>
            </th>
            <th scope="col" className="px-3 py-4 whitespace-nowrap">
              <div className="flex items-center">
                <span>Pull reason</span>
                <div className="cursor-pointer">
                  <DownArrow className="ms-1.5" />
                </div>
              </div>
            </th>
            <th scope="col" className="px-3 py-4 whitespace-nowrap">
              <div className="flex items-center">
                <span>Failure cause</span>
                <div className="cursor-pointer">
                  <DownArrow className="ms-1.5" />
                </div>
              </div>
            </th>
            <th scope="col" className="px-3 py-4 whitespace-nowrap">
              <div className="flex items-center">
                <span>Failed item</span>
                <div className="cursor-pointer">
                  <DownArrow className="ms-1.5" />
                </div>
              </div>
            </th>
            <th scope="col" className="px-3 py-4 whitespace-nowrap">
              <div className="flex items-center">
                <span>Primary failed component</span>
                <div className="cursor-pointer">
                  <DownArrow className="ms-1.5" />
                </div>
              </div>
            </th>
            <th scope="col" className="px-3 py-4 whitespace-nowrap">
              <div className="flex items-center">
                <span>Failure descriptor</span>
                <div className="cursor-pointer">
                  <DownArrow className="ms-1.5" />
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody className="font-normal text-sm text-neutral-grey">
          {loading ? (
            <tr className="border-b border-dark">
              <td colSpan={12} className="text-lg text-center py-5">
                Loading...
              </td>
            </tr>
          ) : filteredDifaTrackings?.length > 0 ? (
            filteredDifaTrackings.map((difaRundaysDetail: any, index: number) => (
              <tr key={index} className="border-b border-dark">
                <td className=" px-3 py-4">{difaRundaysDetail?.difaId}</td>
                <td className="px-3 py-4">{formatDateInNumbers(difaRundaysDetail?.installDate)}</td>
                <td className="px-3 py-4">{difaRundaysDetail?.difaFailedDate ? formatDateInNumbers(difaRundaysDetail?.difaFailedDate) : "-"}</td>
                <td className="px-3 py-4">{formatDateInNumbers(difaRundaysDetail.pullDate)}</td>
                <td className="px-3 py-4">{difaRundaysDetail?.rundaysToPullStartDate}</td>
                <td className="px-3 py-4">{formatDateInNumbers(difaRundaysDetail?.actionDate)}</td>
                <td className="px-3 py-4 whitespace-nowrap">
                  <div className="flex flex-col">
                    {formatPullReason(difaRundaysDetail?.pullReason)}
                  </div>
                </td>
                <td className="px-3 py-4 whitespace-nowrap">{difaRundaysDetail?.failureCauseGeneral !== "" ? difaRundaysDetail?.failureCauseGeneral : "-"}</td>
                <td className="px-3 py-4 whitespace-nowrap">{difaRundaysDetail?.primaryFailedSubComponent || "-"}</td>
                <td className="px-3 py-4 whitespace-nowrap">{difaRundaysDetail?.primaryFailedComponent !== "" ? difaRundaysDetail?.primaryFailedComponent : "-"}</td>
                <td className="px-3 py-4 whitespace-nowrap">{difaRundaysDetail?.failureDescriptors !== "" ? difaRundaysDetail?.failureDescriptors : "-"}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={12} className="text-lg text-center py-5">
                No data found
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default PriorRuns;