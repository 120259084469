import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getEquipmentAction, saveEquipmentAction } from './DIFAInspectionService';

// Async Thunk for GET API
export const fetchEquipmentAction: any = createAsyncThunk(
    'difaInspection/fetchEquipmentAction',
    async ({ difaId }: { difaId: string }) => {
        const response = await getEquipmentAction(difaId);
        return response;
    }
);

// Async Thunk for POST API
export const postEquipmentAction: any = createAsyncThunk(
    'difaInspection/postEquipmentAction',
    async (
        {
            partType,
            serialNumber,
            equipmentDescription,
            difaAction,
            results,
            comments,
        }: {
            partType: string;
            serialNumber: string;
            equipmentDescription: string;
            difaAction: string;
            results: string;
            comments: string;
        },
        { rejectWithValue }
    ) => {
        try {
            const response = await saveEquipmentAction(
                partType,
                serialNumber,
                equipmentDescription,
                difaAction,
                results,
                comments
            );
            return response;
        } catch (error: any) {
            console.error("Error saving equipment action:", error);
            return rejectWithValue(error.response?.data || error.message);
        }
    }
);

// Define the state interface
interface DIFAInspectionState {
    equipmentAction: any; // State for GET API
    equipmentSaveAction: any; // State for POST API
    loading: boolean;
    message: string | null;
}

// Initial state
const initialState: DIFAInspectionState = {
    equipmentAction: null,
    equipmentSaveAction: null,
    loading: false,
    message: null,
};

// Combined Redux Slice
const DIFAInspectionSlice = createSlice({
    name: 'difaInspection',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // Handle GET API actions
        builder.addCase(fetchEquipmentAction.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(fetchEquipmentAction.fulfilled, (state, action) => {
            state.equipmentAction = action.payload;
            state.loading = false;
        });

        builder.addCase(fetchEquipmentAction.rejected, (state, action) => {
            state.message = action.error.message;
            state.loading = false;
        });

        // Handle POST API actions
        builder.addCase(postEquipmentAction.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(postEquipmentAction.fulfilled, (state, action) => {
            state.equipmentSaveAction = action.payload;
            state.loading = false;
            state.message = null; // Clear any error message
        });

        builder.addCase(postEquipmentAction.rejected, (state, action) => {
            state.message = action.payload || "Failed to save equipment action.";
            state.loading = false;
        });
    },
});

export default DIFAInspectionSlice.reducer;
