import React, { useEffect, useState } from "react";

import "./InstallReport.scss";
import "./SectionsReport.scss";
import closeSlider from "../../../images/close-slider.svg";
import downloadReport from "../../../images/download-report.svg";
import sectionExpand from "../../../images/chevron-down.svg";
import sectionCollapse from "../../../images/chevron-up.svg";
import { useSelector } from "react-redux";
import Helpers from "../../../utilities/Helpers";
import Loader from "../../common/loader/Loader";

interface Toggle {
  open: boolean;
  onCloseModal: any;
}

const AttributeCard: React.FC<any> = ({
  installDate,
  serviceDate,
  serviceTech,
  startupDate,
  modemIP,
  customer,
  wellName,
  wellAPI,
  field,
  county,
  state,
}) => {
  return (
    <div className="attribute-card">
      <h2 className="default-attribute-header">Attributes</h2>
      <div className="attribute-row">
        <span className="label">Install date</span>
        <span className="value">{Helpers.dateFormatter(installDate)}</span>
      </div>
      <div className="attribute-row">
        <span className="label">Service date</span>
        <span className="value">{Helpers.dateFormatter(serviceDate)}</span>
      </div>
      <div className="attribute-row">
        <span className="label">Service tech</span>
        <span className="value">{serviceTech}</span>
      </div>
      <div className="attribute-row">
        <span className="label">Start up date</span>
        <span className="value">{Helpers.dateFormatter(startupDate)}</span>
      </div>
      <div className="attribute-row">
        <span className="label">Modem IP address</span>
        <span className="value">{modemIP}</span>
      </div>
      <div className="attribute-row">
        <span className="label">Customer</span>
        <span className="value">{customer}</span>
      </div>
      <div className="attribute-row">
        <span className="label">Well name</span>
        <span className="value">{wellName}</span>
      </div>
      <div className="attribute-row">
        <span className="label">Well API #</span>
        <span className="value">{wellAPI}</span>
      </div>
      <div className="attribute-row">
        <span className="label">Field</span>
        <span className="value">{field}</span>
      </div>
      <div className="attribute-row">
        <span className="label">County</span>
        <span className="value">{county}</span>
      </div>
      <div className="attribute-row">
        <span className="label">State</span>
        <span className="value">{state}</span>
      </div>
    </div>
  );
};

const ESPDetails: React.FC = () => {
  const { startupReport } = useSelector(
    (state: any) => state.preInspectionSummary
  );
  const details: Record<string, string> = {
    'Controller': startupReport?.controller, // Not found in JSON
    "Controller model": startupReport?.controllerModel,
    "Max output Amps": startupReport?.motorAmps, // Not found in JSON
    "Drive PN": startupReport?.drive, // Not found in JSON
    "Xformer S/N": startupReport?.xformerSn,
    "Xformer PN": startupReport?.xformerPn,
    "Motor HP": startupReport?.motorHp,
    "Motor Volts": startupReport?.motorVolts,
    "Motor Amps": startupReport?.motorAmps,
    "Cable size": startupReport?.cableSize, // Not found in JSON
    "Cable length": startupReport?.cableLength,
    "Downhole temperature": startupReport?.downholetemp,
    "Min Hz": startupReport?.minHz,
    "Max Hz": startupReport?.maxHz,
    "Harmonic filter S/N": startupReport?.harmonicFilterSn,
    "Xformer secondary voltage chosen":
      startupReport?.xformerSecondaryVoltageChosen,
    "Connection type": startupReport?.connectiontype,
    "Tap switch 1": startupReport?.tapSwtch1,
    "Tap switch 2": startupReport?.tapSwtch2,
  };

  return (
    <div className="attribute-card">
      <h2 className="default-attribute-header">
        Equipment information and settings
      </h2>
      <div>
        {Object.entries(details).map(([label, value], index) => (
          <div key={index} style={styles.row}>
            <span>{label}</span>
            <span>{value}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

const headerStyle = {
  color: "#F7F9F9",
  fontSize: "12px",
  fontFamily: "Mulish",
  fontWeight: "400",
  lineHeight: "18px",
  padding: "9px 12px",
  borderRight: "1px #4A5463 solid",
  borderBottom: "1px #4A5463 solid",
};

const cellStyle = {
  fontWeight: 400,
  fontSize: "12px",
  lineHeight: "20px",
  fontFamily: "Mulish, sans-serif",
  borderRight: "1px #4A5463 solid",
  color: "#f7f9f9",
  padding: "9px 12px",
};
const Section: React.FC<{ title: string }> = ({ title }) => {
  const { startupReport } = useSelector(
    (state: any) => state.preInspectionSummary
  );
  const [isExpanded, setIsExpanded] = useState(false);
  const tblOperating = {
    "Set frequency": startupReport?.operatingParameter?.setFrequency,
    "Max frequency": startupReport.operatingParameter?.maxFrequency,
    "Min frequency": startupReport.operatingParameter?.minFrequency,
    "Start current limit": startupReport.operatingParameter?.startCurrentLimit,
    "Run current limit": startupReport.operatingParameter?.runCurrentLimit,
    "Accel time": startupReport.operatingParameter?.accelTime,
    "Decel time": startupReport.operatingParameter?.decelTime,
    "Starting mode": startupReport.operatingParameter?.startingMode,
    "Single phase volts": startupReport.operatingParameter?.singlePhaseVolts,
  };

  const tblOperatingSub1 = {
    "Overload set point": startupReport?.operatingParameter?.overloadSetPoint,
    "OL fault delay": startupReport.operatingParameter?.olfaultDelay,
    "Restarts on OL": startupReport.operatingParameter?.restartsonOl,
    "Restart delay on OL": startupReport.operatingParameter?.restartDelayonOl,
    "Underload set point": startupReport.operatingParameter?.underloadSetPoint,
    "Underload fault delay": startupReport.operatingParameter?.ulfaultDelay,
    "Restarts on underload": startupReport.operatingParameter?.restartsonUl,
    "Restart delay on underload": startupReport.operatingParameter?.restartDelayonUl,
    "Low frequency fault delay": startupReport.operatingParameter?.lowFreqFaultDelay,
    "Low frequency enabled": startupReport.operatingParameter?.lowFreqEnabled,
    "Low frequency disabled": startupReport.operatingParameter?.lowFreqDisabled
  };
  const tblOperatingSub2 = {
    "High motor temp set point":
      startupReport?.operatingParameter?.highMtrTempSetPnt,
    "Motor temp fault delay": startupReport.operatingParameter?.mtrTempFaultDelay,
    "Motor temp restart": startupReport.operatingParameter?.mtrTempRestart,
    "Motor temp restart delay":
      startupReport.operatingParameter?.mtrTempRestartDelay,
    "Motor temp fault enabled":
      startupReport.operatingParameter?.mtrTempFaultEnabled,
    "Motor temp fault disabled": startupReport.operatingParameter?.mtrTempFaultDisabled,
    "PID enabled/disabled": startupReport.operatingParameter?.pidEnabled,
    "PID PSI set point": startupReport.operatingParameter?.pidpsisetPoint,
    "PID low level shutdown":
      startupReport.operatingParameter?.pidlowLevelShtdwn,
    "PID gain": startupReport.operatingParameter?.pidgain,
    "PID I-time": startupReport.operatingParameter?.pidiTime,
  };
  const tblStartupReading1 = {
    "Tubing PSI at start": startupReport?.startUpReadings?.tubingPsistart,
    "Tubing PSI at 1 hour": startupReport.startUpReadings?.tubingPsi1hr,
    "Casing PSI at start": startupReport.startUpReadings?.casingPsistart,
    "Casing PSI at 1 hour": startupReport.startUpReadings?.casingPsi1hr,
    "Motor temo at start": startupReport.startUpReadings?.motorTempStart,
    "Motor temo at 1 hour": startupReport.startUpReadings?.motorTemp1hr,
    "Static intake at start": startupReport.startUpReadings?.staticIntakeStart,
    "Intake PSI at 1 hour": startupReport.startUpReadings?.intakePsi1hr,
    "Flow rate at start": startupReport.startUpReadings?.flowRateStart,
    "Flow rate at 1 hour": startupReport.startUpReadings?.flowRate1hr,
    "Pump up time": startupReport.startUpReadings?.pumpUpTime,
  };

  const tblStartupReading2 = {
    "Trending parameters established at start up (as per tech alert VSD best practice VFD trending)":
      startupReport?.trendingParametersEst,
    "Tubing PSI at 1 hour SMARTEN VSD dec. ramp adapt set to NO position":
      startupReport?.smartenVsdnoposition,
    "Customer permissive to fault the drive": startupReport?.customerPermtoFlt,
    "Tested customer permissive to fault the drive":
      startupReport.testedCustomerPermtoFlt,
  };

  const tblReadingsHeaders1 = [
    {
      headers: ["", "Hz", "A/B", "A/C", "B/C"],
    },
  ];
  const tblReadingsHeaders2 = [
    {
      headers: ["", "Hz", "A / ground", "B / ground", "C / ground"],
    },
  ];

  const tblReadingsHeaders3 = [
    {
      headers: ["", "Hz", "A", "B", "C"],
    },
  ];

  const metaDataReadings1 = ["DriveOutputVoltsǾtoǾ", "MotorDownholeVoltsǾtoǾ"];

  const enumStartupDetailType: any = {
    DriveOutputVoltsǾtoǾ: "Drive output volts Ǿ to Ǿ",
    MotorDownholeVoltsǾtoǾ: "Motor / downhole volts Ǿ to Ǿ",
    DriveOutputVoltsǾtogrnd: "Drive output volts Ǿ to Ǿ to ground",
    MotorDownholeVoltsǾtogrnd: "Motor / downhole volts Ǿ to Ǿ to ground",
    DriveOutputCurrent: "Drive output current",
    MotorDownholeCurrent: "Motor / downhole current",
    MotorDownholeCurrent_Onehr: "Motor / downhole current at 1 hour",
  };
  const metaDataReadings2 = [
    "DriveOutputVoltsǾtogrnd",
    "MotorDownholeVoltsǾtogrnd",
  ];
  const metaDataReadings3 = [
    "DriveOutputCurrent",
    "MotorDownholeCurrent",
    "MotorDownholeCurrent_Onehr",
  ];

  const tblProblemsReorted = [
    {
      headers: ["Problem reported"],
      data: [startupReport?.problemsreported],
    },
    {
      headers: ["Date", " Changes made", "Customer representative"],
      data: [
        startupReport?.changesMadeDate,
        startupReport?.changesMade,
        startupReport?.changesMadeCustomerRep,
      ],
    },
  ];

  const tblBrakeHP = {
    Series: startupReport?.startupBrakeHpdata?.series,
    "Stage type": startupReport.startupBrakeHpdata?.stagetype,
    "HP per stage": startupReport.startupBrakeHpdata?.hpperstage,
    "Stage count": startupReport.startupBrakeHpdata?.stagecount,
    "Required HP": startupReport.startupBrakeHpdata?.reqiredhp,
    "50 Hz": startupReport.startupBrakeHpdata?._50hertz,
    "55 Hz": startupReport.startupBrakeHpdata?._55hertz,
    "60 Hz": startupReport.startupBrakeHpdata?._60hertz,
    "65 Hz": startupReport.startupBrakeHpdata?._65hertz,
    "70 Hz": startupReport.startupBrakeHpdata?._70hertz,
    "75 Hz": startupReport.startupBrakeHpdata?._75hertz,
  };

  return (
    <div className="section">
      <div
        className="section-header"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <span>{title}</span>
        <img src={isExpanded ? sectionCollapse : sectionExpand} alt="chevron" />
      </div>

      {isExpanded && title === "Setup / Operating parameters" ? (
        <>
          <div className="section-open-section-content" key={title}>
            <div className="subsection-styling pd15">
              {Object.entries(tblOperating).map(([label, value], index) => (
                <div key={index} style={styles.row}>
                  <span>{label}</span>
                  <span>{value}</span>
                </div>
              ))}
            </div>
          </div>
          <div className="display-flex">
            <div
              className="section-open-section-content sub-section"
              key={title}
            >
              <div className="subsection-styling pd15">
                {Object.entries(tblOperatingSub1).map(
                  ([label, value], index) => (
                    <div key={index} style={styles.row}>
                      <span>{label}</span>
                      <span>{value}</span>
                    </div>
                  )
                )}
              </div>
            </div>
            <div
              className="section-open-section-content sub-section"
              key={title}
            >
              <div className="subsection-styling pd15">
                {Object.entries(tblOperatingSub2).map(
                  ([label, value], index) => (
                    <div key={index} style={styles.row}>
                      <span>{label}</span>
                      <span>{value}</span>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </>
      ) : isExpanded && title === "Start up readings" ? (
        <>
          <div className="section-open-section-content" key={title}>
            <div className="subsection-styling pd15">
              {Object.entries(tblStartupReading1).map(
                ([label, value], index) => (
                  <div key={index} style={styles.row}>
                    <span>{label}</span>
                    <span>{value}</span>
                  </div>
                )
              )}
            </div>
            <div className="subsection-styling margin-top-bottom">
              {tblReadingsHeaders1.map((set, index) => (
                <div key={index}>
                  <div className="flex-display">
                    {set.headers.map((header, i) => (
                      <div key={i} style={{ flex: 1, ...headerStyle }}>
                        {header}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
              {startupReport?.startUpReadingDetails
                ?.filter((item: any) => {
                  return metaDataReadings1.includes(item.startupDetailType);
                })
                .map((datum: any, i: any) => (
                  <div className="sequence-rows">
                    <div className="sequence-cells">
                      {enumStartupDetailType[datum.startupDetailType]}
                    </div>
                    <div className="sequence-cells">{datum?.hzValue}</div>
                    <div className="sequence-cells">{datum?.abValue}</div>
                    <div className="sequence-cells">
                      {datum?.itemDiscription}
                    </div>
                    <div className="sequence-cells">{datum?.bcValue}</div>
                  </div>
                ))}
            </div>

            <div className="subsection-styling margin-top-bottom">
              {tblReadingsHeaders2.map((set, index) => (
                <div key={index}>
                  <div className="flex-display" >
                    {set.headers.map((header, i) => (
                      <div key={i} style={{ flex: 1, ...headerStyle }}>
                        {header}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
              {startupReport?.startUpReadingDetails
                ?.filter((item: any) => {
                  return metaDataReadings2.includes(item.startupDetailType);
                })
                .map((datum: any, i: any) => (
                  <div className="sequence-rows">
                    <div className="sequence-cells">
                      {enumStartupDetailType[datum.startupDetailType]}
                    </div>
                    <div className="sequence-cells">{datum?.hzValue}</div>
                    <div className="sequence-cells">{datum?.aGrndValue}</div>
                    <div className="sequence-cells">{datum?.bGrndValue}</div>
                    <div className="sequence-cells">{datum?.cGrndValue}</div>
                  </div>
                ))}
            </div>
            <div className="subsection-styling margin-top-bottom">
              {tblReadingsHeaders3.map((set, index) => (
                <div key={index}>
                  <div className="flex-display">
                    {set.headers.map((header, i) => (
                      <div key={i} style={{ flex: 1, ...headerStyle }}>
                        {header}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
              {startupReport?.startUpReadingDetails
                ?.filter((item: any) => {
                  return metaDataReadings3.includes(item.startupDetailType);
                })
                .map((datum: any, i: any) => (
                  <div className="sequence-rows">
                    <div className="sequence-cells">
                      {enumStartupDetailType[datum.startupDetailType]}
                    </div>
                    <div className="sequence-cells">{datum?.hzValue}</div>
                    <div className="sequence-cells">{datum?.aValue}</div>
                    <div className="sequence-cells">{datum?.bValue}</div>
                    <div className="sequence-cells">{datum?.cValue}</div>
                  </div>
                ))}
            </div>

            <div className="subsection-styling pd15">
              {Object.entries(tblStartupReading2).map(
                ([label, value], index) => (
                  <div key={index} style={styles.row}>
                    <span>{label}</span>
                    <span>{value}</span>
                  </div>
                )
              )}
            </div>
          </div>
        </>
      ) : isExpanded && title === "Brake HP data" ? (
        <>
          <div className="section-open-section-content" key={title}>
            <div className="subsection-styling pd15">
              {Object.entries(tblBrakeHP).map(([label, value], index) => (
                <div key={index} style={styles.row}>
                  <span>{label}</span>
                  <span>{value}</span>
                </div>
              ))}
            </div>
          </div>
        </>
      ) : isExpanded && title === "Problem reported and changes made" ? (
        <div className="section-open-section-content" key={title}>
          {tblProblemsReorted.map((set, index) => (
            <div
              key={index}
              className="subsection-styling mb10"
            >
              <div className="flex-display">
                {set.headers.map((header, i) => (
                  <div key={i} style={{ flex: 1, ...headerStyle }}>
                    {header}
                  </div>
                ))}
              </div>
              <div className="flex-display">
                {set.data.map((datum, i) => (
                  <div key={i} style={{ flex: 1, ...cellStyle }}>
                    {datum}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

const SectionList: React.FC = () => {
  const sections = [
    "No load test",
    "Setup / Operating parameters",
    "Start up readings",
    "Brake HP data",
    "Problem reported and changes made",
  ];

  return (
    <div className="section-list">
      {sections.map((section, index) => (
        <Section key={index} title={section} />
      ))}
    </div>
  );
};

const ServiceReport: React.FC<Toggle> = ({ open, onCloseModal }) => {
  const { startupReport,loading } = useSelector(
    (state: any) => state.preInspectionSummary
  );
  useEffect(() => {
    document
      .getElementById("slider")
      ?.classList.toggle(open ? "open" : "close");

    return () => {
      document.getElementById("slider")?.classList.toggle("close");
    };
  }, [open]);

  document.querySelectorAll(".section-header").forEach((header) => {
    header.addEventListener("click", () => {
      header.parentElement?.classList.toggle("section-open");
    });
  });
  const onClick = () => {
    onCloseModal();
  };
  return (
    <div className="main-component">
      {loading && <Loader isOverlay />}
      
      <CardHeader onClose={onClick} downloadLink={startupReport?.fileLink} />
      {Helpers.isObjectNotEmpty(ServiceReport) ? (
          <>
      <AttributeCard
        installDate={startupReport?.unitInstallDate}
        serviceTech={startupReport?.serviceTechnician}
        startupDate={startupReport?.startupDate}
        modemIP={startupReport?.modemIP}
        customer={startupReport?.customer}
        wellName={startupReport?.wellName}
        wellAPI={startupReport?.wellApiNumber}
        field={startupReport?.field}
        county={startupReport?.county}
        state={startupReport?.state}
      />
      <ESPDetails />
      <SectionList />
      </>
        ) : (
          <div>
            <div className="text-lg text-center py-40">No data found</div>
          </div>
        )}
    </div>
  );
};

const CardHeader: React.FC<any> = ({ onClose, downloadLink }) => {
  return (
    <div className="report-card-header">
      <div className="header-icon" onClick={onClose}>
        <img
          src={closeSlider}
          onClick={onClose}
          alt="icon"
          width="24"
          height="24"
        />
      </div>
      <div className="header-title">Service report</div>
      <div className={`${downloadLink ? "" : "disabled"} download-button `}>
        <a href={downloadLink} download>
          <img
            src={downloadReport}
            alt="download-icon"
            width="129"
            height="20"
          />
        </a>
      </div>
    </div>
  );
};

const styles = {
  container: {
    backgroundColor: "#001023",
    padding: "24px",
    borderRadius: "8px",
  },
  header: {
    color: "#f4fbfc",
    fontFamily: "Mulish",
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "28px",
    marginBottom: "20px",
    marginLeft: "100px !important",
  },
  h2: {
    marginLeft: "1px !important",
  },
  row: {
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#f7f9f9",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px",
    borderBottom: "1px solid #4a5463",
    height: "36px",
  },
  label: {
    flex: "1",
    textAlign: "left",
    fontFamily: "Mulish",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "20px",
  },
  value: {
    flex: "1",
    textAlign: "right",
    fontFamily: "Mulish",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "20px",
  },
};

export default ServiceReport;
