import React from "react";
import calendar from "./../../../images/calendar.svg";
import "./DIFACard.css";
import { useNavigate } from "react-router-dom";
import type { DifaCardProps } from "../../models/DashboardDifaList";
import { Engineer } from "../../models/Engineer";
import { formatDate, getFirstLastEngineerLetter } from "../../common/services/UtilityService";
import { useSelector } from "react-redux";

const DIFACard: React.FC<DifaCardProps> = ({
  difaId,
  summary,
  wellName,
  dismantleDate,
  engineer,
  emailAddress,
  colorCode,
}) => {
  const navigate = useNavigate();
  const getAllCustomers = useSelector(
    (state: any) => state.difaList.allCustomers
  );

  const difaDetails = () => {
    navigate("/difa-details", {
      state: { difaId: difaId, customerName: engineer },
    });
  };

  const EngineerName = getAllCustomers.find(
    (x: Engineer) => x.emailAddress === emailAddress
  )?.dismantleEngineer;

  const EngFirstandLastName = getFirstLastEngineerLetter(EngineerName);

  return (
    <div className="difa-card">
      <div className="difa-card-name">{engineer}</div>
      <h3>{wellName}</h3>
      <p dangerouslySetInnerHTML={{ __html: summary?.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>').substring(0, 200) + "..." || "" }} />
      <div className="calender-time">
        <img src={calendar} alt="calendar" /> <span>{formatDate(dismantleDate)}</span>
      </div>
      <div className="card-footer">
        <div className="difa-type-container">
          <div
            className="difa-type-name"
            style={{ backgroundColor: colorCode }}
          >
            {EngFirstandLastName}
          </div>
          <span className="difa-type">DIFA ID# {difaId}</span>
        </div>
        <button className="go-to-difa" onClick={difaDetails}>
          Go to DIFA
        </button>
      </div>
    </div>
  );
};

export default DIFACard;
