import instance from '../../config/HttpCommon';

export const getDifaDetails = async (difaId: string) => {
    const DifaListRes = await instance.get(`/Difa/DifaDetails?difaId=${difaId}`);
    return DifaListRes?.data;
}

export const getInboundTestReport = async (difaId: string) => {
    const inbountTestReport = await instance.get(`Difa/GetInboundTestingReport?difaId=${difaId}`);
    return inbountTestReport?.data;
}


export const getReportStatus = async (wellApiNumber: string, difaId: string) => {
    const difaReportStatus = await instance.get(
        `/Reportbuilder/GetDifaReportStatus?wellApiNumber=${wellApiNumber}&difaID=${difaId}`
    );
    return difaReportStatus?.data;
  };