import React from "react";
import DIFACard from "./DIFACard";
import type { DashboardLayoutProps } from "../../models/DashboardDifaList";
import "./DashboardLayout.css";
import Loader from "../../common/loader/Loader";

const DashboardLayout: React.FC<DashboardLayoutProps> = ({
  sections,
  visibleCards,
  loading,
}) => {
  return (
    <div className="dashboard-container md:flex gap-6 flex flex-col md:flex-row">
      {sections.map((section) => (
        <div
          className={`dashboard-section h-full w-full md:flex-1 md:h-full md:w-1/3 ${
            section.title && section.title.replaceAll(" ", "")
          }`}
          key={section.title}
        >
          <div className="section-title-container sticky top-0">
            <div className="section-title">{section.title}</div>
            <div className="section-count">{section.cards?.length}</div>
          </div>
          <div className="section-card-container overflow-y-auto flex flex-col py-5 gap-4">
            {section.cards?.slice(0, visibleCards).map((card, index) => (
              <DIFACard
                key={index}
                difaId={card?.difaId}
                summary={card?.summary}
                dismantleDate={card?.dismantleDate}
                engineer={card?.engineer}
                emailAddress={card?.emailAddress}
                status={card?.status}
                wellName={card?.wellName}
                colorCode={card?.colorCode}
              />
            ))}
          </div>
          {loading && visibleCards < section.cards.length && <Loader />}
        </div>
      ))}
    </div>
  );
};

export default DashboardLayout;
