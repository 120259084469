import React, { ChangeEvent, useState } from 'react'
import UploadDocuments from '../../../common/uploadFiles/UploadDocuments'
import DifaSwitch from '../../../common/switch/DifaSwitch';
import { useDispatch, useSelector } from 'react-redux';
import PDFViewer from '../../../common/pdfViewer/FileViewer';
import EmptyView from '../../../common/emptyPreview/View';
import Helpers from '../../../../utilities/Helpers';
import { setScaleDeposit } from '../../../difaPacketBuilder/difaPacketBuilderSlice';

const ScaleDeposite: React.FC<{ source: string }> = ({ source }) => {
    const { scaleDeposit } = useSelector((state: any) => state.difaPacketBuilder);
    const dispatch = useDispatch();
    const [expanded, setExpanded] = useState(scaleDeposit?.isSelected || false);
    const [uploadedFiles, setUploadedFiles] = useState<File[]>(
        scaleDeposit?.scaleDepositFile ? [scaleDeposit?.scaleDepositFile] : []
    );
    const isPreview = source === "preview-report";

    const onExpand = () => {
        setExpanded(!expanded);
        dispatch(setScaleDeposit({ ...scaleDeposit, isSelected: !expanded }));
    };

    const handleFilesChange = async (files: File[]): Promise<void> => {
        setUploadedFiles(files);
        const scaleDepositFile: any = files.length
            ? {
                link: URL.createObjectURL(files[0]),
                blob: await Helpers.convertFiletoBase64(files[0]),
                name: files[0].name,
            }
            : null;
        dispatch(setScaleDeposit({ ...scaleDeposit, scaleDepositFile }));
    };

    return (
        <div className="flex-auto flex flex-col w-full gap-5">
            {isPreview ? (
                scaleDeposit?.isSelected ? (
                    <PDFViewer file={scaleDeposit?.scaleDepositFile?.link} />
                ) : (
                    <EmptyView />
                )
            ) : (
                <div className="difa-widget-containers">
                    <div className="header-main">
                        <div className="flex flex-row justify-between">
                            <div>
                                <h3>Scale deposit analysis results</h3>
                                <h5>Upload a performance match to compare the actual versus expected performance of the ESP's design.</h5>
                            </div>
                            <div className="flex flex-row items-center gap-3">
                                <DifaSwitch checked={expanded} onChange={onExpand} />
                                <h4 className="whitespace-nowrap">Include in report</h4>
                            </div>
                        </div>
                    </div>
                    <div className="block w-full border-t border-dark p-6">
                        <UploadDocuments
                            fileType="pdf"
                            maxFileSize={100} // Max size: 100 MB
                            onFilesChange={handleFilesChange}
                            uploadedFiles={uploadedFiles}
                        />
                    </div>
                </div>
            )}
        </div>
    )
}

export default ScaleDeposite
