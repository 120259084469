import { useSelector } from 'react-redux';
import DifaInput from '../../common/input/DifaInput';
import DifaSwitch from '../../common/switch/DifaSwitch';
import './WellRunLifeInformation.scss';
import { formatDateInNumbers, formatOnlyDate } from '../../common/services/UtilityService';
import Loader from '../../common/loader/Loader';
interface WellRunLifeInformationProps {
    difaId: string | number;
}


const WellRunLifeInformation: React.FC<WellRunLifeInformationProps> = ({
    difaId,
}) => {

    const difaDetails = useSelector(
        (state: any) => state.difaDetails?.difaDetails
    );
    const { difaRundaysDetails, loading } = useSelector(
        (state: any) => state.preInspectionSummary
    );

    return (
        <div className='flex-auto flex flex-col w-full gap-5'>
            {loading && <Loader isOverlay />}
            {difaDetails && <>
                <div className="difa-widget-containers">
                    <div className="header-main">
                        <h3>Well information</h3>
                        <h5>Include relevant supporting documentation to help our customers understand the ESP's operational history.</h5>
                    </div>
                    <div className="container-body flex flex-col gap-2">
                        <div className="flex flex-col md:flex-row gap-4">
                            <DifaInput disabled={true} caption="Operatar" value={difaDetails.operator} />
                            <DifaInput disabled={true} caption="Well Name" value={difaDetails.wellName} />
                        </div>
                        <div className="flex flex-col md:flex-row gap-4">
                            <DifaInput disabled={true} caption="Field" value={difaDetails.field} />
                            <DifaInput disabled={true} caption="State" value={difaDetails.state} />
                        </div>
                    </div>
                </div>
                <div className="difa-widget-containers">
                    <div className="header-main">
                        <h3>Run life details</h3>
                    </div>
                    <div className="container-body flex flex-col gap-2">
                        <div className="flex flex-col md:flex-row gap-4">
                            <DifaInput disabled={true} caption="Install date" isDateField={true} value={formatOnlyDate(difaDetails.espIntalledDate)} />
                            <DifaInput disabled={true} caption="Start date" isDateField={true} value={formatOnlyDate(difaDetails.espStartedDate)} />
                        </div>
                        <div className="flex flex-col md:flex-row gap-4">
                            <DifaInput disabled={true} caption="Failed date" isDateField={true} value={formatOnlyDate(difaDetails.espFailedDate)} />
                            <DifaInput disabled={true} caption="Pulled date" isDateField={true} value={formatOnlyDate(difaDetails.espPulledDate)} />
                        </div>
                        <div className="flex flex-col md:flex-row gap-4">
                            <DifaInput disabled={true} caption="Installed to  pulled run days" value={difaDetails.rundaysToPullStartDate} />
                            <DifaInput disabled={true} caption="Started to failed run days" value={difaDetails.rundaysToFailedDate} />
                        </div>
                        <div className="flex flex-col md:flex-row gap-4">
                            <DifaInput disabled={true} caption="Run number" value={difaDetails.runNumber} />
                            <DifaInput disabled={true} caption="DIFA inspection date" value={difaDetails.difaInspectionDate} />
                        </div>
                        <div className="flex flex-col md:flex-row gap-4">
                            <DifaInput disabled={true} caption="Report start date" isDateField={true} value="Nov 10, 2023" />
                            <DifaInput disabled={true} caption="Report submitted date" isDateField={true} value="Nov 10, 2023" />
                        </div>
                    </div>
                </div>
            </>}
            <div className="difa-widget-containers">
                <div className="header-main">
                    <div className="flex flex-row justify-between">
                        <div>
                            <h3>Prior runs</h3>
                            <h5>Include the history of previous ESP runs on this well</h5>
                        </div>
                        <DifaSwitch />
                    </div>
                </div>
                <div className="container-body flex flex-col gap-2">
                    <div className="table-main">
                        <div className="table">
                            <div
                                className="table-row sticky top-0"
                                style={{ background: "#253240" }}
                            >
                                <div className="th w-1/12 min-w-20"> DIFA ID#</div>
                                <div className="th w-1/12 min-w-28">Install date</div>
                                <div className="th w-1/12 min-w-28">Pull date</div>
                                <div className="th w-1/12 min-w-20">Rundays</div>
                                <div className="th w-1/5 min-w-52">Pull reason</div>
                                <div className="th w-1/5 min-w-52">Failure cajuse</div>
                                <div className="th w-1/5 min-w-52">Prmiary failed component</div>
                            </div>
                            {loading && <Loader />}
                            {!(difaRundaysDetails && difaRundaysDetails.length) ? (
                                <div className="no-data">No Data Found</div>
                            ) : (
                                difaRundaysDetails.map((pump: any, index: number) => {
                                    return (
                                        <div className="table-row" key={index}>
                                            <div className="table-column w-1/12 min-w-20">
                                                {pump.difaId}
                                            </div>
                                            <div className="table-column w-1/12 min-w-28">
                                                {formatDateInNumbers(pump?.installDate)}
                                            </div>
                                            <div className="table-column w-1/122 min-w-28">
                                                {formatDateInNumbers(pump?.pullDate)}
                                            </div>
                                            <div className="table-column w-1/12 min-w-20">
                                                {pump?.runDays}
                                            </div>
                                            <div className="table-column w-1/5 min-w-52">
                                                {pump?.pullReason}
                                            </div>
                                            <div className="table-column w-1/5 min-w-52">
                                                {pump?.failureCauseGeneral ? pump?.failureCauseGeneral + '\n' : ''}
                                                {pump?.failureCauseGeneral ? pump?.failureCauseSpecific + '\n' : ''}
                                            </div>
                                            <div className="table-column w-1/5 min-w-52">
                                                {pump?.primaryFailedComponent ? pump?.primaryFailedComponent + '\n' : ''}
                                                {pump?.primaryFailedSubComponent ? pump?.primaryFailedSubComponent + '\n' : ''}
                                            </div>
                                        </div>
                                    );
                                })
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WellRunLifeInformation;