
import { HTMLProps } from 'react';
import './DifaSwitch.scss';
export interface DifaSwitchProps extends HTMLProps<HTMLInputElement> {
    classNames?: string;
}


const DifaSwitch: React.FC<DifaSwitchProps> = ({
    classNames,
    ...addtionalProps
}) => {
    return (
        <div className='flex flex-col'>
            <label className="switch">
                <input type="checkbox" {...addtionalProps} />
                <span className="slider round"></span>
            </label>
        </div>
    );
}

export default DifaSwitch;
