import { DismantleRequestProps } from "../../models/DismantleRequest";
import { VerticalTabContentProps } from "../../models/DismantleRequest";
import "./VerticalTabContent.scss";

const VerticalTabContent: React.FC<VerticalTabContentProps> = ({
  activeTab,
  tabContent,
  selectedIds,
  handleCheckbox,
  handleAllSelected,
  handleAllSelectCheckbox,
  name,
  headerButtontext,
  isCheckboxVisible,
}) => {
  return (
    <div className="content-table">
      <div className="table-header">
        <div className="table-heading">{name[activeTab]}</div>
        <div className="table-header-button">
          {activeTab !== "PROTACTOR" && (
            <div className="card-header-button disabled">
              <button className="button-text" disabled={true}>{headerButtontext()}</button>
            </div>
          )}

          {activeTab === "PUMP MSC" && (
            <div className="card-header-button disabled">
              <button className="button-text" disabled={true}>View flow test</button>
            </div>
          )}
        </div>
      </div>
      <div className="table-main">
        <div className="table">
          <div className="table-row table-content-heading">
            {isCheckboxVisible && (
              <div className="th select flex justify-center">
                <label className="custom-checkbox">
                  <input
                    type="checkbox"
                    checked={handleAllSelectCheckbox()}
                    onChange={() => handleAllSelected(activeTab)}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
            )}
            <div className="th sno"> </div>
            <div className="th serial">Serial #</div>
            <div className="th eq-type">Equipment type</div>
            <div className="th run-days">Run days</div>
            <div className="th i-results">Inbound results</div>
            <div className="th i-comments">Inbound comments</div>
          </div>

          {tabContent.length === 0 ? (
            <div className="no-data">No Data Found</div>
          ) : (
            tabContent.map((pump: DismantleRequestProps, index: number) => {
              return (
                <div className="table-row" key={index}>
                  {isCheckboxVisible && (
                    <div className="table-column select justify-center">
                      <label className="custom-checkbox">
                        <input
                          type="checkbox"
                          checked={
                            selectedIds.includes(pump?.serialNumber)
                              ? true
                              : false
                          }
                          onChange={() => handleCheckbox(pump?.serialNumber)}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  )}
                  <div className="table-column sno justify-center">{index + 1}</div>
                  <div className="table-column serial">
                    {pump?.serialNumber}
                  </div>
                  <div className="table-column eq-type">
                    {pump?.equipementType}
                  </div>
                  <div className="table-column run-days">
                    {pump?.runtime ?? "New"}
                  </div>
                  <div
                    className={`table-column ${
                      (pump?.inboundResults === "Fail" ? "failed" : "pass")  
                    } i-results`}
                  >
                    {pump?.inboundResults === "Fail" ? "Failed" : "Pass"}
                  </div>
                  <div className="table-column i-comments">
                    {pump?.comments !== "" ? pump?.comments : "-"}
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>
    </div>
  );
};

export default VerticalTabContent;
