import React, { useEffect, useState } from 'react';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

interface AlarmEventsChartProps {
  chartData: any; // Replace 'any' with the appropriate type
  id: string;
}

const AlarmEventsChart: React.FC<AlarmEventsChartProps> = ({ chartData, id }) => {

  const SCREEN_WIDTH = 1024;
  const [isTabScreen, setIsTabScreen] = useState(window.innerWidth <= SCREEN_WIDTH);

  useEffect(() => {
    const handleResize = () => {
      setIsTabScreen(window.innerWidth <= SCREEN_WIDTH);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Call initially to set the state based on the initial screen size

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isTabScreen]);

  useEffect(() => {
    const root = am5.Root.new(id);
    root.setThemes([am5themes_Animated.new(root)]);

    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: true,
        panY: true,
        pinchZoomX: true,
      })
    );

    const xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "eventType",
        maxDeviation: 0.2,
        renderer: am5xy.AxisRendererX.new(root, {
          minGridDistance: 30,
          cellStartLocation: 0.1,
          cellEndLocation: 0.9,
        }),
      })
    );

    xAxis.children.push(
      am5.Label.new(root, {
        fill: am5.color(0xf7f9f9),
        text: "Event type",
        x: am5.percent(50),
        centerX: am5.p50,
      })
    );

    xAxis.get("renderer").grid.template.set("visible", false);
    xAxis.get("renderer").labels.template.setAll({
      fill: am5.color(0xf7f9f9),
      rotation: isTabScreen ? -90 : 0, // Rotate labels if in tab screen
      x: 0.5,
      y: 0.5,
      centerY: am5.percent(50),
      centerX: am5.p50,
      text: "{eventName}",
      populateText: true,
      oversizedBehavior: "wrap",
      textAlign: "center",
    });

    xAxis.get("renderer").labels.template.adapters.add("text", function (text, target) {
      const dataContext = target?.dataItem?.dataContext as { eventName: string };
      if (dataContext?.eventName) {
        const parts = dataContext.eventName.split(" ");
        if (parts?.length > 2) {
          const firstPart = parts.slice(0, Math.ceil(parts?.length / 2)).join(" ");
          const secondPart = parts.slice(Math.ceil(parts?.length / 2)).join(" ");
          return `${firstPart}\n${secondPart}`;
        }
        return parts.join("\n");
      }
      return text;
    });

    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {
          stroke: am5.color(0xf7f9f9),
        }),
      })
    );

    yAxis.get("renderer").grid.template.set("visible", true);
    yAxis.get("renderer").grid.template.set("stroke", am5.color("#F7F9F9"));
    yAxis.get("renderer").labels.template.setAll({
      fill: am5.color(0xf7f9f9),
    });

    yAxis.children.unshift(
      am5.Label.new(root, {
        fill: am5.color(0xf7f9f9),
        rotation: -90,
        text: "Alarm events",
        y: am5.percent(50),
        x: -20,
        centerX: am5.p50,
      })
    );

    const series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: "Event Count",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "eventCount",
        categoryXField: "eventType",
      })
    );

    series.bullets.push(function (root) {
      return am5.Bullet.new(root, {
        locationX: 0.5,
        locationY: 1,
        sprite: am5.Label.new(root, {
          text: "{valueY}",
          fill: am5.color("#F7F9F9"),
          centerX: am5.percent(50),
          centerY: am5.percent(100),
          populateText: true,
        }),
      });
    });

    // Define color mapping
    const colorMapping: { [key: string]: am5.Color } = {
      Idle: am5.color(0x006dfa), // Blue
      Shutdown: am5.color(0xf04438), // Red
    };

    series.columns.template.adapters.add("fill", (fill, target) => {
      const eventType = (target.dataItem?.dataContext as { eventType: string })
        .eventType;
      if (eventType) {
        const key = eventType.split(",")[0]?.trim();
        return colorMapping[key] || fill;
      }
      return fill;
    });

    series.columns.template.adapters.add("stroke", (stroke, target) => {
      const eventType = (target.dataItem?.dataContext as { eventType: string })
        .eventType;
      if (eventType) {
        const key = eventType.split(",")[0]?.trim();
        return colorMapping[key] || stroke;
      }
      return stroke;
    });

    // Sort data in descending order based on eventCount
    const sortedData = [...chartData].sort(
      (a, b) => b.eventCount - a.eventCount
    );

    const alarmChartData = sortedData?.map((data) => ({
      ...data,
      eventName: data.eventType.split(",")[1]?.trim(),
    }));

    xAxis.data.setAll(alarmChartData);
    series.data.setAll(alarmChartData);

    return () => {
      root.dispose();
    };
  }, [id, chartData]);

  return <div id={id} className="w-full"></div>;
}

export default AlarmEventsChart
