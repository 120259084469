import React, { useEffect } from 'react';
import closeSlider from "../../../../images/close-slider.svg";
import downArrow from "../../../../images/chevron-down.svg";
import { ReactComponent as DownloadBtn } from "../../../../images/download-report.svg";
import Helpers from '../../../../utilities/Helpers';
import { formatDateInNumbers, formatTime } from '../../../common/services/UtilityService';

interface Toggle {
    open: boolean;
    onCloseModal: () => void;
    ticketData: any;
}

const TicketView: React.FC<Toggle> = ({ open, onCloseModal, ticketData }) => {

    useEffect(() => {
        document
            .getElementById("slider")
            ?.classList.toggle(open ? "open" : "close");

        return () => {
            document.getElementById("slider")?.classList.toggle("close");
        };
    }, [open]);

    document.querySelectorAll(".section-header").forEach((header) => {
        header.addEventListener("click", () => {
            header.parentElement?.classList.toggle("section-open");
        });
    });

    const onClick = () => {
        onCloseModal();
    };

    return (
        <div className="main-component">
            <CardHeader onClose={onClick} />
            {Helpers.isObjectNotEmpty(ticketData) ? (
                <div className="flex flex-col gap-[18px] text-neutral-grey px-8 py-6">
                    <div className="bg-dark-700 border border-dark rounded-lg">
                        <div className="flex items-center justify-between py-4 px-6 border-bottom">
                            <h2 className="text-xl font-semibold text-white">Ticket #{ticketData?.ticketId}</h2>
                            <div className="flex items-center  justify-center cursor-pointer py-1 px-2 border border-dark rounded-[4px]">
                                <button className="text-xs font-semibold">Closed</button>
                            </div>
                        </div>
                        <div className="flex items-center px-6">
                            <div className="flex flex-col w-full justify-center gap-1 border-r border-dark py-4 pr-4">
                                <label className="text-xs text-grey-100">Ticket type</label>
                                <label className="text-sm font-medium">14858</label>
                            </div>
                            <div className="flex flex-col w-full gap-1 justify-center border-r border-dark p-4">
                                <label className="text-xs text-grey-100">Assigned to</label>
                                <label className="text-sm font-medium">{ticketData?.assignee ?? "-"}</label>
                            </div>
                            <div className="flex flex-col w-full gap-1 justify-center py-4 pl-4">
                                <label className="text-xs text-grey-100">Requested date</label>
                                <label className="text-sm font-medium">{formatDateInNumbers(ticketData?.lastUpdated)} {formatTime(ticketData?.lastUpdated, true)}</label>
                            </div>
                        </div>
                    </div>

                    <div className="difa-widget-containers flex flex-col py-7 px-6 gap-3">
                        <div className="flex flex-row items-center justify-between gap-3">
                            <h3 className="text-lg font-semibold flex-1 !text-[#F4FBFC]">{ticketData?.subject}</h3>
                            <p className="font-normal text-xs text-neutral-grey">{formatDateInNumbers(ticketData?.lastUpdated)} {formatTime(ticketData?.lastUpdated)}</p>
                        </div>
                        <p className="text-sm font-medium">To: {ticketData?.requester}</p>
                        <div className="flex flex-col gap-5 text-sm w-full md:w-5/6">
                            <p>{ticketData?.description}</p>
                            <p className="text-[#60BFDA] font-semibold">{ticketData?.requester} <br />{ticketData?.groupName} </p>
                        </div>
                    </div>
                </div>
            ) : (
                <div>
                    <div className="text-lg text-center py-40">No data found</div>
                </div>
            )}
        </div>
    )
}

const CardHeader: React.FC<any> = ({ onClose }) => {
    return (
        <div className="report-card-header">
            <div className="flex gap-6 items-center">
                <div className="flex gap-3 items-center">
                    <div className="pr-3 border-r-2 border-[#B8C5CC]" onClick={onClose}>
                        <img
                            src={closeSlider}
                            onClick={onClose}
                            alt="icon"
                        />
                    </div>
                    <div>
                        <img
                            src={downArrow}
                            alt="download-icon"
                        />
                    </div>
                </div>
                <h2 className="text-[23px] font-bold">Optimization ticket</h2>
            </div>
            <div className="cursor-pointer">
                <DownloadBtn />
            </div>
        </div>
    );
};

export default TicketView
