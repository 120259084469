import React, { useEffect } from "react";
import "./NavBar.scss";
import UNB_Software_icons from "../../images/UNB_Software _icons.svg"; // Corrected import for SVG
import { ReactComponent as UserListIcon } from "../../images/home-line.svg";
import { ReactComponent as AnalyticsNavIcon } from "../../images/file-07.svg";
import { Link, useLocation } from "react-router-dom";
import Tooltip from "../common/tooltip/Tooltip";
import RouteConstant from "../../constants/routes";
import { ReactComponent as CollapseIcon } from '../../images/log-out-01.svg';
import AuthService from '../../services/authentication/auth.service';
import TokenService from "../../services/authentication/token.service";
const EXPIRE_TIME_IN_MILLI_SECOND = 2700000;

const NavigationBar: React.FC = () => {

  const userRole =TokenService.getUserRole();
  
  useEffect(() => {
    const timerId = setInterval(() => {
      console.log('Refresh token called');
      if (!AuthService.refreshTokenFromServer()) {
        AuthService.logout();
        window.location.replace(
          process.env.REACT_APP_CONNEXIA_DEVELOPMENT_URL +
          '/Account/Login?app=' +
          process.env.REACT_APP_DEVELOPMENT_WEB_APP_REDIRECT_URL,
        );
      }
    }, EXPIRE_TIME_IN_MILLI_SECOND);
    return () => {
      clearInterval(timerId);
    };
  }, []);

  const handleLogoutClick = () => {
    AuthService.logout();
  };

  const location = useLocation();
  return (
    <div className="navigation-bar-container">
      <div className="navigation-bar-top-container">
        <div className="navigation-bar-navigation-section-container">
          <Link to="/" className="navigation-bar-logo-image-container">
            <img
              id="unb_software_icons"
              src={UNB_Software_icons}
              alt="UNB-Image"
              className="navigation-bar-logo-image"
            />
          </Link>
          <div className="navigation-bar-buttons-container">
            <Tooltip content="Dashboard" direction="right">
              {userRole && userRole === "Manager" ? (
                <Link
                to={RouteConstant.managerDashboard}
                className={`navigation-bar-navigation-image-container ${location.pathname === RouteConstant.managerDashboard
                    ? "active-link"
                    : ""
                  }`}
              >
                <UserListIcon width="24" height="24" stroke="#F4FBFC" />
              </Link>) : (
                <Link
                to={RouteConstant.userDashboard}
                className={`navigation-bar-navigation-image-container ${location.pathname === RouteConstant.userDashboard
                    ? "active-link"
                    : ""
                  }`}
              >
                <UserListIcon width="24" height="24" stroke="#F4FBFC" />
              </Link>
              )}
            </Tooltip>

            <Tooltip content="Documents" direction="right">
              <Link
                to={RouteConstant.documentation}
                className={`navigation-bar-navigation-image-container ${location.pathname === RouteConstant.documentation
                    ? "active-link"
                    : ""
                  }`}
              >
                <AnalyticsNavIcon width="24" height="24" stroke="#F4FBFC" />
              </Link>
            </Tooltip>
          </div>
        </div>
        <div className='navigation-bar-slide-out-section-container'>
          {AuthService.isUserLoggedIn() && (
            <Tooltip content='Logout' direction='right'>
              <div
                data-testid='collapse-icon'
                className={`navigation-bar-navigation-image-container`}
                onClick={handleLogoutClick}
              >
                <CollapseIcon width='24' height='24' stroke='#F4FBFC' />
              </div>
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
};

export default NavigationBar;
