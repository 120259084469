import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getDifaPackets, getDifaReports } from './DocumentationService';

// Async Thunk for fetching Difa Packets
export const fetchDifaPackets = createAsyncThunk(
  'difa/fetchDifaPackets',
  async (
    {
      pageNumber,
      pageSize,
      operator,
      installDate,
      failedDate,
      pullDate,
      reportPublished,
      rundays,
      difaSceduled,
      pullReasion,
      failureCause,
      failedItem,
      primaryFailedComponent,
    }: {
      pageNumber: number;
      pageSize: number;
      operator: string[];
      installDate: string[];
      failedDate: string[];
      pullDate: string[];
      reportPublished: string[];
      rundays: string[];
      difaSceduled: string[];
      pullReasion: string[];
      failureCause: string[];
      failedItem: string[];
      primaryFailedComponent: string[];
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await getDifaPackets(
        pageNumber,
        pageSize,
        operator,
        installDate,
        failedDate,
        pullDate,
        reportPublished,
        rundays,
        difaSceduled,
        pullReasion,
        failureCause,
        failedItem,
        primaryFailedComponent
      );
      return response;
    } catch (error: any) {
      console.error('Error fetching Difa packets:', error);
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// Add new thunk for reports
export const fetchDifaReports = createAsyncThunk(
  'difa/fetchDifaReports',
  async (
    {
      pageNumber,
      pageSize,
      operator,
      installDate,
      failedDate,
      pullDate,
      reportPublished,
      rundays,
      difaSceduled,
      pullReasion,
      failureCause,
      failedItem,
      primaryFailedComponent,
    }: {
      pageNumber: number;
      pageSize: number;
      operator: string[];
      installDate: string[];
      failedDate: string[];
      pullDate: string[];
      reportPublished: string[];
      rundays: string[];
      difaSceduled: string[];
      pullReasion: string[];
      failureCause: string[];
      failedItem: string[];
      primaryFailedComponent: string[];
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await getDifaReports(
        pageNumber,
        pageSize,
        operator,
        installDate,
        failedDate,
        pullDate,
        reportPublished,
        rundays,
        difaSceduled,
        pullReasion,
        failureCause,
        failedItem,
        primaryFailedComponent
      );
      return response;
    } catch (error: any) {
      console.error('Error fetching Difa reports:', error);
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// Define the state interface
interface DifaState {
  packets: {
    data: any;
    loading: boolean;
    error: string | null;
  }
  reports: {
  data: any; // State for fetched Difa packets
  loading: boolean; // Loading state for API calls
  error: string | null; // Error message, if any
  };
}

// Initial state
const initialState: DifaState = {
  packets: {
    data: null,
    loading: false,
    error: null
  },
  reports: {
    data: null,
    loading: false,
    error: null
  }
};

// Redux Slice
const difaSlice = createSlice({
  name: 'documentation',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Handle pending state
    builder.addCase(fetchDifaPackets.pending, (state) => {
      state.packets.loading = true;
      state.packets.error = null; // Clear previous errors
    });

    // Handle fulfilled state
    builder.addCase(fetchDifaPackets.fulfilled, (state, action) => {
      state.packets.data = action.payload; // Store API response
      state.packets.loading = false;
    });

    // Handle rejected state
    builder.addCase(fetchDifaPackets.rejected, (state, action) => {
      state.packets.error = action.payload as string; // Set error message
      state.packets.loading = false;
    });

    // Add report cases
    builder
      .addCase(fetchDifaReports.pending, (state) => {
        state.reports.loading = true;
        state.reports.error = null;
      })
      .addCase(fetchDifaReports.fulfilled, (state, action) => {
        state.reports.data = action.payload;
        state.reports.loading = false;
        state.reports.error = null;
      })
      .addCase(fetchDifaReports.rejected, (state, action) => {
        state.reports.error = action.payload as string;
        state.reports.loading = false;
        state.reports.data = null;
      });
  },
});

export default difaSlice.reducer;
