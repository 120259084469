import { toast } from "react-toastify";

export const toaster = {
    success: (title: string, msg: string) => toast.success(<div className="flex flex-col">
        <div className="text-lg">
            {title}
        </div>
        <div className="text-sm">
            {msg}
        </div>
    </div>),
    error: (msg: string, title = 'Opps!!') => toast.error(<div className="flex flex-col">
        <div className="text-lg">
            {title}
        </div>
        <div className="text-sm">
            {msg}
        </div>
    </div>)
};