import instance from "../../config/HttpCommon";

export const getEquipmentAction = async (difaId: string) => {
    try {
        const EquipmentActionRes = await instance.get(`ReportBuilder/GetEquipmentAction?difaId=${difaId}`);
        console.log("API Response for Equipment Action:", EquipmentActionRes.data);
        return EquipmentActionRes?.data;
    } catch (error) {
        console.error("Error fetching equipment action:", error);
        throw error;
    }
}

export const saveEquipmentAction = async (partType: string, serialNumber: string, equipmentDescription:string, difaAction:string, results:string, comments:string) => {
    try {
        const payload = {
            partType,
            serialNumber,
            equipmentDescription,
            difaAction,
            results,
            comments,
        };

        const saveEquipmentRes = await instance.post(`ReportBuilder/SaveEquipmentAction`, payload);
        console.log("API Response for saving Equipment Action:", saveEquipmentRes.data);
        return saveEquipmentRes?.data;
    } catch (error) {
        console.error("Error fetching equipment action:", error);
        throw error;
    }
}