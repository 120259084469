import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import { useDispatch, useSelector } from 'react-redux';
import { fetchGeneralReport, fetchPublishedDraft, fetchSizingSummary, postPublishPacket, removePacket, resetCreateGalleryState, resetPacketBuilderState, resetPublishPacketNotification } from '../../difaPacketBuilder/difaPacketBuilderSlice';
import Header from "../../difaPacketBuilder/components/Header";
import WellRunLifeInformation from '../../difaPacketBuilder/components/WellRunLifeInformation';
import EspSizing from '../../difaPacketBuilder/components/espSizing/EspSizing';
import InstallAndWellOperation from '../../difaPacketBuilder/components/installWellOperation/InstallAndWellOperation';
import PullAndInbound from '../../difaPacketBuilder/components/pullAndInbound/PullAndInbound';
import Production from '../../difaPacketBuilder/components/production/Production';
import { fetchDifaDetails } from '../../DifaPages/DifaDetailsSlice';
import { fetchOptimizationTickets, fetchRundaysDetails } from '../../preInspectionSummary/PreInspectionSummarySlice';
import Match from '../components/match/Match';
import CauseOfFailure from '../components/causeOfFailure/CauseOfFailure';
import DIFAInspection from '../components/difaInspection/DIFAInspection';
import { fetchDismantlePumps } from '../../dismentleRequest/DismantleRequestSlice';
import "../DifaReportBuilder.scss";
import ScaleDeposite from '../components/scaleDepositeAnalysis/ScaleDeposite';
import PublishedDifaDialog from '../../difaPacketBuilder/components/PublishSuccessDialog';
import PublishEditedDifaDialog from '../../difaPacketBuilder/components/PublishEditPacketDialog';
import RemoveConfirmationDialog from '../../common/confirmationDialog/RemoveItem';

const PAGE_WELL_RUN_LIFE = "Well and run life information";
const PAGE_ESP_SIZING = "ESP sizing";
const PAGE_INSTALL_AND_WELL_OPERATIONS = "Install and well operations";
const PAGE_PRODUCTION = "Production";
const PAGE_PULL_AND_INBOUND = "Pull and inbound";
const PAGE_MATCH = "Match"
const PAGE_CAUSE_OF_FAILURE = "Cause of Failure";
const PAGE_DIFA_INSPECTION = "DIFA inspection";
const PAGE_SCALE_DEPOSIT = "Scale deposit analysis results";

const DifaReportPreview = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { reportStatus } = useSelector((state: any) => state.difaDetails);

  const [currentPage, setCurrentPage] = useState(PAGE_WELL_RUN_LIFE);
  const { difaId, customerName, wellName } = location.state || {};
  const { difaDetails } = useSelector((state: any) => state.difaDetails);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [showPublishDialog, setShowPublishDialog] = useState(false);
  const [showPublishedDialog, setShowPublishedDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [viewMode, setViewMode] = useState('')
  const { galleryTransactionMsg, publishPacketNotification, draftId } = useSelector(
    (state: any) => state?.difaPacketBuilder
  );

  useEffect(() => {
    reportStatus && setViewMode(reportStatus?.isReportPublished ? "preview-published-report" : "preview-report");
  }, [reportStatus]);


  useEffect(() => {
    if (difaDetails?.wellApiNumber !== undefined) {
      dispatch(
        fetchRundaysDetails({ wellApiNumber: difaDetails?.wellApiNumber })
      );
      dispatch(
        fetchGeneralReport({
          wellApiNumber: difaDetails?.wellApiNumber,
          difaId: difaDetails?.difaId,
        })
      );
      dispatch(
        fetchOptimizationTickets({
          wellName: encodeURIComponent(difaDetails?.wellName),
        })
      );
    }
  }, [dispatch, difaDetails]);

  useEffect(() => {
    dispatch(fetchDifaDetails({ difaId }));
    dispatch(fetchDismantlePumps({ difaId }));
  }, [dispatch, difaId]);

  useEffect(() => {
    if (difaDetails?.wellApiNumber !== undefined) {
      dispatch(
        fetchSizingSummary({ wellApiNumber: difaDetails?.wellApiNumber })
      );
      dispatch(
        fetchGeneralReport({
          wellApiNumber: difaDetails?.wellApiNumber,
          difaId: difaDetails?.difaId,
        })
      );
      dispatch(
        fetchOptimizationTickets({
          wellName: encodeURIComponent(difaDetails?.wellName),
        })
      );
      dispatch(fetchPublishedDraft({ wellApiNumber: difaDetails?.wellApiNumber, difaId,source:'report-preview' }))
            
    }
  }, [dispatch, difaDetails]);

  const publishReport = async (checked: boolean) => {
    setShowEditDialog(false);
    dispatch(postPublishPacket({
      publishPacket: {
        "wellApinumber": difaDetails?.wellApiNumber,
        "difaId": difaId,
        "IsAllowViewtoCustomer": checked,
      },
      source: "report-builder",
    },
    ));
  }

  useEffect(() => {
    if (publishPacketNotification?.type === 'publish-success') {
      dispatch(resetPublishPacketNotification())
      setShowPublishedDialog(true)
    }
    if (publishPacketNotification?.type === 'publish-failed') {
      toast.error(<div className="flex flex-col">
        <div className="text-lg">
          Opps!!
        </div>
        <div className="text-sm">
          Failed to publish report. Please try again later.
        </div>
      </div>)
      dispatch(resetPublishPacketNotification())
    }

  }, [publishPacketNotification])

  const navigateToPreview = () => {
    setShowPublishDialog(false);
    setShowPublishedDialog(false);
    setViewMode("preview-published-report");
  };

  const redirectToDashboard = () => {
    dispatch(resetPacketBuilderState());
    navigate("/", {
      state: {}
    })
  };



  const handleRemove = (remove: boolean) => {
    !remove && setShowDeleteDialog(false);
    if (remove) {
      setShowDeleteDialog(false);
      dispatch(removePacket({
        publishPacket: {
          "wellApiNumber": difaDetails?.wellApiNumber,
          "difaId": difaId
        },
        source: 'report-preview'
      }));
      dispatch(resetPacketBuilderState())
      toast.success(
        <div className="flex flex-col">
          <div className="text-lg">
            Document deleted.
          </div>
          <div className="text-sm">
          DIFA report has been successfully removed.
          </div>
        </div>,
        { style: { width: "400px" } }
      );
      navigate("/difa-details", {
        state: { difaId, customerName, wellName }
      });
    }
  }

  const reportTitle = draftId ? "DIFA report # " + draftId : "DIFA report";

  return (
    <div className="packet-builder">
      <Header
        pageName="DIFA packet builder"
        difaPacketName={reportTitle}
        customerName={customerName}
        wellName={wellName}
        source={viewMode}
        onSaveHandler={() => { }}
        onPublishHandler={() => { setShowEditDialog(true) }}
        onDeleteHandler={() => { setShowDeleteDialog(true) }}
      />
      <div className="flex flex-col gap-5 p-5 preview-header">
        <div className="text-container">
          <span className={`text-part cursor-pointer ${currentPage === PAGE_WELL_RUN_LIFE && 'selected'}`} onClick={() => setCurrentPage(PAGE_WELL_RUN_LIFE)}>Run life Summary</span>
          <span className={`text-part cursor-pointer ${currentPage === PAGE_CAUSE_OF_FAILURE && 'selected'}`} onClick={() => setCurrentPage(PAGE_CAUSE_OF_FAILURE)}>Cause of Failure</span>
          <span className={`text-part cursor-pointer ${currentPage === PAGE_DIFA_INSPECTION && 'selected'}`} onClick={() => setCurrentPage(PAGE_DIFA_INSPECTION)}>DIFA inspection</span>
          <span className={`text-part cursor-pointer ${currentPage === PAGE_SCALE_DEPOSIT && 'selected'}`} onClick={() => setCurrentPage(PAGE_SCALE_DEPOSIT)}>Scale deposit analysis results</span>
          <span className={`text-part cursor-pointer ${currentPage === PAGE_PULL_AND_INBOUND && 'selected'}`} onClick={() => setCurrentPage(PAGE_PULL_AND_INBOUND)}>Inbound and pull</span>
          <span className={`text-part cursor-pointer ${currentPage === PAGE_MATCH && 'selected'}`} onClick={() => setCurrentPage(PAGE_MATCH)}>Match</span>
          <span className={`text-part cursor-pointer ${currentPage === PAGE_PRODUCTION && 'selected'}`} onClick={() => setCurrentPage(PAGE_PRODUCTION)}>Production</span>
          <span className={`text-part cursor-pointer ${currentPage === PAGE_INSTALL_AND_WELL_OPERATIONS && 'selected'}`} onClick={() => setCurrentPage(PAGE_INSTALL_AND_WELL_OPERATIONS)}>Well operations and install</span>
          <span className={`text-part cursor-pointer ${currentPage === PAGE_ESP_SIZING && 'selected'}`} onClick={() => setCurrentPage(PAGE_ESP_SIZING)}>ESP sizing</span>
        </div>
      </div>
      <div className="flex flex-col md:flex-row gap-5 p-7 h-fit">
        <div className="flex-auto flex flex-col w-full md:w-3/4 gap-5">
          {currentPage === PAGE_WELL_RUN_LIFE && (
            <WellRunLifeInformation difaId={difaId} source="preview-report" />
          )}
          {currentPage === PAGE_ESP_SIZING && (
            <EspSizing
              wellApiNumber={difaDetails?.wellApiNumber}
              source="preview-report"
              difaId={difaId}
            />
          )}
          {currentPage === PAGE_INSTALL_AND_WELL_OPERATIONS && (
            <InstallAndWellOperation
              wellApiNumber={difaDetails?.wellApiNumber}
              difaId={difaDetails?.difaId}
              source="preview-report"
            />
          )}
          {currentPage === PAGE_PRODUCTION && <Production source="preview-report" />}
          {currentPage === PAGE_PULL_AND_INBOUND && (
            <PullAndInbound
              wellApiNumber={difaDetails?.wellApiNumber}
              difaId={difaDetails?.difaId}
              source="preview-report" />
          )}
          {currentPage === PAGE_MATCH && <Match source="preview-report" />}
          {currentPage === PAGE_DIFA_INSPECTION && <DIFAInspection wellApiNumber={difaDetails?.wellApiNumber}
            difaId={difaDetails?.difaId}
            source="preview-report" />}
          {currentPage === PAGE_CAUSE_OF_FAILURE && <CauseOfFailure source="preview-report" />}
          {currentPage === PAGE_SCALE_DEPOSIT && <ScaleDeposite source="preview-report" />}
        </div>
      </div>
      <PublishedDifaDialog show={showPublishedDialog} close={() => { setShowEditDialog(false); setShowPublishedDialog(false) }} onRedirect={navigateToPreview} onBackToPI={redirectToDashboard} mode="report" />
      <PublishEditedDifaDialog show={showEditDialog} close={() => { setShowEditDialog(false); }} submit={(checked) => publishReport(checked)} />
      <RemoveConfirmationDialog show={showDeleteDialog} mode='report' close={(remove: boolean) => handleRemove(remove)}
      />
    </div>
  )
}

export default DifaReportPreview
