// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.difa-details {
  background: #001023;
}

.difa-detail-content {
  background: #001023;
  display: flex;
  justify-content: center;
  padding: 24px 32px;
}
.difa-detail-content .difa-container {
  display: flex;
  width: 100%;
  gap: 24px;
  justify-content: space-between;
}
.difa-detail-content .difa-container .left-side-container {
  border-radius: 8px;
  border: 1px solid #4a5463;
  background: linear-gradient(181deg, #253240 -16.87%, rgba(37, 48, 64, 0.4) 35.14%);
  overflow: hidden;
}
.difa-detail-content .difa-container .rigth-side-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow: hidden;
}
.difa-detail-content .difa-details-header {
  display: flex;
  align-items: center;
  padding: 20px 24px;
  color: #f7f9f9;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  border-bottom: 1px solid #4a5463;
}`, "",{"version":3,"sources":["webpack://./src/features/DifaPages/DifaDetails.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;AACJ;;AAEA;EACI,mBAAA;EACA,aAAA;EACA,uBAAA;EACA,kBAAA;AACJ;AACI;EACI,aAAA;EACA,WAAA;EACA,SAAA;EACA,8BAAA;AACR;AACQ;EAEI,kBAAA;EACA,yBAAA;EACA,kFAAA;EAGA,gBAAA;AAFZ;AAKQ;EAEI,aAAA;EACA,sBAAA;EACA,SAAA;EACA,gBAAA;AAJZ;AAeI;EACI,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,gCAAA;AAbR","sourcesContent":[".difa-details {\n    background: #001023;\n}\n\n.difa-detail-content {\n    background: #001023;\n    display: flex;\n    justify-content: center;\n    padding: 24px 32px;\n\n    .difa-container {\n        display: flex;\n        width: 100%;\n        gap: 24px;\n        justify-content: space-between;\n\n        .left-side-container {\n          //  width: 35.47%;\n            border-radius: 8px;\n            border: 1px solid #4a5463;\n            background: linear-gradient(181deg,\n                    #253240 -16.87%,\n                    rgba(37, 48, 64, 0.4) 35.14%);\n            overflow: hidden;\n        }\n\n        .rigth-side-container {\n          //  width: 62.69%;\n            display: flex;\n            flex-direction: column;\n            gap: 24px;\n            overflow: hidden;\n        }\n\n        // /@media screen and (max-width: 768px) {\n\n        //     .left-side-container, .rigth-side-container {\n        //         width: 100%;\n        //     }  \n        // }\n    }\n\n    .difa-details-header {\n        display: flex;\n        align-items: center;\n        padding: 20px 24px;\n        color: #f7f9f9;\n        font-size: 18px;\n        font-style: normal;\n        font-weight: 600;\n        line-height: 28px;\n        border-bottom: 1px solid #4a5463;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
