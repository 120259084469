import React, { useEffect, useState } from 'react'
import DifaSwitch from '../../../common/switch/DifaSwitch'
import { useDispatch, useSelector } from 'react-redux';
import { setWellInstallation } from '../../difaPacketBuilderSlice';

const OperationalSummary : React.FC<{source: string}>= ({source}) => {
    
    const { wellInstallation } = useSelector(
        (state: any) => state.difaPacketBuilder
    );
    const dispatch = useDispatch();

    const [expanded, setExpanded] = useState(wellInstallation?.isOpSummarySelected || false);
    const [panel, setPanel] = useState(false);
    const [operationalSummary, setOperatonalSummary] = useState(wellInstallation?.opSummary || "");
    const onExpand = () => {
        setExpanded(!expanded);
        dispatch(setWellInstallation({ ...wellInstallation, isOpSummarySelected: !expanded }))
    }

    const onSummaryChange = (e: any) => {
        setOperatonalSummary(e.target.value)
        dispatch(setWellInstallation({ ...wellInstallation, opSummary: operationalSummary }))
    }

    useEffect(() => {
        if (!expanded) {
            setPanel(true);
        }
    }, [expanded])

    const isReport = source?.includes("report");
  
    return (
        <div className="difa-widget-containers">
            <div className="header-main">
                <div className="flex flex-row justify-between flex-wrap">
                    <div>
                        <h3>Operational summary</h3>
                        <h5>Summarize your observations about the ESP's Operational life based on the data presented above.</h5>
                    </div>
                    <div className="flex flex-row items-center gap-3">
                        <DifaSwitch checked={expanded} onChange={onExpand} />
                        <h4 className="whitespace-nowrap">Include in {isReport ? 'report' : 'packet'}</h4>
                    </div>
                </div>
            </div>
            <div className="flex flex-col gap-5 py-5 px-6 border-t border-dark">
                <textarea
                    className="card-textarea"
                    maxLength={500}
                    rows={6}
                    onChange={(e) => onSummaryChange(e)}
                    onBlur={(e) => onSummaryChange(e)}
                    value={operationalSummary}
                    placeholder="Enter operational summary here..."
                ></textarea>
            </div>
        </div>
    )
}

export default OperationalSummary
