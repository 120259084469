import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as DownArrow } from "../../../images/chevron-down.svg";

const CustomDropdown: React.FC<{ options: { label: string; icon: string, isDisabled: boolean }[], onSelect: (option: string) => void }> = ({ options, onSelect }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('More options');
  const dropdownRef = useRef<HTMLDivElement>(null);


  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleOptionClick = (option: string) => {
    setSelectedOption(option);
    setIsOpen(false);
    onSelect(option);
    // Handle option click logic here
  };

  return (
    <div className="relative inline-block text-left" ref={dropdownRef}>
      <div className='h-auto text-[#F7F9F9]'>
        <button
          type="button"
          className="inline-flex justify-center gap-2 items-center rounded-md border border-dark shadow-sm px-4 py-2 bg-dark-700 text-sm font-medium"
          onClick={() => setIsOpen(!isOpen)}
        >
          More options
          <DownArrow />
        </button>
      </div>

      {isOpen && (
        <div className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg extra-options">
          <div className="py-1 packet-edit-btn" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
            {options?.map((option, index) => (
              <button
                key={index}
                className="flex items-center px-2 text-sm text-gray-700 hover:cursor-pointer"
                onClick={() => handleOptionClick(option.label)}
                disabled={option.isDisabled}
              >
                <img src={option.icon} alt={option.label} />
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomDropdown;