import React, { useEffect, useState } from 'react';
import { ReactComponent as ButtonMenuImg } from "../../../images/button-menu-image.svg";
import RemoveConfirmation from '../gallery/RemoveConfirmation';
import RemoveIcon from "../../../images/remove.svg";
import EditIcon from "../../../images/edit.svg";
import RenameIcon from "../../../images/rename.svg";
import CreateGallery from '../gallery/CreateGallery';
import { useDispatch, useSelector } from 'react-redux';
import { renameGallery } from '../../difaPacketBuilder/difaPacketBuilderSlice';
import { toaster } from '../services/ToasterService';

interface CustomImageDropdownProps {
    gl: any;
    showRename?: boolean,
    source: string;
    removeGallery: (galleryId: string) => void;
    editGalleryHandle: (showEditGallery: boolean, gl: any) => void;
    onRenameSuccess?: () => void;
}

const CustomImageDropdown: React.FC<CustomImageDropdownProps> = ({ gl, removeGallery, editGalleryHandle, showRename = true, source, onRenameSuccess = () => { } }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [showRemove, setShowRemove] = useState(false);
    const [showGalleryNameDialog, setShowGalleryNameDialog] = useState(false);
    const dispatch = useDispatch();
    const {
    } = useSelector((state: any) => state?.difaPacketBuilder);

    const options = [
        { label: 'Edit', show: true, icon: EditIcon },
        { label: 'Rename', show: showRename, icon: RenameIcon },
        { label: 'Remove', show: true, icon: RemoveIcon },
    ];

    const handleRemove = (remove: boolean) => {
        setShowRemove(false);
        if (remove) {
            removeGallery(gl?.galleryID);
        }
    }

    const handleOptionClick = (option: string) => {
        setIsOpen(false);
        // Handle option click logic here
        if (option === "Edit") {
            editGalleryHandle(true, gl)
        }
        if (option === 'Remove') {
            setShowRemove(true);
        }
        if (option === 'Rename') {
            setShowGalleryNameDialog(true)
        }
    };

    const handleGalleryClose = async (galleryName?: string) => {
        if (galleryName) {
            const response = await dispatch(renameGallery({ galleryId: gl?.galleryID, galleryName, source }));
            if (response?.meta?.requestStatus === 'fulfilled') {
                toaster.success('Gallery renamed', "To see your images select the gallery.");
                onRenameSuccess();
                setShowGalleryNameDialog(false);
            }
            else if (response?.error) {
                toaster.error(typeof response?.payload === 'object' ? response?.payload.message : response?.payload);
            }
        } else {
            setShowGalleryNameDialog(false);
        }
    }

    return (
        <>
            <ButtonMenuImg className="absolute right-0 top-1" onClick={() => setIsOpen(!isOpen)} />
            {isOpen && (
                <div className="origin-top-right absolute top-7 right-0 mt-2 w-56 rounded-md shadow-lg bg-dark-800 border border-dark p-1">
                    <div role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                        {options?.filter(x => x.show)?.map((option, index) => (
                            <button
                                key={index}
                                className="px-2 py-3 text-sm text-gray-700 hover:cursor-pointer"
                                onClick={() => handleOptionClick(option.label)}
                            >
                                <img src={option.icon} alt={option.label} />
                            </button>
                        ))}
                    </div>
                </div>
            )}

            <RemoveConfirmation
                show={showRemove}
                mode='gallery'
                close={(remove: boolean) => handleRemove(remove)}
            />
            {
                showGalleryNameDialog &&
                <CreateGallery show={showGalleryNameDialog} close={handleGalleryClose} updateGalleryName={true} gl={gl} />
            }
        </>
    );
};

export default CustomImageDropdown;