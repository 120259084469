import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

const PreInspection = () => {

    const { difaDetails } = useSelector((state: any) => state.difaDetails);
    const navigate = useNavigate();

    const location = useLocation()
    const { difaId, customerName } = location.state || {};

    const redirectToPIS = () => {
        navigate("pi-summary", { state: { difaId: difaId, customerName: customerName, wellName: difaDetails.wellName } })
    }

    return (
        <div className="tab-content-box">
            <div className="content">
                <div dangerouslySetInnerHTML={{ __html: difaDetails.preInspectionSummary?.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>') 
                    || "No Pre-inspection snapshot available. For full details of events that lead up to pull. Click on the \"View full summary\" button." }} />
            </div>
            <div className="view-summary">
                <button className="footer-btn" onClick={redirectToPIS}>
                    View full summary
                </button>
            </div>
        </div>

    );
}

export default PreInspection;