import React, { useEffect, useRef, useState } from 'react';
import closeSlider from "../../../../images/close-slider.svg";
import Helpers from '../../../../utilities/Helpers';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../store';
import { postEquipmentAction } from '../../DIFAInspectionSlice';


interface Toggle {
    open: boolean;
    onCloseModal: any;
    EquipmentData: any;
}

const EquipmentAction: React.FC<Toggle & { newTable: () => void }> = ({ open, onCloseModal, EquipmentData, newTable }) => {

    const dispatch = useDispatch<AppDispatch>();
    const overlayRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleOutsideClick = (event: MouseEvent) => {
            if (overlayRef.current && !overlayRef.current.contains(event.target as Node)) {
                onCloseModal(); // Close overlay if clicked outside
            }
        };

        // Add event listener on mount
        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            // Clean up event listener on unmount
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [onCloseModal]);
    const [formData, setFormData] = useState({
        action: "",
        results: "",
        comments: "",
    });


    const DifaActions = [
        'Dismantled',
        'Inspected',
        'Verified shaft rotation',
        'Verified shaft travel',
        'N/A for dismantle',
        'Place in C/O inventory',
        'Sent to B/A for testing',
        'Sent to retest'
    ];
    const DifaResults = ['Failed',
        'Pass',
        'Not inspected'
    ];

    useEffect(() => {
        document
            .getElementById("slider")
            ?.classList.toggle(open ? "open" : "close");

        return () => {
            document.getElementById("slider")?.classList.toggle("close");
        };
    }, [open]);

    document.querySelectorAll(".section-header").forEach((header) => {
        header.addEventListener("click", () => {
            header.parentElement?.classList.toggle("section-open");
        });
    });

    const onClick = () => {
        onCloseModal();
    };

    const isFormValid = formData.results?.trim() !== "" && formData.results?.trim() !== "";
    const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleChangeTxt = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleCancel = () => {
        setFormData({ action: "", results: "", comments: "" });
        if (onCloseModal) {
            onCloseModal();
        }
    };
    const handleSave = async () => {
        const payload = {
            partType: EquipmentData.partType,
            serialNumber: EquipmentData.serialNumber,
            equipmentDescription: EquipmentData.equipementDescription,
            difaAction: formData.action,
            results: formData.results,
            comments: formData.comments,
        };

        try {
            const result = await dispatch(postEquipmentAction(payload)).unwrap();
            console.log("Save successful from post Action API:", result);
            newTable();
            onCloseModal(); // Close the modal on success
        } catch (error) {
            console.error("Error saving equipment action:", error);

        }
    };

    return (
        <div ref={overlayRef} className="overlay-container">
            <div className="main-component !h-screen">
                <CardHeader onClose={onClick} />
                {Helpers.isObjectNotEmpty(EquipmentData) ? (
                    <div className="flex flex-col px-8 py-6 gap-8 text-neutral-grey justify-between">
                        <div className="difa-widget-containers">
                            <div className="header-main font-semibold text-xl">Details</div>
                            <div className="flex flex-col gap-4 px-6 pb-5">
                                <div className="flex flex-col gap-4">
                                    <div className="input-field-container">
                                        <label className="input-field-caption">Serial number</label>
                                        <p className="bg-dark-700 border border-dark text-base rounded-lg py-2.5 px-3 shadow-md">{EquipmentData?.serialNumber}</p>
                                    </div>
                                    <div className="input-field-container">
                                        <label className="input-field-caption">Equipment details</label>
                                        <p className="bg-dark-700 border border-dark text-base rounded-lg py-2.5 px-3 shadow-md">{EquipmentData?.equipementDescription}</p>
                                    </div>
                                </div>
                                <div className="flex fex-col md:flex-row items-center justify-between gap-4">
                                    <div className="input-field-container">
                                        <label className="input-field-caption">DIFA action</label>
                                        <select
                                            name="action"
                                            value={formData.action}
                                            onChange={handleChange}
                                            className="input-field"
                                        >
                                            <option value="Select action">Select action</option>
                                            {DifaActions.map((option, index) => (
                                                <option key={index} value={option}>
                                                    {option}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="input-field-container">
                                        <label className="input-field-caption">Results</label>
                                        <select
                                            name="results"
                                            value={formData.results}
                                            onChange={handleChange}
                                            className="input-field"
                                        >
                                            <option value="Select action">Select result</option>
                                            {DifaResults.map((option, index) => (
                                                <option key={index} value={option}>
                                                    {option}
                                                </option>
                                            ))}                    </select>
                                    </div>
                                </div>

                                <textarea
                                    name="comments"
                                    maxLength={500}
                                    rows={6}
                                    value={formData.comments}
                                    onChange={handleChangeTxt}
                                    placeholder="Add comments here"
                                    className="card-textarea"
                                ></textarea>
                            </div>
                        </div>
                        <div className="gap-2 bottom-0 right-0 fixed p-8">
                            <button onClick={handleCancel} className="bg-dark-800 px-4 py-2.5 rounded-lg border border-dark mr-2">
                                Cancel
                            </button>
                            <button
                                disabled={!isFormValid}
                                onClick={handleSave}
                                className={`${isFormValid ? "save-button" : "bg-dark-700 px-4 py-2.5 rounded-lg text-gray-200 border border-dark cursor-not-allowed"}`}
                            >
                                Save Details
                            </button>

                        </div>
                    </div>
                ) : (
                    <div>
                        <div className="text-lg text-center py-40">No data found</div>
                    </div>
                )}
            </div>
        </div>
    )
}

const CardHeader: React.FC<any> = ({ onClose }) => {
    return (
        <div className="report-card-header !justify-start">
            <div className="header-icon" onClick={onClose}>
                <img
                    src={closeSlider}
                    onClick={onClose}
                    alt="icon"
                />
            </div>
            <div className="text-base font-bold">
                Equipment action
            </div>
        </div>
    );
};

export default EquipmentAction