// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown-container {
  position: relative;
}

.selected-item {
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown-arrow {
  width: 16px;
  height: 16px;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
}

.dropdown-item {
  padding: 10px;
  cursor: pointer;
  display: flex;
}
.dropdown-item :hover {
  opacity: 0.5;
}

.filter-label {
  padding: 10px;
  font-weight: bold;
}

.left-25 {
  left: 25%;
}`, "",{"version":3,"sources":["webpack://./src/features/common/gallery/EquipmentDropdown.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;AACJ;;AAEE;EACE,WAAA;EACA,aAAA;EACA,kBAAA;EACA,eAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;AACJ;;AAEE;EACE,WAAA;EACA,YAAA;AACJ;;AAEE;EACE,kBAAA;EACA,SAAA;EACA,OAAA;EACA,QAAA;AACJ;;AAGE;EACE,aAAA;EACA,eAAA;EACA,aAAA;AAAJ;AAGI;EACI,YAAA;AADR;;AAOE;EACE,aAAA;EACA,iBAAA;AAJJ;;AAOE;EACE,SAAA;AAJJ","sourcesContent":[".dropdown-container {\n    position: relative;\n  }\n  \n  .selected-item {\n    color: #fff;\n    padding: 10px;\n    border-radius: 5px;\n    cursor: pointer;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n  }\n  \n  .dropdown-arrow {\n    width: 16px;\n    height: 16px;\n  }\n  \n  .dropdown-menu {\n    position: absolute;\n    top: 100%;\n    left: 0;\n    right: 0;\n    \n  }\n  \n  .dropdown-item {\n    padding: 10px;\n    cursor: pointer;\n    display: flex;\n    \n    \n    :hover {\n        opacity: 0.5;\n      }\n}\n  \n  \n  \n  .filter-label {\n    padding: 10px;\n    font-weight: bold;\n  }\n  \n  .left-25{\n    left:25%\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
