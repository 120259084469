import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import closeIcon from "./../../../images/close-icon.svg";
import DeleteIcon from './../../../images/trash-01.svg';

import {
  BeforeSaveEventArgs,
  EditCompleteEventArgs,
  ImageEditorComponent,
  Theme,
} from "@syncfusion/ej2-react-image-editor";
import {
  Browser,
  EmitType,
  getComponent,
  isNullOrUndefined,
  registerLicense,
} from "@syncfusion/ej2-base";
import { getFileType, toDataURL } from "../services/UtilityService";
import { useDispatch, useSelector } from "react-redux";
import RemoveConfirmation from "./RemoveConfirmation";
import { fetchImageGalleryByID, modifyImageDetails, removeGallery, removeImage, resetCreateGalleryState } from "../../difaPacketBuilder/difaPacketBuilderSlice";
import { toast } from "react-toastify";
import SaveAlert from "./SaveAlert";
import { toaster } from "../services/ToasterService";
registerLicense(process.env.REACT_APP_SYNCFUSION_LICENSE_KEY!);

interface EditGalleryComponentProps {
  show: boolean;
  wellApiNumber: string;
  source: string;
  galleryID: string;
  onGalleryClose?: () => void;
  onAddNewImages?: () => void;
  onRemoveGallery?: (galleryId: string) => void,
}

const EditGalleryComponent: React.FC<EditGalleryComponentProps> = ({
  show,
  wellApiNumber,
  galleryID,
  onGalleryClose = () => { },
  onAddNewImages = () => { },
  source,
}) => {
  const [photoEditorId, setPhotoEditorId] = useState('');
  const [showDialog, setShowDialog] = useState(false);
  const [showRemove, setShowRemove] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [photoDetailsUpdated, setPhotoDetailsUpdated] = useState(false);
  const [removeMode, setRemoveMode] = useState('gallery');
  const [galleryDetails, setGalleryDetails] = useState({} as any);
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const [tempActiveItemIndex, setTempActiveItemIndex] = useState(null as any);
  const [description, setDescription] = useState(null as any);
  const dispatch = useDispatch();
  const isPreview = source === "preview-packet" || source === "preview-report";
  const {
    loading,
    galleryTransactionMsg,
    activeGalleryDetails
  } = useSelector((state: any) => state?.difaPacketBuilder);

  const toolbar = [
    "Annotate",
    "Undo",
    "Redo",
    "Text",
    "Pen",
    "Line",
    "Rectangle",
    "Ellipse",
    "Arrow",
    "Image",
    "Transform",
    "Finetune",
    "Crop",
    "Filter",
    "Frame",
    "Resize",
    "Redact",
    "Confirm",
    "Reset",
    "ZoomIn",
    "ZoomOut",
    "Pan",
    { id: 'delete', template: `<div><img style="width:20px; height:17px" src='${DeleteIcon}'/></div>`, align: 'Right' },
    { id: 'save-action', template: `<span style="font-size: 17px;margin-left: 10px;" class="e-btn-icon e-icons e-btn-save e-icons"></span>`, align: 'Right' },

  ] as any;
  useEffect(() => {
    setShowDialog(show);
  }, [show]);
  const close = () => {
    setShowDialog(false);
    onGalleryClose();
  };

  useEffect(() => {
    setTimeout(() => {
      setPhotoEditorId(`image-editor${new Date().getTime()}`);
    });
  }, [])

  const descriptionChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(e.target?.value);
    setPhotoDetailsUpdated(true);
  }

  const activeItem = () => {
    if (activeGalleryDetails?.reportImageDetails?.length) {
      return activeGalleryDetails.reportImageDetails[activeItemIndex];
    }
    return {};
  }

  useEffect(() => {
    loadImage(activeItem());
    setDescription(activeItem()?.description);
  }, [activeItemIndex])

  useEffect(() => {
    dispatch(fetchImageGalleryByID({ galleryID, source }));
  }, [galleryID])

  useEffect(() => {
    if (activeGalleryDetails) {
      setGalleryDetails(activeGalleryDetails);
      if (activeGalleryDetails?.reportImageDetails?.length) {
        setDescription(activeItem().description);
        loadImage(activeItem());
      }
    }
  }, [activeGalleryDetails])

  let imgObj: any = useRef(null);

  const handleRemove = async (remove: boolean) => {
    setShowRemove(false);
    if (remove) {
      if (removeMode === 'gallery') {
        const response = await dispatch(removeGallery({ galleryId: galleryDetails?.galleryID, source }));
        if (response?.meta?.requestStatus === 'fulfilled') {
          toaster.success('Gallery removed', "To see your images select the gallery.");
          setShowDialog(false);
          onGalleryClose();
        }
        else if (response?.error) {
          toaster.error(typeof response?.payload === 'object' ? response?.payload.message : response?.payload);
        }
      } else {
        const response = await dispatch(removeImage({ imageId: activeItem()?.reportImageDetailID, source }));
        if (response?.meta?.requestStatus === 'fulfilled') {
          toaster.success('Image removed', "To see your images select the gallery.");
          dispatch(fetchImageGalleryByID({ galleryID, source }));
        }
        else if (response?.error) {
          toaster.error(typeof response?.payload === 'object' ? response?.payload.message : response?.payload);
        }
      }
      setPhotoDetailsUpdated(false);

    }
  }

  const handleSaveAlertClose = (leave: boolean) => {
    if (leave) {
      setActiveItemIndex(tempActiveItemIndex);
      setPhotoDetailsUpdated(false);
    }
    setShowAlert(false);
  }

  const handleChangeActiveItem = async (index: number) => {
    if (photoDetailsUpdated) {
      setTempActiveItemIndex(index);
      await updateImageDetails();
    } else {
      setActiveItemIndex(index);
    }
  }

  const toolbarItemClicked = async (args: any) => {
    if (args.item.id === 'delete') {
      setShowRemove(true);
      setRemoveMode('single');
    } else if (args.item.id === 'save-action') {
      await updateImageDetails();
    }
  }

  const updateImageDetails = async (canClose?: boolean) => {
    if (photoDetailsUpdated) {
      let imageData = imgObj.current?.getImageData();
      let canvas = document.createElement('canvas');
      let ctx = canvas.getContext('2d') as any;
      canvas.width = imageData.width;
      canvas.height = imageData.height;
      ctx.putImageData(imageData, 0, 0);
      const fileBase64 = canvas.toDataURL();
      const updatedDetails = { ...activeItem(), fileBase64, description };
      const response = await dispatch(modifyImageDetails({ imageDetails: updatedDetails, source }));
      if (response?.meta?.requestStatus === 'fulfilled') {
        toaster.success('Image updated', "To see your images select the gallery.");
        dispatch(fetchImageGalleryByID({ galleryID, source }));
        if (canClose) {
          close();
        }
      }
      else if (response?.error) {
        toaster.error(typeof response?.payload === 'object' ? response?.payload.message : response?.payload);
      }
      setPhotoDetailsUpdated(false);
    }
  }

  const loadImage = (item: any) => {
    setTimeout(() => {
      if (item && imgObj.current) {
        imgObj.current?.open(item?.fileLink);
      }
    })
  };
  // Handler used to reposition the tooltip on page scroll
  const onScroll = () => {
    if (document.getElementById("image-editor_sliderWrapper")) {
      let slider = getComponent(
        document.getElementById("image-editor_sliderWrapper") as any,
        "slider"
      ) as any;
      slider.refreshTooltip(slider.tooltipTarget);
    }
  };
  if (!isNullOrUndefined(document.getElementById("right-pane"))) {
    (document.getElementById("right-pane") as any).addEventListener(
      "scroll",
      onScroll.bind(this)
    );
  }

  const editComplete = (args: EditCompleteEventArgs) => {
    if (['reset', 'file-open'].includes(args.action)) {
      setPhotoDetailsUpdated(false);
    } else {
      setPhotoDetailsUpdated(true);
    }
  }

  return (
    <>
      <Dialog open={showDialog} onClose={async () => {
        await updateImageDetails(true);
        close();
      }} className="relative z-50">
        <DialogBackdrop
          transition
          className="fixed inset-0 backdrop-blur-sm transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
        />

        <div className="fixed inset-0 z-50 w-screen">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <DialogPanel
              transition
              className="flex dialog-max-height relative transform overflow-hidden rounded-lg difa-background-with-border text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-sm md:min-w-4/5 md:w-4/5 md:max-w-full data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
            >
              <div className="difa-widget-containers w-full">
                <div className="header-main border-bottom">
                  <div className="flex flex-row justify-between">
                    <div>
                      {isPreview ? (
                        <h3>Image gallery</h3>
                      ) : (
                        <h3>{galleryDetails?.galleryName} gallery</h3>
                      )}
                    </div>
                    <a href="javascript:void(0)" onClick={close}>
                      <img src={closeIcon} alt="close" />
                    </a>
                  </div>
                </div>
                <div className="pl-6 pr-6 w-full flex flex-col overflow-y-auto image-container pb-16 table-main">
                  {showDialog && galleryDetails?.reportImageDetails && (
                    <div className="flex">
                      <div className="w-full max-w-80 pt-5 pr-5">
                        <h3>Images ({galleryDetails.reportImageDetails?.length})</h3>
                        {!isPreview &&
                          <a href="javascipt:void(0)" className="mt-3 difa-background-with-border w-full p-1 rounded-xl flex items-center justify-center gap-2" onClick={async () => {
                            await updateImageDetails();
                            onAddNewImages();
                          }}>
                            <span className="text-2xl">+</span> Add images
                          </a>
                        }
                        <div className="mt-6 flex gap-3 flex-wrap">
                          {
                            galleryDetails.reportImageDetails.map((photo: any, index: number) => (
                              <div className="flex flex-col gap-3 max-w-48 cursor-pointer" onClick={() => handleChangeActiveItem(index)}>
                                <div className={`difa - widget - containers max - w - 36 h - 28 pt - 2 pb - 2 field - background ${index === activeItemIndex ? 'active-item-border' : ''} `}>
                                  <div className="w-full h-full difa-background flex items-center">
                                    <img
                                      className="h-20 w-32"
                                      src={photo.thumbnailFileLink}
                                      alt="meter"
                                    ></img>
                                  </div>
                                </div>
                              </div>
                            ))
                          }
                        </div>
                      </div>
                      <div className="w-full">
                        <div className="control-pane w-full">
                          <div>
                            <div className="row">
                              <div className="col-lg-12">
                                <div className="difa-img-editor-container border-left">
                                  <div className="inline ">
                                    <div className="inline">
                                      {
                                        photoEditorId &&
                                        <ImageEditorComponent
                                          key={photoEditorId}
                                          id={photoEditorId}
                                          ref={imgObj}
                                          editComplete={editComplete}
                                          toolbar={toolbar}
                                          theme={Theme.Tailwind}
                                          toolbarItemClicked={(arags: any) => toolbarItemClicked(arags)}
                                        />
                                      }
                                    </div>
                                    <div className="flex flex-col gap-5 py-5 px-6 bg-dark-800 border-left">
                                      <textarea
                                        className="card-textarea"
                                        maxLength={500}
                                        rows={3}
                                        value={description}
                                        onChange={descriptionChange}
                                        placeholder="Write a short description to help people understand what this image is"
                                      ></textarea>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="flex-row flex border-top justify-between items-center h-16">
                  {!isPreview && (
                    <div className="btn-remove ml-6">
                      <button
                        className="header-btn-text w-32"
                        onClick={() => {
                          setRemoveMode('gallery');
                          setShowRemove(true);
                        }}
                      >
                        Remove&nbsp;Gallery
                      </button>
                    </div>
                  )}
                  <div className={`flex  gap-3 ${isPreview ? 'px-5' : ''}`}>
                    <div className="secondary-button">
                      <button
                        className="header-btn-text w-20"
                        onClick={close}
                      >
                        Close
                      </button>
                    </div>
                    <div className="primary-button mr-6">
                      <button
                        className="header-btn-text"
                        onClick={async () => updateImageDetails(true)}
                        disabled={!photoDetailsUpdated}
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </DialogPanel>
          </div>
        </div >
      </Dialog >

      <RemoveConfirmation
        show={showRemove}
        mode={removeMode as any}
        close={(remove: boolean) => handleRemove(remove)}
      />
      <SaveAlert show={showAlert} close={(remove: boolean) => handleSaveAlertClose(remove)} />
    </>
  );
};

export default EditGalleryComponent;
