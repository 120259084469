import React, { ChangeEvent, useEffect, useState } from "react";
import calenderIcon from "../../../images/calendar.svg";
import arrowUpIcon from "../../../images/arrow-narrow-up.svg";
import arrowDownIcon from "../../../images/arrow-down.svg";
import { ReactComponent as ArrowIcon } from "../../../images/chevron-down.svg";
import {
  AlarmHistoryParams,
  AlarmHistoryProps,
} from "../../models/PreInpectionSummary";
import {
  fetchFrequentAlarmReport,
  getAlarmHistoryData,
} from "../PreInspectionSummarySlice";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../common/loader/Loader";
import {
  formatDateInNumbers,
  formatTime,
} from "../../common/services/UtilityService";
import "./AlarmHistory.scss";
import FrequentHistoryAlarm from "./charts/FrequentHistoryAlarm";

const AlarmHistory: React.FC<AlarmHistoryProps> = ({ wellApiNumber }) => {
  const dispatch = useDispatch();
  const { alarmHistoryEvent, frequentHistoryAlarm, loading } = useSelector(
    (state: any) => state?.preInspectionSummary
  );

  const alarmHistoryOption = [
    { name: "All alarm event", value: "All" },
    { name: "Shutdowns", value: "Shutdown" },
    { name: "Idle", value: "Idle" },
  ];

  const [selectedOption, setSelectedOption] = useState<string>(
    alarmHistoryOption[0]?.value
  );
  const [order, setOrder] = useState<string>("asc");
  const [sortColumn, setSortColumn] = useState<string>("");

  const handleAlarmHistory = (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(e.target.value);
  };

  const handleSorting = (column: string) => {
    const newOrder = order === "asc" ? "desc" : "asc";
    setOrder(newOrder);
    setSortColumn(column);
  };


  useEffect(() => {
    const alarmHistoryParams: AlarmHistoryParams = {
      wellApiNumber: wellApiNumber,
      eventType: selectedOption,
      sortOrder: order,
    };
    if (wellApiNumber && selectedOption) {
      dispatch(getAlarmHistoryData(alarmHistoryParams));
      dispatch(
        fetchFrequentAlarmReport({ wellApiNumber, eventType: selectedOption })
      );
    }
  }, [dispatch, wellApiNumber, selectedOption]);

  const sortedAlarmEvents = Array.isArray(alarmHistoryEvent?.alarmEventHistoryDto)
    ? [...alarmHistoryEvent.alarmEventHistoryDto].sort((a: any, b: any) => {
      if (sortColumn === "event") {
        return order === "asc"
          ? a.event.localeCompare(b.event)
          : b.event.localeCompare(a.event);
      } else if (sortColumn === "date") {
        return order === "asc"
          ? new Date(a.eventDate).getTime() - new Date(b.eventDate).getTime()
          : new Date(b.eventDate).getTime() - new Date(a.eventDate).getTime();
      }
      return 0;
    })
    : [];

  return (
    <div className="alarm-history flex flex-col rounded-lg">
      {loading && <Loader isOverlay />}
      <div className="alarm-history-header flex items-center text-lg font-semibold">
        Alarm History
      </div>
      <div className="alarm-history-body flex flex-col p-6 gap-6">
        <div className="alarm-history-event flex flex-wrap justify-between gap-3">
          <div className="inline-block relative w-56">
            <select
              onChange={handleAlarmHistory}
              value={selectedOption}
              className="alarm-history-event-filter block appearance-none w-full px-4 py-2.5 pr-8 rounded-lg text-sm font-medium focus:outline-none"
            >
              {alarmHistoryOption.map((option, index) => (
                <option key={index} value={option?.value}>
                  {option?.name}
                </option>
              ))}
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2.5">
              <ArrowIcon className="h-5 w-5" stroke="#F7F9F9" />
            </div>
          </div>
          <div className="alarm-history-event-date flex items-center gap-2 rounded-lg">
            <img src={calenderIcon} alt="calender-icon" />
            {alarmHistoryEvent && (
              <span className="text-sm font-semibold">
                {Object.keys(alarmHistoryEvent).length > 0 && alarmHistoryEvent?.eventStartDate &&
                  `${formatDateInNumbers(
                    alarmHistoryEvent?.eventStartDate
                  )} - ${formatDateInNumbers(alarmHistoryEvent?.eventEndDate)}`}
              </span>
            )}
          </div>
        </div>
        <div className="flex flex-col md:flex-row gap-6">
          <div className="alarm-history-card flex flex-col w-full md:w-1/2 rounded-lg">
            <div className="ah-card-header flex items-center text-lg font-semibold">
              Alarm event history
            </div>
            <div className="card-table relative">
              <table className="w-full text-left">
                <thead className="text-xs font-semibold top-0 sticky">
                  <tr className="table-row">
                    <th scope="col" className="px-5 py-4">
                      <div className="flex items-center">
                        Date
                        <img
                          onClick={() => handleSorting("date")}
                          src={
                            sortColumn === "date" && order === "asc"
                              ? arrowUpIcon
                              : arrowDownIcon
                          }
                          alt="sort icon"
                          className="ms-1.5 cursor-pointer"
                        />
                      </div>
                    </th>
                    <th scope="col" className="px-5 py-4">
                      <div className="flex items-center">
                        Event
                        <img
                          onClick={() => handleSorting("event")}
                          src={
                            sortColumn === "event" && order === "asc"
                              ? arrowUpIcon
                              : arrowDownIcon
                          }
                          alt="sort icon"
                          className="ms-1.5 cursor-pointer"
                        />
                      </div>
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
            <div className="card-table-body relative overflow-y-auto">
              <table className="w-full text-left">
                <tbody className="text-sm font-normal">
                  {sortedAlarmEvents.length > 0 ? (
                    sortedAlarmEvents.map((alarmEvent: any, index: number) => (
                      <tr key={index} className="table-row">
                        <td scope="col" className="px-5 py-3">
                          {formatDateInNumbers(alarmEvent?.eventDate) +
                            " " +
                            formatTime(alarmEvent?.eventDate)}
                        </td>
                        <td className="px-5 py-3">{alarmEvent?.event}</td>
                      </tr>
                    ))
                  ) : (
                    <tr className="table-row">
                      <td colSpan={2} className="text-lg text-center py-5">
                        No data found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="alarm-history-card flex flex-col w-full md:w-1/2 py-5 px-6 rounded-lg gap-4">
            <div className="frequent-card-header flex items-center text-lg font-semibold pb-5">
              Most frequent historical alarm events
            </div>
            {frequentHistoryAlarm?.length > 0 ? (
              <FrequentHistoryAlarm
                id="frequentChart"
                frequentHistoryData={frequentHistoryAlarm}
                selectedOption={selectedOption}
              />
            ) : (
              <div className="text-center text-lg">No data found</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlarmHistory;
