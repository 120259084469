import React from 'react';
import DifaCheckbox from '../../common/checkbox/DifaCheckbox';

interface CheckboxListProps {
    items: string[];
    selectedItems: string[];
    onItemChange: (item: string) => void;
    searchTerm: string;
}

const CheckboxList: React.FC<CheckboxListProps> = ({ items, selectedItems, onItemChange, searchTerm }) => {
    const filteredItems = items.filter(item =>
        item.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="flex flex-col justify-center">
            {filteredItems.length > 0 ? (
                filteredItems.map(item => (
                    <div key={item} className="flex items-center gap-2 px-3.5 py-2.5 font-normal text-base">
                        <DifaCheckbox type="checkbox" checked={selectedItems.includes(item)}
                            onChange={() => onItemChange(item)}
                        />
                        {item}
                    </div>
                ))
            ) : (
                <p>No items found</p>
            )}
        </div>
    );
};

export default CheckboxList;
