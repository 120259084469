import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
    createImageGallery,
    deleteImage, deleteImageGallery,
    getDaviationSurvey,
    getDifaTrackingDetails,
    getEquipmentDetails,
    getImageGalleryByID,
    getImagesGallery,
    getInstallReportImages,
    getPullReportImages,
    getSizingSummary,
    saveEspSizingDoc,
    saveWellandRunlife,
    updateImageDetails,
    updateImageGallery,
    GetDraft,
    GetDocuments,
    getInboundReportImages,
    publishDifaPacket,
    createDifaReportBuilder,
    renameImageGallery,
    getGalleryEquipments,
    deletePacket,
    getDifaPublishReport,
    deleteDraft
} from "./difaPacketBuilderServices";

export const fetchSizingSummary: any = createAsyncThunk(
    "packet-builder/getSizingSummary",
    async ({ wellApiNumber }: { wellApiNumber: string }) => {
        return await getSizingSummary(wellApiNumber);
    }
);

export const fetchPublishedDraft: any = createAsyncThunk(
    "packet-builder/GetDraft",
    async ({ wellApiNumber, difaId, source }: { wellApiNumber: string, difaId: string, source?: string }) => {
        return await GetDraft(wellApiNumber, difaId, source || '');
    }
);

export const fetchDocuments: any = createAsyncThunk(
    "packet-builder/GetDocuments",
    async ({ wellApiNumber, difaId, category, source }: { wellApiNumber: string, difaId: string, category: string, source?: string }) => {
        return await GetDocuments(wellApiNumber, difaId, category, source || '');
    }
);

export const fetchDifaPublishReport: any = createAsyncThunk(
    "packet-builder/getDifaPublishReport",
    async ({ wellApiNumber, difaId }: { wellApiNumber: string, difaId: string }) => {
        return await getDifaPublishReport(wellApiNumber, difaId);
    }
);


export const fetchDeviationSurvey: any = createAsyncThunk(
    "packet-builder/getDeviationSurvey",
    async ({ wellApiNumber }: { wellApiNumber: string }) => {
        return await getDaviationSurvey(wellApiNumber);
    }
);

export const fetchEquipmentDetails: any = createAsyncThunk(
    "packet-builder/getEquipmentDetails",
    async ({ wellApiNumber }: { wellApiNumber: string }) => {
        return await getEquipmentDetails(wellApiNumber);
    }
);

export const fetchInstallReportImages: any = createAsyncThunk(
    "packet-builder/getInstallReportImages",
    async ({ wellApiNumber, difaId, source }: { wellApiNumber: string, difaId: string, source?: string }) => {
        return await getInstallReportImages(wellApiNumber, difaId, source);
    }
);

export const fetchTrendsReportImages: any = createAsyncThunk(
    "packet-builder/getTrendsReportImages",
    async ({ wellApiNumber, difaId }: { wellApiNumber: string, difaId: string }) => {
        return await getInstallReportImages(wellApiNumber, difaId);
    }
);

export const fetchPullImageGallery: any = createAsyncThunk(
    "packet-builder/GetPullImageGallary",
    async ({ wellApiNumber, difaId, reportType = 'pull', source }: { wellApiNumber: string, difaId: string, reportType: string, source?: string }) => {
        return await getImagesGallery(wellApiNumber, difaId, reportType, source);
    }
);

export const fetchInstallImageGallery: any = createAsyncThunk(
    "packet-builder/GetInstallImageGallary",
    async ({ wellApiNumber, difaId, reportType = 'install', source }: { wellApiNumber: string, difaId: string, reportType: string, source?: string }) => {
        return await getImagesGallery(wellApiNumber, difaId, reportType, source);
    }
);

export const fetchInspectionImageGallary: any = createAsyncThunk(
    "packet-builder/GetInspectionImageGallary",
    async ({ wellApiNumber, difaId, reportType = 'inspection', source }: { wellApiNumber: string, difaId: string, reportType: string, source?: string }) => {
        return await getImagesGallery(wellApiNumber, difaId, reportType, source);
    }
);

export const fetchTrendsImageGallery: any = createAsyncThunk(
    "packet-builder/GetTrendsImageGallary",
    async ({ wellApiNumber, difaId, reportType = 'trend', source }: { wellApiNumber: string, difaId: string, reportType: string, source?: string }) => {
        return await getImagesGallery(wellApiNumber, difaId, reportType, source);
    }
);

export const fetchInboundImageGallery: any = createAsyncThunk(
    "packet-builder/GetInboundImageGallary",
    async ({ wellApiNumber, difaId, reportType = 'inbound', source }: { wellApiNumber: string, difaId: string, reportType: string, source?: string }) => {
        return await getImagesGallery(wellApiNumber, difaId, reportType, source);
    }
);

export const fetchImageGalleryByID: any = createAsyncThunk(
    "packet-builder/fetchImageGalleryByID",
    async ({ galleryID, source }: { galleryID: string, source?: string }) => {
        return await getImageGalleryByID(galleryID, source);
    }
);

export const fetchPullReportImages: any = createAsyncThunk(
    "difa/GetPullReportImages",
    async ({ wellApiNumber, difaId }: { wellApiNumber: string, difaId: string }) => {
        return await getPullReportImages(wellApiNumber, difaId);
    }
);

export const fetchInboundReportImages: any = createAsyncThunk(
    "difa/GetInboundReportImages",
    async ({ difaId, partType }: { difaId: string, partType: string }) => {
        return await getInboundReportImages(difaId, partType);
    }
);

export const postImageGallery: any = createAsyncThunk(
    "difa/createImageGallery",
    async (galleryDetails: { details: any, source?: string }, thunkAPI) => {
        try {
            return await createImageGallery(galleryDetails.details, galleryDetails.source || '');

        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data);
        }
    }
);

export const modifyImageGallery: any = createAsyncThunk(
    "difa/modifyImageGallery",
    async (galleryDetails: { details: any, source?: string }) => {
        return await updateImageGallery(galleryDetails.details, galleryDetails.source || '');
    }
);

export const fetchGalleryEquipments: any = createAsyncThunk(
    "difa/getGalleryEquipments",
    async ({ wellApiNumber, difaId }: { wellApiNumber: string, difaId: string }) => {
        return await getGalleryEquipments(wellApiNumber, difaId);
    }
);

export const modifyImageDetails: any = createAsyncThunk(
    "difa/modifyImageDetails",
    async ({ imageDetails, source }: { imageDetails: any, source?: string }) => {
        return await updateImageDetails(imageDetails, source);
    }
);

export const renameGallery: any = createAsyncThunk(
    "difa/renameImageGallery", async ({ galleryId, galleryName, source }: { galleryId: number, galleryName: string, source?: string }) => {
        return await renameImageGallery(galleryId, galleryName, source);
    }
);

export const removeGallery: any = createAsyncThunk(
    "difa/removeGallery",
    async ({ galleryId, source }: { galleryId: string, source?: string }) => {
        return await deleteImageGallery(galleryId, source);
    }
);

export const removeDraft: any = createAsyncThunk(
    "difa/removeDraft",
    async ({ deleteObj, source }: { deleteObj: any, source?: string }) => {
        return await deleteDraft(deleteObj, source || '');
    }
);

export const removeImage: any = createAsyncThunk(
    "difa/removeImage",
    async ({ imageId, source }: { imageId: string, source?: string }) => {
        return await deleteImage(imageId, source);
    }
);

export const fetchGeneralReport: any = createAsyncThunk(
    "difa/getGeneralReport", async ({ wellApiNumber, difaId }: { wellApiNumber: string, difaId: string }) => {
        var response = await getDifaTrackingDetails(wellApiNumber, difaId);
        return response;
    }
);



export const postPublishPacket: any = createAsyncThunk(
    "Difa/postPublishPacket", async ({ publishPacket, source }: { publishPacket: any, source?: string }) => {
        return await publishDifaPacket(publishPacket, source || '');
    }
);

export const removePacket: any = createAsyncThunk(
    "Difa/removePacket", async ({ publishPacket, source }: { publishPacket: any, source?: string }) => {
        return await deletePacket(publishPacket, source || '');
    }
);


export const SaveAsDraft: any = createAsyncThunk(
    "Difa/SaveAsDraft", async ({ draftDetails, source }: { draftDetails: any, source?: string }) => {
        return await saveWellandRunlife(draftDetails, source || '');
    }
);

export const BeginReport: any = createAsyncThunk(
    "Difa/BeginReport", async (BeginReport: { BeginReport: any }) => {
        return await createDifaReportBuilder(BeginReport);
    }
);

export const pushEspSizingDoc: any = createAsyncThunk(
    "Difa/saveEspSizingDoc",
    async (installWellOperations: { payload: any, source?: string }) => {
        return await saveEspSizingDoc(installWellOperations.payload, installWellOperations.source || '');
    }
);

export const resetCreateGalleryState = createAction('RESET_ACREATE_GALLERY');


export const resetPublishPacketNotification = createAction('RESET_PUBISH_PACKET_NOTIFICATION');

export const resetPublishFile = createAction('RESET_PACKET_PUBLISH_FILE');

const initialState: any = {
    loading: false,
    sizingSummary: null,
    generalReport: null,
    deviationSurvey: null,
    equipmentDetails: null,
    installReportImages: null,
    pullReportImages: null,
    inboundReportImages: null,
    installImageGallery: null,
    inspectionImageGallery: null,
    inboundImageGallery: null,
    trendsImageGallery: null,
    trendsReportImages: null,
    pullImageGallery: null,
    activeGalleryDetails: null,
    production: {},
    match: null,
    scaleDeposit: null,
    message: null,
    wellInstallation: {},
    galleryTransactionMsg: null,
    wellRunLife: {},
    draftId: null,
    publishedId: null,
    publishPacketNotification: null,
    pullReasons: {},
    isInboundTestingSelected: false,
    difaInspection: {
        summary: " "
    },
    chmEmployee: [{ name: "", role: "" }],
    attendees: [{ name: "", role: "" }],
    causeOfFailure: {
        primaryFailedItems: "",
        primaryFailedSubComponents: "",
        failureCategoryItem: "",
        failureDescriptors: "",
        failureCategory: "",
        failureDescriptors2: "",
        genericCauseOfFailure: "",
        specificFailureCause: "",
    },
    champxEmp: [],
    publishedFileLink: null,
    galleryEquipments: null,
    preSelectedServiceReports: null,
    preSelectedOptimizationTickets: null,
    preSelectedgeneralReports: null,
    publishedPDF: null,
    isEdit: false,
};

const DifaPacketBuilderSlice = createSlice({
    name: "difaPacketBuilder",
    initialState,
    reducers: {
        setSizingSummary: (state, action) => {
            state.sizingSummary = action.payload;
        },
        setEditFlow: (state, action) => {
            state.isEdit = action.payload;
        },
        setProduction: (state, action) => {
            state.production = action.payload;
        },
        setMatch: (state, action) => {
            state.match = action.payload;
        },
        setScaleDeposit: (state, action) => {
            state.scaleDeposit = action.payload;
        },
        setWellInstallation: (state, action) => {
            state.wellInstallation = action.payload;
        },
        setWellRunLife: (state, action) => {
            state.wellRunLife = action.payload;
        },
        setPullReasons: (state, action) => {
            state.pullReasons = action.payload;
        },
        setInBoundSelection: (state, action) => {
            state.isInboundTestingSelected = action.payload;
        },
        setPublishedId: (state, action) => {
            state.publishedId = state.draftId;
        },
        setCauseOfFailure: (state, action) => {
            state.causeOfFailure = { ...state.causeOfFailure, ...action.payload };
        },
        setDifaInspection: (state, action) => {
            state.difaInspection = { ...state.difaInspection, ...action.payload };
        },
        setChmEmployee: (state, action) => {
            state.chmEmployee = action.payload;
        },
        setAttendees: (state, action) => {
            state.attendees = action.payload;
        },
        resetPacketBuilderState: () => initialState,
    },
    extraReducers: (builder: any) => {
        //pending
        builder.addCase(fetchSizingSummary.pending, (state: any) => {
            state.loading = true;
        });

        //fullfilled
        builder.addCase(fetchSizingSummary.fulfilled, (state: any, action: any) => {
            // state.sizingSummary = action.payload;
            state.loading = false;
        });

        //rejected
        builder.addCase(fetchSizingSummary.rejected, (state: any, action: any) => {
            state.message = action.payload;
            state.loading = false;
        });

        //pending
        builder.addCase(fetchDeviationSurvey.pending, (state: any) => {
            state.loading = true;
        });

        //fullfilled
        builder.addCase(fetchDeviationSurvey.fulfilled, (state: any, action: any) => {
            state.deviationSurvey = action.payload;
            state.loading = false;
        });

        //rejected
        builder.addCase(fetchDeviationSurvey.rejected, (state: any, action: any) => {
            state.message = action.payload;
            state.loading = false;
        });

        builder.addCase(fetchEquipmentDetails.pending, (state: any) => {
            state.loading = true;
        });

        //fullfilled
        builder.addCase(fetchEquipmentDetails.fulfilled, (state: any, action: any) => {
            state.equipmentDetails = action.payload;
            state.loading = false;
        });

        //rejected
        builder.addCase(fetchEquipmentDetails.rejected, (state: any, action: any) => {
            state.message = action.payload;
            state.loading = false;
        });


        builder.addCase(fetchInstallReportImages.pending, (state: any) => {
            state.loading = true;
        });

        //fullfilled
        builder.addCase(fetchInstallReportImages.fulfilled, (state: any, action: any) => {
            state.installReportImages = action.payload;
            state.loading = false;
        });

        //rejected
        builder.addCase(fetchInstallReportImages.rejected, (state: any, action: any) => {
            state.message = action.payload;
            state.installReportImages = [];
            state.loading = false;
        });

        builder.addCase(fetchTrendsReportImages.pending, (state: any) => {
            state.loading = true;
        });

        //fullfilled
        builder.addCase(fetchTrendsReportImages.fulfilled, (state: any, action: any) => {
            state.trendsReportImages = action.payload;
            state.loading = false;
        });

        //rejected
        builder.addCase(fetchTrendsReportImages.rejected, (state: any, action: any) => {
            state.message = action.payload;
            state.trendsReportImages = [];
            state.loading = false;
        });

        builder.addCase(fetchInstallImageGallery.pending, (state: any) => {
            state.loading = true;
        });

        //fullfilled
        builder.addCase(fetchInstallImageGallery.fulfilled, (state: any, action: any) => {
            state.installImageGallery = action.payload;
            state.loading = false;
        });

        //rejected
        builder.addCase(fetchInstallImageGallery.rejected, (state: any, action: any) => {
            state.message = action.payload;
            state.installImageGallery = [];
            state.loading = false;
        });


        builder.addCase(fetchInspectionImageGallary.pending, (state: any) => {
            state.loading = true;
        });

        //fullfilled
        builder.addCase(fetchInspectionImageGallary.fulfilled, (state: any, action: any) => {
            state.inspectionImageGallery = action.payload;
            state.loading = false;
        });

        //rejected
        builder.addCase(fetchInspectionImageGallary.rejected, (state: any, action: any) => {
            state.message = action.payload;
            state.inspectionImageGallery = [];
            state.loading = false;
        });

        builder.addCase(fetchTrendsImageGallery.pending, (state: any) => {
            state.loading = true;
        });

        //fullfilled
        builder.addCase(fetchTrendsImageGallery.fulfilled, (state: any, action: any) => {
            state.trendsImageGallery = action.payload;
            state.loading = false;
        });

        //rejected
        builder.addCase(fetchTrendsImageGallery.rejected, (state: any, action: any) => {
            state.message = action.payload;
            state.trendsImageGallery = [];
            state.loading = false;
        });

        builder.addCase(fetchInboundImageGallery.pending, (state: any) => {
            state.loading = true;
        });

        //fullfilled
        builder.addCase(fetchInboundImageGallery.fulfilled, (state: any, action: any) => {
            state.inboundImageGallery = action.payload;
            state.loading = false;
        });

        //rejected
        builder.addCase(fetchInboundImageGallery.rejected, (state: any, action: any) => {
            state.message = action.payload;
            state.inboundImageGallery = [];
            state.loading = false;
        });

        builder.addCase(fetchImageGalleryByID.pending, (state: any) => {
            state.loading = true;
        });

        //fullfilled
        builder.addCase(fetchImageGalleryByID.fulfilled, (state: any, action: any) => {
            state.activeGalleryDetails = action.payload;
            state.loading = false;
        });

        //rejected
        builder.addCase(fetchImageGalleryByID.rejected, (state: any, action: any) => {
            state.message = action.payload;
            state.loading = false;
        });

        builder.addCase(fetchPullImageGallery.pending, (state: any) => {
            state.loading = true;
        });

        //fullfilled
        builder.addCase(fetchPullImageGallery.fulfilled, (state: any, action: any) => {
            state.pullImageGallery = action.payload;
            state.loading = false;
        });

        //rejected
        builder.addCase(fetchPullImageGallery.rejected, (state: any, action: any) => {
            state.message = action.payload;
            state.pullImageGallery = [];
            state.loading = false;
        });

        builder.addCase(fetchPullReportImages.pending, (state: any) => {
            state.loading = true;
        });

        //fullfilled
        builder.addCase(fetchPullReportImages.fulfilled, (state: any, action: any) => {
            state.pullReportImages = action.payload;
            state.loading = false;
        });

        //rejected
        builder.addCase(fetchPullReportImages.rejected, (state: any, action: any) => {
            state.message = action.payload;
            state.pullReportImages = [];
            state.loading = false;
        });

        builder.addCase(fetchInboundReportImages.pending, (state: any) => {
            state.loading = true;
        });

        //fullfilled
        builder.addCase(fetchInboundReportImages.fulfilled, (state: any, action: any) => {
            state.inboundReportImages = action.payload;
            state.loading = false;
        });

        //rejected
        builder.addCase(fetchInboundReportImages.rejected, (state: any, action: any) => {
            state.message = action.payload;
            state.inboundReportImages = [];
            state.loading = false;
        });

        builder.addCase(fetchGeneralReport.pending, (state: any) => {
            state.loading = true;
        });

        //fullfilled
        builder.addCase(fetchGeneralReport.fulfilled, (state: any, action: any) => {
            state.generalReport = action.payload;
            state.loading = false;
        });

        //rejected
        builder.addCase(fetchGeneralReport.rejected, (state: any, action: any) => {
            state.message = action.payload;
            state.loading = false;
        });

        builder.addCase(postImageGallery.pending, (state: any) => {
            state.loading = true;
        });

        //fullfilled
        builder.addCase(postImageGallery.fulfilled, (state: any, action: any) => {
            state.galleryTransactionMsg = {
                key: new Date().getTime(),
                message: action.payload,
                type: 'create-success'
            };
            state.loading = false;
        });

        //rejected
        builder.addCase(postImageGallery.rejected, (state: any, action: any) => {
            state.galleryTransactionMsg = {
                key: new Date().getTime(),
                message: action.payload,
                type: 'create-error'
            };
            state.loading = false;
        });


        builder.addCase(modifyImageGallery.pending, (state: any) => {
            state.loading = true;
        });

        //fullfilled
        builder.addCase(modifyImageGallery.fulfilled, (state: any, action: any) => {
            state.galleryTransactionMsg = {
                key: new Date().getTime(),
                message: action.payload,
                type: 'update-success'
            };
            state.loading = false;
        });

        //rejected
        builder.addCase(modifyImageGallery.rejected, (state: any, action: any) => {
            state.galleryTransactionMsg = {
                key: new Date().getTime(),
                message: action.payload,
                type: 'update-error'
            };
            state.loading = false;
        });

        builder.addCase(fetchGalleryEquipments.pending, (state: any) => {
            state.loading = true;
        });

        //fullfilled
        builder.addCase(fetchGalleryEquipments.fulfilled, (state: any, action: any) => {
            state.galleryEquipments = action.payload;
            state.loading = false;
        });

        //rejected
        builder.addCase(fetchGalleryEquipments.rejected, (state: any, action: any) => {
            state.message = action.payload;
            state.loading = false;
        });

        //pending
        builder.addCase(renameGallery.pending, (state: any) => {
            state.loading = true;
        });

        //fullfilled
        builder.addCase(renameGallery.fulfilled, (state: any, action: any) => {
            state.galleryTransactionMsg = {
                key: new Date().getTime(),
                message: action.payload,
                type: 'rename-success'
            };
            state.loading = false;
        });

        //rejected
        builder.addCase(renameGallery.rejected, (state: any, action: any) => {
            state.galleryTransactionMsg = {
                key: new Date().getTime(),
                message: action.payload,
                type: 'rename-error'
            };
            state.loading = false;
        });

        builder.addCase(modifyImageDetails.pending, (state: any) => {
            state.loading = true;
        });

        //fullfilled
        builder.addCase(modifyImageDetails.fulfilled, (state: any, action: any) => {
            state.galleryTransactionMsg = {
                key: new Date().getTime(),
                message: action.payload,
                type: 'update-image-success'
            };
            state.loading = false;
        });

        //rejected
        builder.addCase(modifyImageDetails.rejected, (state: any, action: any) => {
            state.galleryTransactionMsg = {
                key: new Date().getTime(),
                message: action.payload,
                type: 'update-image-error'
            };
            state.loading = false;
        });

        builder.addCase(removeGallery.pending, (state: any) => {
            state.loading = true;
        });

        //fullfilled
        builder.addCase(removeGallery.fulfilled, (state: any, action: any) => {
            state.galleryTransactionMsg = {
                key: new Date().getTime(),
                message: action.payload,
                type: 'remove-success'
            };
            state.loading = false;
        });

        //rejected
        builder.addCase(removeGallery.rejected, (state: any, action: any) => {
            state.galleryTransactionMsg = {
                key: new Date().getTime(),
                message: action.payload,
                type: 'remove-error'
            };
            state.loading = false;
        });

        builder.addCase(removeImage.pending, (state: any) => {
            state.loading = true;
        });

        //fullfilled
        builder.addCase(removeImage.fulfilled, (state: any, action: any) => {
            state.galleryTransactionMsg = {
                key: new Date().getTime(),
                message: action.payload,
                type: 'remove-image-success'
            };
            state.loading = false;
        });

        //rejected
        builder.addCase(removeImage.rejected, (state: any, action: any) => {
            state.galleryTransactionMsg = {
                key: new Date().getTime(),
                message: action.payload,
                type: 'remove-image-error'
            };
            state.loading = false;
        });

        builder.addCase(resetCreateGalleryState, (state: any, action: any) => {
            state.galleryTransactionMsg = null;
        });

        builder.addCase(resetPublishFile, (state: any, action: any) => {
            state.publishedPDF = null;
        });

        builder.addCase(resetPublishPacketNotification, (state: any, action: any) => {
            state.publishPacketNotification = null;
        });

        builder.addCase(postPublishPacket.pending, (state: any) => {
            state.loading = true;
            state.publishPacketNotification = null;
        });

        //fullfilled
        builder.addCase(postPublishPacket.fulfilled, (state: any, action: any) => {
            state.message = action.payload;
            state.loading = false;
            state.publishPacketNotification = {
                type: 'publish-success',
                message: action.payload
            };
        });

        //rejected
        builder.addCase(postPublishPacket.rejected, (state: any, action: any) => {
            state.message = action.payload;
            state.loading = false;
            state.publishPacketNotification = {
                type: 'publish-failed',
                message: action.payload
            }
        });

        builder.addCase(removePacket.pending, (state: any) => {
            state.loading = true;
        });

        //fullfilled
        builder.addCase(removePacket.fulfilled, (state: any, action: any) => {
            state.message = action.payload;
            state.loading = false;
        });

        //rejected
        builder.addCase(removePacket.rejected, (state: any, action: any) => {
            state.message = action.payload;
            state.loading = false;
        });

        builder.addCase(SaveAsDraft.pending, (state: any) => {
            state.loading = true;
        });

        //fullfilled
        builder.addCase(SaveAsDraft.fulfilled, (state: any, action: any) => {
            state.draftId = action.payload?.draftID;
            state.message = action.payload;
            state.loading = false;
        });

        //rejected
        builder.addCase(SaveAsDraft.rejected, (state: any, action: any) => {
            state.message = action.payload;
            state.loading = false;
        });


        builder.addCase(pushEspSizingDoc.pending, (state: any) => {
            state.loading = true;
        });

        //fullfilled
        builder.addCase(pushEspSizingDoc.fulfilled, (state: any, action: any) => {
            state.message = action.payload;
            state.loading = false;
            state.draftId = action.payload?.draftID;
        });

        //rejected
        builder.addCase(pushEspSizingDoc.rejected, (state: any, action: any) => {
            state.message = action.payload;
            state.loading = false;
        });
        builder.addCase(fetchPublishedDraft.pending, (state: any) => {
            state.loading = true;
        });

        //fullfilled
        builder.addCase(fetchPublishedDraft.fulfilled, (state: any, action: any) => {
            state.message = action.payload;
            state.loading = false;
            state.draftId = action.payload?.draftID ?? (action.payload?.packetDraftDetailId ?? action.payload?.reportBuilderDraftDetailId);
            state.publishedId = action.payload?.reportBuilderDraftDetailId;
            const response = action.payload;
            if (response) {
                state.wellInstallation.isAlarmsSelected = response.isIncludeAlarms;
                state.pullReasons.failureCategory = response.failureCategory;
                state.pullReasons.failureDescriptors = response.failureDescriptors;
                state.wellInstallation.isDocumentationSelected = response.isIncludeDocumentation;
                state.IsIncludeESPSizing = response.isIncludeESPSizing;
                state.wellInstallation.isGallerySelected = response.isIncludeInstallImageGallery;
                state.wellInstallation.isOpSummarySelected = response.isIncludeOperationalSummery;
                state.wellRunLife.isRunsSelected = response.isIncludePriorRuns;
                state.production.isSelected = response.isIncludeProductionPlot;
                state.pullReasons.isPullSelected = response.isIncludeReasonForPull;
                state.isIncludeRunLifeDetails = response.isIncludeRunLifeDetails;
                state.IsIncludeTrendHistoryGallery = response.isIncludeTrendHistoryGallery;
                state.isIncludeWellInformation = response.isIncludeWellInformation;
                state.wellInstallation.opSummary = response.operationalSummery;
                state.isInboundTestingSelected = response.isIncludeInboundTesting;
                    state.optimizationTicketDetails = response.optimizationTicketDetails;
                state.priorRunDetails = response.priorRunDetails;
                if (response.priorRunDetails) {
                    const runs = JSON.parse(response.priorRunDetails);
                    state.wellRunLife.selectedDifas = runs?.map(((x: any) => ({ difaId: x.DifaID, selected: x.IsIncludeInReport })));
                }
                state.pullReasons.pullSummary = response.pullSummary;
                state.serviceReportsDetails = response.serviceReportsDetails;
                state.publishedFileLink = response.publishedFileLink;
                state.wellInstallation.additionalDocuments = response.additionalDocuments;
                try {
                    state.preSelectedServiceReports = response.serviceReportsDetails && JSON.parse(response.serviceReportsDetails);
                } catch (error) {
                    console.log('Invalid json ', error);
                    state.preSelectedServiceReports = null;
                }
                try {
                    state.preSelectedOptimizationTickets = response.optimizationTicketDetails && JSON.parse(response.optimizationTicketDetails);
                } catch (error) {
                    console.log('Invalid json ', error);
                    state.preSelectedOptimizationTickets = null;
                }
                try {
                    state.preSelectedgeneralReports = response.generelReportsDetails && JSON.parse(response.generelReportsDetails);
                } catch (error) {
                    console.log('Invalid json ', error);
                    state.preSelectedgeneralReports = null;
                }
            }
        });

        //rejected
        builder.addCase(fetchPublishedDraft.rejected, (state: any, action: any) => {
            state.message = action.payload;
            state.loading = false;
        });

        builder.addCase(BeginReport.pending, (state: any) => {
            state.loading = true;
        });

        //fullfilled
        builder.addCase(BeginReport.fulfilled, (state: any, action: any) => {
            state.reportId = action.payload?.reportID
            state.message = action.payload;
            state.loading = false;
        });

        //rejected
        builder.addCase(BeginReport.rejected, (state: any, action: any) => {
            state.message = action.payload;
            state.loading = false;
        });

        builder.addCase(fetchDifaPublishReport.pending, (state: any) => {
            state.loading = true;
        });

        //fullfilled
        builder.addCase(fetchDifaPublishReport.fulfilled, (state: any, action: any) => {
            state.publishedPDF = action.payload;
            state.loading = false;
        });

        //rejected
        builder.addCase(fetchDifaPublishReport.rejected, (state: any, action: any) => {
            state.message = 'report-download-failed';
            state.loading = false;
        });

        builder.addCase(fetchDocuments.pending, (state: any) => {
            state.loading = true;
        });

        //fullfilled
        builder.addCase(fetchDocuments.fulfilled, (state: any, action: any) => {
            state.message = action.payload;
            state.loading = false;
            state.isIncludeESPSizing = action.payload?.isIncludeESPSizing;

            const docs = action.payload.difaPacketDocuments;
            if (docs) {
                switch (docs.category) {
                    case 'esp':
                        state.sizingSummary = {
                            ...state.sizingSummary,
                            isSelected: action.payload?.isIncludeESPSizing,
                            sizingFile: {
                                link: action.payload.difaPacketDocuments?.fileLink,
                                blob: action.payload.difaPacketDocuments?.blobLink,
                                name: action.payload.difaPacketDocuments?.fileName,
                            }
                        }
                        break;
                    case 'additional':
                        state.wellInstallation.additionalDocuments = {
                            name: action.payload.difaPacketDocuments?.fileName,
                            link: action.payload.difaPacketDocuments?.fileLink,
                            blob: action.payload.difaPacketDocuments?.blobLink,
                        }
                        break;
                    case 'production':
                        state.production = {
                            ...state.production,
                            isSelected: action.payload?.isIncludeProductionPlot,
                            productionFile: {
                                link: action.payload.difaPacketDocuments?.fileLink,
                                blob: action.payload.difaPacketDocuments?.blobLink,
                                name: action.payload.difaPacketDocuments?.fileName,
                            }
                        };
                        break;
                    default:
                        break;
                }
            }

        });

        //rejected
        builder.addCase(fetchDocuments.rejected, (state: any, action: any) => {
            state.message = action.payload;
            state.loading = false;
        });
    },
});

export const { setSizingSummary, setProduction, setMatch, setScaleDeposit, setWellInstallation, setWellRunLife, setInBoundSelection, setPullReasons, resetPacketBuilderState, setPublishedId, setCauseOfFailure, setDifaInspection, setChmEmployee, setAttendees, setEditFlow } = DifaPacketBuilderSlice.actions;


export default DifaPacketBuilderSlice.reducer;