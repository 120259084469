import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

const DismantleRequest = () => {

    const location = useLocation()
    const { difaId, customerName } = location.state || {};
    const navigate = useNavigate();
    const { difaDetails } = useSelector((state: any) => state.difaDetails);

    const redirectToDS = () => {
        navigate("dismantle-request", { state: { difaId: difaId, customerName: customerName, wellName: difaDetails?.wellName, wellApiNumber: difaDetails?.wellApiNumber } })
    }

    return (
        <div className="inspection-box">
            <div className="inspection-text-container">
                <div className="inspection-text">
                    Want to disassemble a specific piece of equipment? <br />
                    Click the button below to send your request.
                </div>
            </div>
            <div className="inspection-button">
                <button className="footer-btn" onClick={redirectToDS}>Make request</button>
            </div>
        </div>
    );
}

export default DismantleRequest;