import { useEffect, useState } from "react";
import { Dialog, DialogBackdrop, DialogPanel } from '@headlessui/react'
import closeIcon from "./../../../images/close-icon.svg";
import HrizontalTab from "../tabs/horizontalTab";
import DifaDropdown from "../dropdown/Dropdown";
import DifaInput from "../input/DifaInput";
import DifaCheckbox from "../checkbox/DifaCheckbox";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../loader/Loader";
import { useLocation } from "react-router-dom";
import { getFileName, getFileType, sentenceCase, toBase64, toDataURL } from "../services/UtilityService";
import './Gallery.scss';
import { groupBy, keys } from 'lodash';
import CreateGallery from "./CreateGallery";
import UploadDocuments from "../uploadFiles/UploadDocuments";
import { modifyImageGallery, postImageGallery } from "../../difaPacketBuilder/difaPacketBuilderSlice";
import SelectEquipmentDialog from "./SelectEquipmentDialog";
import { toaster } from "../services/ToasterService";

interface GalleryComponentProps {
    show: boolean;
    wellApiNumber: string;
    difaID?: string;
    onGalleryClose?: () => void,
    images: Array<any>,
    loading?: boolean,
    defaultGroupName?: string,
    reportType?: string,
    mode?: 'create' | 'update' | string,
    showGalleryTab?: boolean,
    source: string
}

const GalleryComponent: React.FC<GalleryComponentProps> = ({ show, reportType = '', difaID = '', wellApiNumber, onGalleryClose = () => { }, images = [], loading = false, mode = 'create', defaultGroupName = '', showGalleryTab = true, source = '' }) => {
    const isInspectionFlow = reportType === 'inspection';
    const [showDialog, setShowDialog] = useState(false);
    const [showGalleryNameDialog, setShowGalleryNameDialog] = useState(false);
    const [showEquipmentDialog, setShowEquipmentDialog] = useState(false);
    const [galleryLoader, setGalleryLoader] = useState(loading);
    const [galleryName, setGalleryName] = useState('');
    const [equipment, setEquipment] = useState('');
    const [activeTab, setActiveTab] = useState(showGalleryTab ? 0 : 1);
    const [activeItem, setActiveItem] = useState(null as any);
    const location = useLocation();
    const isUpdate = mode === 'update';
    const [locationWiseImages, setLocationWiseImages] = useState({} as any);
    const dispatch = useDispatch();
    const [selectedPhotosFromGallery, setSelectedPhotosFromGallery] = useState([] as any);

    const [uploadedFiles, setUploadedFiles] = useState<any>([]);

    const { activeGalleryDetails, galleryEquipments } = useSelector((state: any) => state?.difaPacketBuilder);

    function handleUploadedFiles(files: File[]): void {
        const newFiles = [...uploadedFiles];
        files.forEach(i => newFiles.push({
            originalFileName: i.name,
            blob: URL.createObjectURL(i),
            file: i
        }));
        setUploadedFiles(newFiles);
    }

    const close = () => {
        setShowDialog(false);
        onGalleryClose();
    }
    useEffect(() => {
        setGalleryLoader(loading);
    }, [loading])

    useEffect(() => {
        if (isUpdate && activeGalleryDetails && images?.length) {
            const existingImages = activeGalleryDetails.reportImageDetails || [];
            let reportImages = existingImages.filter((x: any) => x.reportImageDataLakeID);
            reportImages = reportImages.map((x: any) => x.reportImageDataLakeID);
            const filterDataLakeImages = images.filter((x: any) => reportImages.includes(x.reportImageDataLakeID));
            setSelectedPhotosFromGallery(filterDataLakeImages.map((x: any) => x.reportImageDataLakeID));
        }
    }, [activeGalleryDetails, images])

    useEffect(() => {
        setShowDialog(show);
    }, [show]);
    useEffect(() => {
        if (images && images?.length) {
            const grouppedImages = groupBy(images, (x: any) => x.location || defaultGroupName);
            setLocationWiseImages(grouppedImages);
        } else {
            setLocationWiseImages({});
        }
    }, [images])

    const tabs = showGalleryTab ? ['Image library', 'My device'] : ['My device'];

    const handleEquipmentClose = async (part: any) => {
        if (part) {
            setEquipment(part);
            setShowEquipmentDialog(false);
            await handleGalleryClose(part);
        } else
            setShowEquipmentDialog(false);
    }

    const handleGalleryClose = async (name?: string) => {
        if (name) {
            setGalleryLoader(true);
            let reportImages = [] as any;
            if (selectedPhotosFromGallery?.length) {
                for (const item of selectedPhotosFromGallery) {
                    const selectedImages = (images || []).find(x => x.reportImageDataLakeID === item);
                    if (selectedImages) {
                        try {
                            let dataUrl = await toDataURL(selectedImages.fileLink) as string;
                            const fileType = getFileType(selectedImages.fileLink)
                            dataUrl = dataUrl.replace('data:application/octet-stream;base64', `data:image/${fileType};base64`);
                            reportImages.push({
                                originalFileName: getFileName(selectedImages.fileLink),
                                reportImageDataLakeID: item,
                                description: '',
                                fileBase64: dataUrl
                            })
                        } catch (error) {
                            console.log(error);
                        }
                    }
                }
            }
            if (uploadedFiles && uploadedFiles.length) {
                for (const item of uploadedFiles) {
                    let dataUrl = await toBase64(item.file) as string;
                    reportImages.push({
                        originalFileName: item.originalFileName,
                        originalFileLink: '',
                        description: '',
                        fileBase64: dataUrl
                    })
                }
            }

            if (isUpdate && activeGalleryDetails?.reportImageDetails?.length) {
                reportImages = [...reportImages, ...activeGalleryDetails.reportImageDetails.filter((x: any) => !x.reportImageDataLakeID)];
            }

            let newGallery = {
                reportType,
                galleryName: name,
                wellApiNumber,
                difaID: difaID?.toString(),
                reportImageDetails: reportImages,
                galleryID: isUpdate ? activeGalleryDetails?.galleryID : null,
            }

            const response = isUpdate ? await dispatch(modifyImageGallery({ details: newGallery, source })) : await dispatch(postImageGallery({ details: newGallery, source }));
            if (response?.meta?.requestStatus === 'fulfilled') {
                toaster.success(isUpdate ? 'Gallery updated' : 'Gallery added', "To see your images select the gallery.");
                setShowDialog(false);
                setShowGalleryNameDialog(false);
                setGalleryName(name);
                onGalleryClose();

            } else if (response?.error) {
                toaster.error(typeof response?.payload === 'object' ? response?.payload.message : response?.payload);
            }
        } else {
            setShowGalleryNameDialog(false);
            setGalleryName('');
        }
    }

    const photoSelectionChange = (e: any, photoId: any) => {
        if (e.target.checked) {
            setSelectedPhotosFromGallery([...selectedPhotosFromGallery, photoId]);
        } else {
            setSelectedPhotosFromGallery(selectedPhotosFromGallery?.length ? selectedPhotosFromGallery.filter((x: any) => x !== photoId) : []);
        }
    }

    return (
        <>
            {galleryLoader && <Loader isOverlay />}
            <Dialog open={showDialog} onClose={close} className="relative z-50">
                <DialogBackdrop
                    transition
                    className="fixed inset-0 backdrop-blur-sm transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
                />

                <div className="fixed inset-0 z-50 w-screen">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <DialogPanel
                            transition
                            className="flex dialog-max-height relative transform overflow-hidden rounded-lg difa-background-with-border text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-sm md:min-w-4/5 md:w-4/5 md:max-w-full data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                        >
                            <div className="difa-widget-containers">
                                <div className="header-main border-bottom">
                                    <div className="flex flex-row justify-between">
                                        <div>
                                            <h3>{isUpdate ? 'Update' : 'Create'} gallery</h3>
                                        </div>
                                        <a href="javascript:void(0)" onClick={close}>
                                            <img src={closeIcon} alt="close" />
                                        </a>
                                    </div>
                                </div>
                                <div className="w-full flex flex-col overflow-y-auto image-container pb-16 table-main">
                                    {
                                        tabs?.length > 1
                                        &&
                                        <HrizontalTab tabs={tabs} classNames="pl-6" tabChange={setActiveTab} />
                                    }
                                    {activeTab === 0 &&
                                        <div className="flex flex-row">
                                            <div className="w-full px-6 py-4">
                                                {
                                                    false &&
                                                    <div className="flex flex-row justify-between items-end">
                                                        <div className="w-80"> <DifaDropdown caption="Filter by" value="All images" options={[{ name: 'All images', value: 'All images' }]} /></div>
                                                        <div className="w-80"><DifaInput caption="" /></div>
                                                    </div>
                                                }
                                                <div>
                                                    {keys(locationWiseImages)?.map((key: string) => (
                                                        <div className="mt-6">
                                                            <h2 className="mt-1 mb-3">{sentenceCase(key)}</h2>
                                                            <div className="flex gap-3 flex-wrap">
                                                                {
                                                                    locationWiseImages[key]?.map((photo: any, index: number) => (
                                                                        <div className="flex flex-col gap-3  w-48 max-w-48" onClick={() => setActiveItem(photo)}>
                                                                            <div className={`difa-widget-containers relative max-w-48 h-48 p-2 field-background ${activeItem === photo ? 'active-item-border' : ''}`}>
                                                                                <div className="absolute top-5">
                                                                                    <DifaCheckbox disabled={!photo?.reportImageDataLakeID} checked={selectedPhotosFromGallery?.length && !!selectedPhotosFromGallery.find((x: any) => x === photo.reportImageDataLakeID)} onChange={(e: any) => photoSelectionChange(e, photo.reportImageDataLakeID)} />
                                                                                </div>
                                                                                <div className="w-full h-full difa-background flex items-center">
                                                                                    <img className="h-32 w-full" src={photo.fileLink} alt={photo.originalFileName}></img>
                                                                                </div>
                                                                            </div>
                                                                            <h5>
                                                                                {photo.caption}
                                                                            </h5>
                                                                        </div>
                                                                    ))
                                                                }
                                                            </div>
                                                        </div>
                                                    ))}
                                                    {
                                                        !images?.length &&
                                                        <div className="opacity-65">No images were found for the specified DIFA.</div>
                                                    }
                                                </div>
                                            </div>
                                            {
                                                activeItem
                                                &&
                                                <div className="flex-auto border-left p-5 max-w-80">
                                                    <img className="h-32 w-60 min-w-60 object-cover" src={activeItem.fileLink} alt="meter"></img>
                                                    <h2 className="mt-1 mb-1">{activeItem.caption}</h2>
                                                    <h5 className="mt-1 mb-1">{getFileType(activeItem.fileLink)} image</h5>
                                                    <h2 className="mt-3 mb-3">Image details</h2>
                                                    <div className="mt-3">
                                                        <div className="border-bottom flex justify-between gap-2"> <h5>Source</h5><h5>{activeItem.source}</h5></div>
                                                    </div>
                                                    <div className="mt-3">
                                                        <div className="border-bottom flex justify-between gap-2"> <h5>Location</h5><h5>{activeItem.location}</h5></div>
                                                    </div>
                                                    <div className="mt-3">
                                                        <div className="flex justify-between gap-5"> <h5>Comments</h5><h5>{activeItem.comments}</h5></div>
                                                    </div>
                                                </div>
                                            }

                                        </div>
                                    }
                                    {activeTab === 1 && showDialog &&
                                        <div className="flex">
                                            <div className="block w-full p-6">
                                                <UploadDocuments
                                                    supportMultiChunks={false}
                                                    key={'image-gallery'}
                                                    showPreview={false}
                                                    fileType={'images'}
                                                    showFileTypeError={false}
                                                    onFilesChange={handleUploadedFiles}
                                                />
                                                <div className="flex gap-3 flex-wrap mt-8">
                                                    {
                                                        uploadedFiles?.map((photo: any, index: number) => (
                                                            <div className="flex flex-col gap-3  w-48 max-w-48" onClick={() => { }}>
                                                                <div className={`difa-widget-containers relative max-w-48 h-48 p-2 field-background ${true ? 'active-item-border' : ''}`}>
                                                                    <div className="w-full h-full difa-background flex items-center">
                                                                        {/* <Loader showText={false} /> */}
                                                                        <img className="h-32 w-full" src={photo.blob} alt={photo.caption}></img>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="flex-row flex gap-3 border-top justify-end items-center h-16">
                                    <div className="secondary-button">
                                        <button className="header-btn-text w-20" onClick={close}>Cancel</button>
                                    </div>
                                    {
                                        isUpdate ?
                                            <div className="primary-button mr-8">
                                                <button className="header-btn-text" onClick={async () => await handleGalleryClose(activeGalleryDetails?.galleryName)} disabled={!(uploadedFiles.length || selectedPhotosFromGallery.length)}>Update</button>
                                            </div> :
                                            <div className="primary-button mr-8">
                                                <button className="header-btn-text" onClick={() => isInspectionFlow ? setShowEquipmentDialog(true) : setShowGalleryNameDialog(true)} disabled={!(uploadedFiles.length || selectedPhotosFromGallery.length)}>Next</button>
                                            </div>
                                    }
                                </div>
                            </div>
                        </DialogPanel>
                    </div>
                </div>
            </Dialog>
            {
                showGalleryNameDialog &&
                <CreateGallery show={showGalleryNameDialog} close={async (name: string | any) => await handleGalleryClose(name)} />
            }
            {showEquipmentDialog &&
                <SelectEquipmentDialog show={showEquipmentDialog} data={galleryEquipments} close={(part: string | any) => handleEquipmentClose(part)} />
            }
        </>
    )
}

export default GalleryComponent;