import React, { useState, useEffect, useRef } from 'react';
import Header from './components/Header';
import Filter, { FilterOption } from './components/Filter';
import DashboardLayout from './components/DashboardLayout';
import Loader from '../common/loader/Loader';
import './Dashboard.css';
import { useDispatch } from 'react-redux';
import { fetchCustomers, fetchDifaList } from './DashboardSlice';
import { DifaDetails } from '../models/DifaDetails';
import { Engineer } from '../models/Engineer';
import { GetUserEmail } from '../common/services/UserService';
import { getHSLColor, indexOfLongestArray, sentenceCase } from '../common/services/UtilityService';

const Dashboard: React.FC = () => {
  const dispatch = useDispatch();
  const [visibleCards, setVisibleCards] = useState(4);
  const [loading, setLoading] = useState(false);
  const [difaList, setDifaList] = useState<any>([]);
  const [filtedData, setFiltedData] = useState<any>([]);
  const [enginnersSpcificColorCode, setEnginnersSpcificColorCode] = useState<{ emailId: string, color: string }[]>([]);

  const [loadingData, setLoadingData] = useState(true);
  const observer = useRef<IntersectionObserver | null>(null);
  const [customerList, setCustomerList] = useState<Array<FilterOption>>(new Array<FilterOption>());
 

  const handleFilterChange = (filter: FilterOption) => {
    getDifaList(filter.value || '');
  };

  const loadMoreCards = () => {
    if (!loading && visibleCards < difaList.length) {
      setLoading(true);
      setTimeout(() => {
        setVisibleCards(prev => prev + 4); // Load 4 more cards
        setLoading(false);
      },500); // Simulate a delay for loading more cards
    }
  };

  /**
   * Gets tje Difa List
   * @param {string} emailId
   */
  const getDifaList = (emailId: string) => {
    const startPage = "1";
    const pageSize = "100";
    setLoadingData(true);
    dispatch(fetchDifaList({ emailId, startPage, pageSize })).unwrap().then((response: any) => {
      const difaDto: DifaDetails[] = response?.difaDto;
      response = (difaDto).map((x:any) => ({ ...x, engineer: sentenceCase(x.engineer) }));
      const sections = [
        {
          title: 'Pre-inspection',
          cards: getColorMappingResponse(response.filter((card: any) => card?.status === 'Not Started')),
        },
        {
          title: 'In-progress',
          cards: getColorMappingResponse(response.filter((card: any) => card?.status === 'Started')),
        },
        {
          title: 'Pending',
          cards: getColorMappingResponse(response.filter((card: any) => card?.status === 'Pending')),
        },
      ];
      setDifaList(response);
      setFiltedData(sections);
      setLoadingData(false);
    }).catch((err: any) => {
      console.error(err);
      setLoadingData(false);
    });
  }
  
  /**
   * Get Color code mapping
   */
  const getColorMappingResponse = (response: DifaDetails[]) => {
   return response.map(x => ({
      ...x, colorCode: (() => {
        var colorCd = (enginnersSpcificColorCode || []).find(e => e.emailId === x.emailAddress);
        return colorCd?.color;
      })()
    }))
  }

  /**
   * Get all customers
   */
  const getAllCustomers = () => {
    setLoadingData(true);
    dispatch(fetchCustomers()).unwrap().then((response: Engineer[]) => {
      var engineers = (response || []).map((x: Engineer) => ({ name: x.dismantleEngineer, value: x.emailAddress }) as FilterOption);
      let options: FilterOption[] = [
        { name: "My assigned DIFA's", value: GetUserEmail() },
        { name: "All assigned DIFA's", value: undefined },
      ];
      setEnginnersSpcificColorCode((response || []).map((x: Engineer) => ({ emailId: x.emailAddress, color: getHSLColor(x.emailAddress || '') }) as any));
      options = [...options, ...engineers];
      setCustomerList(options);
      if(options && options.length){
        getDifaList(options[0].value || '');
      }else{
        setLoadingData(false);
      }
    }).catch((err: any) => {
      console.error(err);
      setLoadingData(false);
    });
  }

  useEffect(() => {
    getAllCustomers();
  }, [dispatch]);

  useEffect(() => {
    if (observer.current) {
      observer.current.disconnect();
    }
    const sectionCards = [
      document.querySelectorAll('.dashboard-section.Pre-inspection .difa-card'),
      document.querySelectorAll('.dashboard-section.In-progress .difa-card'),
      document.querySelectorAll('.dashboard-section.Pending .difa-card')
    ]

    const largeIndex= indexOfLongestArray(sectionCards);
    const legnthOfSection = sectionCards[largeIndex]?.length;
    if (legnthOfSection) {
      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting) {
          loadMoreCards();
        }
      }, { threshold: 1.0 });

      observer.current.observe(sectionCards[largeIndex][legnthOfSection-1]);
    }

    return () => observer.current?.disconnect();
  }, [difaList, visibleCards]);

  return (
    <div className="dashboard">
      <Header />
      <div className="dashboard-content">
        <Filter options={customerList} onFilterChange={handleFilterChange} />
        {loadingData && <Loader isOverlay={true} />}
        <DashboardLayout
          sections={filtedData}
          visibleCards={visibleCards}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default Dashboard;
