import React, { useEffect, useState } from 'react';
import AuthService from '../../services/authentication/auth.service';
import { LoginModel } from '../../models/Login';
import { useLocation } from 'react-router-dom';
import Loader from '../common/loader/Loader';
import { resetPacketBuilderState } from '../difaPacketBuilder/difaPacketBuilderSlice';
import { useDispatch } from 'react-redux';
import TokenService from '../../services/authentication/token.service';

const Login = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  // Access individual query parameters
  const ref = queryParams.get('ref');
  useEffect(() => {
    setIsLoading(true);
    const userCredentials: LoginModel = {
      grant_type: 'form',
      refresh_token: '',
      reference: ref !== null ? ref : "",
    };

    AuthService.login(userCredentials)
      .then((response) => {
        const userRole =TokenService.getUserRole();
       if (response === 'success') {
          setIsLoading(false);
          dispatch(resetPacketBuilderState());
          userRole === 'Manager' ? window.location.assign('/manager-dashboard') : window.location.assign('/dashboard');
        } else {
          setIsLoading(false);
        }
      })
      .catch(function (error) {
        console.error(error);
        setIsLoading(false);
      });
  }, []);

  return <div className='login-loader-margin'>{isLoading && <Loader isOverlay= {true} />}</div>;
};

export default Login;
