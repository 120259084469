import React, { useState } from "react";
import { useSelector } from "react-redux";
import './preview.css';
import ViewGallery from "../../../common/gallery/ViewGallery";

const DIFAPreview: React.FC<{}> = (

) => {
  const { difaInspection, installImageGallery, inspectionImageGallery, inspectionReportImages, galleryTransactionMsg } = useSelector((state: any) => state?.difaPacketBuilder);
  const chmEmployee = useSelector((state: any) => state.difaPacketBuilder.chmEmployee);
  const attendees = useSelector((state: any) => state.difaPacketBuilder.attendees);
  const [showViewGallery, setShowViewGallery] = useState(false);
  const [images, setImages] = useState([] as any);
  const getInitials = (name: string | undefined | null): string | null => {
    if (!name) return null; //   Fallback initials for missing/invalid name
    const [firstName, lastName] = name.split(" ");
    return `${firstName?.charAt(0) || ""}${lastName?.charAt(0) || ""}`.toUpperCase();
  };


  return (
    <div className="flex flex-col gap-6 text-neutral-grey">
      <section className="flex flex-row gap-6">
        {/* Inspection Summary */}
        <div className="flex flex-col gap-5 difa-widget-containers px-5 py-[18px]">
          <div className="pb-3 border-bottom">
            <h3 className="font-semibold text-lg">Inspection summary</h3>
          </div>
          <p className="text-sm text-grey-100">{difaInspection?.summary?.trim() ? difaInspection.summary : "No inspection summary provided"}</p>
        </div>
        {/* Inspection Image Evidence */}
        <div className="flex flex-col gap-5 difa-widget-containers px-5 py-[18px]">
          <div className="pb-3 border-bottom">
            <h3 className="font-semibold text-lg ">Inspection image evidence</h3>
          </div>
          {installImageGallery && installImageGallery.length > 0 ? (
            <div className="flex flex-row gap-5 border-t border-dark cursor-pointer rounded-md flex-wrap">
              {installImageGallery.map((gl: any, index: number) => (
                <div className="flex flex-col gap-3 w-72 max-w-72" key={index}>
                  <div
                    onClick={() => {
                      setImages(gl?.reportImageDetails);
                      setShowViewGallery(true);
                    }}
                    className="difa-widget-containers bg-white relative max-w-72 h-64 p-2"
                  >
                    <div className="w-full h-full flex items-center flex-col relative">
                      {gl.reportImageDetails && gl.reportImageDetails?.length > 0 && (
                        <img
                          className="h-48 w-full"
                          src={gl.reportImageDetails[0].fileLink}
                          alt="gallery"
                        />
                      )}
                      <div className="flex justify-between w-full items-center p-2">
                        <h2>{gl?.galleryName}</h2>
                        <h5>{gl?.reportImageDetails?.length} images</h5>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            // Display "No images added" message with proper Tailwind styling
            <p className="text-sm text-gray-500">No images added</p>
          )}
          {showViewGallery && (
            <ViewGallery
              images={images}
              show={showViewGallery}
              close={() => setShowViewGallery(false)}
              name="inbound images"
            />
          )}


          {showViewGallery && (
            <ViewGallery images={images} show={showViewGallery} close={() => setShowViewGallery(false)} name="inbound images" />
          )}

          <p className="text-sm text-grey-100"></p>
        </div>
      </section>

      {/* Attendees */}
      <section className="flex flex-col gap-6 difa-widget-containers px-5 py-[18px]">
        <div className="pb-3 border-bottom">
          <h3 className="font-semibold text-lg">Attendees</h3>
        </div>

        {/* Customer and ChampionX Wrapper */}
        <div className="flex flex-col lg:flex-row gap-6">
          {/* Customer Box */}
          <div className="flex flex-col gap-5 bg-custom flex-1">
            <div className="pb-3">
              <h3 className="font-semibold text-lg">Customer</h3>
            </div>
            <ul className="text-sm text-grey-100">
              {attendees && attendees.length > 0 && attendees.map((attendee: any, index: number) => (
                <li key={index} className="flex items-center gap-4 mb-4">
                  {/* Circle with Initials */}
                  {getInitials(attendee?.name) && (
                    <div
                      className="flex items-center justify-center rounded-full bg-indigo-500 text-white font-bold w-10 h-10"
                      title={attendee?.name}
                    >
                      {getInitials(attendee?.name)}
                    </div>
                  )}
                  {/* Name and Role */}
                  <div>
                    <p className="font-semibold">{attendee?.name}</p>
                    <p className="text-grey-500">{attendee?.role}</p>
                  </div>
                </li>
              ))}
            </ul>
          </div>

          {/* ChampionX Box */}
          <div className="flex flex-col gap-5 bg-custom flex-1">
            <div className="pb-3 ">
              <h3 className="font-semibold text-lg">ChampionX</h3>
            </div>
            <ul className="text-sm text-grey-100">
              {chmEmployee && chmEmployee.length > 0 && chmEmployee.map((employee: any, index: number) => (
                <li key={index} className="flex items-center gap-4 mb-4">
                  {/* Circle with Initials */}
                  {getInitials(employee?.name) && (
                    <div
                      className="flex items-center justify-center rounded-full bg-indigo-500 text-white font-bold w-10 h-10"
                      title={employee?.name}
                    >
                      {getInitials(employee?.name)}
                    </div>
                  )}
                  {/* Name and Role */}
                  <div>
                    <p className="font-semibold">{employee?.name}</p>
                    <p className="text-grey-500">{employee?.role}</p>
                  </div>
                </li>
              ))}
            </ul>
          </div>

        </div>
      </section>
    </div>
  );
};

export default DIFAPreview;