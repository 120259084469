import React from 'react';
import AuthService from '../../services/authentication/auth.service';

interface AuthComponentProps {
  component: React.FC;
}

const AuthComponent: React.FC<AuthComponentProps> = ({ component: Component }) => {
  return AuthService.isUserLoggedIn() ? <Component /> : <Redirect />;
};

function Redirect() {
  window.location.replace(
    process.env.REACT_APP_CONNEXIA_DEVELOPMENT_URL +
      '/Account/Login?app=' +
      process.env.REACT_APP_DEVELOPMENT_WEB_APP_REDIRECT_URL,
  );
  return null; // or any valid JSX element
}

export default AuthComponent;
