import SizingSchematic from "./SizingSchematic";
import "./ESPSizing.scss";
import PDFViewer from "../../../common/pdfViewer/FileViewer";
import EmptyView from "../../../common/emptyPreview/View";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

const EspSizing: React.FC<{
  wellApiNumber: string;
  difaId: string;
  source: string;
  unsavedCallback?: () => void;
}> = ({ wellApiNumber, source, difaId, unsavedCallback }) => {
  const { sizingSummary } = useSelector(
    (state: any) => state.difaPacketBuilder
  );
  const isPreview = source === "preview-packet" || source === "preview-report";

  const [pdfUrl, setPdfUrl] = useState<string | null>(null);

  useEffect(() => {
    if (sizingSummary?.sizingFile?.link) {
      // Check if the link is a blob or a URL
      if (sizingSummary.sizingFile.link instanceof Blob) {
        // Create an object URL for the blob
        const objectUrl = URL.createObjectURL(sizingSummary.sizingFile.link);
        setPdfUrl(objectUrl);

        // Cleanup the object URL when the component unmounts
        return () => {
          URL.revokeObjectURL(objectUrl);
        };
      } else {
        // Assume it's a valid URL
        setPdfUrl(sizingSummary.sizingFile.link);
      }
    } else {
      setPdfUrl(null);
    }
  }, [sizingSummary?.sizingFile?.link]);

  return (
    <div className="flex-auto flex flex-col w-full gap-5">
      {isPreview ? (
        sizingSummary?.isSelected && pdfUrl ? (
          <PDFViewer file={pdfUrl} />
        ) : (
          <EmptyView />
        )
      ) : (
        <SizingSchematic wellApiNumber={wellApiNumber} difaId={difaId} unsavedCallback={unsavedCallback} source={source}/>
      )}
    </div>
  );
};

export default EspSizing;
