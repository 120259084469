import React from 'react'
import { NotificationToasterProps } from '../../models/DismantleRequest';
import "./NotificationToaster.scss";

const NotificationToaster: React.FC<NotificationToasterProps> = ({ type, message, onClose }) => {

    return (
        <div id="toast-bottom-left" className={`fixed flex items-center w-full max-w-xs right-5 toast-notification sm:w-full sm:max-w-md ${type}`} role="alert">
            <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8">
                {type === 'success' ? (
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="check-circle" clipPath="url(#clip0_8047_6446)">
                            <path id="Icon" d="M6.2513 10.0003L8.7513 12.5003L13.7513 7.50033M18.3346 10.0003C18.3346 14.6027 14.6037 18.3337 10.0013 18.3337C5.39893 18.3337 1.66797 14.6027 1.66797 10.0003C1.66797 5.39795 5.39893 1.66699 10.0013 1.66699C14.6037 1.66699 18.3346 5.39795 18.3346 10.0003Z" stroke="#32D583" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                        </g>
                        <defs>
                            <clipPath id="clip0_8047_6446">
                                <rect width="20" height="20" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                ) : (
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="alert-circle" clipPath="url(#clip0_8082_14828)">
                            <path id="Icon" d="M10.0013 6.66699V10.0003M10.0013 13.3337H10.0096M18.3346 10.0003C18.3346 14.6027 14.6037 18.3337 10.0013 18.3337C5.39893 18.3337 1.66797 14.6027 1.66797 10.0003C1.66797 5.39795 5.39893 1.66699 10.0013 1.66699C14.6037 1.66699 18.3346 5.39795 18.3346 10.0003Z" stroke="#F97066" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </g>
                        <defs>
                            <clipPath id="clip0_8082_14828">
                                <rect width="20" height="20" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                )}
            </div>
            <div className="flex flex-col">
                <div className="ml-3 font-bold">{message}</div>
                <div className="ml-3 font-normal">{type === "success" ? "The team will be notified of the dismantle request." : "Please try again."}</div>
            </div>

            <button onClick={onClose} className="ml-auto -mx-1.5 -my-1.5 p-1.5  inline-flex items-center justify-center h-8 w-8" aria-label="Close">
                <span className="sr-only">Close</span>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="x-close">
                        <path id="Icon" d="M15 5L5 15M5 5L15 15" stroke="#F7F9F9" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round" />
                    </g>
                </svg>
            </button>
        </div>
    );
};

export default NotificationToaster
