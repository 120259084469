import instance from '../../config/HttpCommon';

export const getDifaList = async (emailId: string, startPage: string, pageSize: string) => {
    const DifaListRes = await instance.get(`Difa/DifaList?emailId=${emailId}&pageNumber=${startPage}&pageSize=${pageSize}`);
    return DifaListRes?.data;
}

export const GetAllEngineers = async () => {
    const DifaListRes = await instance.get(`Difa/GetAllEngineers`);
    return DifaListRes?.data;
}