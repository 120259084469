// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.difa-image-editor {
  margin: 0 auto;
}

.difa-img-editor-container {
  height: 80vh;
  width: 100%;
}

@media only screen and (max-width: 700px) {
  .difa-img-editor-container {
    height: 70vh;
    width: 100%;
  }
}
.control-wrapper {
  height: 100%;
}

.image-container {
  max-height: calc(100% - 130px);
}

.image-view-container {
  max-height: calc(100% - 100px);
}

.gallery-animate {
  position: relative;
  animation-name: fade;
  animation-duration: 1.5s;
}

@keyframes animateLeft {
  from {
    right: -300px;
    opacity: 0;
  }
  to {
    right: 0;
    opacity: 1;
  }
}
.gallery-container {
  position: relative;
}
.gallery-container .navigation-disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}
.gallery-container .move-left {
  position: absolute;
  left: -50px;
  top: 50%;
  z-index: 1;
}
.gallery-container .move-right {
  position: absolute;
  right: -50px;
  top: 50%;
  z-index: 1;
}`, "",{"version":3,"sources":["webpack://./src/features/common/gallery/Gallery.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;AACJ;;AAEA;EACI,YAAA;EACA,WAAA;AACJ;;AAEA;EACI;IACI,YAAA;IACA,WAAA;EACN;AACF;AAEA;EACI,YAAA;AAAJ;;AAGA;EACI,8BAAA;AAAJ;;AAGA;EACI,8BAAA;AAAJ;;AAGA;EACI,kBAAA;EACA,oBAAA;EACA,wBAAA;AAAJ;;AAGA;EACI;IACI,aAAA;IACA,UAAA;EAAN;EAGE;IACI,QAAA;IACA,UAAA;EADN;AACF;AAIA;EACI,kBAAA;AAFJ;AAII;EACI,YAAA;EACA,mBAAA;EACA,oBAAA;AAFR;AAKI;EACI,kBAAA;EACA,WAAA;EACA,QAAA;EACA,UAAA;AAHR;AAMI;EACI,kBAAA;EACA,YAAA;EACA,QAAA;EACA,UAAA;AAJR","sourcesContent":[".difa-image-editor {\n    margin: 0 auto;\n}\n\n.difa-img-editor-container {\n    height: 80vh;\n    width: 100%;\n}\n\n@media only screen and (max-width: 700px) {\n    .difa-img-editor-container {\n        height: 70vh;\n        width: 100%;\n    }\n}\n\n.control-wrapper {\n    height: 100%;\n}\n\n.image-container {\n    max-height: calc(100% - 130px);\n}\n\n.image-view-container {\n    max-height: calc(100% - 100px);\n}\n\n.gallery-animate {\n    position: relative;\n    animation-name: fade;\n    animation-duration: 1.5s;\n}\n\n@keyframes animateLeft {\n    from {\n        right: -300px;\n        opacity: 0\n    }\n\n    to {\n        right: 0;\n        opacity: 1\n    }\n}\n\n.gallery-container {\n    position: relative;\n\n    .navigation-disabled {\n        opacity: 0.5;\n        cursor: not-allowed;\n        pointer-events: none;\n    }\n\n    .move-left {\n        position: absolute;\n        left: -50px;\n        top: 50%;\n        z-index: 1;\n    }\n\n    .move-right {\n        position: absolute;\n        right: -50px;\n        top: 50%;\n        z-index: 1;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
