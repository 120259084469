//import Helpers from '../../utilities/Helpers';
import LocalStorageConstant from '../../constants/localstroage.constant';

const getLocalExpiresIn = (): string => {
  return localStorage.getItem(LocalStorageConstant.expires_in) ?? '';
};

const getLocalRefreshToken = (): string => {
  return localStorage.getItem(LocalStorageConstant.refresh_token) ?? '';
};

const getLocalAccessToken = (): string => {
  return localStorage.getItem(LocalStorageConstant.access_token) ?? '';
};

const getCorrelationId = (): string => {
  return localStorage.getItem(LocalStorageConstant.correlationId) ?? '';
};

const addOrUpdateLocalExpiresIn = (token: string) => {
  localStorage.setItem(LocalStorageConstant.expires_in, token);
};

const addOrUpdateLocalAccessToken = (token: string) => {
  localStorage.setItem(LocalStorageConstant.access_token, token);
};

const addOrUpdateLocalRefreshToken = (token: string) => {
  localStorage.setItem(LocalStorageConstant.refresh_token, token);
};
const addOrUpdateLocalCorrelationId = (correlationId: string) => {
  localStorage.setItem(LocalStorageConstant.correlationId, correlationId);
};

const getUser: any = () => {
  const user: string | null = localStorage.getItem(LocalStorageConstant.userName);
  return user ? JSON.parse(user) : null;
};

const setUser = (user: any) => {
  //Helpers.ConsoleLog(JSON.stringify(user));
  localStorage.setItem(LocalStorageConstant.userName, JSON.stringify(user) ?? '');
};

const removeUser = () => {
  localStorage.removeItem(LocalStorageConstant.userAddresses);
  localStorage.removeItem(LocalStorageConstant.userName);
  localStorage.removeItem(LocalStorageConstant.access_token);
  localStorage.removeItem(LocalStorageConstant.refresh_token);
  localStorage.removeItem(LocalStorageConstant.expires_in);
  localStorage.removeItem(LocalStorageConstant.correlationId);
};

const TokenService = {
  getLocalExpiresIn,
  getLocalRefreshToken,
  getLocalAccessToken,
  addOrUpdateLocalExpiresIn,
  addOrUpdateLocalAccessToken,
  addOrUpdateLocalRefreshToken,
  addOrUpdateLocalCorrelationId,
  getCorrelationId,
  getUser,
  setUser,
  removeUser,
};

export default TokenService;
