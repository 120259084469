import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import DifaSwitch from '../../../common/switch/DifaSwitch';
import { DismantleRequestProps, NameInterface } from "../../../models/DismantleRequest";
import { fetchInboundImageGallery, fetchInboundReportImages, removeGallery, resetCreateGalleryState, setInBoundSelection } from "../../difaPacketBuilderSlice";
import { createDifaDismantleRequest, updateGetDismantleRequestRunTime } from "../../../dismentleRequest/DismantleRequestSlice";
import { useLocation } from "react-router-dom";
import GalleryComponent from "../../../common/gallery/Gallery";
import EditGalleryComponent from "../../../common/gallery/EditGallery";
import CustomImageDropdown from "../../../common/dropdownWithIcon/CustomImageDropdown";
import ViewGallery from "../../../common/gallery/ViewGallery";
import { galleryUtility } from "../../../common/services/GalleryUtility";

const InboundTesting: React.FC<{ source: string }> = ({ source }) => {
    const { isInboundTestingSelected } = useSelector(
        (state: any) => state.difaPacketBuilder
    );
    const { inboundReportImages, inboundImageGallery, loading, galleryTransactionMsg } = useSelector(
        (state: any) => state?.difaPacketBuilder
    );
    const location = useLocation();
    const { difaDetails } = useSelector((state: any) => state.difaDetails);
    const { difaId } = location.state || {};
    const dispatch = useDispatch();
    const [galleryMode, setGalleryMode] = useState('create');
    const [expanded, setExpanded] = useState(isInboundTestingSelected || false);
    const [activeTab, setActiveTab] = useState<string>("PUMP MSC");
    const [tabContent, setTabContent] = useState<any>([]);
    const [showGallery, setShowGallery] = useState(false);
    const [showEditGallery, setShowEditGallery] = useState(false);
    const [activeGalleryItem, setActiveGalleryItem] = useState(null as any);
    const isPreview = source === "preview-packet" || source === "preview-report";
    const isReportBuilder = source === "report-builder";
    const [showViewGallery, setShowViewGallery] = useState(false);
    const [images, setImages] = useState([] as any);

    const onExpand = () => {
        setExpanded(!expanded);
        dispatch(setInBoundSelection(!expanded))
    }

    useEffect(() => {
        refreshGallery();
    }, []);

    const refreshGallery = () => {
        dispatch(fetchInboundImageGallery({ wellApiNumber: difaDetails?.wellApiNumber, difaId, source }));
    }

    const getDifaDismantleRequest = useSelector(
        (state: any) => state.dismantleRequest?.getDismantleRequest[0]
    );

    const dismantleRequest = getDifaDismantleRequest?.dismantleRequests ?? [];

    useEffect(() => {
        if (!inboundReportImages?.length && showGallery) {
            dispatch(fetchInboundReportImages({ partType: '', difaId }));
        }
    }, [showGallery]);

    const onBlurEvent = () => {
        const dismantleRequestsWithoutId = dismantleRequest.map((pump: any) => {
            return {
                dismantleRequested: pump?.dismantleRequested,
                serialNumber: pump?.serialNumber,
                equipementType: pump?.equipementType,
                inboundResults: pump?.inboundResults,
                runtime: pump?.runtime,
                comments: pump?.comments,
                partType: pump?.partType
            };
        });

        const payload = {
            dismantleRequests: dismantleRequestsWithoutId,
            difaId: getDifaDismantleRequest?.difaId,
            wellName: getDifaDismantleRequest?.wellName,
            wellApiNumber: getDifaDismantleRequest?.wellApinumber,
            comment: getDifaDismantleRequest?.comment,
        }

        dispatch(createDifaDismantleRequest(payload));

    }

    useEffect(() => {
        if (dismantleRequest?.length > 0) {

            setTabContent(
                dismantleRequest.filter(
                    (equipment: DismantleRequestProps) => equipment.partType === activeTab
                )
            );

        }
    }, [activeTab, getDifaDismantleRequest, dismantleRequest]);

    const name: NameInterface = {
        "PUMP MSC": "Pumps",
        "GAS HANDLING": "Gas handling",
        "PROTECTOR": "Protector",
        "MOTOR DH": "Motor",
        "SENSOR-DH": "Sensor",
        "CABLE": "Cable & MLE",
    };

    const handleRuntimeChange = (serialNumber: any, event: any) => {
        const value = event.target.value;
        dispatch(updateGetDismantleRequestRunTime({ serialNumber: serialNumber, runtime: value, dismantleRequest: dismantleRequest }));
    };

    const editGalleryHandle = (showEditGallery: boolean, gl: any) => {
        if (gl?.reportType === "inbound") {
            setShowEditGallery(showEditGallery);
            setActiveGalleryItem(gl);
        }
    }
    const isReport = source.includes("report");


    return (
        <div className="difa-widget-containers">
            <div className="header-main">
                <div className="flex flex-row justify-between flex-wrap">
                    <div>
                        <h3>Inbound Testing</h3>
                        {!isPreview && (
                            <h5>
                                Add inbound testings results and images to your DIFA packet
                            </h5>
                        )}
                    </div>
                    {!isPreview && (
                        <div className="flex flex-row items-center gap-3">
                            <DifaSwitch checked={expanded} onChange={onExpand} />
                            <h4 className="whitespace-nowrap">Include in {isReport ? 'report' : 'packet'}</h4>
                            <button className="btn-secondary font-bold text-sm">
                                View inbound document
                            </button>
                        </div>
                    )}
                </div>
            </div>
            <div className="flex flex-col gap-6 border-t border-dark py-5 px-6">
                <div className="flex flex-col gap-2">
                    <h3>Results</h3>
                    <p className="text-sm font-medium">
                        Summary of how the ESP performed during inbound testing.
                    </p>
                </div>
                <div className="difa-detail-card-tab-content">
                    <div className="difa-card-tabs flex-col md:flex-row">
                        {Object.keys(name)?.map((key) => (
                            <div
                                key={key}
                                className={`tab-item ${activeTab === key ? "active" : ""}`}
                                onClick={() => setActiveTab(key)}
                            >
                                <div
                                    className={` ${activeTab === key ? "lable-active" : "tab-lable"
                                        }`}
                                >
                                    {name[key]}
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="table-main relative border border-dark overflow-auto shadow-md sm:rounded-lg max-h-96">
                        <table className="w-full text-left">
                            <thead className="text-xs font-semibold top-0 sticky text-neutral-grey border-b border-dark">
                                <tr className="table-row">
                                    <th scope="col" className="px-3 py-4 whitespace-nowrap">
                                        <div className="flex items-center">
                                            Postion on ESP
                                        </div>
                                    </th>
                                    <th scope="col" className="px-3 py-4">
                                        <div className="flex items-center whitespace-nowrap">
                                            Serial #
                                        </div>
                                    </th>
                                    <th scope="col" className="px-3 py-4 w-1/5">
                                        <div className="flex items-center">
                                            Equipment description
                                        </div>
                                    </th>
                                    <th scope="col" className="px-3 py-4 whitespace-nowrap">
                                        <div className="flex items-center">
                                            Inbound results
                                        </div>
                                    </th>
                                    <th scope="col" className="px-3 py-4 whitespace-nowrap">
                                        <div className="flex items-center">
                                            Run time
                                        </div>
                                    </th>
                                    <th scope="col" className="px-3 py-4">
                                        <div className="flex items-center">
                                            Comments
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="text-sm font-normal text-neutral-grey">
                                {tabContent?.length === 0 ? (
                                    <tr className="table-row">
                                        <td colSpan={6} className="text-lg text-center py-5">
                                            No data found
                                        </td>
                                    </tr>
                                ) : (
                                    tabContent?.map((pump: any, index: number) => (
                                        <tr key={index} className="border-b border-dark">
                                            <td className="px-3 py-3 whitespace-nowrap">{name[activeTab] === "Pumps" ? name[activeTab].substring(0, 4) : name[activeTab]}{" "}{index + 1}</td>
                                            <td className="px-3 py-3">{pump?.serialNumber}</td>
                                            <td className="px-3 py-3 break-all">{pump?.equipementType}</td>
                                            <td className={`px-3 py-3 ${pump?.inboundResults === "Fail" ? "failed" : "passed"}`}>
                                                {pump?.inboundResults === "Fail"
                                                    ? "Failed"
                                                    : pump?.inboundResults}
                                            </td>
                                            <td className="px-3 py-3 whitespace-nowrap">
                                                {isPreview ? pump?.runtime || "-" : (
                                                    <input type="text"
                                                        placeholder="Add run days"
                                                        className="bg-dark-800 border border-dark px-3.5 py-3 w-full rounded-lg"
                                                        onBlur={onBlurEvent}
                                                        onChange={(event) => handleRuntimeChange(pump?.serialNumber, event)}
                                                        value={pump?.runtime ?? ""} />
                                                )}
                                            </td>
                                            <td className="px-3 py-3 w-2/6">{pump?.comments || "-"}</td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="flex flex-col gap-4 border-t border-dark pt-6">
                    <div className="flex flex-row gap-3 justify-between items-center">
                        <div className="block">
                            {isPreview ? (
                                <>
                                    <h3>Inbound image evidence</h3>
                                    <h5>
                                        Image galleries showcase photos taken during inbound
                                        testing.
                                    </h5>
                                </>
                            ) : (
                                <>
                                    <h3>Inbound image gallery</h3>
                                    <h5>Upload, annotate, and edit inbound images.</h5>
                                </>
                            )}
                        </div>
                        {!isPreview && <button className="btn-secondary font-bold text-sm" onClick={() => {
                            setShowGallery(true);
                            setGalleryMode('create');
                        }}>Create gallery</button>}
                    </div>
                    {!inboundImageGallery || !inboundImageGallery?.length ? (
                        <div className="flex flex-col justify-center">
                            <div className="flex p-6 border border-dark rounded-lg items-center justify-center bg-dark-800">
                                <p className="font-normal text-sm text-center py-6">
                                    No images have been added. To add photos and create an inbound image collection, <br /> click the "Create gallery" button.
                                </p>
                            </div>
                        </div>
                    ) : (
                        <div className="flex flex-row gap-5 pt-5 border-t border-dark cursor-pointer rounded-md flex-wrap">
                            {inboundImageGallery &&
                                inboundImageGallery?.map((gl: any, index: number) => (
                                    <div className="flex flex-col gap-3  w-72 max-w-72"
                                        onClick={isPreview ? () => {
                                            setImages(gl?.reportImageDetails);
                                            setShowViewGallery(true);
                                        } : undefined}>
                                        <div className={`difa-widget-containers  difa-background relative max-w-72 h-[270px] p-2`}>
                                            <div className="w-full h-full flex items-center flex-col relative">
                                                {!isPreview && <CustomImageDropdown source={source} gl={gl} removeGallery={(galleryId: string) => galleryUtility.handleRemove(galleryId, source, refreshGallery)} editGalleryHandle={editGalleryHandle} onRenameSuccess={refreshGallery} />}
                                                <img className="h-48 w-full" src={gl?.reportImageDetails?.length && gl.reportImageDetails[0].thumbnailFileLink} alt={'gallery'} onClick={() => !isPreview ? editGalleryHandle(true, gl) : undefined}></img>
                                                <div className="flex justify-between w-full p-2 gap-3">
                                                    <h2 className="break-all">{gl?.galleryName}</h2>
                                                    <h5>{gl?.reportImageDetails?.length}&nbsp;images</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    )}
                </div>
            </div>
            {showGallery &&
                <GalleryComponent
                    show={showGallery}
                    defaultGroupName="Inbound Images"
                    difaID={difaId}
                    reportType="inbound"
                    mode={galleryMode}
                    source={source}
                    images={inboundReportImages}
                    loading={loading}
                    onGalleryClose={() => {
                        setShowGallery(false);
                        refreshGallery();
                    }}
                    wellApiNumber={difaDetails.wellApiNumber}
                />}

            {showEditGallery &&
                <EditGalleryComponent
                    source=''
                    galleryID={activeGalleryItem?.galleryID}
                    onRemoveGallery={(galleryId: string) => galleryUtility.handleRemove(galleryId, source, refreshGallery)}
                    show={showEditGallery}
                    onAddNewImages={() => {
                        setShowEditGallery(false);
                        setGalleryMode('update');
                        setShowGallery(true);
                    }}
                    onGalleryClose={() => {
                        setShowEditGallery(false);
                        setActiveGalleryItem(null);
                        refreshGallery();
                    }}
                    wellApiNumber={difaDetails.wellApiNumber}
                />}

            {showViewGallery && (
                <ViewGallery images={images} show={showViewGallery} close={() => setShowViewGallery(false)} name="inbound images" />
            )}
        </div>
    )
}

export default InboundTesting
