
import React, { useEffect, useState } from 'react';
import { fetchGalleryEquipments, fetchInspectionImageGallary, fetchInstallImageGallery, fetchInstallReportImages, removeGallery, setDifaInspection } from "../../../difaPacketBuilder/difaPacketBuilderSlice";
import { useDispatch, useSelector } from 'react-redux';
import { NameInterface } from '../../../models/DismantleRequest';
import ChampXEmp from './ChampXEmp';
import DIFAAttendees from './DIFAAttendees';
import { fetchEquipmentAction } from '../../DIFAInspectionSlice'
import EquipmentAction from './EquipmentAction';
import Loader from '../../../common/loader/Loader';
import CustomImageDropdown from '../../../common/dropdownWithIcon/CustomImageDropdown';
import GalleryComponent from '../../../common/gallery/Gallery';
import EditGalleryComponent from '../../../common/gallery/EditGallery';
import DIFAPreview from './DIFAPreview';
import { galleryUtility } from '../../../common/services/GalleryUtility';

const DIFAInspection: React.FC<{
  wellApiNumber: string;
  difaId: string;
  source: string;
}> = ({ wellApiNumber, difaId, source }) => {
  const [activeTab, setActiveTab] = useState<string>("PUMP MSC");
  const [tabContent, setTabContent] = useState<any>([]);
  const [selectedEqp, setSelectedEqp] = useState<any>(null);
  const dispatch = useDispatch();
  const [showInspectionGallery, setShowInspectionGallery] = useState(false);
  const [showEditInspectionGallery, setShowEditInspectionGallery] = useState(false);
  const [activeInspectionGalleryItem, setActiveInspectionGalleryItem] = useState(null as any);
  const [inspectionGalleryMode, setInspectionGalleryMode] = useState('create');
  const isPreview = source === "preview-report";


  const { difaInspection, inspectionImageGallery, inspectionReportImages, galleryTransactionMsg } = useSelector((state: any) => state?.difaPacketBuilder);
  const [showEqpAction, setShowEqpAction] = useState<boolean>(false);
  const [res, setRes] = useState({ payload: [] });
  const loading = useSelector((state: any) => state?.difaInspection?.loading) || false;

  const name: NameInterface = {
    "PUMP MSC": "Pumps",
    "GAS HANDLING": "Gas handling",
    "PROTECTOR": "Protector",
    "MOTOR DH": "Motor",
    "SENSOR-DH": "Sensor",
    "CABLE": "Cable & MLE",
  };

  useEffect(() => {
    dispatch(fetchEquipmentAction({ difaId: difaId }))
      .then((response: any) => {
        setRes(response);
      })
      .catch((error: any) => {
        console.error("Error fetching equipment action: ", error);
      });
  }, [dispatch, difaId]);

  const EditGalleryHandle = (showEditGallery: boolean, gl: any) => {
    setShowEditInspectionGallery(showEditGallery);
    setActiveInspectionGalleryItem(gl);
  }

  const refreshGallery = () => {
    dispatch(fetchInspectionImageGallary({ wellApiNumber, difaId, source }));
  };

  useEffect(() => {
    refreshGallery();
  }, [])

  useEffect(() => {
    if (res?.payload?.length > 0) {
      const filteredData = res.payload.filter(
        (equipment: any) => equipment.partType === activeTab
      );
      setTabContent(filteredData);
    } else {
      setTabContent([]);
    }
  }, [res, activeTab]);

  const handleEqpAactionChange = (serialNumber: string, equipementDescription: string, activeTab: string) => () => {
    setSelectedEqp({ serialNumber, equipementDescription, activeTab });
    setShowEqpAction(true);
  };
  // Helper function to map result values to colors
  const getResultColor = (result: string) => {
    switch (result) {
      case "Pass":
        return "#12B76A"; // Green for Pass
      case "Failed":
        return "#F04438"; // Red for Failed
      case "Not inspected":
        return "#FFFFFF"; // White for Not Inspected (ensure table background supports visibility)
      default:
        return "#000000"; // Default black for undefined cases
    }
  };


  useEffect(() => {
    dispatch(fetchGalleryEquipments({ wellApiNumber, difaId }))
  }, [galleryTransactionMsg]);

  return (
    <div className="flex flex-col gap-6 text-neutral-grey difa-inspection">
      <div className="difa-widget-containers">
        <div className="header-main">
          <h3>{isPreview ? "Inspection equipment action" : "DIFA Inspection page"}</h3>
          {!isPreview && <h5>Add inspection results and images to your report</h5>}
        </div>

        <div className="flex flex-col border-top gap-6 px-6 py-5">
          <div className="difa-widget-containers">
            {!isPreview && (
              <div className="header-main">
                <h3>Equipment Action</h3>
                <h5>Record the equipment action, inspection result, and any relevant comments.</h5>
              </div>
            )}

            <div className="difa-detail-card-tab-content pt-3 pb-5 px-6 border-top">
              <div className="difa-card-tabs flex-col md:flex-row">
                {Object.keys(name)?.map((key) => (
                  <div
                    key={key}
                    className={`tab-item ${activeTab === key ? "active" : ""}`}
                    onClick={() => setActiveTab(key)}
                  >
                    <div className={activeTab === key ? "lable-active" : "tab-lable"}>
                      {name[key]}
                    </div>
                  </div>
                ))}
              </div>

              <div className="table-main relative border border-dark overflow-auto shadow-md sm:rounded-lg max-h-96">
                {loading && <Loader isOverlay={true} />}
                <table className="w-full text-left">
                  <thead className="text-xs font-semibold top-0 sticky text-neutral-grey border-b border-dark">
                    <tr className="table-row">
                      <th className="px-3 py-4 whitespace-nowrap">Serial #</th>
                      <th className="px-3 py-4 w-1/5">Equipment description</th>
                      <th className="px-3 py-4 whitespace-nowrap">DIFA action</th>
                      <th className="px-3 py-4 whitespace-nowrap">DIFA results</th>
                      <th className="px-3 py-4">Comments</th>
                    </tr>
                  </thead>
                  <tbody className="text-sm font-normal text-neutral-grey">
                    {tabContent?.length === 0 ? (
                      <tr className="table-row">
                        <td colSpan={5} className="text-lg text-center py-5">
                          No data found
                        </td>
                      </tr>
                    ) : (
                      tabContent?.map((pump: any, index: any) => (
                        <tr key={index} className="border-b border-dark">
                          {!isPreview && <td
                            onClick={() =>
                              handleEqpAactionChange(pump.serialNumber, pump.equipementDescription, activeTab)
                            }
                            className="px-3 py-3 text-Indigo cursor-pointer hover:underline"
                          >
                            {pump.serialNumber}
                          </td>}
                          {isPreview && <td

                            className="px-3 py-3"
                          >
                            {pump.serialNumber}
                          </td>}
                          <td className="px-3 py-3 break-words">{pump.equipementDescription}</td>
                          <td className="px-3 py-3">{pump.difaAction}</td>
                          <td className="px-3 py-3 whitespace-nowrap" style={{ color: getResultColor(pump.results) }}>
                            {pump.results}
                          </td>
                          <td className="px-3 py-3 w-2/6">{pump.comments}</td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {isPreview ? (
            <DIFAPreview />
          ) : (
            <>
              <div className="flex flex-col gap-4 pt-6 border-top">
                <div className="flex flex-col gap-1">
                  <h3 className="text-base font-semibold">Summary</h3>
                  <p className="text-sm font-medium text-grey-200">
                    Enter your observations from the inspection and any prior supporting evidence.
                  </p>
                </div>
                <textarea
                  className="card-textarea"
                  maxLength={500}
                  rows={6}
                  value={difaInspection?.summary}
                  placeholder="Add summary here..."
                  onBlur={(e) =>
                    dispatch(setDifaInspection({ ...difaInspection, summary: e.target.value }))
                  }
                  onChange={(e) =>
                    dispatch(setDifaInspection({ ...difaInspection, summary: e.target.value }))
                  }
                ></textarea>

                <div className="flex flex-col gap-4 pt-6 border-top">
                  <div className="flex items-center justify-between gap-4">
                    <div className="flex flex-col gap-1">
                      <h3 className="text-base font-semibold">Image gallery</h3>
                      <p className="text-sm font-medium text-grey-200">
                        Upload, annotate, and edit images from your inspection.
                      </p>
                    </div>
                    <button
                      className="btn-secondary font-bold text-sm"
                      onClick={() => {
                        setInspectionGalleryMode("create");
                        setShowInspectionGallery(true);
                      }}
                    >
                      Create gallery
                    </button>
                  </div>

                  {!inspectionImageGallery?.length ? ( // Pass this data as props to Preview
                    <div className="flex flex-col justify-center">
                      <div className="flex p-6 border border-dark rounded-lg items-center justify-center bg-dark-800">
                        <p className="font-normal text-sm text-center py-6 text-neutral-gray-300">
                          No images have been added. To add photos and create a gallery, click the "Add <br /> image gallery" button.
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div className="flex flex-row gap-5 p-6 border-t border-dark cursor-pointer rounded-md flex-wrap">
                      {inspectionImageGallery.map((gl: any, index: any) => {
                        const galleryName = gl?.galleryName.split("Serial");
                        return (
                          <div className="flex flex-col gap-3 w-72 max-w-72" key={index}>
                            <div className="difa-widget-containers difa-background relative max-w-72 h-[270px] p-2">
                              <div className="w-full h-full flex items-center flex-col relative">
                                <CustomImageDropdown  // Don't show in Preview
                                  gl={gl}
                                  source={source}
                                  showRename={false}
                                  editGalleryHandle={EditGalleryHandle}
                                  removeGallery={(galleryId: string) => galleryUtility.handleRemove(galleryId, source, refreshGallery)}
                                  onRenameSuccess={refreshGallery}
                                />
                                {gl.reportImageDetails?.length && (
                                  <img
                                    className="h-48 w-full"
                                    src={gl.reportImageDetails[0].thumbnailFileLink}
                                    alt="gallery"
                                    onClick={() => EditGalleryHandle(true, gl)}
                                  />
                                )}
                                <div className="flex justify-between w-full">
                                  <h2 className="break-all">{galleryName[0]}</h2>
                                  <h5>{gl?.reportImageDetails?.length}&nbsp;images</h5>
                                </div>
                                <div className="flex justify-between w-full">
                                  <h5>Serial {galleryName[1]}</h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>

              {showInspectionGallery && (
                <GalleryComponent
                  difaID={difaId}
                  reportType="inspection"
                  show={showInspectionGallery}
                  mode={inspectionGalleryMode}
                  images={inspectionReportImages}
                  loading={loading}
                  onGalleryClose={() => {
                    setShowInspectionGallery(false);
                    refreshGallery();
                  }}
                  wellApiNumber={wellApiNumber}
                  showGalleryTab={false}
                  source={source}
                />
              )}
            </>
          )}

          {showEditInspectionGallery && (
            <EditGalleryComponent
              show={showEditInspectionGallery}
              galleryID={activeInspectionGalleryItem?.galleryID}
              onRemoveGallery={(galleryId: string) => galleryUtility.handleRemove(galleryId, source, refreshGallery)}
              onAddNewImages={() => {
                setShowEditInspectionGallery(false);
                setInspectionGalleryMode("update");
                setShowInspectionGallery(true);
              }}
              onGalleryClose={() => {
                setShowEditInspectionGallery(false);
                setActiveInspectionGalleryItem(null);
                refreshGallery();
              }}
              wellApiNumber={wellApiNumber}
              source={source}
            />
          )}
          {!isPreview &&
            <>
              <ChampXEmp />
              <DIFAAttendees />
            </>
          }
          {showEqpAction && selectedEqp && (
            <EquipmentAction
              open={showEqpAction}
              onCloseModal={() => setShowEqpAction(false)}
              EquipmentData={selectedEqp}
              newTable={() => {
                dispatch(fetchEquipmentAction({ difaId }))
                  .then((response: any) => setRes(response))
                  .catch((error: any) => console.error("Error getting table data:", error));
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}
export default DIFAInspection;