import React from "react";
import DismantleRequest from "./tabCards/DismantleRequest";
import PreInspection from "./tabCards/PreInspection";
import Tabs from "../../common/horizontalTabs/Tabs";
import DismantleRequestDetails from "./tabCards/DismantleRequestDetails";
import { useDispatch, useSelector } from "react-redux";
import "./DifaInspectionDetails.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { GetUserEmail } from "../../common/services/UserService";
import { BeginReport, setEditFlow } from "../../difaPacketBuilder/difaPacketBuilderSlice";

const DifaDetailsRight: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { difaId, customerName } = location.state || {};
  const navigate = useNavigate();
  const { difaDetails, reportStatus } = useSelector((state: any) => state.difaDetails);

  const userEmail = GetUserEmail();

  const getDismantleRequest = useSelector((state: any) => state.dismantleRequest?.getDismantleRequest);

  const tabs = [
    { label: "Pre-inspection", component: PreInspection },
    {
      label: "Dismantle request",
      component: getDismantleRequest?.length > 0
        ? DismantleRequestDetails
        : DismantleRequest,
    },
  ];

  const handleDifaReport = () => {
    
    !reportStatus?.isReportPublished && !reportStatus?.isReportExist && dispatch(BeginReport({
      "wellApinumber": difaDetails?.wellApiNumber,
      "difaId": difaId
    }))
    if (reportStatus?.isReportPublished) {
      dispatch(setEditFlow(false))
          
      navigate("difa-report-preview", {
        state: {
          difaId: difaId,
          customerName: customerName,
          wellName: difaDetails.wellName,
        },
      });
    }
    else
      navigate("difa-report-builder", {
        state: {
          difaId: difaId,
          customerName: customerName,
          wellName: difaDetails.wellName,
        },
      });
  };

  const isNavigationDisabled = userEmail !== difaDetails?.emailAddress;
  return (
    <>
      <div className="difa-details-right-section">
        <div className="difa-details-header">Pre-inspection</div>
        <Tabs tabs={tabs} />
      </div>

      <div className="difa-details-right-section">
        <div className="difa-details-header">Inspection</div>
        <div className="content-section">
          <div className="inspection-box">
            <div className="inspection-text-container">
              <div className="inspection-text">
                {reportStatus?.isReportPublished ?
                  <>
                    View your DIFA inspection report. <br />
                    Click below to open.
                  </>
                  : reportStatus?.isReportExist ? <>
                    Continue your DIFA inspection report. <br />
                    Click below to finish.</>
                    : <>
                      Ready to start your DIFA inspection? <br />
                      Click below to start the process.
                    </>
                  }
              </div>
            </div>
            <div className={`inspection-button`}>
              <button className="footer-btn" onClick={handleDifaReport}>
                {reportStatus?.isReportPublished ? 'View report' : reportStatus?.isReportExist ? "Continue report" : "Begin report"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DifaDetailsRight;
