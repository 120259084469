// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PIS-doc-container .tab-section {
  padding: 0;
  border-bottom: none;
}
.PIS-doc-container .download-img {
  border-right: 1px solid #a0b1bb;
}
.PIS-doc-container .search-box {
  border: 1px solid #4a5463;
  background: var(--Dark-mode-800, #001023);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  padding-top: 10px;
  padding-bottom: 10px;
  color: #b8c5cc;
}
.PIS-doc-container .documentaion-table-container thead {
  background: #253040;
}
.PIS-doc-container .documentaion-table-container tr:last-child {
  border-bottom: none !important;
}
.PIS-doc-container .documentaion-table-container tr:hover {
  background: #253040;
}
.PIS-doc-container .documentaion-table-container .difa-id {
  cursor: pointer;
  color: #60BFDA;
}
.PIS-doc-container .documentaion-table-container .difa-id:hover {
  text-decoration: underline;
  color: #60BFDA;
}
.PIS-doc-container .tab-inactive {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/features/preInspectionSummary/components/PISummaryDocumentaion.scss"],"names":[],"mappings":"AACE;EACE,UAAA;EACA,mBAAA;AAAJ;AAGE;EACE,+BAAA;AADJ;AAIE;EACE,yBAAA;EACA,yCAAA;EACA,kDAAA;EACA,iBAAA;EACA,oBAAA;EACA,cAAA;AAFJ;AAOI;EACE,mBAAA;AALN;AASM;EACE,8BAAA;AAPR;AAUM;EACE,mBAAA;AARR;AAYI;EACE,eAAA;EACA,cAAA;AAVN;AAYM;EACE,0BAAA;EACA,cAAA;AAVR;AAeE;EACE,aAAA;AAbJ","sourcesContent":[".PIS-doc-container {\n  .tab-section {\n    padding: 0;\n    border-bottom: none;\n  }\n\n  .download-img {\n    border-right: 1px solid #a0b1bb;\n  }\n\n  .search-box {\n    border: 1px solid #4a5463;\n    background: var(--Dark-mode-800, #001023);\n    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);\n    padding-top: 10px;\n    padding-bottom: 10px;\n    color: #b8c5cc;\n  }\n\n  .documentaion-table-container {\n\n    thead {\n      background: #253040;\n    }\n\n    tr {\n      &:last-child {\n        border-bottom: none !important;\n      }\n\n      &:hover {\n        background: #253040;\n      }\n    }\n\n    .difa-id {\n      cursor: pointer;\n      color: #60BFDA;\n\n      &:hover {\n        text-decoration: underline;\n        color: #60BFDA;\n      }\n    }\n  }\n\n  .tab-inactive {\n    display: none;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
