import React from "react";
import "./PISummaryDifaDetails.scss";
import { useSelector } from "react-redux";
import { formatDate, formatDateInNumbers, getFirstLastEngineerLetter, getHSLColor } from "../../common/services/UtilityService";

interface PISummaryDifaDetailsProps {
  difaId: string | number;
}

const PISummaryDifaDetails: React.FC<PISummaryDifaDetailsProps> = ({
  difaId,
}) => {

  const difaDetails = useSelector(
    (state: any) => state.difaDetails?.difaDetails
  );

  const assigneeColor = getHSLColor(difaDetails.emailAddress || '');
  
  return (
    <div className="pi-summary-content-header">
      {difaDetails && (
        <>
          <div className="summary-header-difa-details">
            <div className="difa-detail-heading">DIFA ID#</div>
            <div className="difa-detail-data">{difaId}</div>
          </div>
          <div className="summary-header-difa-details">
            <div className="difa-detail-heading">Install date</div>
            <div className="difa-detail-data">{formatDateInNumbers(difaDetails?.espIntalledDate)}</div>
          </div>
          <div className="summary-header-difa-details">
            <div className="difa-detail-heading">Failed date</div>
            <div className="difa-detail-data">{difaDetails?.espFailedDate ? formatDateInNumbers(difaDetails?.espFailedDate) : "-"}</div>
          </div>
          <div className="summary-header-difa-details">
            <div className="difa-detail-heading">Pull date</div>
            <div className="difa-detail-data">{formatDateInNumbers(difaDetails?.espPulledDate)}</div>
          </div>
          <div className="summary-header-difa-details">
            <div className="difa-detail-heading">Run days to failed date</div>
            <div className="difa-detail-data">{difaDetails?.rundaysToFailedDate}</div>
          </div>
          <div className="summary-header-difa-details">
            <div className="difa-detail-heading">Run days to pull start date</div>
            <div className="difa-detail-data">{difaDetails?.rundaysToPullStartDate}</div>
          </div>
          <div className="summary-header-difa-details">
            <div className="difa-detail-heading">DIFA scheduled</div>
            <div className="difa-detail-data">{formatDate(difaDetails?.dismantleDate)}</div>
          </div>
          <div className="summary-header-difa-details">
            <div className="difa-detail-heading">Assigned to</div>
            <div className="difa-detail-data">
              <span className="assignee-name" style={{backgroundColor: assigneeColor}}>{getFirstLastEngineerLetter(difaDetails?.dismantleEngineer)}</span> {difaDetails?.dismantleEngineer}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PISummaryDifaDetails;
