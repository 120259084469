/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */

import closeIcon from "./../../../images/close-icon.svg";
import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";

const PublishDifaDialog: React.FC<{ show: boolean, close?: () => void, submit?: () => void }> = ({
    show = false,
    close = () => { close() },
    submit = () => { submit() },
}) => {
    return (
        <Dialog open={show} onClose={() => close()} className="relative z-50">
            <DialogBackdrop
                transition
                className="fixed inset-0 backdrop-blur-sm transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />
            <DialogBackdrop
                transition
                className="fixed inset-0 backdrop-blur-sm transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className="fixed inset-0 z-10 w-screen">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0 sm:min-w-full">
                    <DialogPanel
                        transition
                        className="flex success-dialog-max-height relative transform overflow-hidden rounded-lg difa-background-with-border text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-xl md:min-w-120 md:w-120  data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                    >
                        <div className="difa-widget-containers modal-bg">
                            <div className="header-main border-bottom">
                                <div className="flex flex-row justify-between">
                                    <div>
                                        <span className="publish-modal-heading">Publish packet</span>
                                    </div>
                                    <a href="javascript:void(0)" onClick={() => close()}>
                                        <img src={closeIcon} alt="close" />
                                    </a>
                                </div>
                            </div>
                            <div className="success-dialog-body p-6 w-full flex flex-col overflow-y-auto pb-16 table-main border-bottom">
                                <div className="flex flex-col h-24 items-center justify-center gap-6">
                                    <span className="modal-heading" >Ready to publish your DIFA packet?</span>
                                    <span className="confirm-sub-header text-center">By publishing, this will make the report visible to customers and notify them that their DIFA packet is ready for review. </span>
                                </div>
                            </div>
                            <div className="flex-row flex gap-3 justify-end items-center h-16 mr-6">
                                <div className="secondary-button">
                                    <button className="header-btn-text" onClick={() => close()} >Cancel</button>

                                </div>
                                <div className="primary-button">
                                    <button className="header-btn-text" onClick={() => submit()} >Publish</button>
                                </div>
                            </div>
                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>

    );
}

export default PublishDifaDialog;