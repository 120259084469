import instance from "../../config/HttpCommon";

export const getDifaSchedular = async (pageNumber: number, pageSize: number ) => {
  const difaSchedularRes = await instance.get(`/Scheduler/GetSchedulers?pageNumber=${pageNumber}&pageSize=${pageSize}`);
  return difaSchedularRes?.data;
}

export const getSchedulersByJobid = async (jobId: number, quoteId: number) => {
  //const difaSchedularRes = await instance.get(`/Scheduler/GetSchedulerByQuoteID?quoteId=${93224}&jobRequestId=${108702}`);
  const difaSchedularRes = await instance.get(`/Scheduler/GetSchedulerByQuoteID?quoteId=${quoteId}&jobRequestId=${jobId}`);
  return difaSchedularRes?.data;
};

export const getEngineers = async () => {
  const enginners = await instance.get(`/Scheduler/GetEngineers`);
  return enginners?.data;
}

export const saveScheduler = async (scheduler: any) => {
  const response = await instance.post(`/Scheduler/SaveScheduler`, scheduler);
  return response?.data;
}

export const deleteScheduler = async (schedulerId: number) => {
  const response = await instance.delete(`/Scheduler/DeleteScheduler?schedulerID=${schedulerId}`);
  return response?.data;
}