
import { HTMLProps } from 'react';
import './DifaInput.scss';
export interface DifaInputProps extends HTMLProps<HTMLInputElement> {
    classNames?: string;
    caption: string;
    placeholder?: string;
    value?: string;
    maxLength?: number;
    isDateField?: boolean;
}


const DifaInput: React.FC<DifaInputProps> = ({
    caption,
    classNames,
    isDateField,
    ...addtionalProps
}) => {
    return (
        <div className='flex flex-col w-full'>
            <div className="input-field-container">
                <div className="input-field-caption">{caption}</div>
                <div className='input-field-wrapper'>
                    <input {...addtionalProps} className={`input-field ${isDateField ? 'icon-date' : ''} ${classNames}`} />
                </div>
            </div>
        </div>
    );
}

export default DifaInput;
