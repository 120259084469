import React, { ChangeEvent, useEffect, useState } from 'react';
import { formatDateInNumbers } from '../../../../common/services/UtilityService';
import { fetchServiceReport } from '../../../../preInspectionSummary/PreInspectionSummarySlice';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as DownArrow } from '../../../../../images/chevron-down.svg';
import { ReactComponent as UpArrow } from '../../../../../images/chevron-up.svg';
import DifaCheckbox from '../../../../common/checkbox/DifaCheckbox';
import ServiceReport from '../../../../preInspectionSummary/components/ServiceReport';
import { useSortableData } from '../../../../hooks/useSortableData';
import { setWellInstallation } from '../../../difaPacketBuilderSlice';

interface DifaRundaysDetail {
  difaId: string;
  serviceDate: string;
  serviceCompleteDate: string;
  serviceProblem: string;
  serviceResolution: string;
  wellApiNumber: string;
}
const IWOServiceReport: React.FC<{ searchQuery: string; source: string }> = ({
  searchQuery,
  source,
}) => {
  const { wellInstallation, preSelectedServiceReports } = useSelector(
    (state: any) => state.difaPacketBuilder
  );
  const [showServiceReport, setShowServiceReport] = useState(false);
  const [selectedReports, setSelectedReports] = useState<DifaRundaysDetail[]>(
    wellInstallation?.serviceReports || []
  );
  const dispatch = useDispatch();
  const { difaRundaysDetails = [], loading } = useSelector(
    (state: any) => state.preInspectionSummary
  );

  useEffect(() => {
    const matchingReports = difaRundaysDetails.filter((ticket: any) =>
      preSelectedServiceReports?.some(
        (preSelected: any) => preSelected.DifaID === ticket.difaId
      )
    );
    preSelectedServiceReports && setSelectedReports(matchingReports)
    
  }, [preSelectedServiceReports]);

  const isPreview = source === 'preview-packet' || source === 'preview-report';
  
  const openReport = (wellApiNumber: string) => {
    dispatch(fetchServiceReport({ wellApiNumber: wellApiNumber }));
    setShowServiceReport(true);
  };

  const {
    items: sortedDifaTrackings,
    requestSort,
    sortConfig,
  } = useSortableData<DifaRundaysDetail>(difaRundaysDetails);
   
  const filteredDifaServices = sortedDifaTrackings.filter((item) =>
    item?.difaId.includes(searchQuery)
  );
  const selectDifa = (
    e: ChangeEvent<HTMLInputElement>,
    difaRundaysDetails: DifaRundaysDetail
  ) => {
    const selectedReport = filteredDifaServices.find(
      (x: any) => x.difaId === difaRundaysDetails.difaId
    );
    if (selectedReport && e.target.checked) {
      const selectedReportsInterim = [...selectedReports, selectedReport];
      setSelectedReports(selectedReportsInterim);
      dispatch(
        setWellInstallation({
          ...wellInstallation,
          serviceReports: selectedReportsInterim,
        })
      );
    } else {
      const selectedReportsInterim = selectedReports.filter(
        (x: any) => x.difaId !== difaRundaysDetails.difaId
      );
      setSelectedReports(selectedReportsInterim);
      dispatch(
        setWellInstallation({
          ...wellInstallation,
          serviceReports: selectedReportsInterim,
        })
      );
    }
  };

  const reportstoDisplay = !isPreview
    ? filteredDifaServices
    : filteredDifaServices?.filter((a: any) => {
      return selectedReports?.find((x: any) => x.difaId === a.difaId);
    });

  return (
    <div className='table-main relative border border-dark overflow-auto shadow-md sm:rounded-lg max-h-80'>
      <table className='w-full text-left'>
        <thead className='text-xs font-semibold border-b border-dark top-0 sticky text-neutral-grey z-10'>
          <tr>
            {!isPreview && (
              <th scope='col' className='px-3 py-4'>
                <DifaCheckbox
                  onChange={(e: ChangeEvent<HTMLInputElement>) => { }}
                />
              </th>
            )}
            <th scope='col' className='px-3 py-4'>
              <div
                className='flex items-center whitespace-nowrap cursor-pointer'
                onClick={() => requestSort('difaId')}
              >
                Quote ID
                {sortConfig?.key === 'difaId' &&
                  sortConfig.direction === 'asc' ? (
                  <UpArrow className='ms-1.5' />
                ) : (
                  <DownArrow className='ms-1.5' />
                )}
              </div>
            </th>
            <th scope='col' className='px-3 py-4'>
              <div
                className='flex items-center cursor-pointer'
                onClick={() => requestSort('serviceDate')}
              >
                Start date
                {sortConfig?.key === 'serviceDate' &&
                  sortConfig.direction === 'asc' ? (
                  <UpArrow className='ms-1.5' />
                ) : (
                  <DownArrow className='ms-1.5' />
                )}
              </div>
            </th>
            <th scope='col' className='px-3 py-4'>
              <div
                className='flex items-center cursor-pointer'
                onClick={() => requestSort('serviceCompleteDate')}
              >
                Complete date
                {sortConfig?.key === 'serviceCompleteDate' &&
                  sortConfig.direction === 'asc' ? (
                  <UpArrow className='ms-1.5' />
                ) : (
                  <DownArrow className='ms-1.5' />
                )}
              </div>
            </th>
            <th scope='col' className='px-3 py-4'>
              <div className='flex items-center'>Problem</div>
            </th>
            <th scope='col' className='px-3 py-4'>
              <div className='flex items-center'>Resolution</div>
            </th>
          </tr>
        </thead>
        <tbody className='text-sm font-normal text-neutral-grey'>
          {loading ? (
            <tr>
              <td colSpan={6} className='text-lg text-center py-5'>
                Loading...
              </td>
            </tr>
          ) : reportstoDisplay?.length > 0 ? (
            reportstoDisplay.map((difaRundaysDetail: any, index: number) => (
              <tr key={index} className='border-b border-dark'>
                {!isPreview && (
                  <th scope='row' className='px-3 py-4'>
                    <DifaCheckbox
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        selectDifa(e, difaRundaysDetail);
                      }}
                      checked={
                        !!selectedReports.find(
                          (x: any) => x.difaId === difaRundaysDetail.difaId
                        )
                      }
                    />
                  </th>
                )}
                <th
                  scope='row'
                  className='px-3 py-4 text-Indigo whitespace-nowrap hover:underline cursor-pointer'
                  onClick={() => openReport(difaRundaysDetail?.wellApiNumber)}
                >
                  {difaRundaysDetail?.difaId}
                </th>
                <td className='px-3 py-4'>
                  {formatDateInNumbers(difaRundaysDetail?.serviceDate)}
                </td>
                <td className='px-3 py-4'>
                  {difaRundaysDetail?.serviceCompleteDate
                    ? formatDateInNumbers(
                      difaRundaysDetail?.serviceCompleteDate
                    )
                    : '-'}
                </td>
                <td className='px-3 py-4'>
                  {difaRundaysDetail?.serviceProblem}
                </td>
                <td className='px-3 py-4 w-2/5'>
                  {difaRundaysDetail?.serviceResolution
                    ? difaRundaysDetail?.serviceResolution.slice(0, 60) + '....'
                    : '-'}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={6} className='text-lg text-center py-5'>
                No data found
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {showServiceReport && (
        <ServiceReport
          open={showServiceReport}
          onCloseModal={setShowServiceReport}
        />
      )}
    </div>
  );
};

export default IWOServiceReport;
