import React, { useEffect, useState } from "react";
import closeSlider from "../../../images/close-slider.svg";
import chevronDown from "../../../images/chevron-down.svg";
import chevronUp from "../../../images/chevron-up.svg";
import CheckboxList from "./CheckboxList";
import "../Documentation.scss";
import SearchIcon from "../../../images/search-md.svg";
import  DifaReport from "./DifaReport";
import { FiltersType } from "./DifaPacket";
import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { fetchDifaReports } from "../DocumentationSlice";

interface Toggle {
  data: DifaReport[];
  open: boolean;
  onCloseModal: () => void;
  onApplyFilters: (filters: FiltersType) => void;
}

const FilterReports: React.FC<Toggle> = ({ data, open, onCloseModal, onApplyFilters }) => {
  const [visibleField, setVisibleField] = useState<string | null>(null);
  const [selectedItems, setSelectedItems] = useState<{ [key: string]: string[] }>({});
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filterOptions, setFilterOptions] = useState<Record<string, string[]>>({
    "Operator": [],
    "Well Name": [],
    "Install Date": [],
    "Failed Date": [],
    "Pulled Date": [],
    "Run Days": [],
    "DIFA Scheduled": [],
    "Report Published": [],
    "Pull Reason": [],
    "Failure Cause": [],
    "Failed Item": [],
    "Primary Failed Component": [],
    "Failure Descriptor": [],
  });

  type AppDispatch = ThunkDispatch<any, any, AnyAction>;
  const dispatch: AppDispatch = useDispatch();
  const { data: tableData} = useSelector((state: any) => state.documentation.reports);

  // Fetch data when component mounts
  useEffect(() => {
    dispatch(
      fetchDifaReports({
        pageNumber: 1,
        pageSize: 100,
        operator: [],
        installDate: [],
        failedDate: [],
        pullDate: [],
        reportPublished: [],
        rundays: [],
        difaSceduled: [],
        pullReasion: [],
        failureCause: [],
        failedItem: [],
        primaryFailedComponent: [],
      })
    );
  }, [dispatch]);

 // Process API response and populate filter options
  useEffect(() => {
    if (tableData && Array.isArray(tableData)) {
      
      const uniqueValues = (key: keyof DifaReport): string[] => {
        const values = tableData
          .map((item: DifaReport) => {
            const value = item[key];
            if (!value) return null;
            
            // Handle date fields
            if (value instanceof Date || (typeof value === 'string' && key.toString().toLowerCase().includes('date'))) {
              const date = new Date(value);
              return !isNaN(date.getTime()) ? date.toLocaleDateString('en-US') : null;
            }
            
            // Handle numeric fields
            if (typeof value === 'number') {
              return value.toString();
            }
            
            // Handle string fields
            return value.toString();
          })
          .filter((value): value is string => value !== null && value !== 'undefined' && value !== '-');

        return Array.from(new Set(values));
      };

      // Map API fields to filter options
      const updatedFilterOptions = {
        "Operator": uniqueValues("operatorName"),
        "Install Date": uniqueValues("installDate"),
        "Failed Date": uniqueValues("failedDate"),
        "Pulled Date": uniqueValues("pulledDate"),
        "Report Published": uniqueValues("reportPublished"),
        "Run Days": uniqueValues("rundays"),
        "DIFA Scheduled": uniqueValues("difaScheduled"),
        "Pull Reason": uniqueValues("pullReasion"),
        "Failure Cause": uniqueValues("failureCause"),
        "Failed Item": uniqueValues("failedItem"),
        "Primary Failed Component": uniqueValues("primaryFailedComponent"),
        "Failure Descriptor": uniqueValues("failureDescriptor"),
      };
      setFilterOptions(updatedFilterOptions);
    }
  }, [tableData]);


  // Slide-in filter overlay toggle
  useEffect(() => {
    document.getElementById("slider")?.classList.toggle(open ? "open" : "close");
    return () => {
      document.getElementById("slider")?.classList.toggle("close");
    };
  }, [open]);

  // Handle visibility toggle for each field
  const handleFieldClick = (field: string) => {
    setVisibleField(visibleField === field ? null : field);
  };

  // Handle changes to selected items for checkboxes
  const handleItemChange = (field: string, item: string) => {
    setSelectedItems((prevSelectedItems) => ({
      ...prevSelectedItems,
      [field]: prevSelectedItems[field]?.includes(item)
        ? prevSelectedItems[field].filter((i) => i !== item)
        : [...(prevSelectedItems[field] || []), item],
    }));
  };

    const handleApply = () => {
      const fieldMapping: { [key: string]: keyof FiltersType } = {
        "Operator": "operator",
        "Install Date": "installDate",
        "Failed Date": "failedDate",
        "Pulled Date": "pullDate",
        "Report Published": "reportPublished",
        "Run Days": "rundays",
        "DIFA Scheduled": "difaSceduled",
        "Pull Reason": "pullReasion",
        "Failure Cause": "failureCause",
        "Failed Item": "failedItem",
        "Primary Failed Component": "primaryFailedComponent",
      };
  
      const mappedFilters = Object.entries(selectedItems).reduce((acc, [key, values]) => {
            const apiField = fieldMapping[key];
            if (apiField) {
              acc[apiField] = values;
            }
            return acc;
          }, {} as FiltersType);
          onApplyFilters(mappedFilters);
          onCloseModal();
        };
  
  return (
    <div id="slider" className="main-component !w-[613px]">
      <CardHeader onClose={onCloseModal} />
      {data ? (
        <div className="flex flex-col gap-6 text-neutral-grey px-6 py-5">
          {Object.keys(filterOptions).map((field) => (
            <div key={field} className="flex flex-col gap-4">
              <div
                className="flex items-center justify-between cursor-pointer py-3 pr-1.5 border-bottom"
                onClick={() => handleFieldClick(field)}
              >
                <label className="text-sm font-bold">{field}</label>
                <img
                  src={visibleField === field ? chevronUp : chevronDown}
                  alt={visibleField === field ? "Collapse" : "Expand"}
                  className="w-4 h-4 transition-transform duration-200"
                />
              </div>

              {visibleField === field && (
                <div className="flex flex-col px-4">
                  <div className="relative w-full">
                    {searchTerm.length === 0 && (
                      <img
                        src={SearchIcon}
                        className="absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-400"
                        alt="Search"
                      />
                    )}
                    <input
                      type="search"
                      className="py-2.5 px-3.5 w-full text-base font-medium rounded-lg border border-dark bg-dark-800 pl-10"
                      placeholder={`Search ${field}`}
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </div>
                  <CheckboxList
                    items={filterOptions[field]} // Use dynamically populated filterOptions
                    selectedItems={selectedItems[field] || []}
                    onItemChange={(item) => handleItemChange(field, item)}
                    searchTerm={searchTerm}
                  />
                </div>
              )}
            </div>
          ))}
        </div>
      ) : (
        <div className="text-lg text-center flex items-center justify-center">No data found</div>
      )}

      <div className="flex justify-end sticky bottom-0 gap-3 py-4 px-8 border-top bg-dark-800">
        <button
          className="bg-dark-700 px-3.5 py-2 rounded-lg text-sm font-bold hover:bg-dark-600"
          onClick={() => {
            setSearchTerm("");
            setSelectedItems({});
          }}
        >
          Reset All
        </button>
        <button
          className="px-3.5 py-2 rounded-lg text-sm font-bold apply-btn hover:bg-blue-400"
          onClick={handleApply} // Handle "Apply" button click
        >
          Apply
        </button>
      </div>
    </div>
  );
};

const CardHeader: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  return (
    <div className="report-card-header !justify-start">
      <div className="header-icon" onClick={onClose}>
        <img src={closeSlider} alt="icon" />
        <div className="text-[23px] font-bold">Filter</div>
      </div>
    </div>
  );
};

export default FilterReports;
