import React, { useState } from "react";
import "./PISummaryChart.scss";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../common/loader/Loader";
import XYChartComponent from "./RundaysChart";
import MainComponent from "./InstallReport";
import StartupReport from "./StartupReport";
import PullReport from "./PullReport";
import {
  fetchInstallReport,
  fetchPullReport,
  fetchStartupReport,
} from "../PreInspectionSummarySlice";
import { PISummarySnapshotandChartProps } from "../../models/PreInpectionSummary";

const PISummarySnapshotandChart: React.FC<PISummarySnapshotandChartProps> = ({
  wellApiNumber,
}) => {
  const dispatch = useDispatch();
  const [showReport, setShowReport] = useState(false);
  const [showStartup, setStartupReport] = useState(false);
  const [showPullReport, setPullReport] = useState(false);
  const { difaRundaysDetails, loading } = useSelector(
    (state: any) => state.preInspectionSummary
  );
  const openReport = (type: string) => {
    if (type === "install") {
      if (!showReport){
        dispatch(fetchInstallReport({ wellApiNumber: wellApiNumber }));
        setPullReport(false);
        setStartupReport(false);
        setShowReport(!showReport);
      }
    } else if (type === "startup") {
      if (!showStartup){
        dispatch(fetchStartupReport({ wellApiNumber: wellApiNumber }));
        setStartupReport(!showStartup);
        setPullReport(false);
        setShowReport(false);
      }
    } else if (type === "pull") {
        if (!showPullReport){
        dispatch(fetchPullReport({ wellApiNumber: wellApiNumber }));
        setPullReport(!showPullReport);
        setShowReport(false);
        setStartupReport(false);
      }
    }
  };
  return (
    <>
      <div className="pi-summary-content-chart flex-col md:flex-row">
        <div className="pre-inspection-summary w-full md:w-1/2">
          <div className="pis-header">
            <h3>Pre-inspection snapshot</h3>
          </div>
          <div className="pis-content">
            <p>The unit was pulled on Feb 10, 2023</p>
            <p>
              According to the pull report, the unit tested unbalanced and
              grounded downhole. Approximately 270' of main cable was removed
              while pulling out of hole due the cable coming out without bands
              (most bands were lost downhole). All equipment had free shaft
              rotation. The pump O-rings were slightly degraded. The motor was
              balanced with low resistance. The cable - MLE was not tested.
            </p>
          </div>
          <div className="pis-footer">
            <div className="pis-footer-btn">
              <button
                className="pis-footer-btn-text"
                onClick={() => openReport("install")}
              >
                View install report
              </button>
            </div>
            <div className="pis-footer-btn">
              <button
                className="pis-footer-btn-text"
                onClick={() => openReport("startup")}
              >
                View start up report
              </button>
            </div>
            <div className="pis-footer-btn">
              <button
                className="pis-footer-btn-text"
                onClick={() => openReport("pull")}
              >
                View pull report
              </button>
            </div>
          </div>
        </div>
        <div className="pre-inspection-summary w-full md:w-1/2">
          <div className="pis-header">
            <h3>Runday comparison</h3>
            <p>View the rundays for all ESP's that have run in this well</p>
          </div>
          <div className="mt-5 pb-2">
            {loading && <Loader />}
            <XYChartComponent id="chart1" data={difaRundaysDetails} />
          </div>
          <div className="pis-rundayChart"></div>
        </div>
      </div>
      {showReport && (
        <MainComponent open={showReport} onCloseModal={setShowReport} />
      )}
      {showStartup && (
        <StartupReport open={showStartup} onCloseModal={setStartupReport} />
      )}
      {showPullReport && (
        <PullReport open={showStartup} onCloseModal={setPullReport} />
      )}
    </>
  );
};

export default PISummarySnapshotandChart;
