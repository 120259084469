import React, { useEffect, useState } from "react";
import dotVertical from "../../../../images/dots-vertical.svg";
import editIcon from "../../../../images/edit-05.svg";
import deleteIcon from "../../../../images/trash-01.svg";

type Nullable<T> = T | null;

const RecommendationList = () => {
    const [recommendations, setRecommendations] = useState<string[]>([
        "Due to the life stage of the well consider moving to a Rod Lift system",
        "Consider use of sand guard and desander to minimize abrasive wear/fallback to combat sand.",
    ]);
    const [newRecommendation, setNewRecommendation] = useState("");
    const [isEditing, setIsEditing] = useState(false);
    const [currentRecommendation, setCurrentRecommendation] = useState<Nullable<number>>(null);
    const [openMenuIndex, setOpenMenuIndex] = useState<Nullable<number>>(null);

    useEffect(() => {
        const persistedRecommendations = localStorage.getItem("recommendations");
        if (persistedRecommendations) {
            setRecommendations(JSON.parse(persistedRecommendations));
        }
    }, []);

    useEffect(() => {
        localStorage.setItem("recommendations", JSON.stringify(recommendations));
    }, [recommendations]);

    const addRecommendation = () => {
        if (isEditing) {
            const updatedTodos = recommendations.map((todo, index) =>
                index === currentRecommendation ? newRecommendation : todo
            );
            setRecommendations(updatedTodos);
            setIsEditing(false);
        } else if (newRecommendation.trim()) {
            setRecommendations([...recommendations, newRecommendation]);
        }
        setNewRecommendation("");
    };

    const handleEdit = (index: number) => {
        setIsEditing(true);
        setCurrentRecommendation(index);
        setNewRecommendation(recommendations[index]);
        setOpenMenuIndex(null); // Close menu on edit
    };

    const handleDelete = (index: number) => {
        setRecommendations((prev) => {
            const newRecommendations = prev.filter((_, todoIndex) => todoIndex !== index);

            // Ensure local storage is updated immediately
            localStorage.setItem("recommendations", JSON.stringify(newRecommendations));

            return newRecommendations;
        });

        if (isEditing && index === currentRecommendation) {
            setIsEditing(false);
            setNewRecommendation("");
        }

        setOpenMenuIndex(null); // Close menu on delete
    };

    const toggleMenu = (index: number) => {
        setOpenMenuIndex(openMenuIndex === index ? null : index);
    };

    return (
        <div className="difa-widget-containers rounded-lg shadow-md">
            <div className="header-main">
                <h3>Recommendations</h3>
                <h5>Provide recommendations to assist our customers with next steps for their ESPs.</h5>
            </div>

            <div className="flex flex-col border-t border-dark gap-4 px-6 py-5">
                {recommendations.map((todo, index) => (
                    <div key={index} className="flex justify-between items-center p-4 border border-dark rounded-lg relative">
                        <p className="text-sm font-normal">{todo}</p>

                        {/* Ellipsis Button */}
                        <button onClick={() => toggleMenu(index)} className="p-1 focus:outline-none">
                            <img src={dotVertical} alt="edit-recommendation" />
                        </button>

                        {/* Dropdown Menu */}
                        {openMenuIndex === index && (
                            <div className="absolute right-0 top-8 bg-gray-800 text-white shadow-lg rounded-lg p-2 w-32">
                                <button
                                    className="flex items-center w-full text-left px-3 py-2 hover:bg-gray-700"
                                    onClick={() => handleEdit(index)}
                                >
                                    <img src={editIcon} alt="Edit" className="w-4 h-4 mr-2" />
                                    Edit
                                </button>
                                <button
                                    className="flex items-center w-full text-left px-3 py-2 hover:bg-gray-700"
                                    onClick={() => handleDelete(index)}
                                >
                                    <img src={deleteIcon} alt="Delete" className="w-4 h-4 mr-2" />
                                    Delete
                                </button>
                            </div>
                        )}
                    </div>
                ))}

                <textarea
                    className="card-textarea"
                    maxLength={500}
                    rows={3}
                    value={newRecommendation}
                    placeholder="Enter a recommendation..."
                    onChange={(event) => setNewRecommendation(event.target.value)}
                ></textarea>
                <div className="flex justify-start items-center cursor-pointer">
                    <button
                        type="submit"
                        className="primary-button !px-3.5 !py-2 text-sm font-bold"
                        onClick={addRecommendation}
                    >
                        {isEditing ? "Update Recommendation" : "Add Recommendation"}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default RecommendationList;
