import React from "react";
import { HeaderProps } from "../../DifaPages/components/HeaderProps";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as ArrowRightIcon } from "../../../images/chevron-right.svg";
import RouteConstant from "../../../constants/routes";
import { useDispatch, useSelector } from "react-redux";
import { resetPacketBuilderState } from "../../difaPacketBuilder/difaPacketBuilderSlice";

const Header: React.FC<HeaderProps> = ({
  headerName,
  customerName,
  wellName,  
  isPacketAvailable,
  ispacketPublished
}) => {
  const { draftId, publishedId } = useSelector(
    (state: any) => state.difaPacketBuilder
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { difaId } = location.state || {};

  const difaDetails = () => {
    navigate("/difa-details", {
      state: { difaId: difaId, customerName: customerName },
    });
  };

  const navigateToDifaPacketBuilder = () => {
    navigate("/difa-details/difa-packet-builder", {
      state: { difaId: difaId, customerName: customerName, wellName },
    });
  };

  const viewPublishedPacket = () => {
      navigate("/difa-details/preview-difa-packet", {
        state: { difaId: difaId, customerName: customerName, wellName },
      });
    }

  const dashboard = () => {
    dispatch(resetPacketBuilderState());
    navigate("/");
  };

  const redirectToWellName = () => {
    wellName && navigate(
      RouteConstant.wellName.replace(
        ":wellName",
        encodeURIComponent(wellName)),
      {
        state: { difaId: difaId, customerName: customerName },
      });
  };

  return (
    <header className="main-header">
      <div className="header-content">
        <div className="breadcrumb-container">
          <div className="breadcrumb-item" onClick={dashboard}>
            Dashboard
          </div>
          <div className="breadcrumb-separator">
            <ArrowRightIcon />
          </div>
          <div className="breadcrumb-item" onClick={difaDetails}>
            DIFA details
          </div>
          <div className="breadcrumb-separator">
            <ArrowRightIcon />
          </div>
          <div className="breadcrumb-item active">{headerName}</div>
        </div>
        <div className="header-details">
          <div className="header-details-content">
            <div className="header-title">{headerName}</div>
            <div className="header-meta">
             {customerName &&  <div className="meta-company">{customerName} |</div> }
              <div className="meta-project">
                {wellName}
              </div>
            </div>
          </div>
          <div className="header-details-button">
            {ispacketPublished ?  
            <button className="header-btn-text" onClick={viewPublishedPacket}>View DIFA packet</button>
            : 
            <button className="header-btn-text" onClick={navigateToDifaPacketBuilder}>{isPacketAvailable ? 'Edit DIFA packet':'Generate DIFA packet'}</button>
          }
          </div>
        </div>
      </div>
      <div className="divider"></div>
    </header>
  );
};

export default Header;
