import React from "react";
import DismantleRequest from "./tabCards/DismantleRequest";
import PreInspection from "./tabCards/PreInspection";
import Tabs from "../../common/horizontalTabs/Tabs";
import DismantleRequestDetails from "./tabCards/DismantleRequestDetails";
import { useSelector } from "react-redux";
import "./DifaInspectionDetails.scss";
import { useLocation, useNavigate } from "react-router-dom";

const DifaDetailsRight: React.FC = () => {
  const location = useLocation();
  const { difaId, customerName } = location.state || {};
  const navigate = useNavigate();
  const { difaDetails } = useSelector((state: any) => state.difaDetails);

  const getDismantleRequest = useSelector((state: any) => state.dismantleRequest?.getDismantleRequest);
  
  const tabs = [
    { label: "Pre-inspection", component: PreInspection },
    {
      label: "Dismantle request",
      component: getDismantleRequest?.length > 0
        ? DismantleRequestDetails
        : DismantleRequest,
    },
  ];

  const handleDifaReport = () => {
    navigate("difa-report-builder", {
      state: {
        difaId: difaId,
        customerName: customerName,
        wellName: difaDetails.wellName,
      },
    });
  };

  return (
    <>
      <div className="difa-details-right-section">
        <div className="difa-details-header">Pre-inspection</div>
        <Tabs tabs={tabs} />
      </div>

      <div className="difa-details-right-section">
        <div className="difa-details-header">Inspection</div>
        <div className="content-section">
          <div className="inspection-box">
            <div className="inspection-text-container">
              <div className="inspection-text">
                Ready to start your DIFA report? <br />
                Click below to start.
              </div>
            </div>
            <div className="inspection-button">
              <button className="footer-btn" onClick={handleDifaReport}>
                Begin report
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DifaDetailsRight;
