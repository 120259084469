import { useEffect, useState } from "react";
import ViewGallery from "../../common/gallery/ViewGallery";
import { DismantleRequestProps } from "../../models/DismantleRequest";
import { VerticalTabContentProps } from "../../models/DismantleRequest";
import "./VerticalTabContent.scss";
import { useDispatch, useSelector } from "react-redux";
import { fetchInboundReportImages } from "../../difaPacketBuilder/difaPacketBuilderSlice";
import { useLocation } from "react-router-dom";
import Loader from "../../common/loader/Loader";

const VerticalTabContent: React.FC<VerticalTabContentProps> = ({
  activeTab,
  tabContent,
  selectedIds,
  handleCheckbox,
  handleAllSelected,
  handleAllSelectCheckbox,
  name,
  isCheckboxVisible,
}) => {
  const [showViewGallery, setShowViewGallery] = useState(false);
  const [images, setImages] = useState([] as any);
  const location = useLocation();
  const { difaId } = location.state || {};
  let { inboundReportImages, loading } = useSelector(
    (state: any) => state?.difaPacketBuilder
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchInboundReportImages({
      difaId,
      partType: ''
    }));
  }, [])

  useEffect(() => {
    setImages(inboundReportImages?.filter((x: any) => x.caption === activeTab) || []);
  }, [activeTab]);

  return (
    <div className="content-table">
      {loading && <Loader isOverlay />}
      <div className="table-header">
        <div className="table-heading">{name[activeTab]}</div>
        <div className="table-header-button">

          {activeTab !== "PROTECTOR" && (
            <div className="card-header-button cursor-pointer" onClick={() => setShowViewGallery(true)}>
              <button className="button-text" disabled={!images?.length}>View inbound images</button>
            </div>
          )}
        </div>
      </div>
      <div className="table-main">
        <div className="table">
          <div className="table-row table-content-heading">
            {isCheckboxVisible && (
              <div className="th select flex justify-center">
                <label className="custom-checkbox">
                  <input
                    type="checkbox"
                    checked={handleAllSelectCheckbox()}
                    onChange={() => handleAllSelected(activeTab)}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
            )}
            <div className="th sno"> </div>
            <div className="th serial">Serial #</div>
            <div className="th eq-type">Equipment type</div>
            <div className="th i-results">Inbound results</div>
            <div className="th i-comments">Inbound comments</div>
          </div>

          {tabContent?.length === 0 ? (
            <div className="no-data">No Data Found</div>
          ) : (
            tabContent?.map((pump: DismantleRequestProps, index: number) => {
              return (
                <div className="table-row" key={index}>
                  {isCheckboxVisible && (
                    <div className="table-column select justify-center">
                      <label className="custom-checkbox">
                        <input
                          type="checkbox"
                          checked={
                            selectedIds.includes(pump?.serialNumber)
                              ? true
                              : false
                          }
                          onChange={() => handleCheckbox(pump?.serialNumber)}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  )}
                  <div className="table-column sno justify-center">{index + 1}</div>
                  <div className="table-column serial">
                    {pump?.serialNumber}
                  </div>
                  <div className="table-column eq-type">
                    {pump?.equipementType}
                  </div>
                  <div
                    className={`table-column ${(pump?.inboundResults === "Fail" ? "failed" : "pass")
                      } i-results`}
                  >
                    {pump?.inboundResults === "Fail" ? "Failed" : "Pass"}
                  </div>
                  <div className="table-column i-comments">
                    {pump?.comments !== "" ? pump?.comments : "-"}
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>
      <ViewGallery images={images} show={showViewGallery} close={() => setShowViewGallery(false)} name="Inbound images" />
    </div>

  );
};

export default VerticalTabContent;
