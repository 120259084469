// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*General Section Styling 

  
  /* Smaller Box Styling */
  .flex-1 {
    flex: 1 1;
  }
  
 
  
  .rounded-lg {
    border-radius: 8px;
  }
  
 
  
  /* Initials Circle */
  .w-10 {
    width: 2.5rem;
  }
  
  .h-10 {
    height: 2.5rem;
  }
  
  .rounded-full {
    border-radius: 9999px;
  }
  
  .bg-indigo-500 {
    background-color: #6366f1; /* Indigo */
  }
  
  .text-white {
    color: #ffffff;
  }
  
  /* Typography */
  .font-semibold {
    font-weight: 600;
  }
  
  .text-grey-100 {
    color: #9ca3af;
  }
  
  .text-grey-500 {
    color: #6b7280;
  }
  

  
  .text-lg {
    font-size: 1.125rem;
  }
  
  .mb-4 {
    margin-bottom: 1rem;
  }
  
  .gap-6 {
    gap: 1.5rem;
  }
  /* Updated box background color */
.bg-custom {
    
    border: 1px solid var(--Darkmode-400, #4a5463); /* Slightly darker border for definition */
    border-radius: 8px;
    padding: 16px;
  }
  
  /* Consistent spacing and layout */
  .gap-6 {
    gap: 1.5rem;
  }
  
  .mb-4 {
    margin-bottom: 1rem;
  }
  
  /* Typography fixes */
  .font-semibold {
    font-weight: 600;
  }
  `, "",{"version":3,"sources":["webpack://./src/features/difaReport/components/difaInspection/preview.css"],"names":[],"mappings":"AAAA;;;0BAG0B;EACxB;IACE,SAAO;EACT;;;;EAIA;IACE,kBAAkB;EACpB;;;;EAIA,oBAAoB;EACpB;IACE,aAAa;EACf;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,qBAAqB;EACvB;;EAEA;IACE,yBAAyB,EAAE,WAAW;EACxC;;EAEA;IACE,cAAc;EAChB;;EAEA,eAAe;EACf;IACE,gBAAgB;EAClB;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,cAAc;EAChB;;;;EAIA;IACE,mBAAmB;EACrB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,WAAW;EACb;EACA,iCAAiC;AACnC;;IAEI,8CAA8C,EAAE,0CAA0C;IAC1F,kBAAkB;IAClB,aAAa;EACf;;EAEA,kCAAkC;EAClC;IACE,WAAW;EACb;;EAEA;IACE,mBAAmB;EACrB;;EAEA,qBAAqB;EACrB;IACE,gBAAgB;EAClB","sourcesContent":["/*General Section Styling \n\n  \n  /* Smaller Box Styling */\n  .flex-1 {\n    flex: 1;\n  }\n  \n \n  \n  .rounded-lg {\n    border-radius: 8px;\n  }\n  \n \n  \n  /* Initials Circle */\n  .w-10 {\n    width: 2.5rem;\n  }\n  \n  .h-10 {\n    height: 2.5rem;\n  }\n  \n  .rounded-full {\n    border-radius: 9999px;\n  }\n  \n  .bg-indigo-500 {\n    background-color: #6366f1; /* Indigo */\n  }\n  \n  .text-white {\n    color: #ffffff;\n  }\n  \n  /* Typography */\n  .font-semibold {\n    font-weight: 600;\n  }\n  \n  .text-grey-100 {\n    color: #9ca3af;\n  }\n  \n  .text-grey-500 {\n    color: #6b7280;\n  }\n  \n\n  \n  .text-lg {\n    font-size: 1.125rem;\n  }\n  \n  .mb-4 {\n    margin-bottom: 1rem;\n  }\n  \n  .gap-6 {\n    gap: 1.5rem;\n  }\n  /* Updated box background color */\n.bg-custom {\n    \n    border: 1px solid var(--Darkmode-400, #4a5463); /* Slightly darker border for definition */\n    border-radius: 8px;\n    padding: 16px;\n  }\n  \n  /* Consistent spacing and layout */\n  .gap-6 {\n    gap: 1.5rem;\n  }\n  \n  .mb-4 {\n    margin-bottom: 1rem;\n  }\n  \n  /* Typography fixes */\n  .font-semibold {\n    font-weight: 600;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
