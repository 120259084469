import { useDispatch, useSelector } from "react-redux";
import DifaInput from "../../common/input/DifaInput";
import DifaSwitch from "../../common/switch/DifaSwitch";
import "./WellRunLifeInformation.scss";
import {
  formatDateInNumbers,
  formatOnlyDate,
  formatPullReason,
} from "../../common/services/UtilityService";
import Loader from "../../common/loader/Loader";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import DifaCheckbox from "../../common/checkbox/DifaCheckbox";
import { setWellRunLife } from "../difaPacketBuilderSlice";
import { fetchServiceReport } from "../../preInspectionSummary/PreInspectionSummarySlice";

interface WellRunLifeInformationProps {
  difaId: string | number;
  source: string;
}

const WellRunLifeInformation: React.FC<WellRunLifeInformationProps> = ({
  difaId,
  source,
}) => {
  const dispatch = useDispatch();

  const difaDetails = useSelector((state: any) => state.difaDetails?.difaDetails);
  const { generalReport, wellRunLife, causeOfFailure, priorRunDetails } = useSelector((state: any) => state.difaPacketBuilder);
  const isPreview = source === "preview-packet" || source === "preview-report";
  const { difaRundaysDetails, loading } = useSelector(
    (state: any) => state.preInspectionSummary
  );
  const [expanded, setExpanded] = useState(
    wellRunLife?.isRunsSelected || false
  );
  const [selectedDifas, setSelectedDifas] = useState(wellRunLife?.selectedDifas || []);
  const [showServiceReport, setShowServiceReport] = useState(false);

  const openReport = (wellApiNumber: string) => {
    dispatch(fetchServiceReport({ wellApiNumber: wellApiNumber }));
    setShowServiceReport(true);
  };

  const onExpand = (e: ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    setExpanded(checked);
    const selectedRuns = selectedDifas?.map((x: any) => ({ ...x, selected: checked })) as any
    setSelectedDifas(selectedRuns);
    dispatch(setWellRunLife({ ...wellRunLife, isRunsSelected: checked, selectedDifas: selectedRuns }));
  };

  const onSelected = (e: ChangeEvent<HTMLInputElement>, difaId: string) => {
    const checked = e.target.checked;
    const selected = selectedDifas?.map((x: any) => ({
      ...x,
      selected: x.difaId === difaId ? checked : x.selected,
    })) as any
    setSelectedDifas(
      selected
    );
    dispatch(setWellRunLife({ ...wellRunLife, selectedDifas: selected }));
  };

  const runstoDisplay = !isPreview ?
    difaRundaysDetails?.map((x: any) => ({ ...x, selected: selectedDifas?.find((y: any) => y.difaId === x.difaId) })) :
    Array.isArray(difaRundaysDetails) && difaRundaysDetails?.filter((a: any) => {
      return selectedDifas?.find((x: any) => x.difaId === a.difaId && x.selected);
    });

  useEffect(() => {
    !selectedDifas?.length && setSelectedDifas(
      difaRundaysDetails?.length
        ? difaRundaysDetails?.map((x: any) => ({
          difaId: x.difaId,
          selected: false,
        }))
        : []
    );
  }, [difaRundaysDetails]);
  const showRuns = !isPreview || wellRunLife?.isRunsSelected;
  const isRportBuilder = source === "report-builder";
  const isReportPreview = source === "preview-report";
  const isReport = source.includes("report");

  return (
    <div className="flex-auto flex flex-col w-full gap-5">
      {loading && <Loader isOverlay />}
      {difaDetails && (
        <>
          {!isPreview && (
            <div className="difa-widget-containers">
              <div className="header-main">
                <h3>Well information</h3>
                <h5>
                  Include relevant supporting documentation to help our
                  customers understand the ESP's operational history.
                </h5>
              </div>
              <div className="container-body flex flex-col gap-2 border-top">
                <div className="flex flex-col md:flex-row gap-4">
                  <DifaInput
                    disabled={true}
                    caption="Operator"
                    value={difaDetails.operator}
                  />
                  <DifaInput
                    disabled={true}
                    caption="Well Name"
                    value={difaDetails.wellName}
                  />
                </div>
                <div className="flex flex-col md:flex-row gap-4">
                  <DifaInput
                    disabled={true}
                    caption="Field"
                    value={difaDetails.field}
                  />
                  <DifaInput
                    disabled={true}
                    caption="State"
                    value={difaDetails.state}
                  />
                </div>
              </div>
            </div>
          )}
          <div className="flex flex-row gap-6">
            <div className={`difa-widget-containers ${isReportPreview ? 'w-full md:w-1/3' : ''} `}>
              <div className="header-main">
                <h3>{!isPreview ? "Run life details" : "Run information"}</h3>
              </div>
              {isPreview ? (
                <div className={`flex flex-col ${!isReportPreview ? 'md:flex-row gap-6' : 'md:flex-col'}   px-6 py-5 border-top`}>
                  <div className={`flex flex-col w-full ${!isReportPreview ? 'md:w-1/2' : ''} gap-2`}>
                    <div className="flex items-center justify-between gap-2 pb-2 px-4 border-bottom">
                      <label className="label">Installed date</label>
                      <label className="value">
                        {formatOnlyDate(difaDetails?.espIntalledDate)}
                      </label>
                    </div>
                    <div className="flex items-center justify-between gap-2 px-4 pb-2 border-bottom">
                      <label className="label">Start date</label>
                      <label className="value">
                        {formatOnlyDate(difaDetails?.espStartedDate)}
                      </label>
                    </div>
                    <div className="flex items-center justify-between gap-2 pb-2 px-4 border-bottom">
                      <label className="label">Failed date</label>
                      <label className="value">
                        {formatOnlyDate(difaDetails?.espFailedDate)}
                      </label>
                    </div>
                    <div className={`flex items-center justify-between gap-2 pb-2 px-4 ${isReportPreview ? 'border-bottom' : ''}`}>
                      <label className="label">Pulled date</label>
                      <label className="value">
                        {formatOnlyDate(difaDetails?.espPulledDate)}
                      </label>
                    </div>
                  </div>
                  <div className={`flex flex-col w-full ${!isReportPreview ? 'md:w-1/2' : ''} gap-2`}>
                    <div className={`flex items-center justify-between gap-2 pb-2 px-4 border-bottom ${isReportPreview ? 'pt-2' : ''}`}>
                      <label className="label">Installed / run days</label>
                      <label className="value">
                        {difaDetails?.rundaysToPullStartDate} /{" "}
                        {difaDetails?.runNumber}
                      </label>
                    </div>
                    <div className="flex items-center justify-between gap-2 pb-2 px-4 border-bottom">
                      <label className="label">Reason for pull</label>
                      <label className="value">
                        {generalReport?.length > 0 && (generalReport[0]?.pullReason ?? "-")}
                      </label>
                    </div>
                    <div className="flex items-center justify-between gap-2 pb-2 px-4 border-bottom">
                      <label className="label">DIFA inspection date</label>
                      <label className="value">
                        {formatOnlyDate(difaDetails?.dismantleDate)}
                      </label>
                    </div>
                    <div className={`flex items-center justify-between gap-2 px-4 ${isReportPreview ? 'pb-2 border-bottom' : ''}`}>
                      <label className="label">Assigned DIFA engineer</label>
                      <label className="value">
                        {difaDetails?.dismantleEngineer || "-"}
                      </label>
                    </div>
                    {isReportPreview && (
                      <>
                        <div className="flex items-center justify-between gap-2 pb-2 px-4 border-bottom">
                          <label className="label">Report completed</label>
                          <label className="value">
                            {formatOnlyDate(difaDetails?.dismantleDate)}
                          </label>
                        </div>
                        <div className="flex items-center justify-between gap-2 px-4">
                          <label className="label">Report published</label>
                          <label className="value">
                            {difaDetails?.dismantleEngineer || "-"}
                          </label>
                        </div>

                      </>
                    )}
                  </div>

                </div>


              ) : (
                <div className="container-body flex flex-col gap-2 border-top">
                  <div className="flex flex-col md:flex-row gap-4">
                    <DifaInput
                      disabled={true}
                      caption="Install date"
                      isDateField={true}
                      value={formatOnlyDate(difaDetails.espIntalledDate)}
                    />
                    <DifaInput
                      disabled={true}
                      caption="Start date"
                      isDateField={true}
                      value={formatOnlyDate(difaDetails.espStartedDate)}
                    />
                  </div>
                  <div className="flex flex-col md:flex-row gap-4">
                    <DifaInput
                      disabled={true}
                      caption="Failed date"
                      isDateField={true}
                      value={formatOnlyDate(difaDetails.espFailedDate)}
                    />
                    <DifaInput
                      disabled={true}
                      caption="Pulled date"
                      isDateField={true}
                      value={formatOnlyDate(difaDetails.espPulledDate)}
                    />
                  </div>
                  <div className="flex flex-col md:flex-row gap-4">
                    {isRportBuilder ? (
                      <>
                        <DifaInput
                          disabled={true}
                          caption="Started to failed run days"
                          value={difaDetails.rundaysToFailedDate}
                        />
                        <DifaInput
                          disabled={true}
                          caption="Installed to  pulled run days"
                          value={difaDetails.rundaysToPullStartDate}
                        /></>) : (<>
                          <DifaInput
                            disabled={true}
                            caption="Installed to  pulled run days"
                            value={difaDetails.rundaysToPullStartDate}
                          />
                          <DifaInput
                            disabled={true}
                            caption="Started to failed run days"
                            value={difaDetails.rundaysToFailedDate}
                          />
                        </>
                    )}

                  </div>
                  {isRportBuilder && (<>
                    <div className="flex flex-col md:flex-row gap-4">
                      <DifaInput
                        disabled={true}
                        caption="Run number"
                        value={difaDetails.rundaysToFailedDate}
                      />
                      <DifaInput
                        disabled={true}
                        caption="DIFA inspection date"
                        isDateField={true}
                        value={formatOnlyDate(difaDetails.espPulledDate)}
                      />
                    </div>
                    <div className="flex flex-col md:flex-row gap-4">
                      <DifaInput
                        disabled={true}
                        caption="Report start date"
                        isDateField={true}
                        value={formatOnlyDate(difaDetails.espPulledDate)}
                      />
                      <DifaInput
                        disabled={true}
                        caption="Report submitted date"
                        isDateField={true}
                        value='Select date'
                      />
                    </div>
                  </>
                  )}
                </div>
              )}
            </div>
            {source === 'preview-report' && (
              <div className={`difa-widget-containers flex flex-col ${isReportPreview ? 'w-full md:w-2/3' : ''} `}>
                <div className="header-main border-bottom">
                  <h3>{"DIFA conclusion"}</h3>
                </div>
                <div className="px-5 pt-3 pb-5"><h5>{causeOfFailure?.conclusion ?? 'The conclusion is not provided yet.'}</h5></div>
              </div>
            )}
          </div>
        </>
      )}
      {showRuns && (
        <div className="difa-widget-containers">
          <div className="header-main border-bottom">
            <div className="flex flex-row justify-between">
              <div>
                <h3>Prior runs</h3>
                {!isPreview && (
                  <h5>Choose which historical ESP runs from this well to include in the {isReport ? 'report' : 'packet'}</h5>)}
              </div>
              {!isPreview && (
                <div className="flex flex-row gap-3 items-center">
                  <DifaSwitch checked={expanded} onChange={onExpand} />
                  <h4 className="whitespace-nowrap">Include in {isReport ? 'report' : 'packet'}</h4>
                </div>
              )}
            </div>
          </div>
          <div className="container-body flex flex-col gap-2 pt-20">
            <div className="table-main dpb-table-main">
              <div className="table">
                <div
                  className="table-row sticky top-0"
                  style={{ background: "#253240" }}
                >
                  {!isPreview && <div className="th w-8"></div>}
                  <div className="th w-1/12 min-w-20"> DIFA ID #</div>
                  <div className="th w-1/12 min-w-28">Install date</div>
                  <div className="th w-1/12 min-w-28">Pull date</div>
                  <div className="th w-1/12 min-w-20">Rundays</div>
                  <div className="th w-1/5 min-w-52">Pull reason</div>
                  <div className="th w-1/5 min-w-52">Failure cause</div>
                  <div className="th w-1/5 min-w-52">
                    Prmiary failed component
                  </div>
                </div>
                {loading && <Loader />}
                {!(runstoDisplay && runstoDisplay.length) ? (
                  <div className="no-data">No Data Found</div>
                ) : (
                  runstoDisplay?.map((pump: any, index: number) => {
                    return (
                      <div className="table-row" key={index}>
                        {!isPreview && (
                          <div className="table-column w-8">
                            <DifaCheckbox
                              disabled={!expanded}
                              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                onSelected(e, pump.difaId)
                              }
                              checked={
                                selectedDifas.find(
                                  (x: any) => x.difaId === pump.difaId
                                )?.selected
                              }
                            />
                          </div>
                        )}
                        <div className="table-column w-1/12 min-w-20">
                          {pump.difaId}
                        </div>
                        <div className="table-column w-1/12 min-w-28">
                          {formatDateInNumbers(pump?.installDate)}
                        </div>
                        <div className="table-column w-1/122 min-w-28">
                          {formatDateInNumbers(pump?.pullDate)}
                        </div>
                        <div className="table-column w-1/12 min-w-20">
                          {pump?.rundaysToPullStartDate}
                        </div>
                        <div className="table-column w-1/5 min-w-52">
                          {pump?.pullReason}
                        </div>
                        <div className="table-column w-1/5 min-w-52">
                          {pump?.failureCauseGeneral
                            ? pump?.failureCauseGeneral + "\n"
                            : ""}
                          {pump?.failureCauseGeneral
                            ? pump?.failureCauseSpecific + "\n"
                            : ""}
                        </div>
                        <div className="table-column w-1/5 min-w-52">
                          {pump?.primaryFailedComponent
                            ? pump?.primaryFailedComponent + "\n"
                            : ""}
                          {pump?.primaryFailedSubComponent
                            ? pump?.primaryFailedSubComponent + "\n"
                            : ""}
                        </div>
                      </div>
                    );
                  })
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default WellRunLifeInformation;
