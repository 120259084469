// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.active-page {
  border-color: #0094BD;
}

.row-bottom-line {
  border-bottom: 1px solid var(--Darkmode-400, #4a5463);
}`, "",{"version":3,"sources":["webpack://./src/features/difaPacketBuilder/components/WellRunLifeInformation.scss"],"names":[],"mappings":"AAAA;EACI,qBAAA;AACJ;;AAEA;EACI,qDAAA;AACJ","sourcesContent":[".active-page {\n    border-color: #0094BD;\n}\n\n.row-bottom-line {\n    border-bottom: 1px solid var(--Darkmode-400, #4a5463);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
