import React, { ChangeEvent, useState } from 'react';
import { ReactComponent as DownArrow } from '../../../../../images/chevron-down.svg';
import { ReactComponent as UpArrow } from '../../../../../images/chevron-up.svg';
import { formatDateInNumbers } from '../../../../common/services/UtilityService';
import { useDispatch, useSelector } from 'react-redux';
import DifaCheckbox from '../../../../common/checkbox/DifaCheckbox';
import {
  fetchInstallReport,
  fetchPullReport,
  fetchStartupReport,
} from '../../../../preInspectionSummary/PreInspectionSummarySlice';
import MainComponent from '../../../../preInspectionSummary/components/InstallReport';
import StartupReport from '../../../../preInspectionSummary/components/StartupReport';
import PullReport from '../../../../preInspectionSummary/components/PullReport';
import { useSortableData } from '../../../../hooks/useSortableData';
import { setWellInstallation } from '../../../difaPacketBuilderSlice';

const GeneralReportTable: React.FC<{ searchQuery: string; source: string }> = ({
  searchQuery,
  source,
}) => {
  const dispatch = useDispatch();
  const { wellInstallation, generalReport, loading } = useSelector(
    (state: any) => state.difaPacketBuilder
  );
  const [showReport, setShowReport] = useState(false);
  const [showStartup, setStartupReport] = useState(false);
  const [showPullReport, setPullReport] = useState(false);
  const isPreview = source === 'preview-packet' || source === 'preview-report';
  const generalReportData = generalReport?.length === 0 ? [
    {
      name: 'Install report',
      startDate: generalReport?.[0]?.installDate ?? '',
      completeDate: generalReport?.[0]?.installCompleteDate ?? '',
      serviceProblem: generalReport?.[0]?.serviceProblem ?? '',
      serviceResolution: generalReport?.[0]?.serviceResolution ?? '',
      type: 'install',
      isSelected:
        wellInstallation?.generalReports?.find((x: any) => x.type === 'install')
          ?.isSelected || false,
      sortOrder: '1',
    },
    {
      name: 'Start up report',
      startDate: generalReport?.[0]?.actionDate ?? '',
      completeDate: generalReport?.[0]?.startupCompleteDate ?? '',
      serviceProblem: generalReport?.[0]?.serviceProblem ?? '',
      serviceResolution: generalReport?.[0]?.serviceResolution ?? '',
      type: 'startup',
      isSelected:
        wellInstallation?.generalReports?.find((x: any) => x.type === 'startup')
          ?.isSelected || false,
      sortOrder: '2',
    },
    {
      name: 'Pull report',
      startDate: generalReport?.[0]?.pullDate ?? '',
      completeDate: generalReport?.[0]?.pullCompleteDate ?? '',
      serviceProblem: generalReport?.[0]?.serviceProblem ?? '',
      serviceResolution: generalReport?.[0]?.serviceResolution ?? '',
      type: 'pull',
      isSelected:
        wellInstallation?.generalReports?.find((x: any) => x.type === 'pull')
          ?.isSelected || false,
      sortOrder: '3',
    },
  ] : [];

  const {
    items: sortedDifaTrackings,
    requestSort,
    sortConfig,
  } = useSortableData(generalReportData);

  const [filteredDifaServices, setFilteredServices] = useState(
    sortedDifaTrackings.filter((item) =>
      item?.name.toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  const openReport = (type: string) => {
    if (type === 'Install report') {
      if (!showReport) {
        dispatch(
          fetchInstallReport({ wellApiNumber: generalReport[0]?.wellApiNumber })
        );
        setPullReport(false);
        setStartupReport(false);
        setShowReport(!showReport);
      }
    } else if (type === 'Start up report') {
      if (!showStartup) {
        dispatch(
          fetchStartupReport({ wellApiNumber: generalReport[0]?.wellApiNumber })
        );
        setStartupReport(!showStartup);
        setPullReport(false);
        setShowReport(false);
      }
    } else if (type === 'Pull report') {
      if (!showPullReport) {
        dispatch(
          fetchPullReport({ wellApiNumber: generalReport[0]?.wellApiNumber })
        );
        setPullReport(!showPullReport);
        setShowReport(false);
        setStartupReport(false);
      }
    }
  };

  const selectReport = (
    e: ChangeEvent<HTMLInputElement>,
    generalReport: any
  ) => {
    if (generalReport) {
      const report = filteredDifaServices.find(
        (x: any) => x.type === generalReport.type
      );
      if (report) {
        report.isSelected = e.target.checked;
        const selectedReportsInterim = [
          ...filteredDifaServices.filter(
            (x: any) => x.type !== generalReport.type
          ),
          report,
        ];
        setFilteredServices(
          selectedReportsInterim.sort((a: any, b: any) =>
            a.sortOrder.localeCompare(b.sortOrder)
          )
        );
      }

      if (e.target.checked) {
        const selectedReportsInterim = wellInstallation?.generalReports
          ? [...wellInstallation?.generalReports]
          : [];
        selectedReportsInterim.push(generalReport);

        dispatch(
          setWellInstallation({
            ...wellInstallation,
            generalReports: selectedReportsInterim,
          })
        );
      } else {
        const selectedReportsInterim = wellInstallation?.generalReports.filter(
          (x: any) => x.type !== generalReport.type
        );
        dispatch(
          setWellInstallation({
            ...wellInstallation,
            generalReports: selectedReportsInterim,
          })
        );
      }
    }
  };

  const reportstoDisplay = !isPreview
    ? filteredDifaServices
    : filteredDifaServices?.filter((a: any) => {
      return wellInstallation?.generalReports?.find(
        (x: any) => x.type === a.type && x.isSelected
      );
    });

  return (
    <div className='table-main relative border border-dark overflow-auto shadow-md sm:rounded-lg max-h-80'>
      <table className='w-full text-left'>
        <thead className='text-xs font-semibold border-b border-dark top-0 sticky text-neutral-grey z-10'>
          <tr>
            {!isPreview && (
              <th scope='col' className='px-3 py-4'>
                <DifaCheckbox
                  onChange={(e: ChangeEvent<HTMLInputElement>) => { }}
                />
              </th>
            )}
            <th scope='col' className='px-3 py-4'>
              <div className='flex items-center whitespace-nowrap'>
                Report name
              </div>
            </th>
            <th scope='col' className='px-3 py-4'>
              <div
                className='flex items-center cursor-pointer'
                onClick={() => requestSort('startDate')}
              >
                Start date
                {sortConfig?.key === 'startDate' &&
                  sortConfig.direction === 'asc' ? (
                  <UpArrow className='ms-1.5' />
                ) : (
                  <DownArrow className='ms-1.5' />
                )}
              </div>
            </th>
            <th scope='col' className='px-3 py-4'>
              <div
                className='flex items-center cursor-pointer'
                onClick={() => requestSort('completeDate')}
              >
                Complete date
                {sortConfig?.key === 'completeDate' &&
                  sortConfig.direction === 'asc' ? (
                  <UpArrow className='ms-1.5' />
                ) : (
                  <DownArrow className='ms-1.5' />
                )}
              </div>
            </th>
            <th scope='col' className='px-3 py-4'>
              <div className='flex items-center'>Problem</div>
            </th>
            <th scope='col' className='px-3 py-4'>
              <div className='flex items-center'>Resolution</div>
            </th>
          </tr>
        </thead>
        <tbody className='text-sm font-normal text-neutral-grey'>
          {loading ? (
            <tr>
              <td colSpan={6} className='text-lg text-center py-5'>
                Loading...
              </td>
            </tr>
          ) : reportstoDisplay?.length > 0 ? (
            reportstoDisplay.map((difaRundaysDetail: any, index: number) => (
              <tr key={index} className='border-b border-dark'>
                {!isPreview && (
                  <th scope='row' className='px-3 py-4'>
                    <DifaCheckbox
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        selectReport(e, difaRundaysDetail);
                      }}
                      checked={difaRundaysDetail.isSelected}
                    />
                  </th>
                )}
                <th
                  scope='row'
                  className='px-3 py-4 text-Indigo whitespace-nowrap hover:underline cursor-pointer'
                  onClick={() => openReport(difaRundaysDetail?.name)}
                >
                  {difaRundaysDetail?.name}
                </th>
                <td className='px-3 py-4'>
                  {formatDateInNumbers(difaRundaysDetail?.startDate || '-')}
                </td>
                <td className='px-3 py-4'>
                  {formatDateInNumbers(difaRundaysDetail?.completeDate) || '-'}
                </td>
                <td className='px-3 py-4'>
                  {difaRundaysDetail?.serviceProblem}
                </td>
                <td className='px-3 py-4 w-2/5'>
                  {difaRundaysDetail?.serviceResolution
                    ? difaRundaysDetail?.serviceResolution.slice(0, 80) + '....'
                    : '-'}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={6} className='text-lg text-center py-5'>
                No data found
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {showReport && (
        <MainComponent open={showReport} onCloseModal={setShowReport} />
      )}
      {showStartup && (
        <StartupReport open={showStartup} onCloseModal={setStartupReport} />
      )}
      {showPullReport && (
        <PullReport open={showStartup} onCloseModal={setPullReport} />
      )}
    </div>
  );
};

export default GeneralReportTable;
