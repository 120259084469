import { configureStore } from "@reduxjs/toolkit";
import DashboardSlice from "./features/dashboard/DashboardSlice";
import DifaDetailsSlice from "./features/DifaPages/DifaDetailsSlice";
import DismantleRequestSlice from "./features/dismentleRequest/DismantleRequestSlice";
import PreInspectionSummarySlice from './features/preInspectionSummary/PreInspectionSummarySlice';
import DifaPacketBuilderSlice from './features/difaPacketBuilder/difaPacketBuilderSlice';

export const store = configureStore({
    reducer: {
        difaList: DashboardSlice,
        difaDetails: DifaDetailsSlice,
        dismantleRequest: DismantleRequestSlice,
        preInspectionSummary: PreInspectionSummarySlice,
        difaPacketBuilder: DifaPacketBuilderSlice,
    }
}
)

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
