import React from "react";
import EllipseIcon1 from "../../../images/Ellipse 46.svg";
import EllipseMainIcon from "../../../images/Ellipse-main.svg";
import VectorLineIcon from "../../../images/Vector 1.svg";
import EllipseEmptyIcon from "../../../images/Ellipse-empty.svg";
import EllipseGrayIcon from "../../../images/Ellipse-gray.svg";
import "./DifaTimeline.scss";
import { useSelector } from "react-redux";
import { formatDate } from "../../common/services/UtilityService";

const DifaTimeline: React.FC = () => {
  const { difaDetails } = useSelector((state: any) => state.difaDetails);

  const downloadViewReport =
    (url: string) => (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      url && window.open(url, "_blank", "noopener,noreferrer");
    };

  return (
    <div className="difa-timeline">
      <div className="difa-details-header">Timeline</div>

      <div className="events-section">
        <div className="event">
          <div className="event-icon-group">
            <img
              className="event-icon-main"
              src={EllipseMainIcon}
              id="event-main-icon"
              alt="main icon"
            />
            <img
              className="event-icon-secondary"
              src={EllipseIcon1}
              alt="secondary icon"
            />
            <img
              className="event-line"
              src={VectorLineIcon}
              alt="connection line"
            />
          </div>
          <div className="event-details">
            <div className="event-title">ESP installed</div>
            <div className="event-info">
              <span>
                {difaDetails?.espIntalledDate
                  ? formatDate(difaDetails?.espIntalledDate)
                  : "N/A"}
              </span>

              <div className="separator"></div>
              <div
                className={`view-report ${
                  !difaDetails?.espInstalledReport && "disabled"
                }`}
                onClick={downloadViewReport(difaDetails?.espInstalledReport)}
              >
                View report
              </div>
            </div>
          </div>
        </div>

        <div className="event">
          <div className="event-icon-group">
            <img
              className="event-icon-main"
              src={EllipseMainIcon}
              id="event-main-icon"
              alt="main icon"
            />
            <img
              className="event-icon-secondary"
              src={EllipseIcon1}
              alt="secondary icon"
            />
            <img
              className="event-line"
              src={VectorLineIcon}
              alt="connection line"
            />
          </div>
          <div className="event-details">
            <div className="event-title">ESP started</div>
            <div className="event-info">
              <span>
                {difaDetails?.espStartedDate
                  ? formatDate(difaDetails?.espStartedDate)
                  : "-"}
              </span>
              <div className="separator"></div>
              <div
                className={`view-report ${
                  !difaDetails?.espStartedReport && "disabled"
                }`}
                onClick={downloadViewReport(difaDetails?.espStartedReport)}
              >
                View report
              </div>
            </div>
          </div>
        </div>

        <div className="event">
          <div className="event-icon-group">
            <img
              className="event-icon-main"
              src={EllipseMainIcon}
              id="event-main-icon"
              alt="main icon"
            />
            <img
              className="event-icon-secondary"
              src={EllipseIcon1}
              alt="secondary icon"
            />
            <img
              className="event-line"
              src={VectorLineIcon}
              alt="connection line"
            />
          </div>
          <div className="event-details">
            <div className="event-title">ESP failed</div>
            <div className="event-info">
              <span>
                {difaDetails?.espFailedDate
                  ? formatDate(difaDetails?.espFailedDate)
                  : "-"}
              </span>
            </div>
          </div>
        </div>

        <div className="event">
          <div className="event-icon-group">
            <img
              className="event-icon-main"
              src={EllipseMainIcon}
              id="event-main-icon"
              alt="main icon"
            />
            <img
              className="event-icon-secondary"
              src={EllipseIcon1}
              alt="secondary icon"
            />
            <img
              className="event-line"
              src={VectorLineIcon}
              alt="connection line"
            />
          </div>
          <div className="event-details">
            <div className="event-title">ESP pulled</div>
            <div className="event-info">
              <span>
                {difaDetails?.espPulledDate
                  ? formatDate(difaDetails?.espPulledDate)
                  : "-"}
              </span>

              <div className="separator"></div>
              <div
                className={`view-report ${
                  !difaDetails?.espPulledReport && "disabled"
                }`}
                onClick={downloadViewReport(difaDetails?.espPulledReport)}
              >
                View report
              </div>
            </div>
          </div>
        </div>

        <div className="event">
          <div className="event-icon-group">
            <img
              className="event-icon-main"
              src={EllipseMainIcon}
              id="event-main-icon"
              alt="main icon"
            />
            <img
              className="event-icon-secondary"
              src={EllipseIcon1}
              alt="secondary icon"
            />
            <img
              className="event-line"
              src={VectorLineIcon}
              alt="connection line"
            />
          </div>
          <div className="event-details">
            <div className="event-title">Inbound testing</div>
            <div className="event-info">
              <span>
                {difaDetails?.inboundTestingDate
                  ? formatDate(difaDetails?.inboundTestingDate)
                  : "-"}
              </span>
              <div className="separator"></div>
              <div className="view-report">View report</div>
            </div>
          </div>
        </div>

        <div className="event">
          <div className="event-icon-group">
            <img
              className="event-icon-empty"
              src={EllipseEmptyIcon}
              alt="event empty icon"
            />
            <img
              className="event-line"
              src={VectorLineIcon}
              alt="connection line"
            />
          </div>
          <div className="event-details">
            <div className="event-title">DIFA inspection</div>
            <div className="event-info">
              <span>
                {difaDetails?.difaInspectionDate
                  ? formatDate(difaDetails?.difaInspectionDate)
                  : "-"}
              </span>
            </div>
          </div>
        </div>

        <div className="event">
          <div className="event-icon-group">
            <img
              className="event-icon-gray"
              src={EllipseGrayIcon}
              alt="event gray icon"
            />
            <img
              className="event-line"
              src={VectorLineIcon}
              alt="connection line"
            />
          </div>
          <div className="event-details">
            <div className="event-title event-inactive">Report findings</div>
            <div className="event-info">
              <span>
                {difaDetails?.reportsFindingsDate
                  ? formatDate(difaDetails?.reportsFindingsDate)
                  : "-"}
              </span>
            </div>
          </div>
        </div>

        <div className="event">
          <div className="event-icon-group">
            <img
              className="event-icon-gray"
              src={EllipseGrayIcon}
              alt="event gray icon"
            />
          </div>
          <div className="event-details">
            <div className="event-title event-inactive">DIFA published</div>
            <div className="event-info">
              <span>
                {difaDetails?.difaPublishedDate
                  ? formatDate(difaDetails?.difaPublishedDate)
                  : "-"}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DifaTimeline;
