import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as ArrowRightIcon } from "../../images/chevron-right.svg";

const DifaReportBuilder = () => {
 
    const location = useLocation();
    const { difaId } = location.state || {};
    const navigate = useNavigate();
  
    const difaDetails = () => {
      navigate("/difa-details", { state: { difaId: difaId } });
    }
    const dashboard = () => {
      navigate("/dashboard");
    }

  return (
    <div className='difa-report-details'>
      <header className="main-header">
        <div className="header-content">
          <div className="breadcrumb-container">
            <div className="breadcrumb-item" onClick={dashboard}>Dashboard</div>
            <div className="breadcrumb-separator"><ArrowRightIcon /></div>
            <div className="breadcrumb-item" onClick={difaDetails}>DIFA details</div>
            <div className="breadcrumb-separator"><ArrowRightIcon /></div>
            <div className="breadcrumb-item active" onClick={difaDetails}>DIFA report builder</div>
          </div>
          <div className="header-details">
            <div className="header-details-content">
              <div className="header-title">DIFA report #12345</div>
              <div className="header-meta">
                <div className="meta-company">Oscorp |</div>
                <div className="meta-project">OSBORN 15-10 (ALLOC-D) 4NB</div>
              </div>
            </div>
          </div>
        </div>
        <div className="divider"></div>
      </header>
    </div>
  )
}

export default DifaReportBuilder
