import { useLocation, useNavigate } from "react-router-dom";
import Header from "./components/Header";
import { useDispatch, useSelector } from "react-redux";
import "./difaPacketBuilder.scss";
import { useEffect, useState } from "react";
import { fetchDifaDetails } from "../DifaPages/DifaDetailsSlice";
import WellRunLifeInformation from "./components/WellRunLifeInformation";
import { toast } from "react-toastify";
import {
  fetchDifaPacketStatus,
  fetchOptimizationTickets,
  fetchRundaysDetails,
} from "../preInspectionSummary/PreInspectionSummarySlice";
import {
  fetchDocuments,
  fetchGeneralReport,
  postPublishPacket,
  pushEspSizingDoc,
  resetCreateGalleryState,
  resetPublishPacketNotification,
  SaveAsDraft,
  setPublishedId,
} from "./difaPacketBuilderSlice";
import EspSizing from "./components/espSizing/EspSizing";
import InstallAndWellOperation from "./components/installWellOperation/InstallAndWellOperation";
import Production from "./components/production/Production";
import PullAndInbound from "./components/pullAndInbound/PullAndInbound";
import Loader from "../common/loader/Loader";
import PublishDifaDialog from "./components/PublishDifaDialog";
import PublishedDifaDialog from "./components/PublishSuccessDialog";
import PublishEditedDifaDialog from "./components/PublishEditPacketDialog";
const PAGE_WELL_RUN_LIFE = "Well and run life information";
const PAGE_ESP_SIZING = "ESP sizing";
const PAGE_INSTALL_AND_WELL_OPERATIONS = "Install and well operations";
const PAGE_PRODUCTION = "Production";
const PAGE_PULL_AND_INBOUND = "Pull and inbound";

const DifaPacketBuilder = () => {
  const navigate = useNavigate();

  const {
    loading,
    sizingSummary,
    production,
    wellInstallation,
    wellRunLife,
    draftId,
    isInboundTestingSelected,
    pullReasons,
    publishPacketNotification,
    galleryTransactionMsg,
    publishedPDF
  } = useSelector((state: any) => state.difaPacketBuilder);

  const { difaPacketStatus } = useSelector(
    (state: any) => state.preInspectionSummary
  );


  const location = useLocation();
  const dispatch = useDispatch();
  const [showPublishDialog, setShowPublishDialog] = useState(false);
  const [showPublishedDialog, setShowPublishedDialog] = useState(false);
  const [unsavedESP, setUnsavedESP] = useState(false);
  const [unsavedProduction, setUnsavedProduction] = useState(false);
  const [unsavedAdditional, setUnsavedAdditional] = useState(false);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const { difaId, customerName, wellName } = location.state || {};
  const { difaDetails } = useSelector((state: any) => state.difaDetails);

  const [currentPage, setCurrentPage] = useState(PAGE_WELL_RUN_LIFE);

  useEffect(() => {
    dispatch(fetchDifaDetails({ difaId }));
  }, [dispatch, difaId]);

  useEffect(() => {
    publishedPDF && window.open(publishedPDF, '_blank');
  }, [publishedPDF])

  useEffect(() => {
    if (difaDetails?.wellApiNumber !== undefined) {
      dispatch(
        fetchRundaysDetails({ wellApiNumber: difaDetails?.wellApiNumber })
      );
      dispatch(
        fetchGeneralReport({
          wellApiNumber: difaDetails?.wellApiNumber,
          difaId: difaDetails?.difaId,
        })
      );
      dispatch(
        fetchOptimizationTickets({
          wellName: encodeURIComponent(difaDetails?.wellName),
        })
      );
      !sizingSummary?.sizingFile && dispatch(fetchDocuments({
        wellApiNumber: difaDetails?.wellApiNumber,
        difaId: difaDetails?.difaId,
        category: 'esp',
        source: "packet-builder"
      }));
      !production?.productionFile && dispatch(fetchDocuments({
        wellApiNumber: difaDetails?.wellApiNumber,
        difaId: difaDetails?.difaId,
        category: 'production',
        source: "packet-builder"
      }));

      dispatch(fetchDocuments({
        wellApiNumber: difaDetails?.wellApiNumber,
        difaId: difaDetails?.difaId,
        category: 'additional',
        source: "packet-builder"
      }));
    }
  }, [dispatch, difaDetails]);

  useEffect(() => {
    if (publishPacketNotification?.type === 'publish-success') {
      dispatch(resetPublishPacketNotification())
      setShowPublishedDialog(true)

    }
    if (publishPacketNotification?.type === 'publish-failed') {
      dispatch(resetPublishPacketNotification())
      toast.error(<div className="flex flex-col">
        <div className="text-lg">
          Opps!!
        </div>
        <div className="text-sm">
          Failed to publish report. Please try again later.
        </div>
      </div>)
    }

  }, [publishPacketNotification])

  const navigateToPreview = async (preview: boolean) => {
    if (preview) {
      navigate("/difa-details/preview-difa-packet", {
        state: { difaId: difaId, customerName: customerName, wellName },
      });
    }
  }
  const publishPacket = async () => {
    dispatch(postPublishPacket({
      publishPacket: {
        "wellApinumber": difaDetails?.wellApiNumber,
        "difaId": difaId,
      }
    }));

  }
  const savePacket = async (shouldNavigate: boolean, preview = false) => {
    let payload: any = "";
    const source = "packet-builder"
    switch (currentPage) {
      case PAGE_WELL_RUN_LIFE:
        const wellRunLifeDetails = {
          wellApinumber: difaDetails?.wellApiNumber,
          difaId: difaId,
          isIncludeWellInformation: true,
          isIncludeRunLifeDetails: true,
          IsIncludePriorRuns: wellRunLife?.isRunsSelected,
          CreatedBy: difaDetails?.emailAddress,
          difaPacketDocuments: null,
          priorRunDetails: "",
        };
        const selectedRuns = wellRunLife?.selectedDifas?.map((item: any) => {
          return {
            DifaID: item.difaId,
            IsIncludeInReport: item.selected,
          };
        });
        wellRunLifeDetails.priorRunDetails = JSON.stringify(selectedRuns);
        await dispatch(SaveAsDraft({ draftDetails: wellRunLifeDetails }));
        navigateToPreview(preview);
        break;
      case PAGE_ESP_SIZING:
        if (unsavedESP) {
          payload = {
            wellApinumber: difaDetails?.wellApiNumber,
            difaId: difaId,
            IsIncludeESPSizing: sizingSummary?.isSelected,
            category: "esp",
            difaPacketDocuments: {
              FileName: sizingSummary?.sizingFile?.name,
              FileBase64: sizingSummary?.sizingFile?.blob,
            },
          };
          await dispatch(pushEspSizingDoc({ payload, source }));
          setUnsavedESP(false);
        }

        navigateToPreview(preview);
        break;
      case PAGE_INSTALL_AND_WELL_OPERATIONS:

        const opTickets = wellInstallation?.opTickets?.map((item: any) => {
          return {
            TicketID: item.ticketId,
            IsIncludeinReport: true,
          }
        });
        const generalReports = wellInstallation?.generalReports?.map((item: any) => {
          return {
            DifaID: item.difaId,
            IsIncludeinReport: true,
          }
        });
        const serviceReports = wellInstallation?.serviceReports?.map((item: any) => {
          return {
            DifaID: item.difaId,
            IsIncludeinReport: true,
          }
        });

        if (wellInstallation.additionalDocuments) {
          payload = {
            wellApinumber: difaDetails?.wellApiNumber,
            difaId: difaId,
            IsIncludeESPSizing: sizingSummary?.isSelected,
            Category: "additional",
            DifaPacketDocuments: {
              FileName: wellInstallation.additionalDocuments.name,
              FileBase64: wellInstallation.additionalDocuments.blob,
            },
          };
          await dispatch(pushEspSizingDoc({ payload, source }));
          setUnsavedAdditional(false);
        }
        payload = {
          wellApiNumber: difaDetails?.wellApiNumber,
          difaId,
          IsIncludeDocumentation: wellInstallation?.isDocumentationSelected,
          IsIncludeInstallImageGallery: wellInstallation?.isGallerySelected,
          IsIncludeAlarms: wellInstallation?.isAlarmsSelected,
          IsIncludeTrendHistoryGallery: false,
          IsIncludeOperationalSummery: wellInstallation?.isOpSummarySelected,
          OperationalSummery: wellInstallation?.opSummary,
          GenerelReportsDetails:
            JSON.stringify(generalReports),
          ServiceReportsDetails: JSON.stringify(serviceReports),
          OptimizationTicketDetails:
            JSON.stringify(opTickets),

          CreatedBy: difaDetails?.emailAddress,
        };
        await dispatch(SaveAsDraft({ draftDetails: payload }));
        navigateToPreview(preview);
        break;
      case PAGE_PRODUCTION:
        if (unsavedProduction) {
          payload = {
            wellApinumber: difaDetails?.wellApiNumber,
            difaId: difaId,
            IsIncludeProductionPlot: production?.isSelected,
            category: "production",
            difaPacketDocuments: {
              FileName: production?.productionFile?.name,
              FileBase64: production?.productionFile?.blob,
            },
          };

          await dispatch(pushEspSizingDoc({ payload, source }));
          setUnsavedProduction(false);
        }
        navigateToPreview(preview);
        break;
      case PAGE_PULL_AND_INBOUND:
      default:
        payload = {
          WellApinumber: difaDetails?.wellApiNumber,
          difaId: difaId,
          IsIncludeReasonForPull: pullReasons?.isPullSelected,
          FailureCategory: pullReasons?.failureCategory,
          FailureDescriptors: pullReasons?.failureDescriptors,
          PullSummary: pullReasons?.pullSummary,
          IsIncludeInboundTesting: isInboundTestingSelected,
        };
        await dispatch(SaveAsDraft({ draftDetails: payload }));
        navigateToPreview(preview);
        break;
    }

    if (shouldNavigate) {
      toast.success(
        <div className="flex flex-col">
          <div className="text-lg">
            DIFA packet has been saved successfully.
          </div>
          <div className="text-sm">
            To resume editing your DIFA packet select “Edit DIFA packet” located
            in the top navigation bar.
          </div>
        </div>,
        { style: { width: "400px" } }
      );

      navigate("/difa-details/pi-summary", {
        state: {
          difaId: difaId,
          customerName: customerName,
          wellName: difaDetails.wellName,
        },
      });
    }
  };
  const packetName = draftId ? "DIFA packet # " + draftId : "DIFA packet";

  const redirectToPreview = () => {
    dispatch(setPublishedId(draftId));
    dispatch(fetchDifaPacketStatus({ wellApiNumber: difaDetails?.wellApiNumber, difaId }));
    navigate("/difa-details/preview-difa-packet", {
      state: { difaId: difaId, customerName: customerName, wellName },
    });
  };

  const redirectToPIS = () => {
    dispatch(setPublishedId(draftId));
    navigate("/difa-details/pi-summary", {
      state: { difaId: difaId, customerName: customerName, wellName },
    });
  };
  return (
    <>
      {loading && <Loader isOverlay />}

      <div className="packet-builder">
        <Header
          pageName="DIFA packet builder"
          difaPacketName={packetName}
          customerName={customerName}
          wellName={wellName}
          source="packet-builder"
          onSaveHandler={savePacket}
          onPublishHandler={() => difaPacketStatus?.isPacketPublished ? setShowEditDialog(true) : setShowPublishDialog(true)}

        />
        <div className="flex flex-col md:flex-row gap-5 p-7 h-fit">
          <div className="flex-col flex flex-auto w-full md:w-1/4 gap-4">
            <div
              onClick={() => {
                savePacket(false);
                setCurrentPage(PAGE_WELL_RUN_LIFE);
              }}
              className={`difa-widget-containers cursor-pointer ${currentPage === PAGE_WELL_RUN_LIFE ? "active-page" : ""
                }`}
            >
              <div className="container-body">
                <div className="header-small">{PAGE_WELL_RUN_LIFE}</div>
              </div>
            </div>
            <div
              onClick={() => {
                savePacket(false);
                setCurrentPage(PAGE_ESP_SIZING);
              }}
              className={`difa-widget-containers cursor-pointer ${currentPage === PAGE_ESP_SIZING ? "active-page" : ""
                }`}
            >
              <div className="container-body">
                <div className="header-small">{PAGE_ESP_SIZING}</div>
              </div>
            </div>
            <div
              onClick={() => {
                savePacket(false);
                setCurrentPage(PAGE_INSTALL_AND_WELL_OPERATIONS);
              }}
              className={`difa-widget-containers cursor-pointer ${currentPage === PAGE_INSTALL_AND_WELL_OPERATIONS
                ? "active-page"
                : ""
                }`}
            >
              <div className="container-body">
                <div className="header-small">
                  {PAGE_INSTALL_AND_WELL_OPERATIONS}
                </div>
              </div>
            </div>
            <div
              onClick={() => {
                savePacket(false);
                setCurrentPage(PAGE_PRODUCTION);
              }}
              className={`difa-widget-containers cursor-pointer ${currentPage === PAGE_PRODUCTION ? "active-page" : ""
                }`}
            >
              <div className="container-body">
                <div className="header-small">{PAGE_PRODUCTION}</div>
              </div>
            </div>
            <div
              onClick={() => {
                savePacket(false);
                setCurrentPage(PAGE_PULL_AND_INBOUND);
              }}
              className={`difa-widget-containers cursor-pointer ${currentPage === PAGE_PULL_AND_INBOUND ? "active-page" : ""
                }`}
            >
              <div className="container-body">
                <div className="header-small">{PAGE_PULL_AND_INBOUND}</div>
              </div>
            </div>
          </div>
          <div className="flex-auto flex flex-col w-full md:w-3/4 gap-5">
            {currentPage === PAGE_WELL_RUN_LIFE && (
              <WellRunLifeInformation difaId={difaId} source="" />
            )}
            {currentPage === PAGE_ESP_SIZING && (
              <EspSizing
                wellApiNumber={difaDetails?.wellApiNumber}
                difaId={difaId}
                source=""
                unsavedCallback={() => setUnsavedESP(true)}
              />
            )}
            {currentPage === PAGE_INSTALL_AND_WELL_OPERATIONS && (
              <InstallAndWellOperation
                wellApiNumber={difaDetails?.wellApiNumber}
                difaId={difaDetails?.difaId}
                source=""
              />
            )}
            {currentPage === PAGE_PRODUCTION && <Production source="" unsavedCallback={() => setUnsavedProduction(true)}
            />}
            {currentPage === PAGE_PULL_AND_INBOUND && (
              <PullAndInbound
                wellApiNumber={difaDetails?.wellApiNumber}
                difaId={difaDetails?.difaId}
                source=""
              />
            )}
          </div>
        </div>
      </div>
      <PublishDifaDialog show={showPublishDialog} close={() => { setShowPublishDialog(false); }} submit={() => publishPacket()} />
      <PublishedDifaDialog show={showPublishedDialog} close={() => { setShowPublishDialog(false); setShowEditDialog(false); setShowPublishedDialog(false) }} onRedirect={() => redirectToPreview()} onBackToPI={() => redirectToPIS()} mode="" />
      <PublishEditedDifaDialog show={showEditDialog} close={() => { setShowEditDialog(false); }} submit={() => publishPacket()} />
    </>
  );
};

export default DifaPacketBuilder;
