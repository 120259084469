import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  DismantleRequestProps,
  NameInterface,
  VerticalTabProps,
} from "../../models/DismantleRequest";
import VerticalTabContent from "../../dismentleRequest/components/VerticalTabContent";
import "./VerticalTab.scss";

const VerticalTab: React.FC<VerticalTabProps> = ({
  difaId,
  wellName,
  wellApiNumber,
  onSelectionChange,
  isCheckboxVisible,
}) => {
  const dismantlePumps = useSelector(
    (state: any) => state.dismantleRequest.dismantlePumps
  );

  const getDifaDismantleRequest = useSelector(
    (state: any) => state.dismantleRequest.getDismantleRequest[0]
  );

  const [activeTab, setActiveTab] = useState<string>("PUMP MSC");
  const [tabContent, setTabContent] = useState<DismantleRequestProps[]>([]);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  useEffect(() => {
    if (getDifaDismantleRequest) {
      const selectedDifaDismantleId = getDifaDismantleRequest?.dismantleRequests
        .filter(
          (pump: DismantleRequestProps) => pump?.dismantleRequested === true
        )
        ?.map((pump: DismantleRequestProps) => pump?.serialNumber);
      setSelectedIds(selectedDifaDismantleId);
    }
  }, [getDifaDismantleRequest]);

  useEffect(() => {
    setTabContent(
      dismantlePumps.filter(
        (equipment: DismantleRequestProps) => equipment?.partType === activeTab
      )
    );
  }, [activeTab, dismantlePumps]);

  useEffect(() => {
    const selectedData = dismantlePumps?.map((pump: DismantleRequestProps) => {
      if (selectedIds.includes(pump?.serialNumber)) {
        return {
          dismantleRequested: true,
          serialNumber: pump?.serialNumber,
          equipementType: pump?.equipementType,
          inboundResults: pump?.inboundResults,
          runtime: pump?.runtime ?? "",
          comments: pump?.comments,
          partType: pump?.partType,
        };
      } else {
        return {
          dismantleRequested: pump?.dismantleRequested,
          serialNumber: pump?.serialNumber,
          equipementType: pump?.equipementType,
          inboundResults: pump?.inboundResults,
          runtime: pump?.runtime ?? "",
          comments: pump?.comments,
          partType: pump?.partType,
        };
      }
    });

    const finalData = {
      difaId: difaId,
      wellName: wellName,
      wellApiNumber: wellApiNumber ?? "",
      comment: getDifaDismantleRequest?.comment ?? "",
      dismantleRequests: selectedData?.length > 0 ? selectedData : getDifaDismantleRequest?.dismantleRequests,
    };
    onSelectionChange && onSelectionChange(finalData);
  }, [selectedIds, difaId, getDifaDismantleRequest, wellName, wellApiNumber]);

  const name: NameInterface = {
    "PUMP MSC": "Pumps",
    "GAS HANDLING": "Gas separators",
    "PROTECTOR": "Protector",
    "MOTOR DH": "Motor",
    "SENSOR-DH": "Sensor",
    "CABLE": "Cable & MLE",
  };

  const countFailed = (pumpName: string) => {
    const checkNoData = dismantlePumps.filter(
      (equipment: DismantleRequestProps) => equipment.partType === pumpName
    );
    if (!dismantlePumps || dismantlePumps?.length === 0) {
      return "no data";
    }

    if (checkNoData?.length === 0) {
      return "no data";
    }

    let count = 0;
    let allPassed = true;
    dismantlePumps.forEach((pump: DismantleRequestProps) => {
      if (pump.partType === pumpName) {
        if (pump.inboundResults === "Fail") {
          count++;
          allPassed = false;
        } else if (pump.inboundResults !== "Pass") {
          allPassed = false;
        }
      }
    });
    if (allPassed) {
      return "Passed";
    } else if (count === 1) {
      return "1 failure";
    } else {
      return `${count} failures`;
    }
  };

  const handleCheckbox = (id: string) => {
    selectedIds.includes(id)
      ? setSelectedIds(selectedIds.filter((item) => item !== id))
      : setSelectedIds([...selectedIds, id]);
  };

  const handleAllSelected = (tabName: string) => {
    const currentEquipementIds = dismantlePumps
      .filter(
        (equipment: DismantleRequestProps) => equipment.partType === tabName
      )
      ?.map((pump: DismantleRequestProps) => pump.serialNumber);

    const currentSelectedIds = selectedIds.filter((id) =>
      currentEquipementIds.includes(id)
    );

    if (
      currentSelectedIds?.length <= currentEquipementIds?.length &&
      currentSelectedIds?.length > 0 &&
      currentEquipementIds?.length > 0
    ) {
      setSelectedIds(
        selectedIds.filter((id) => !currentEquipementIds.includes(id))
      );
    } else {
      setSelectedIds([...selectedIds, ...currentEquipementIds]);
    }
  };

  const handleAllSelectCheckbox = () => {
    const currentEquipementIds = dismantlePumps
      .filter(
        (equipment: DismantleRequestProps) => equipment.partType === activeTab
      )
      ?.map((pump: DismantleRequestProps) => pump.serialNumber);

    const currentSelectedIds = selectedIds.filter((id) =>
      currentEquipementIds.includes(id)
    );

    return (
      currentEquipementIds?.length === currentSelectedIds?.length &&
      currentSelectedIds?.length > 0
    );
  };

  const headerButtontext = () => {
    switch (activeTab) {
      case "PUMP MSC":
        return "View pump inspection form";
      case "GAS HANDLING":
        return "View inspection sheet";
      case "MOTOR DH":
        return "View motor report";
      case "SENSOR-DH":
        return "View sensor report";
      case "CABLE":
        return "View cable workover";
      default:
        return "";
    }
  };

  return (
    <>
      <div className="card-tabs w-full md:w-1/4">
        {Object.keys(name)?.map((key) => (
          <div
            key={key}
            className={`tab-item gap-1 flex-wrap ${activeTab === key ? "active" : ""}`}
            onClick={() => setActiveTab(key)}
          >
            <div className="tab-lable">{name[key]}</div>
            {countFailed(key) !== "no data" ? (
              <div
                className={
                  countFailed(key) === "Passed"
                    ? "tab-status-passed"
                    : "tab-status-failure"
                }
              >
                {countFailed(key)}
              </div>
            ) : (
              <div className="not-tested font-semibold text-xs text-center rounded">Not tested</div>
            )}
          </div>
        ))}
      </div>
      <div className="card-tab-content w-full md:w-3/4">
        <VerticalTabContent
          activeTab={activeTab}
          tabContent={tabContent}
          selectedIds={selectedIds}
          handleCheckbox={handleCheckbox}
          handleAllSelected={handleAllSelected}
          handleAllSelectCheckbox={handleAllSelectCheckbox}
          name={name}
          headerButtontext={headerButtontext}
          isCheckboxVisible={isCheckboxVisible}
        />
      </div>
    </>
  );
};

export default VerticalTab;
