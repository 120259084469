import React, { ChangeEvent, useState } from 'react'
import DifaSwitch from '../../../common/switch/DifaSwitch'

const SizingSchematic: React.FC<{ wellApiNumber: string }> = ({
    wellApiNumber
}) => {

    const [expanded, setExpanded] = useState(false);

    const onExpand = (e: ChangeEvent<HTMLInputElement>) => {
        const checked = e.target.checked;
        setExpanded(checked);
    };

    return (
        <div className="difa-widget-containers">
            <div className="header-main">
                <div className="flex flex-row justify-between">
                    <div>
                        <h3>DETERMINE sizing schematic</h3>
                        <h5>The schematic illustrates the configuration and dimensions of the components that make up this ESP design.</h5>
                    </div>
                    <div className="flex flex-row items-center gap-3">
                        <DifaSwitch checked={expanded} onChange={onExpand} />
                        <h4 className="whitespace-nowrap">Include in report</h4>
                    </div>
                </div>
            </div>
            {expanded &&
                <div className="flex flex-col gap-8 py-5 px-6 border-t border-dark">
                    <div className="flex flex-col gap-2 text-neutral-grey">
                        <div className="text-lg font-bold">Summary</div>
                        <div className='block'>
                            <div className="flex flex-row gap-2 row-bottom-line justify-between items-center py-2 px-4">
                                <label className="text-sm font-normal">EO tubing</label>
                                <label className="text-sm font-normal">8581.8 ft</label>
                            </div>
                            <div className="flex flex-row gap-2 row-bottom-line justify-between items-center py-2 px-4">
                                <label className="text-sm font-normal">Instake set depth</label>
                                <label className="text-sm font-normal">8581.8 ft</label>
                            </div>
                            <div className="flex flex-row gap-2 row-bottom-line justify-between items-center py-2 px-4">
                                <label className="text-sm font-normal">Required surface power</label>
                                <label className="text-sm font-normal">270.63 kVA</label>
                            </div>
                            <div className="flex flex-row gap-2 row-bottom-line justify-between items-center py-2 px-4">
                                <label className="text-sm font-normal">Design frequency</label>
                                <label className="text-sm font-normal">55 Hz</label>
                            </div>
                            <div className="flex flex-row gap-2 row-bottom-line justify-between items-center py-2 px-4">
                                <label className="text-sm font-normal">Casing size</label>
                                <label className="text-sm font-normal">7 in - 32 lb/ft</label>
                            </div>
                            <div className="flex flex-row gap-2 row-bottom-line justify-between items-center py-2 px-4">
                                <label className="text-sm font-normal">tubing size</label>
                                <label className="text-sm font-normal">2.875 in - 6.5 lb/ft</label>
                            </div>
                            <div className="flex flex-row gap-2 row-bottom-line justify-between items-center py-2 px-4">
                                <label className="text-sm font-normal">Set depth</label>
                                <label className="text-sm font-normal">9,000 ft</label>
                            </div>
                            <div className="flex flex-row gap-2 row-bottom-line justify-between items-center py-2 px-4">
                                <label className="text-sm font-normal">KOP</label>
                                <label className="text-sm font-normal">9,766.1 ft</label>
                            </div>
                            <div className="flex flex-row gap-2 row-bottom-line justify-between items-center py-2 px-4">
                                <label className="text-sm font-normal">ESP length</label>
                                <label className="text-sm font-normal">4808 ft</label>
                            </div>
                            <div className="flex flex-row gap-2 row-bottom-line justify-between items-center py-2 px-4">
                                <label className="text-sm font-normal">Downhole ESP weight</label>
                                <label className="text-sm font-normal">27,706.66 lb</label>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col gap-2 text-neutral-grey">
                        <div className="text-lg font-bold">Recommended parameters</div>
                        <div className='block'>
                            <div className="flex flex-row gap-2 row-bottom-line justify-between items-center py-2 px-4">
                                <label className="text-sm font-normal">Start up frequency</label>
                                <label className="text-sm font-normal">40 Hz</label>
                            </div>
                            <div className="flex flex-row gap-2 row-bottom-line justify-between items-center py-2 px-4">
                                <label className="text-sm font-normal">Min frequency at departure</label>
                                <label className="text-sm font-normal">50 Hz</label>
                            </div>
                            <div className="flex flex-row gap-2 row-bottom-line justify-between items-center py-2 px-4">
                                <label className="text-sm font-normal">Max frequency</label>
                                <label className="text-sm font-normal">60 Hz</label>
                            </div>
                            <div className="flex flex-row gap-2 row-bottom-line justify-between items-center py-2 px-4">
                                <label className="text-sm font-normal">Surface volts @ 60 Hz</label>
                                <label className="text-sm font-normal">3,878.98 volts Hz</label>
                            </div>
                            <div className="flex flex-row gap-2 row-bottom-line justify-between items-center py-2 px-4">
                                <label className="text-sm font-normal">ESP+desander+tail piple</label>
                                <label className="text-sm font-normal">936.9 ft</label>
                            </div>
                            <div className="flex flex-row gap-2 row-bottom-line justify-between items-center py-2 px-4">
                                <label className="text-sm font-normal">Target production</label>
                                <label className="text-sm font-normal">2,000 bpd</label>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col gap-2 text-neutral-grey">
                        <div className="text-lg font-bold">Surface equipment</div>
                        <div className='block'>
                            <div className="flex flex-row gap-2 row-bottom-line justify-between items-center py-2 px-4">
                                <label className="text-sm font-normal">VSD drive</label>
                                <label className="text-sm font-normal">VSD_400KVA_400A_3PH_12P_N4_ESP_CD_SMARTEN XE (1188658)</label>
                            </div>
                            <div className="flex flex-row gap-2 row-bottom-line justify-between items-center py-2 px-4">
                                <label className="text-sm font-normal">Transformer</label>
                                <label className="text-sm font-normal">520 KVA 1400/4850 VOLTS (PN:1107012)</label>
                            </div>
                            <div className="flex flex-row gap-2 row-bottom-line justify-between items-center py-2 px-4">
                                <label className="text-sm font-normal">Harmonic filter</label>
                                <label className="text-sm font-normal">VSD-FILTER_HARMONIC INPUT 165A MTE_480V/60HZ (1160288)</label>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col gap-2 text-neutral-grey">
                        <div className="text-lg font-bold">ESP equipment</div>
                        <div className="relative overflow-x-auto sm:rounded-lg">
                            <table className="w-full text-sm text-left">
                                <thead className="font-bold row-bottom-line">
                                    <tr>
                                        <th scope="col" className="px-4 py-2">
                                            Cable + MLE
                                        </th>
                                        <th scope="col" className="px-4 py-2 text-right">
                                            PIN
                                        </th>
                                        <th scope="col" className="px-4 py-2 text-right">
                                            Length (ft)
                                        </th>
                                        <th scope="col" className="px-4 py-2 text-right">
                                            Weight (lbs)
                                        </th>
                                        <th scope="col" className="px-4 py-2 text-right">
                                            Dim. (T”xW”)
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="font-normal">
                                    <tr>
                                        <td className="px-4 py-2">
                                            CABLE-DH_#2_ROUND STRANDED_3 WIRE_90C_5KV_PP_NBR_GALV
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            1105357
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            8,946.8
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            8,946.8
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            8,946.8
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="px-4 py-2">
                                            CABLE-DH_#2_ROUND STRANDED_3 WIRE_90C_5KV_PP_NBR_GALV
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            1130542
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            180
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            180
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            180
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="relative overflow-x-auto sm:rounded-lg">
                            <table className="w-full text-sm text-left">
                                <thead className="font-bold row-bottom-line">
                                    <tr>
                                        <th scope="col" className="px-4 py-2">
                                            Accessories above ESP
                                        </th>
                                        <th scope="col" className="px-4 py-2 text-right">
                                            PIN
                                        </th>
                                        <th scope="col" className="px-4 py-2 text-right">
                                            Length (ft)
                                        </th>
                                        <th scope="col" className="px-4 py-2 text-right">
                                            Weight (lbs)
                                        </th>
                                        <th scope="col" className="px-4 py-2 text-right">
                                            Set depth (ft)
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="font-normal">
                                    <tr>
                                        <td className="px-4 py-2">
                                            CABLE-DH_#2_ROUND STRANDED_3 WIRE_90C_5KV_PP_NBR_GALV
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            1105357
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            8,946.8
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            8,946.8
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            8,946.8
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="px-4 py-2">
                                            CABLE-DH_#2_ROUND STRANDED_3 WIRE_90C_5KV_PP_NBR_GALV
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            1130542
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            180
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            180
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            180
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="relative overflow-x-auto sm:rounded-lg">
                            <table className="w-full text-sm text-left">
                                <thead className="font-bold row-bottom-line">
                                    <tr>
                                        <th scope="col" className="px-4 py-2">
                                            Pump series: 400
                                        </th>
                                        <th scope="col" className="px-4 py-2 text-right">
                                            PIN
                                        </th>
                                        <th scope="col" className="px-4 py-2 text-right">
                                            Length (ft)
                                        </th>
                                        <th scope="col" className="px-4 py-2 text-right">
                                            Weight (lbs)
                                        </th>
                                        <th scope="col" className="px-4 py-2 text-right">
                                            Set depth (ft)
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="font-normal">
                                    <tr>
                                        <td className="px-4 py-2">
                                            CABLE-DH_#2_ROUND STRANDED_3 WIRE_90C_5KV_PP_NBR_GALV
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            1105357
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            8,946.8
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            8,946.8
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            8,946.8
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="px-4 py-2">
                                            CABLE-DH_#2_ROUND STRANDED_3 WIRE_90C_5KV_PP_NBR_GALV
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            1130542
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            180
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            180
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            180
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="relative overflow-x-auto sm:rounded-lg">
                            <table className="w-full text-sm text-left">
                                <thead className="font-bold row-bottom-line">
                                    <tr>
                                        <th scope="col" className="px-4 py-2">
                                            Gas handling / intake
                                        </th>
                                        <th scope="col" className="px-4 py-2 text-right">
                                            PIN
                                        </th>
                                        <th scope="col" className="px-4 py-2 text-right">
                                            Length (ft)
                                        </th>
                                        <th scope="col" className="px-4 py-2 text-right">
                                            Weight (lbs)
                                        </th>
                                        <th scope="col" className="px-4 py-2 text-right">
                                            Set depth (ft)
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="font-normal">
                                    <tr>
                                        <td className="px-4 py-2">
                                            CABLE-DH_#2_ROUND STRANDED_3 WIRE_90C_5KV_PP_NBR_GALV
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            1105357
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            8,946.8
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            8,946.8
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            8,946.8
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="px-4 py-2">
                                            CABLE-DH_#2_ROUND STRANDED_3 WIRE_90C_5KV_PP_NBR_GALV
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            1130542
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            180
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            180
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            180
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="relative overflow-x-auto sm:rounded-lg">
                            <table className="w-full text-sm text-left">
                                <thead className="font-bold row-bottom-line">
                                    <tr>
                                        <th scope="col" className="px-4 py-2">
                                            Gas separator
                                        </th>
                                        <th scope="col" className="px-4 py-2 text-right">
                                            PIN
                                        </th>
                                        <th scope="col" className="px-4 py-2 text-right">
                                            Length (ft)
                                        </th>
                                        <th scope="col" className="px-4 py-2 text-right">
                                            Weight (lbs)
                                        </th>
                                        <th scope="col" className="px-4 py-2 text-right">
                                            Set depth (ft)
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="font-normal">
                                    <tr>
                                        <td className="px-4 py-2">
                                            CABLE-DH_#2_ROUND STRANDED_3 WIRE_90C_5KV_PP_NBR_GALV
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            1105357
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            8,946.8
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            8,946.8
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            8,946.8
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="px-4 py-2">
                                            CABLE-DH_#2_ROUND STRANDED_3 WIRE_90C_5KV_PP_NBR_GALV
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            1130542
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            180
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            180
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            180
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="relative overflow-x-auto sm:rounded-lg">
                            <table className="w-full text-sm text-left">
                                <thead className="font-bold row-bottom-line">
                                    <tr>
                                        <th scope="col" className="px-4 py-2">
                                            Protectors
                                        </th>
                                        <th scope="col" className="px-4 py-2 text-right">
                                            PIN
                                        </th>
                                        <th scope="col" className="px-4 py-2 text-right">
                                            Length (ft)
                                        </th>
                                        <th scope="col" className="px-4 py-2 text-right">
                                            Weight (lbs)
                                        </th>
                                        <th scope="col" className="px-4 py-2 text-right">
                                            Set depth (ft)
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="font-normal">
                                    <tr>
                                        <td className="px-4 py-2">
                                            CABLE-DH_#2_ROUND STRANDED_3 WIRE_90C_5KV_PP_NBR_GALV
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            1105357
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            8,946.8
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            8,946.8
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            8,946.8
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="px-4 py-2">
                                            CABLE-DH_#2_ROUND STRANDED_3 WIRE_90C_5KV_PP_NBR_GALV
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            1130542
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            180
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            180
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            180
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="relative overflow-x-auto sm:rounded-lg">
                            <table className="w-full text-sm text-left">
                                <thead className="font-bold row-bottom-line">
                                    <tr>
                                        <th scope="col" className="px-4 py-2">
                                            Motor Rating : 360/3543/130 XT Motor Re-rating :<br />
                                            Using Nameplate HP:360 V:3543 A:65
                                        </th>
                                        <th scope="col" className="px-4 py-2 text-right">
                                            PIN
                                        </th>
                                        <th scope="col" className="px-4 py-2 text-right">
                                            Length (ft)
                                        </th>
                                        <th scope="col" className="px-4 py-2 text-right">
                                            Weight (lbs)
                                        </th>
                                        <th scope="col" className="px-4 py-2 text-right">
                                            Set depth (ft)
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="font-normal">
                                    <tr>
                                        <td className="px-4 py-2">
                                            CABLE-DH_#2_ROUND STRANDED_3 WIRE_90C_5KV_PP_NBR_GALV
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            1105357
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            8,946.8
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            8,946.8
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            8,946.8
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="px-4 py-2">
                                            CABLE-DH_#2_ROUND STRANDED_3 WIRE_90C_5KV_PP_NBR_GALV
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            1130542
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            180
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            180
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            180
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="relative overflow-x-auto sm:rounded-lg">
                            <table className="w-full text-sm text-left">
                                <thead className="font-bold row-bottom-line">
                                    <tr>
                                        <th scope="col" className="px-4 py-2">
                                            Sensors
                                        </th>
                                        <th scope="col" className="px-4 py-2 text-right">
                                            PIN
                                        </th>
                                        <th scope="col" className="px-4 py-2 text-right">
                                            Length (ft)
                                        </th>
                                        <th scope="col" className="px-4 py-2 text-right">
                                            Weight (lbs)
                                        </th>
                                        <th scope="col" className="px-4 py-2 text-right">
                                            Set depth (ft)
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="font-normal">
                                    <tr>
                                        <td className="px-4 py-2">
                                            CABLE-DH_#2_ROUND STRANDED_3 WIRE_90C_5KV_PP_NBR_GALV
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            1105357
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            8,946.8
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            8,946.8
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            8,946.8
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="px-4 py-2">
                                            CABLE-DH_#2_ROUND STRANDED_3 WIRE_90C_5KV_PP_NBR_GALV
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            1130542
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            180
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            180
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            180
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="relative overflow-x-auto sm:rounded-lg">
                            <table className="w-full text-sm text-left">
                                <thead className="font-bold row-bottom-line">
                                    <tr>
                                        <th scope="col" className="px-4 py-2">
                                            Desanders
                                        </th>
                                        <th scope="col" className="px-4 py-2 text-right">
                                            PIN
                                        </th>
                                        <th scope="col" className="px-4 py-2 text-right">
                                            Length (ft)
                                        </th>
                                        <th scope="col" className="px-4 py-2 text-right">
                                            Weight (lbs)
                                        </th>
                                        <th scope="col" className="px-4 py-2 text-right">
                                            Set depth (ft)
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="font-normal">
                                    <tr>
                                        <td className="px-4 py-2">
                                            CABLE-DH_#2_ROUND STRANDED_3 WIRE_90C_5KV_PP_NBR_GALV
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            1105357
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            8,946.8
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            8,946.8
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            8,946.8
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="px-4 py-2">
                                            CABLE-DH_#2_ROUND STRANDED_3 WIRE_90C_5KV_PP_NBR_GALV
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            1130542
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            180
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            180
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            180
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="relative overflow-x-auto sm:rounded-lg">
                            <table className="w-full text-sm text-left">
                                <thead className="font-bold row-bottom-line">
                                    <tr>
                                        <th scope="col" className="px-4 py-2">
                                            Tailpipe
                                        </th>
                                        <th scope="col" className="px-4 py-2 text-right">
                                            PIN
                                        </th>
                                        <th scope="col" className="px-4 py-2 text-right">
                                            Length (ft)
                                        </th>
                                        <th scope="col" className="px-4 py-2 text-right">
                                            Weight (lbs)
                                        </th>
                                        <th scope="col" className="px-4 py-2 text-right">
                                            Set depth (ft)
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="font-normal">
                                    <tr>
                                        <td className="px-4 py-2">
                                            CABLE-DH_#2_ROUND STRANDED_3 WIRE_90C_5KV_PP_NBR_GALV
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            1105357
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            8,946.8
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            8,946.8
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            8,946.8
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="px-4 py-2">
                                            CABLE-DH_#2_ROUND STRANDED_3 WIRE_90C_5KV_PP_NBR_GALV
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            1130542
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            180
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            180
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            180
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="flex flex-col gap-2 text-neutral-grey">
                        <div className="text-lg font-bold">Miscellaneous equipment accessories</div>
                        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                            <table className="w-full text-sm text-left">
                                <thead className="font-bold row-bottom-line">
                                    <tr>
                                        <th scope="col" className="px-4 py-2">
                                            Equipment / accessory
                                        </th>
                                        <th scope="col" className="px-4 py-2 text-right">
                                            Required
                                        </th>
                                        <th scope="col" className="px-4 py-2 text-right">
                                            Type
                                        </th>
                                        <th scope="col" className="px-4 py-2 text-right">
                                            Provider
                                        </th>
                                        <th scope="col" className="px-4 py-2 text-right">
                                            Quantity
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="font-normal">
                                    <tr>
                                        <td className="px-4 py-2">
                                            Surface cable length (ft)
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            Yes
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            -
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            -
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            400
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="px-4 py-2">
                                            Cable Clamps
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            Yes
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            -
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            -
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            180
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="px-4 py-2">
                                            Cable Clamps
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            Yes
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            -
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            -
                                        </td>
                                        <td className="px-4 py-2 text-right">
                                            180
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                    <div className="flex flex-col gap-1 text-sm text-neutral-grey">
                        <h5 className="font-bold">Note</h5>
                        <p className='font-normal'>lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                    </div>
                </div>}
        </div>
    )
}

export default SizingSchematic
