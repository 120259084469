interface LocalStorageConstant {
  access_token: string;
  refresh_token: string;
  userName: string;
  userRole: string;
  adminName: string;
  userAddresses: string;
  rememberedCredentials: string;
  expires_in: string;
  correlationId: string;
}

const LocalStorageConstant: LocalStorageConstant = {
  access_token: `${process.env.REACT_APP_AUTH_STORAGE_KEY}access-token`,
  refresh_token: `${process.env.REACT_APP_AUTH_STORAGE_KEY}refresh-token`,
  userName: `${process.env.REACT_APP_AUTH_STORAGE_KEY}user`,
  userRole: `${process.env.REACT_APP_AUTH_STORAGE_KEY}userRole`,
  adminName: `${process.env.REACT_APP_AUTH_STORAGE_KEY}adminName`,
  userAddresses: `${process.env.REACT_APP_AUTH_STORAGE_KEY}user-addresses`,
  rememberedCredentials: `${process.env.REACT_APP_AUTH_STORAGE_KEY}rememberedCredentials`,
  expires_in: `${process.env.REACT_APP_AUTH_STORAGE_KEY}expiresIn`,
  correlationId: `${process.env.REACT_APP_AUTH_STORAGE_KEY}correlationId`,
};

export default LocalStorageConstant;
