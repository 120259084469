import React, { ChangeEvent, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import VerticalTab from "../common/verticalTabs/VerticalTab";
import { useDispatch, useSelector } from "react-redux";
import {
  createDifaDismantleRequest,
  fetchDismantlePumps,
  getDismantleRequestByDifa,
  resetState,
} from "./DismantleRequestSlice";
import Header from "./components/Header";
import "./DismantleRequest.scss";
import { CreateDismantleRequestPayload } from "../models/DismantleRequest";
import NotificationToaster from "../common/notificationToast/NotificationToaster";
import Loader from "../common/loader/Loader";
import { formatDate } from "../common/services/UtilityService";
import useToaster from "../hooks/useToaster";
import { fetchInboundTestReport } from "../DifaPages/DifaDetailsSlice";

const DismantleRequest = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { difaId, customerName, wellName, wellApiNumber } =
    location.state || {};
  const [selectedData, setSelectedData] =
    useState<CreateDismantleRequestPayload>({
      dismantleRequests: [],
      difaId: "",
      wellName: "",
      wellApiNumber: "",
      comment: "",
    });
  const [isButtonDisable, setIsButtonDisable] = useState(false);
  const { toast, addToast, onClose } = useToaster();
  const DELAY_TIME = 4500;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSelectionChange = (data: CreateDismantleRequestPayload) => {
    setSelectedData(data);
  };

  const handleNote = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setSelectedData({ ...selectedData, comment: e.target.value });
  };

  const isDataSelected = selectedData?.dismantleRequests?.every(
    (data) => data?.dismantleRequested === null
  );
  const { getDismantleRequest, loading, success, message } = useSelector(
    (state: any) => state?.dismantleRequest
  );

  const { inboundTestReport } = useSelector((state: any) => state.difaDetails);

  useEffect(() => {
    dispatch(fetchDismantlePumps({ difaId }));
    if(!inboundTestReport) {
      dispatch(fetchInboundTestReport({difaId}));
    }
  }, [dispatch, difaId, inboundTestReport]);

  useEffect(() => {
    dispatch(
      getDismantleRequestByDifa({ dismantleRequestId: difaId, wellApiNumber })
    );
  }, [dispatch, difaId, wellApiNumber]);

  const handleCreateRequest = () => {
    dispatch(createDifaDismantleRequest(selectedData));
  };

  useEffect(() => {
    if (success && !message) {
      setIsButtonDisable(true);
      addToast("success", "Dismantle request sent successfully!");
      setTimeout(() => {
        navigate("/difa-details", {
          state: { difaId: difaId, customerName: customerName, wellName },
        });
      }, DELAY_TIME);
    } else if (!success && message) {
      console.error("Failed to create request.", message);
      addToast("error", "Failed to create request.");
    }
  }, [success, message]);

  useEffect(() => {
    return () => {
      dispatch(resetState()); // Reset state on unmount
    };
  }, [dispatch]);

  const openInboundReport = () => {
    const pdfUrl =  inboundTestReport && inboundTestReport?.fileLink;
    if (pdfUrl) {
      window.open(pdfUrl, "_blank");
    };
  }

  return (
    <div className="dismentle-request-details">
      <Header
        headerName="Dismantle request"
        customerName={customerName}
        wellName={wellName}
      />
      <div className="dismantle-request-content">
        <div className="dismantle-request-card">
          <div className="card-header">
            <div className="card-header-content">
              <div className="card-title">Dismantle request</div>
              <div className="card-discription">
                Select the parts of the ESP that are needed for the DIFA
                inspection
              </div>
            </div>
            <div className="">
              <button className="card-header-button button-text" onClick={openInboundReport} disabled={!inboundTestReport}>View inbound testing report</button>
            </div>
          </div>
          {getDismantleRequest && getDismantleRequest?.length > 0 && (
            <div className="dismantledateAndTime">
              Dismantle request sent on{" "}
              {formatDate(getDismantleRequest[0]?.modifiedDate)}. Following
              equipment are marked to be dismantled.
            </div>
          )}
          <div className="card-tab-container">
            <div className="card-tab-section">
              {loading && <Loader isOverlay />}
              <VerticalTab
                difaId={difaId}
                wellName={wellName}
                wellApiNumber={wellApiNumber}
                onSelectionChange={handleSelectionChange}
                isCheckboxVisible
              />
            </div>
            <div className="card-notes">
              <div className="notes-heading">Additional notes</div>
              <textarea
                className="card-textarea"
                maxLength={500}
                onChange={handleNote}
                value={selectedData?.comment}
                placeholder="Any additional notes here..."
              ></textarea>
            </div>
          </div>
          <div className="ds-card-footer">
            <button
              className="footer-submit-button"
              onClick={handleCreateRequest}
              disabled={isDataSelected || loading || isButtonDisable}
            >
              Submit request
            </button>
          </div>
          {toast && (
            <NotificationToaster
              type={toast?.type}
              message={toast?.message}
              onClose={() => onClose()}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default DismantleRequest;
