interface GrantTypeConstant {
  form: string;
  refreshToken: string;
}

const GrantTypeConstant: any = {
  form: 'sso_external',
  refreshToken: 'refresh_token',
};

export default GrantTypeConstant;
