// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tab-section {
  padding: 0 24px;
  padding-top: 16px;
  border-bottom: 1px solid #4a5463;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}
.tab-section .tabs {
  display: flex;
  justify-content: start;
  align-items: start;
  gap: 24px;
}
.tab-section .tab {
  padding: 1px 4px 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f7f9f9;
  font-size: 1rem;
  font-weight: 600;
  font-family: "Mulish", sans-serif;
  cursor: pointer;
}
.tab-section .tab.active {
  border-bottom: 2px solid #fa6020;
  color: #60bfda;
}`, "",{"version":3,"sources":["webpack://./src/features/common/horizontalTabs/tab.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,iBAAA;EACA,gCAAA;EACA,aAAA;EACA,2BAAA;EACA,qBAAA;AACJ;AACI;EACI,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,SAAA;AACR;AAEI;EACI,qBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;EACA,iCAAA;EACA,eAAA;AAAR;AAGI;EACI,gCAAA;EACA,cAAA;AADR","sourcesContent":[".tab-section {\n    padding: 0 24px;\n    padding-top: 16px;\n    border-bottom: 1px solid #4a5463;\n    display: flex;\n    justify-content: flex-start;\n    align-items: flex-end;\n\n    .tabs {\n        display: flex;\n        justify-content: start;\n        align-items: start;\n        gap: 24px;\n    }\n\n    .tab {\n        padding: 1px 4px 11px;\n        display: flex;\n        justify-content: center;\n        align-items: center;\n        color: #f7f9f9;\n        font-size: 1rem;\n        font-weight: 600;\n        font-family: 'Mulish', sans-serif;\n        cursor: pointer;\n    }\n\n    .tab.active {\n        border-bottom: 2px solid #fa6020;\n        color: #60bfda;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
