import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getDifaDetails, getInboundTestReport, getReportStatus } from "./DifaDetailsServices";

export const fetchDifaDetails: any = createAsyncThunk('dasboard/fetchDifaDetails', async ({difaId}: {difaId: string}) => {
    const response = await getDifaDetails(difaId);
    return response;
});

export const fetchInboundTestReport: any = createAsyncThunk('dasboard/fetchInboundTestReport', async ({difaId}: {difaId: string}) => {
    const response = await getInboundTestReport(difaId);
    return response;
});

export const fetchReportStatus: any = createAsyncThunk('dasboard/fetchReportStatus', async ({ wellApiNumber, difaId }: { wellApiNumber: string, difaId: string }) => {
    const response = await getReportStatus(wellApiNumber, difaId);
    return response;
});

const initialState: any = {
    loading: false,
    difaDetails: {},
    inboundTestReport: null,
    message: null,
    reportStatus: null
};

const difaDetailsSlice = createSlice({
    name: 'difaDetails',
    initialState,
    reducers: {},
    extraReducers: (builder: any) => {
        //pending
        builder.addCase(fetchDifaDetails.pending, (state: any) => {
            state.loading = true;
        });

        //fullfilled
        builder.addCase(fetchDifaDetails.fulfilled, (state: any, action: any) => {
            state.difaDetails = action.payload;
            state.loading = false;
        });

        //rejected
        builder.addCase(fetchDifaDetails.rejected, (state: any, action: any) => {
            state.message = action.payload;
            state.loading = false;
        });

        //pending
        builder.addCase(fetchInboundTestReport.pending, (state: any) => {
            state.loading = true;
        });

        //fullfilled
        builder.addCase(fetchInboundTestReport.fulfilled, (state: any, action: any) => {
            state.inboundTestReport = action.payload;
            state.loading = false;
        });

        //rejected
        builder.addCase(fetchInboundTestReport.rejected, (state: any, action: any) => {
            state.message = action.payload;
            state.loading = false;
        });

        builder.addCase(fetchReportStatus.pending, (state: any) => {
            state.loading = true;
        });


        builder.addCase(fetchReportStatus.fulfilled, (state: any, action: any) => {
            state.reportStatus = action.payload;
            state.loading = false;
        });

        //rejected
        builder.addCase(fetchReportStatus.rejected, (state: any, action: any) => {
            state.message = action.payload;
            state.loading = false;
        });
    }
})

export default difaDetailsSlice.reducer;