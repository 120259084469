import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getSizingSummary } from "./difaPacketBuilderServices";

export const fetchRundaysDetails: any = createAsyncThunk(
    "packet-builder/getSizingSummary",
    async ({ wellApiNumber }: { wellApiNumber: string }) => {
        return await getSizingSummary(wellApiNumber);
    }
);

const initialState: any = {
    loading: false,
    sizingSummary: null,
    message: null,
};

const DifaPacketBuilderSlice = createSlice({
    name: "difaPacketBuilder",
    initialState,
    reducers: {},
    extraReducers: (builder: any) => {
        builder.addCase(fetchRundaysDetails.pending, (state: any) => {
            state.loading = true;
        });

        //fullfilled
        builder.addCase(fetchRundaysDetails.fulfilled, (state: any, action: any) => {
            state.sizingSummary = action.payload;
            state.loading = false;
        });

        //rejected
        builder.addCase(fetchRundaysDetails.rejected, (state: any, action: any) => {
            state.message = action.payload;
            state.loading = false;
        });
    },
});

export default DifaPacketBuilderSlice.reducer;