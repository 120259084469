import React, { useState } from 'react';
import UploadDocuments from '../../../../common/uploadFiles/UploadDocuments';
import { useDispatch, useSelector } from 'react-redux';
import { setWellInstallation } from '../../../difaPacketBuilderSlice';
import Helpers from '../../../../../utilities/Helpers';

const AdditionalDocument = () => {
  const dispatch = useDispatch();
  const { wellInstallation } = useSelector(
    (state: any) => state.difaPacketBuilder
  );
  

  const [uploadedFiles, setUploadedFiles] = useState<File[]>(
    wellInstallation?.additionalDocuments ? [wellInstallation.additionalDocuments] : []
  );
  
  async function handleFilesChange(files: File[]): Promise<void> {
    setUploadedFiles(files);
    files?.length ? dispatch(setWellInstallation({ ...wellInstallation, additionalDocuments: {name: files[0].name,
      blob: await Helpers.convertFiletoBase64(files[0]) }})) :
      dispatch(setWellInstallation({ ...wellInstallation, additionalDocuments: null}));
  }

  return (
    <div className="block w-full">
      <UploadDocuments
        fileType="pdf"
        maxFileSize={100} // Max size: 100 MB
        onFilesChange={handleFilesChange}
        uploadedFiles={uploadedFiles}
      />
    </div>
  )
}

export default AdditionalDocument
