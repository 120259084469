import React from "react";
import "./Header.scss";
import { HeaderProps } from "./HeaderProps";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ArrowRightIcon } from "../../../images/chevron-right.svg";
import { useSelector } from "react-redux";
import RouteConstant from "../../../constants/routes";

const Header: React.FC<HeaderProps> = ({ headerName, customerName }) => {
  const navigate = useNavigate();

  const dashboard = () => {
    navigate("/dashboard");
  };

  const { difaDetails } = useSelector((state: any) => state.difaDetails);

  const redirectToWellName = () => {
    difaDetails?.wellName && navigate(
      RouteConstant.wellName.replace(
        ":wellName",
        encodeURIComponent(difaDetails?.wellName)),
      {
        state: { difaId: difaDetails?.difaId, customerName: customerName },
      });
  };

  return (
    <header className="main-header">
      <div className="header-content">
        <div className="breadcrumb-container">
          <div className="breadcrumb-item" onClick={dashboard}>
            Dashboard
          </div>
          <div className="breadcrumb-separator">
            <ArrowRightIcon />
          </div>
          <div className="breadcrumb-item active">{headerName}</div>
        </div>
        <div className="header-details">
          <div className="header-details-content">
            <div className="header-title">{headerName}</div>
            <div className="header-meta">
              <div className="meta-company">{customerName} |</div>
              <div className="meta-project" onClick = {redirectToWellName} >
                {difaDetails.wellName}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="divider"></div>
    </header>
  );
};

export default Header;
